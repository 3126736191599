import * as R from 'ramda';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as loginActions from './redux/actions';
import { env } from "../../../api/env/index";
import { useSearchParams } from 'react-router-dom';
import { Box, Grid, LinearProgress, Paper } from '@mui/material';
import { useAuth } from '../../../navigations/App';

export default function Login() {
    const { onLogin, onLogout } = useAuth();
    const isMounted = useRef(false);
    const message: string = useSelector((state: any) => state.login.message);
    const isSigned: boolean = useSelector((state: any) => state.login.isSigned);
    const idCliente: any = useSelector((state: any) => state.login.id);

    const [searchParams, setSearchParams] = useSearchParams();
    const pToken: string | null = searchParams.get("token")
    const dispatch = useDispatch();
    useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true;
            dispatch(loginActions.requestLoginByToken(pToken));
        }
        if (isSigned) {
            setTimeout(() => {
                onLogin(idCliente);
            }, 1000);
        }
    }, [isSigned]);

    if (R.isNil(pToken)) {
        setTimeout(() => {
            dispatch(loginActions.requestLogout());
            onLogout();
        }, 1000);
    }

    return (
        <Fragment>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '100vh' }}>
                <Grid item xs={5}>
                    <Paper elevation={24} sx={{ p: 2 }} >
                        <Box sx={{ color: 'text.secondary' }}>{message}</Box>
                        <Box sx={{ width: '100%', pt: 2 }}>
                            <LinearProgress color='info' />
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Fragment>
    );
}