import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, CircularProgress, Dialog, DialogContent, DialogContentText, Tooltip, Typography } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import * as disposicionesActions from './redux/actions';
import { IDisposicionesRequest } from '../../../models/IDisposicionesRequest';
import * as R from 'ramda';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { DatosDisposiciones } from '../../../models/IDisposicionesResponse';
import { getNumberToCurrency } from '../../../utils';
import theme from '../../../theme';
import { IExportarArchivoDisposicionesRequest } from '../../../models/IExportarArchivoRequest';
import { IExportarArchivoResponse } from '../../../models/IExportarArchivoResponse';
import { TableLoader } from '../../loader/TableLoader';
import { TableNotFoundData } from '../../notFoundData/TableNotFoundData';
import moment from 'moment';
import 'moment/locale/es';
import { StyledTableCell, StyledTableRow } from '../../styledTables/StyledTables';
moment.locale('es');

export default function Disposiciones(dataSend: IDisposicionesRequest) {
    let totalesDisposiciones: any = [];
    let numRegistros: Array<any> = [];

    let datos = <TableLoader colums={8} text={'Buscando disposiciones...'} />;

    let exportarArchivoSend: IExportarArchivoDisposicionesRequest = {
        TipoExportacion: '',
        IdForoCondiciones: '',
        IdCliente: '',
        IdTipoFinanciamiento: ''
    }

    const dispatch = useDispatch();
    const disposicionesRequest = useCallback(() => dispatch(disposicionesActions.requestDisposiciones(dataSend)), [dispatch, dataSend]);
    const archivoDisposicionesRequest = useCallback(() => dispatch(disposicionesActions.requestExportarArchivoDisposiciones(exportarArchivoSend)), [dispatch, exportarArchivoSend]);

    React.useEffect(() => {
        disposicionesRequest();
    }, []);

    const datosArchivo: IExportarArchivoResponse = useSelector((state: any) => state.exportarArchivoDisposiciones);
    const inProgressArchivo: any = useSelector((state: any) => state.exportarArchivoDisposiciones.inProgress);
    const IdCliente: any = useSelector((state: any) => state.login.id);

    let mensajeGeneracionArchivo = <Typography variant='body1'>Generando...</Typography>

    const [openGeneracionArchivo, setOpenGeneracionArchivo] = React.useState(false);
    const datosDisposiciones: any = useSelector((state: any) => state.disposiciones.data);
    const inProgress: any = useSelector((state: any) => state.disposiciones.inProgress);

    if (!R.isNil(datosDisposiciones && !inProgress)) {
        if (!R.isNil(datosDisposiciones.disposicionesAP)) {
            totalesDisposiciones = datosDisposiciones.disposicionesAP.filter((e: DatosDisposiciones) => e.numeroDisposicion != 0);
            numRegistros = datosDisposiciones.disposicionesAP.filter((e: DatosDisposiciones) => e.numeroDisposicion != 0);
            datos = datosDisposiciones.disposicionesAP.filter((e: DatosDisposiciones) => e.numeroDisposicion != 0).map((disposicion: DatosDisposiciones) =>
                <StyledTableRow
                    key={disposicion.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <StyledTableCell>{disposicion.claveProductoInterna}</StyledTableCell>
                    <StyledTableCell>{disposicion.numeroDisposicion}</StyledTableCell>
                    <StyledTableCell>{getNumberToCurrency(disposicion.saldoMontoDispuesto)}</StyledTableCell>
                    <StyledTableCell>{getNumberToCurrency(disposicion.capitalAAmortizar)}</StyledTableCell>
                    <StyledTableCell>{disposicion.fechaSolicitud != null ? moment(disposicion.fechaSolicitud).format('dddd LL') : ""}</StyledTableCell>
                    <StyledTableCell>{disposicion.fechaDispersion != null ? moment(disposicion.fechaDispersion).format('dddd LL') : ""}</StyledTableCell>
                    <StyledTableCell>{disposicion.claveRastreo}</StyledTableCell>
                    <StyledTableCell>{disposicion.estatusTransaccion}</StyledTableCell>
                </StyledTableRow>)
        }
        else if (!R.isNil(datosDisposiciones.disposicionesCS)) {
            totalesDisposiciones = datosDisposiciones.disposicionesCS.filter((e: DatosDisposiciones) => e.numeroDisposicion != 0);
            numRegistros = datosDisposiciones.disposicionesCS.filter((e: DatosDisposiciones) => e.numeroDisposicion != 0);
            datos = datosDisposiciones.disposicionesCS.filter((e: DatosDisposiciones) => e.numeroDisposicion != 0).map((disposicion: DatosDisposiciones) =>
                <StyledTableRow
                    key={disposicion.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <StyledTableCell>{disposicion.claveProductoInterna}</StyledTableCell>
                    <StyledTableCell>{disposicion.numeroDisposicion}</StyledTableCell>
                    <StyledTableCell>{getNumberToCurrency(disposicion.saldoMontoDispuesto)}</StyledTableCell>
                    <StyledTableCell>{getNumberToCurrency(disposicion.saldoMontoDispuesto)}</StyledTableCell>
                    <StyledTableCell>{disposicion.fechaSolicitud != null ? moment(disposicion.fechaSolicitud).format('dddd LL') : ""}</StyledTableCell>
                    <StyledTableCell>{disposicion.fechaDispersion != null ? moment(disposicion.fechaDispersion).format('dddd LL') : ""}</StyledTableCell>
                    <StyledTableCell>{disposicion.claveRastreo}</StyledTableCell>
                    <StyledTableCell>{disposicion.estatusTransaccion}</StyledTableCell>
                </StyledTableRow>)
        }
        else if (!R.isNil(datosDisposiciones.disposicionesCC)) {
            totalesDisposiciones = datosDisposiciones.disposicionesCC.filter((e: DatosDisposiciones) => e.numeroDisposicion != 0);
            numRegistros = datosDisposiciones.disposicionesCC.filter((e: DatosDisposiciones) => e.numeroDisposicion != 0);
            datos = datosDisposiciones.disposicionesCC.filter((e: DatosDisposiciones) => e.numeroDisposicion != 0).map((disposicion: DatosDisposiciones) =>
                <StyledTableRow
                    key={disposicion.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <StyledTableCell>{disposicion.claveProductoInterna}</StyledTableCell>
                    <StyledTableCell>{disposicion.numeroDisposicion}</StyledTableCell>
                    <StyledTableCell>{getNumberToCurrency(disposicion.saldoMontoDispuesto)}</StyledTableCell>
                    <StyledTableCell>S/A</StyledTableCell>
                    <StyledTableCell>{disposicion.fechaSolicitud != null ? moment(disposicion.fechaSolicitud).format('dddd LL') : ""}</StyledTableCell>
                    <StyledTableCell>{disposicion.fechaDispersion != null ? moment(disposicion.fechaDispersion).format('dddd LL') : ""}</StyledTableCell>
                    <StyledTableCell>{disposicion.claveRastreo}</StyledTableCell>
                    <StyledTableCell>{disposicion.estatusTransaccion}</StyledTableCell>
                </StyledTableRow>)
        }
    }
    if (inProgress) {
        totalesDisposiciones = [];
        numRegistros = [];
        datos = <TableLoader colums={8} text={'Buscando disposiciones...'} />;
    }
    if (datosDisposiciones?.length == 0) {
        datos = <TableNotFoundData colums={8} text={'No se encontraron disposiciones.'} />;
    }

    if (!R.isNil(datosArchivo) && !inProgressArchivo) {
        mensajeGeneracionArchivo =
            <Typography sx={{ color: datosArchivo.succeeded ? 'success.main' : 'error.main' }}>{datosArchivo.message}</Typography>
        setTimeout(() => {
            setOpenGeneracionArchivo(false);
        }, 3000);
    }

    function PopUpGeneracionArchivo() {
        return (
            <Dialog
                key={'archivoExportado'}
                open={openGeneracionArchivo}
                fullWidth={true}
                maxWidth={'xs'}>
                <DialogContent sx={{ m: 2, textAlign: 'center' }} id="dialogContent">
                    <DialogContentText>
                        {mensajeGeneracionArchivo}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }

    const exportarArchivoDisposiciones = (tipoExportacion: string) => {
        exportarArchivoSend = {
            TipoExportacion: tipoExportacion,
            IdForoCondiciones: dataSend.IdForoCondiciones,
            IdCliente: IdCliente,
            IdTipoFinanciamiento: dataSend.IdTipoFinanciamiento
        }
        setOpenGeneracionArchivo(true)
        archivoDisposicionesRequest();
    }

    function TablaDisposiciones() {
        return (
            <><Box sx={{ mb: 2, float: 'right' }}>
                <Tooltip arrow title={numRegistros.length > 0 ? '' : 'No se podrá exportar la tabla si no hay Disposiciones.'}>
                    <Button sx={{ ml: 2, color: numRegistros.length > 0 ? '#16a0ff' : 'error.main' }}
                        disabled={inProgress}
                        onClick={() => exportarArchivoDisposiciones('XLS')}>
                        <FileDownloadOutlinedIcon sx={{ mr: 1 }} />Exportar excel</Button>
                </Tooltip>
                <Tooltip arrow title={numRegistros.length > 0 ? '' : 'No se podrá exportar la tabla si no hay Disposiciones.'}>
                    <Button sx={{ ml: 2, color: numRegistros.length > 0 ? '#16a0ff' : 'error.main' }}
                        disabled={inProgress}
                        onClick={() => exportarArchivoDisposiciones('PDF')}>
                        <FileDownloadOutlinedIcon sx={{ mr: 1 }} />Exportar PDF</Button>
                </Tooltip>
            </Box>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 800 }} aria-label="customized table">
                        <TableBody>
                            <TableRow>
                                <TableCell><b>Clave interna</b></TableCell>
                                <TableCell><b>Número de disposición</b></TableCell>
                                <TableCell><b>Monto a dispersar</b></TableCell>
                                <TableCell><b>Capital a amortizar</b></TableCell>
                                <TableCell><b>Fecha de la solicitud</b></TableCell>
                                <TableCell><b>Fecha de la dispersión</b></TableCell>
                                <TableCell><b>Clave de rastreo</b></TableCell>
                                <TableCell><b>Estatus</b></TableCell>
                            </TableRow>
                            <TableRow sx={{ backgroundColor: '#bae3ff' }}>
                                <TableCell><b>Totales:</b></TableCell>
                                <TableCell><b>{totalesDisposiciones.reduce((a: number, b: DatosDisposiciones) => a + 1, 0)}</b></TableCell>
                                <TableCell><b>{getNumberToCurrency(totalesDisposiciones.reduce((a: number, b: DatosDisposiciones) => a + b.saldoMontoDispuesto, 0))}</b></TableCell>
                                <TableCell><b>{getNumberToCurrency(totalesDisposiciones.reduce((a: number, b: DatosDisposiciones) => a + (b.capitalAAmortizar ?? b.saldoMontoDispuesto), 0))}</b></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            {datos}
                        </TableBody>
                    </Table>
                </TableContainer></>
        );
    }

    return (
        <><Typography variant="h6" color="#2E2D2E" sx={{ mt: 2 }}>Disposiciones</Typography>
            <TablaDisposiciones />
            <PopUpGeneracionArchivo /></>
    );
}