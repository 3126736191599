import * as type from '../../../../system/types';
import * as actions from '../../../../system/types';

export function requestValorTiieActual(data: any): actions.requestValorTiieActualAction {
    return {
        type: type.VALORTIIEACTUAL_REQUEST,
        inProgress: true,
        data
    };
}

export function onValorTiieActualResponse(
    data: number,
    message: string
): actions.onValorTiieActualResponseAction {
    return {
        type: type.VALORTIIEACTUAL_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onValorTiieActualFailed(
    data: any,
    message: string
): actions.getValorTiieActualFailedAction {
    return {
        type: type.VALORTIIEACTUAL_FAILED,
        message,
        inProgress: false
    };
}