// import { createStore, applyMiddleware, compose } from 'redux';
// import createSagaMiddleware from 'redux-saga';
// import { persistStore, persistCombineReducers } from 'redux-persist';
// import storage from 'redux-persist/es/storage';
// import rootSagas from './sagas';
// /* Reducers */
// import rootReducers from './reducers';
// import { env } from '../api/env';

// const isLocalhost = Boolean(
//     window.location.hostname === 'localhost' ||
//     // [::1] is the IPv6 localhost address.
//     window.location.hostname === '[::1]' ||
//     // 127.0.0.1/8 is considered localhost for IPv4.
//     window.location.hostname.match(
//         /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
//     )
// );
// /* Redux-Persist */
// const rootReducer = persistCombineReducers({
//     key: 'root',
//     storage,
// }, rootReducers);
// const middlewares = [];
// const enhancers = [];
// /* Saga */
// const sagaMonitor = env.NODE_ENV == 'development' ? console.tron.createSagaMonitor() : null;
// const sagaMiddleware = createSagaMiddleware({ sagaMonitor });
// middlewares.push(sagaMiddleware);
// enhancers.push(applyMiddleware(...middlewares));
// /* Create Store */
// const createAppropriateStore = process.env.NODE_ENV == 'dev' ? console.tron.createStore : createStore;
// export const store = createAppropriateStore(
//     rootReducer,
//     compose(...enhancers),
// );
// /* Redux-Persist + Store */
// export const persistor = persistStore(store);
// /* Run saga */
// sagaMiddleware.run(rootSagas)
/*
import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from './reducers';
import rootSaga from './sagas';
import logger from "redux-logger";

const sagaMiddleware = createSagaMiddleware();

const configureStore = () => {
    const store = createStore(rootReducer, applyMiddleware(sagaMiddleware, logger));
    sagaMiddleware.run(rootSaga);
    return store;
};

export default configureStore;
*/

// configureStore.js

import createSagaMiddleware from 'redux-saga';
import logger from "redux-logger";
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootSaga from './sagas';
import globalReducer from './reducers';
import { applyMiddleware, createStore } from '@reduxjs/toolkit';
import { env } from '../api/env';

const sagaMiddleware = createSagaMiddleware();

// const persistConfig = {
//     key: 'root',
//     storage,
// }

// const persistedReducer = persistReducer(persistConfig, globalReducer)

const configureStore = () => {
    // let store = createStore(persistedReducer, applyMiddleware(sagaMiddleware, logger));
    const isDevelopment = (env.NODE_ENV.toUpperCase() == 'DEVELOPMENT');
    const store = (isDevelopment ?
        createStore(globalReducer, applyMiddleware(sagaMiddleware, logger)) :
        createStore(globalReducer, applyMiddleware(sagaMiddleware))
    );
    sagaMiddleware.run(rootSaga);
    let persistor = persistStore(store)
    return { store, persistor }
};

export default configureStore;