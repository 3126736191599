import { CircularProgress, TableCell, TableRow, Typography } from "@mui/material";

type Props = {
    colums: number,
    text: string
}

export function TableLoader(props: Props) {
    return (<TableRow>
        <TableCell colSpan={props.colums}>
            <Typography align="center" sx={{ mb: 1 }}><CircularProgress /></Typography>
            <Typography align="center" sx={{ fontSize: 14 }}>{props.text}</Typography>
        </TableCell>
    </TableRow>);
}