import { IFinanciamientosRequest } from "../models/IFinanciamientosRequest";
import { IDisposicionesRequest } from "../models/IDisposicionesRequest";
import { IAmortizacionesRequest } from "../models/IAmortizacionesRequest";
import { IDatosFinanciamientoRequest } from "../models/IDatosFinanciamientoRequest";
import { IFacturasPaqueteRequest, IPaquetesFactorajeRequest } from "../models/IPaquetesFactorajeRequest";
import { DatosFinanciamientos } from "../models/IFinanciamientosResponse";
import { DatosDisposiciones } from "../models/IDisposicionesResponse";
import { DatosFinanciamiento } from "../models/IDatosFinanciamientoResponse";
import { DatosAmortizaciones } from "../models/IAmortizacionesResponse";
import { DatosFacturasPaquete, DatosPaquetesFactoraje } from "../models/IPaquetesFactorajeResponse";
import { IResponse } from "../models/IResponse";
import { ILoginRequest } from "../models/ILoginRequest";
import { IPagosRealizadosRequest } from "../models/IPagosRealizadosRequest";
import { DatosPagosRealizados } from "../models/IPagosRealizadosResponse";
import { IResumenCuentaCorrienteRequest } from "../models/IResumenCuentaCorrienteRequest";
import { DatosResumenCuentaCorriente } from "../models/IResumenCuentaCorrienteResponse";
import { IActivosArrendamientoRequest } from "../models/IActivosArrendamientoRequest";
import { DatosActivosArrendamiento } from "../models/IActivosArrendamientoResponse";
import { ICuentaDepositoRequest, IPostCuentaDepositoRequest } from "../models/ICuentaDepositoRequest";
import { DatosCuentaDeposito, DatosPostCuentaDeposito } from "../models/ICuentaDepositoResponse";
import { DatosInstitucionesBancarias } from "../models/catalogs/IInstitucionesBancariasResponse";
import { DatosTipoCuenta } from "../models/catalogs/ITipoCuentaResponse";
import { DatosCredentials, ICredentialsRequest, ICredentialsResponse } from "../models/ICredentials";
import { DatosWebHookStatus, IWebHookStatusRequest } from "../models/IWebHookStatusRequest";
import { IAmortizacionesActivoRequest } from "../models/IAmortizacionesActivoRequest";
import { DatosAmortizacionesActivo } from "../models/IAmortizacionesActivoResponse";
import { DatosFindValidStatus } from "../models/IFindValidStatusResponse";
import { IAmortizacionesAPagarRequest } from "../models/IAmortizacionesAPagarRequest";
import { DatosAmortizacionesAPagar } from "../models/IAmortizacionesAPagarResponse";
import { ICheckAccountBalanceRequest, IStpCompaniesRequest, ISubAccountMovimientosRequest, ISubAccountsRequest } from "../models/IStpCompaniesRequest";
import { DatosStpCompanies, DatosSubAccountMovimientos, DatosSubAccounts, ICheckAccountBalanceResponse } from "../models/IStpCompaniesResponse";
import { IGRPARequest, IGRPFRequest, IGRPRequest, ISimularPagoRequest } from "../models/ISimularPagoRequest";
import { DatosGRP, DatosSimularPago } from "../models/ISimularPagoResponse";
import { IExportarArchivoActivosRequest, IExportarArchivoAmortizacionesRequest, IExportarArchivoDisposicionesRequest, IExportarArchivoPagosRealizadosRequest } from "../models/IExportarArchivoRequest";
import { IPaquetesAPagarRequest } from "../models/IPaquetesAPagarRequest";
import { DatosPaquetesAPagar } from "../models/IPaquetesAPagarResponse";
import { DatosTipoActivo } from "../models/catalogs/ITipoActivoResponse";
import { DatosSubtipoActivo } from "../models/catalogs/ISubtipoActivoResponse";
import { DatosTipoArrendamiento } from "../models/catalogs/ITipoArrendamientoResponse";
import { DatosTipoPagoAP } from "../models/catalogs/ITipoPagoAPResponse";
import { DatosPeriodicidad } from "../models/catalogs/IPeriodicidadResponse";
import { DatosResultadoCotizadorAP } from "../models/cotizador/IResultadoCotizadorAPResponse";
import { DatosTipoPersona } from "../models/catalogs/ITipoPersonaResponse";
import { DatosTipoTasa } from "../models/catalogs/ITipoTasaResponse";
import { DatosTipoTasaMoratoria } from "../models/catalogs/ITipoTasaMoratoriaResponse";
import { DatosTipoCalculo } from "../models/catalogs/ITipoCalculoResponse";
import { DatosAmortizacionesCotizadorCS } from "../models/cotizador/IAmortizacionesCotizadorCSResponse";
import { IOpcionesContratoAPRequest, IResultadoOpcionContratoAPRequest } from "../models/IOpcionesContratoAPRequest";
import { DatosOpcionesContratoAP, DatosResultadoOpcionContratoAP } from "../models/IOpcionesContratoAPResponse";
import { IFilters } from "./reducers";

// CLIENTES //

//#region LOADER
export const ENABLE_LOADER = 'ENABLE_LOADER';
export interface getEnableLoaderAction {
    type: typeof ENABLE_LOADER;
    response: IResponse;
    totalLoaded: number;
}
export const DISABLE_LOADER = 'DISABLE_LOADER';
export interface getDisableLoaderAction {
    type: typeof DISABLE_LOADER;
    response: IResponse;
    totalLoaded: number;
}
export const SET_FILTERS = 'SET_FILTERS';
export interface setFiltersAction {
    type: typeof SET_FILTERS;
    filters: IFilters;
}
export const SET_FILTERS_FINANCIAMIENTO = 'SET_FILTERS_FINANCIAMIENTO';
export interface setFiltersFinanciamientoAction {
    type: typeof SET_FILTERS_FINANCIAMIENTO;
    idFinanciamientoSolicitado: string,
    idForoCondiciones: string
}
export const RESET_FILTERS = 'RESET_FILTERS';
export interface resetFiltersAction {
    type: typeof RESET_FILTERS;
}
//#endregion

//#region FINANCIAMIENTOS
export const FINANCIAMIENTOS_REQUEST = 'FINANCIAMIENTOS_REQUEST';
export interface requestFinanciamientosAction {
    type: typeof FINANCIAMIENTOS_REQUEST;
    data: IFinanciamientosRequest;
    inProgress: boolean;
}
export const FINANCIAMIENTOS_RESPONSE = 'FINANCIAMIENTOS_RESPONSE';
export interface onFinanciamientosResponseAction {
    type: typeof FINANCIAMIENTOS_RESPONSE;
    data: Array<DatosFinanciamientos>;
    message: string;
    inProgress: boolean;
}
export const FINANCIAMIENTOS_FAILED = 'FINANCIAMIENTOS_FAILED';
export interface getFinanciamientosFailedAction {
    type: typeof FINANCIAMIENTOS_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region DISPOSICIONES
export const DISPOSICIONES_REQUEST = 'DISPOSICIONES_REQUEST';
export interface requestDisposicionesAction {
    type: typeof DISPOSICIONES_REQUEST;
    data: IDisposicionesRequest;
    inProgress: boolean;
}
export const DISPOSICIONES_RESPONSE = 'DISPOSICIONES_RESPONSE';
export interface onDisposicionesResponseAction {
    type: typeof DISPOSICIONES_RESPONSE;
    data: Array<DatosDisposiciones>;
    message: string;
    inProgress: boolean;
}
export const DISPOSICIONES_FAILED = 'DISPOSICIONES_FAILED';
export interface getDisposicionesFailedAction {
    type: typeof DISPOSICIONES_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region AMORTIZACIONES
export const AMORTIZACIONES_REQUEST = 'AMORTIZACIONES_REQUEST';
export interface requestAmortizacionesAction {
    type: typeof AMORTIZACIONES_REQUEST;
    data: IAmortizacionesRequest;
    inProgress: boolean;
}
export const AMORTIZACIONES_RESPONSE = 'AMORTIZACIONES_RESPONSE';
export interface onAmortizacionesResponseAction {
    type: typeof AMORTIZACIONES_RESPONSE;
    data: Array<DatosAmortizaciones>;
    message: string;
    inProgress: boolean;
}
export const AMORTIZACIONES_FAILED = 'AMORTIZACIONES_FAILED';
export interface getAmortizacionesFailedAction {
    type: typeof AMORTIZACIONES_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region EXPORTAR ARCHIVO AMORTIZACIONES
export const EXPORTARARCHIVOAMORTIZACIONES_REQUEST = 'EXPORTARARCHIVOAMORTIZACIONES_REQUEST';
export interface requestExportarArchivoAmortizacionesAction {
    type: typeof EXPORTARARCHIVOAMORTIZACIONES_REQUEST;
    data: IExportarArchivoAmortizacionesRequest;
    succeeded: boolean;
    inProgress: boolean;
}
export const EXPORTARARCHIVOAMORTIZACIONES_RESPONSE = 'EXPORTARARCHIVOAMORTIZACIONES_RESPONSE';
export interface onExportarArchivoAmortizacionesResponseAction {
    type: typeof EXPORTARARCHIVOAMORTIZACIONES_RESPONSE;
    data: any;
    message: string;
    succeeded: boolean;
    inProgress: boolean;
}
export const EXPORTARARCHIVOAMORTIZACIONES_FAILED = 'EXPORTARARCHIVOAMORTIZACIONES_FAILED';
export interface getExportarArchivoAmortizacionesFailedAction {
    type: typeof EXPORTARARCHIVOAMORTIZACIONES_FAILED;
    message: string;
    succeeded: boolean;
    inProgress: boolean;
}
//#endregion

//#region EXPORTAR ARCHIVO PAGOS REALIZADOS
export const EXPORTARARCHIVOPAGOSREALIZADOS_REQUEST = 'EXPORTARARCHIVOPAGOSREALIZADOS_REQUEST';
export interface requestExportarArchivoPagosRealizadosAction {
    type: typeof EXPORTARARCHIVOPAGOSREALIZADOS_REQUEST;
    data: IExportarArchivoPagosRealizadosRequest;
    succeeded: boolean;
    inProgress: boolean;
}
export const EXPORTARARCHIVOPAGOSREALIZADOS_RESPONSE = 'EXPORTARARCHIVOPAGOSREALIZADOS_RESPONSE';
export interface onExportarArchivoPagosRealizadosResponseAction {
    type: typeof EXPORTARARCHIVOPAGOSREALIZADOS_RESPONSE;
    data: any;
    message: string;
    succeeded: boolean;
    inProgress: boolean;
}
export const EXPORTARARCHIVOPAGOSREALIZADOS_FAILED = 'EXPORTARARCHIVOPAGOSREALIZADOS_FAILED';
export interface getExportarArchivoPagosRealizadosFailedAction {
    type: typeof EXPORTARARCHIVOPAGOSREALIZADOS_FAILED;
    message: string;
    succeeded: boolean;
    inProgress: boolean;
}
//#endregion

//#region EXPORTAR ARCHIVO DISPOSICIONES
export const EXPORTARARCHIVODISPOSICIONES_REQUEST = 'EXPORTARARCHIVODISPOSICIONES_REQUEST';
export interface requestExportarArchivoDisposicionesAction {
    type: typeof EXPORTARARCHIVODISPOSICIONES_REQUEST;
    data: IExportarArchivoDisposicionesRequest;
    succeeded: boolean;
    inProgress: boolean;
}
export const EXPORTARARCHIVODISPOSICIONES_RESPONSE = 'EXPORTARARCHIVODISPOSICIONES_RESPONSE';
export interface onExportarArchivoDisposicionesResponseAction {
    type: typeof EXPORTARARCHIVODISPOSICIONES_RESPONSE;
    data: any;
    message: string;
    succeeded: boolean;
    inProgress: boolean;
}
export const EXPORTARARCHIVODISPOSICIONES_FAILED = 'EXPORTARARCHIVODISPOSICIONES_FAILED';
export interface getExportarArchivoDisposicionesFailedAction {
    type: typeof EXPORTARARCHIVODISPOSICIONES_FAILED;
    message: string;
    succeeded: boolean;
    inProgress: boolean;
}
//#endregion

//#region EXPORTAR ARCHIVO ACTIVOS
export const EXPORTARARCHIVOACTIVOS_REQUEST = 'EXPORTARARCHIVOACTIVOS_REQUEST';
export interface requestExportarArchivoActivosAction {
    type: typeof EXPORTARARCHIVOACTIVOS_REQUEST;
    data: IExportarArchivoActivosRequest;
    succeeded: boolean;
    inProgress: boolean;
}
export const EXPORTARARCHIVOACTIVOS_RESPONSE = 'EXPORTARARCHIVOACTIVOS_RESPONSE';
export interface onExportarArchivoActivosResponseAction {
    type: typeof EXPORTARARCHIVOACTIVOS_RESPONSE;
    data: any;
    message: string;
    succeeded: boolean;
    inProgress: boolean;
}
export const EXPORTARARCHIVOACTIVOS_FAILED = 'EXPORTARARCHIVOACTIVOS_FAILED';
export interface getExportarArchivoActivosFailedAction {
    type: typeof EXPORTARARCHIVOACTIVOS_FAILED;
    message: string;
    succeeded: boolean;
    inProgress: boolean;
}
//#endregion

//#region DATOSFINANCIAMIENTO
export const DATOSFINANCIAMIENTO_REQUEST = 'DATOSFINANCIAMIENTO_REQUEST';
export interface requestDatosFinanciamientoAction {
    type: typeof DATOSFINANCIAMIENTO_REQUEST;
    data: IDatosFinanciamientoRequest;
    inProgress: boolean;
}
export const DATOSFINANCIAMIENTO_RESPONSE = 'DATOSFINANCIAMIENTO_RESPONSE';
export interface onDatosFinanciamientoResponseAction {
    type: typeof DATOSFINANCIAMIENTO_RESPONSE;
    data: DatosFinanciamiento;
    message: string;
    inProgress: boolean;
}
export const DATOSFINANCIAMIENTO_FAILED = 'DATOSFINANCIAMIENTO_FAILED';
export interface getDatosFinanciamientoFailedAction {
    type: typeof DATOSFINANCIAMIENTO_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region PAQUETES FACTORAJE
export const PAQUETESFACTORAJE_REQUEST = 'PAQUETESFACTORAJE_REQUEST';
export interface requestPaquetesFactorajeAction {
    type: typeof PAQUETESFACTORAJE_REQUEST;
    data: IPaquetesFactorajeRequest;
    inProgress: boolean;
}
export const PAQUETESFACTORAJE_RESPONSE = 'PAQUETESFACTORAJE_RESPONSE';
export interface onPaquetesFactorajeResponseAction {
    type: typeof PAQUETESFACTORAJE_RESPONSE;
    data: Array<DatosPaquetesFactoraje>;
    message: string;
    inProgress: boolean;
}
export const PAQUETESFACTORAJE_FAILED = 'PAQUETESFACTORAJE_FAILED';
export interface getPaquetesFactorajeFailedAction {
    type: typeof PAQUETESFACTORAJE_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region FACTURAS PAQUETE
export const FACTURASPAQUETE_REQUEST = 'FACTURASPAQUETE_REQUEST';
export interface requestFacturasPaqueteAction {
    type: typeof FACTURASPAQUETE_REQUEST;
    data: IFacturasPaqueteRequest;
    inProgress: boolean;
}
export const FACTURASPAQUETE_RESPONSE = 'FACTURASPAQUETE_RESPONSE';
export interface onFacturasPaqueteResponseAction {
    type: typeof FACTURASPAQUETE_RESPONSE;
    data: Array<DatosFacturasPaquete>;
    message: string;
    inProgress: boolean;
}
export const FACTURASPAQUETE_FAILED = 'FACTURASPAQUETE_FAILED';
export interface getFacturasPaqueteFailedAction {
    type: typeof FACTURASPAQUETE_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region LOGIN
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export interface requestLoginAction {
    type: typeof LOGIN_REQUEST;
    data: ILoginRequest;
    inProgress: boolean;
}
export const LOGIN_RESPONSE = 'LOGIN_RESPONSE';
export interface onLoginResponseAction {
    type: typeof LOGIN_RESPONSE;
    data: boolean;
    message: string;
    inProgress: boolean;
}
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export interface requestLogoutAction {
    type: typeof LOGOUT_REQUEST;
    token: null,
}
export const LOGOUT_RESPONSE = 'LOGOUT_RESPONSE';
export interface onLogoutResponseAction {
    type: typeof LOGOUT_RESPONSE;
    app: null;
    uid: null;
    email: null;
    nombreUsuario: null;
    rfc: null;
    idTipoPersona: null;
    isSigned: boolean;
}
export const LOGIN_BY_TOKEN_REQUEST = 'LOGIN_BY_TOKEN_REQUEST';
export interface requestLoginByTokenAction {
    type: typeof LOGIN_BY_TOKEN_REQUEST;
    token: string | null,
}
export const LOGIN_BY_TOKEN_RESPONSE = 'LOGIN_BY_TOKEN_RESPONSE';
export interface onLoginByTokenAction {
    type: typeof LOGIN_BY_TOKEN_RESPONSE;
    app: string;
    uid: string;
    email: string;
    nombreUsuario: string;
    rfc: string;
    idTipoPersona: string;
    isSigned: boolean;
}
export const LOGIN_FAILED = 'LOGIN_FAILED';
export interface getLoginFailedAction {
    type: typeof LOGIN_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region PAGOS REALIZADOS
export const PAGOSREALIZADOS_REQUEST = 'PAGOSREALIZADOS_REQUEST';
export interface requestPagosRealizadosAction {
    type: typeof PAGOSREALIZADOS_REQUEST;
    data: IPagosRealizadosRequest;
    inProgress: boolean;
}
export const PAGOSREALIZADOS_RESPONSE = 'PAGOSREALIZADOS_RESPONSE';
export interface onPagosRealizadosResponseAction {
    type: typeof PAGOSREALIZADOS_RESPONSE;
    data: Array<DatosPagosRealizados>;
    message: string;
    inProgress: boolean;
}
export const PAGOSREALIZADOS_FAILED = 'PAGOSREALIZADOS_FAILED';
export interface getPagosRealizadosFailedAction {
    type: typeof PAGOSREALIZADOS_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region RESUMEN CUENTA CORRIENTE
export const RESUMENCUENTACORRIENTE_REQUEST = 'RESUMENCUENTACORRIENTE_REQUEST';
export interface requestResumenCuentaCorrienteAction {
    type: typeof RESUMENCUENTACORRIENTE_REQUEST;
    data: IResumenCuentaCorrienteRequest;
    inProgress: boolean;
}
export const RESUMENCUENTACORRIENTE_RESPONSE = 'RESUMENCUENTACORRIENTE_RESPONSE';
export interface onResumenCuentaCorrienteResponseAction {
    type: typeof RESUMENCUENTACORRIENTE_RESPONSE;
    data: Array<DatosResumenCuentaCorriente>;
    message: string;
    inProgress: boolean;
}
export const RESUMENCUENTACORRIENTE_FAILED = 'RESUMENCUENTACORRIENTE_FAILED';
export interface getResumenCuentaCorrienteFailedAction {
    type: typeof RESUMENCUENTACORRIENTE_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region ACTIVOS ARRENDAMIENTO
export const ACTIVOSARRENDAMIENTO_REQUEST = 'ACTIVOSARRENDAMIENTO_REQUEST';
export interface requestActivosArrendamientoAction {
    type: typeof ACTIVOSARRENDAMIENTO_REQUEST;
    data: IActivosArrendamientoRequest;
    inProgress: boolean;
}
export const ACTIVOSARRENDAMIENTO_RESPONSE = 'ACTIVOSARRENDAMIENTO_RESPONSE';
export interface onActivosArrendamientoResponseAction {
    type: typeof ACTIVOSARRENDAMIENTO_RESPONSE;
    data: Array<DatosActivosArrendamiento>;
    message: string;
    inProgress: boolean;
}
export const ACTIVOSARRENDAMIENTO_FAILED = 'ACTIVOSARRENDAMIENTO_FAILED';
export interface getActivosArrendamientoFailedAction {
    type: typeof ACTIVOSARRENDAMIENTO_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region AMORTIZACIONES ACTIVO
export const AMORTIZACIONESACTIVO_REQUEST = 'AMORTIZACIONESACTIVO_REQUEST';
export interface requestAmortizacionesActivoAction {
    type: typeof AMORTIZACIONESACTIVO_REQUEST;
    data: IAmortizacionesActivoRequest;
    inProgress: boolean;
}
export const AMORTIZACIONESACTIVO_RESPONSE = 'AMORTIZACIONESACTIVO_RESPONSE';
export interface onAmortizacionesActivoResponseAction {
    type: typeof AMORTIZACIONESACTIVO_RESPONSE;
    data: Array<DatosAmortizacionesActivo>;
    message: string;
    inProgress: boolean;
}
export const AMORTIZACIONESACTIVO_FAILED = 'AMORTIZACIONESACTIVO_FAILED';
export interface getAmortizacionesActivoFailedAction {
    type: typeof AMORTIZACIONESACTIVO_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region GET CUENTA DEPOSITO
export const CUENTADEPOSITO_REQUEST = 'CUENTADEPOSITO_REQUEST';
export interface requestCuentaDepositoAction {
    type: typeof CUENTADEPOSITO_REQUEST;
    data: ICuentaDepositoRequest;
    inProgress: boolean;
}
export const CUENTADEPOSITO_RESPONSE = 'CUENTADEPOSITO_RESPONSE';
export interface onCuentaDepositoResponseAction {
    type: typeof CUENTADEPOSITO_RESPONSE;
    data: DatosCuentaDeposito;
    message: string;
    inProgress: boolean;
}
export const CUENTADEPOSITO_FAILED = 'CUENTADEPOSITO_FAILED';
export interface getCuentaDepositoFailedAction {
    type: typeof CUENTADEPOSITO_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region POST CUENTA DEPOSITO
export const POSTCUENTADEPOSITO_REQUEST = 'POSTCUENTADEPOSITO_REQUEST';
export interface requestPostCuentaDepositoAction {
    type: typeof POSTCUENTADEPOSITO_REQUEST;
    data: IPostCuentaDepositoRequest;
    inProgress: boolean;
}
export const POSTCUENTADEPOSITO_RESPONSE = 'POSTCUENTADEPOSITO_RESPONSE';
export interface onPostCuentaDepositoResponseAction {
    type: typeof POSTCUENTADEPOSITO_RESPONSE;
    data: DatosPostCuentaDeposito;
    message: string;
    inProgress: boolean;
}
export const POSTCUENTADEPOSITO_FAILED = 'POSTCUENTADEPOSITO_FAILED';
export interface getPostCuentaDepositoFailedAction {
    type: typeof POSTCUENTADEPOSITO_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region FIND VALID STATUS
export const FINDVALIDSTATUS_REQUEST = 'FINDVALIDSTATUS_REQUEST';
export interface requestFindValidStatusAction {
    type: typeof FINDVALIDSTATUS_REQUEST;
    data: string;
    inProgress: boolean;
}
export const FINDVALIDSTATUS_RESPONSE = 'FINDVALIDSTATUS_RESPONSE';
export interface onFindValidStatusResponseAction {
    type: typeof FINDVALIDSTATUS_RESPONSE;
    data: DatosFindValidStatus;
    message: string;
    inProgress: boolean;
}
export const FINDVALIDSTATUS_FAILED = 'FINDVALIDSTATUS_FAILED';
export interface getFindValidStatusFailedAction {
    type: typeof FINDVALIDSTATUS_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region CREDENTIALS
export const CREDENTIALS_REQUEST = 'CREDENTIALS_REQUEST';
export interface requestCredentialsAction {
    type: typeof CREDENTIALS_REQUEST;
    data: ICredentialsRequest;
    message: string,
    inProgress: boolean;
}
export const CREDENTIALS_RESPONSE = 'CREDENTIALS_RESPONSE';
export interface onCredentialsResponseAction {
    type: typeof CREDENTIALS_RESPONSE;
    data: DatosCredentials;
    message: string;
    inProgress: boolean;
}
export const CREDENTIALS_FAILED = 'CREDENTIALS_FAILED';
export interface getCredentialsFailedAction {
    type: typeof CREDENTIALS_FAILED;
    message: string;
    data: ICredentialsResponse;
    inProgress: boolean;
}
//#endregion

//#region WEBHOOK STATUS
export const WEBHOOKSTATUS_REQUEST = 'WEBHOOKSTATUS_REQUEST';
export interface requestWebHookStatusAction {
    type: typeof WEBHOOKSTATUS_REQUEST;
    data: IWebHookStatusRequest;
    inProgress: boolean;
}
export const WEBHOOKSTATUS_RESPONSE = 'WEBHOOKSTATUS_RESPONSE';
export interface onWebHookStatusResponseAction {
    type: typeof WEBHOOKSTATUS_RESPONSE;
    data: DatosWebHookStatus;
    message: string;
    inProgress: boolean;
}
export const WEBHOOKSTATUS_FAILED = 'WEBHOOKSTATUS_FAILED';
export interface getWebHookStatusFailedAction {
    type: typeof WEBHOOKSTATUS_FAILED;
    message: string;
    inProgress: boolean;
}

export const WEBHOOKSTATUS_RESET = 'WEBHOOKSTATUS_RESET';
export interface getWebHookStatusResetAction {
    type: typeof WEBHOOKSTATUS_RESET;
    inProgress: boolean;
}
//#endregion

//#region AMORTIZACIONES A PAGAR
export const AMORTIZACIONESAPAGAR_REQUEST = 'AMORTIZACIONESAPAGAR_REQUEST';
export interface requestAmortizacionesAPagarAction {
    type: typeof AMORTIZACIONESAPAGAR_REQUEST;
    data: IAmortizacionesAPagarRequest;
    inProgress: boolean;
}
export const AMORTIZACIONESAPAGAR_RESPONSE = 'AMORTIZACIONESAPAGAR_RESPONSE';
export interface onAmortizacionesAPagarResponseAction {
    type: typeof AMORTIZACIONESAPAGAR_RESPONSE;
    data: Array<DatosAmortizacionesAPagar>;
    message: string;
    inProgress: boolean;
}
export const AMORTIZACIONESAPAGAR_FAILED = 'AMORTIZACIONESAPAGAR_FAILED';
export interface getAmortizacionesAPagarFailedAction {
    type: typeof AMORTIZACIONESAPAGAR_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region SIMULAR PAGO
export const SIMULARPAGO_REQUEST = 'SIMULARPAGO_REQUEST';
export interface requestSimularPagoAction {
    type: typeof SIMULARPAGO_REQUEST;
    data: ISimularPagoRequest;
    inProgress: boolean;
}
export const SIMULARPAGO_RESPONSE = 'SIMULARPAGO_RESPONSE';
export interface onSimularPagoResponseAction {
    type: typeof SIMULARPAGO_RESPONSE;
    data: DatosSimularPago;
    message: string;
    inProgress: boolean;
}
export const SIMULARPAGO_FAILED = 'SIMULARPAGO_FAILED';
export interface getSimularPagoFailedAction {
    type: typeof SIMULARPAGO_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region GENERAR REFERENCIA DE PAGO
export const GENERARREFERENCIAPAGO_REQUEST = 'GENERARREFERENCIAPAGO_REQUEST';
export interface requestGenerarReferenciaPagoAction {
    type: typeof GENERARREFERENCIAPAGO_REQUEST;
    data: IGRPRequest;
    inProgress: boolean;
}
export const GENERARREFERENCIAPAGO_RESPONSE = 'GENERARREFERENCIAPAGO_RESPONSE';
export interface onGenerarReferenciaPagoResponseAction {
    type: typeof GENERARREFERENCIAPAGO_RESPONSE;
    data: DatosGRP;
    message: string;
    inProgress: boolean;
}
export const GENERARREFERENCIAPAGO_FAILED = 'GENERARREFERENCIAPAGO_FAILED';
export interface getGenerarReferenciaPagoFailedAction {
    type: typeof GENERARREFERENCIAPAGO_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region GENERAR REFERENCIA DE PAGO FACTORAJE
export const GENERARREFERENCIAPAGOFACTORAJE_REQUEST = 'GENERARREFERENCIAPAGOFACTORAJE_REQUEST';
export interface requestGenerarReferenciaPagoFactorajeAction {
    type: typeof GENERARREFERENCIAPAGOFACTORAJE_REQUEST;
    data: IGRPFRequest;
    inProgress: boolean;
}
export const GENERARREFERENCIAPAGOFACTORAJE_RESPONSE = 'GENERARREFERENCIAPAGOFACTORAJE_RESPONSE';
export interface onGenerarReferenciaPagoFactorajeResponseAction {
    type: typeof GENERARREFERENCIAPAGOFACTORAJE_RESPONSE;
    data: DatosGRP;
    message: string;
    inProgress: boolean;
}
export const GENERARREFERENCIAPAGOFACTORAJE_FAILED = 'GENERARREFERENCIAPAGOFACTORAJE_FAILED';
export interface getGenerarReferenciaPagoFactorajeFailedAction {
    type: typeof GENERARREFERENCIAPAGOFACTORAJE_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region GENERAR REFERENCIA DE PAGO ACTIVOS
export const GENERARREFERENCIAPAGOACTIVOS_REQUEST = 'GENERARREFERENCIAPAGOACTIVOS_REQUEST';
export interface requestGenerarReferenciaPagoActivosAction {
    type: typeof GENERARREFERENCIAPAGOACTIVOS_REQUEST;
    data: IGRPARequest;
    inProgress: boolean;
}
export const GENERARREFERENCIAPAGOACTIVOS_RESPONSE = 'GENERARREFERENCIAPAGOACTIVOS_RESPONSE';
export interface onGenerarReferenciaPagoActivosResponseAction {
    type: typeof GENERARREFERENCIAPAGOACTIVOS_RESPONSE;
    data: DatosGRP;
    message: string;
    inProgress: boolean;
}
export const GENERARREFERENCIAPAGOACTIVOS_FAILED = 'GENERARREFERENCIAPAGOACTIVOS_FAILED';
export interface getGenerarReferenciaPagoActivosFailedAction {
    type: typeof GENERARREFERENCIAPAGOACTIVOS_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region PAQUETES A PAGAR
export const PAQUETESAPAGAR_REQUEST = 'PAQUETESAPAGAR_REQUEST';
export interface requestPaquetesAPagarAction {
    type: typeof PAQUETESAPAGAR_REQUEST;
    data: IPaquetesAPagarRequest;
    inProgress: boolean;
}
export const PAQUETESAPAGAR_RESPONSE = 'PAQUETESAPAGAR_RESPONSE';
export interface onPaquetesAPagarResponseAction {
    type: typeof PAQUETESAPAGAR_RESPONSE;
    data: Array<DatosPaquetesAPagar>;
    message: string;
    inProgress: boolean;
}
export const PAQUETESAPAGAR_FAILED = 'PAQUETESAPAGAR_FAILED';
export interface getPaquetesAPagarFailedAction {
    type: typeof PAQUETESAPAGAR_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region RESULTADO OPCION CONTRATO AP
export const RESULTADOOPCIONCONTRATOAP_REQUEST = 'RESULTADOOPCIONCONTRATOAP_REQUEST';
export interface requestResultadoOpcionContratoAPAction {
    type: typeof RESULTADOOPCIONCONTRATOAP_REQUEST;
    data: IResultadoOpcionContratoAPRequest;
    inProgress: boolean;
}
export const RESULTADOOPCIONCONTRATOAP_RESPONSE = 'RESULTADOOPCIONCONTRATOAP_RESPONSE';
export interface onResultadoOpcionContratoAPResponseAction {
    type: typeof RESULTADOOPCIONCONTRATOAP_RESPONSE;
    data: Array<DatosResultadoOpcionContratoAP>;
    message: string;
    inProgress: boolean;
}
export const RESULTADOOPCIONCONTRATOAP_FAILED = 'RESULTADOOPCIONCONTRATOAP_FAILED';
export interface getResultadoOpcionContratoAPFailedAction {
    type: typeof RESULTADOOPCIONCONTRATOAP_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region OPCIONES CONTRATO AP
export const OPCIONESCONTRATOAP_REQUEST = 'OPCIONESCONTRATOAP_REQUEST';
export interface requestOpcionesContratoAPAction {
    type: typeof OPCIONESCONTRATOAP_REQUEST;
    data: IOpcionesContratoAPRequest;
    inProgress: boolean;
}
export const OPCIONESCONTRATOAP_RESPONSE = 'OPCIONESCONTRATOAP_RESPONSE';
export interface onOpcionesContratoAPResponseAction {
    type: typeof OPCIONESCONTRATOAP_RESPONSE;
    data: Array<DatosOpcionesContratoAP>;
    message: string;
    inProgress: boolean;
}
export const OPCIONESCONTRATOAP_FAILED = 'OPCIONESCONTRATOAP_FAILED';
export interface getOpcionesContratoAPFailedAction {
    type: typeof OPCIONESCONTRATOAP_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

// COTIZADOR

//#region RESULTADO COTIZADOR AP
export const RESULTADOCOTIZADORAP_REQUEST = 'RESULTADOCOTIZADORAP_REQUEST';
export interface requestResultadoCotizadorAPAction {
    type: typeof RESULTADOCOTIZADORAP_REQUEST;
    data: any;
    inProgress: boolean;
}
export const RESULTADOCOTIZADORAP_RESPONSE = 'RESULTADOCOTIZADORAP_RESPONSE';
export interface onResultadoCotizadorAPResponseAction {
    type: typeof RESULTADOCOTIZADORAP_RESPONSE;
    data: Array<DatosResultadoCotizadorAP>;
    message: string;
    inProgress: boolean;
}
export const RESULTADOCOTIZADORAP_FAILED = 'RESULTADOCOTIZADORAP_FAILED';
export interface getResultadoCotizadorAPFailedAction {
    type: typeof RESULTADOCOTIZADORAP_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region AMORTIZACIONES COTIZADOR CS
export const AMORTIZACIONESCOTIZADORCS_REQUEST = 'AMORTIZACIONESCOTIZADORCS_REQUEST';
export interface requestAmortizacionesCotizadorCSAction {
    type: typeof AMORTIZACIONESCOTIZADORCS_REQUEST;
    data: any;
    inProgress: boolean;
}
export const AMORTIZACIONESCOTIZADORCS_RESPONSE = 'AMORTIZACIONESCOTIZADORCS_RESPONSE';
export interface onAmortizacionesCotizadorCSResponseAction {
    type: typeof AMORTIZACIONESCOTIZADORCS_RESPONSE;
    data: Array<DatosAmortizacionesCotizadorCS>;
    message: string;
    inProgress: boolean;
}
export const AMORTIZACIONESCOTIZADORCS_FAILED = 'AMORTIZACIONESCOTIZADORCS_FAILED';
export interface getAmortizacionesCotizadorCSFailedAction {
    type: typeof AMORTIZACIONESCOTIZADORCS_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region RECALCULO COTIZADOR CS
export const RECALCULOCOTIZADORCS_REQUEST = 'RECALCULOCOTIZADORCS_REQUEST';
export interface requestRecalculoCotizadorCSAction {
    type: typeof RECALCULOCOTIZADORCS_REQUEST;
    data: any;
    inProgress: boolean;
}
export const RECALCULOCOTIZADORCS_RESPONSE = 'RECALCULOCOTIZADORCS_RESPONSE';
export interface onRecalculoCotizadorCSResponseAction {
    type: typeof RECALCULOCOTIZADORCS_RESPONSE;
    data: Array<DatosAmortizacionesCotizadorCS>;
    message: string;
    inProgress: boolean;
}
export const RECALCULOCOTIZADORCS_FAILED = 'RECALCULOCOTIZADORCS_FAILED';
export interface getRecalculoCotizadorCSFailedAction {
    type: typeof RECALCULOCOTIZADORCS_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region ARCHIVO RESULTADO COTIZADOR CS
export const ARCHIVORESULTADOCOTIZADORCS_REQUEST = 'ARCHIVORESULTADOCOTIZADORCS_REQUEST';
export interface requestArchivoResultadoCotizadorCSAction {
    type: typeof ARCHIVORESULTADOCOTIZADORCS_REQUEST;
    data: any;
    succeeded: boolean;
    inProgress: boolean;
}
export const ARCHIVORESULTADOCOTIZADORCS_RESPONSE = 'ARCHIVORESULTADOCOTIZADORCS_RESPONSE';
export interface onArchivoResultadoCotizadorCSResponseAction {
    type: typeof ARCHIVORESULTADOCOTIZADORCS_RESPONSE;
    data: any;
    message: string;
    succeeded: boolean;
    inProgress: boolean;
}
export const ARCHIVORESULTADOCOTIZADORCS_FAILED = 'ARCHIVORESULTADOCOTIZADORCS_FAILED';
export interface getArchivoResultadoCotizadorCSFailedAction {
    type: typeof ARCHIVORESULTADOCOTIZADORCS_FAILED;
    message: string;
    succeeded: boolean;
    inProgress: boolean;
}
//#endregion

//#region ARCHIVO RESULTADO COTIZADOR AP
export const ARCHIVORESULTADOCOTIZADORAP_REQUEST = 'ARCHIVORESULTADOCOTIZADORAP_REQUEST';
export interface requestArchivoResultadoCotizadorAPAction {
    type: typeof ARCHIVORESULTADOCOTIZADORAP_REQUEST;
    data: any;
    succeeded: boolean;
    inProgress: boolean;
}
export const ARCHIVORESULTADOCOTIZADORAP_RESPONSE = 'ARCHIVORESULTADOCOTIZADORAP_RESPONSE';
export interface onArchivoResultadoCotizadorAPResponseAction {
    type: typeof ARCHIVORESULTADOCOTIZADORAP_RESPONSE;
    data: any;
    message: string;
    succeeded: boolean;
    inProgress: boolean;
}
export const ARCHIVORESULTADOCOTIZADORAP_FAILED = 'ARCHIVORESULTADOCOTIZADORAP_FAILED';
export interface getArchivoResultadoCotizadorAPFailedAction {
    type: typeof ARCHIVORESULTADOCOTIZADORAP_FAILED;
    message: string;
    succeeded: boolean;
    inProgress: boolean;
}
//#endregion

// CATALOGOS //

//#region INSTITUCIONES BANCARIAS
export const INSTITUCIONESBANCARIAS_REQUEST = 'INSTITUCIONESBANCARIAS_REQUEST';
export interface requestInstitucionesBancariasAction {
    type: typeof INSTITUCIONESBANCARIAS_REQUEST;
    data: any;
    inProgress: boolean;
}
export const INSTITUCIONESBANCARIAS_RESPONSE = 'INSTITUCIONESBANCARIAS_RESPONSE';
export interface onInstitucionesBancariasResponseAction {
    type: typeof INSTITUCIONESBANCARIAS_RESPONSE;
    data: Array<DatosInstitucionesBancarias>;
    message: string;
    inProgress: boolean;
}
export const INSTITUCIONESBANCARIAS_FAILED = 'INSTITUCIONESBANCARIAS_FAILED';
export interface getInstitucionesBancariasFailedAction {
    type: typeof INSTITUCIONESBANCARIAS_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region TIPO CUENTA
export const TIPOCUENTA_REQUEST = 'TIPOCUENTA_REQUEST';
export interface requestTipoCuentaAction {
    type: typeof TIPOCUENTA_REQUEST;
    data: any;
    inProgress: boolean;
}
export const TIPOCUENTA_RESPONSE = 'TIPOCUENTA_RESPONSE';
export interface onTipoCuentaResponseAction {
    type: typeof TIPOCUENTA_RESPONSE;
    data: Array<DatosTipoCuenta>;
    message: string;
    inProgress: boolean;
}
export const TIPOCUENTA_FAILED = 'TIPOCUENTA_FAILED';
export interface getTipoCuentaFailedAction {
    type: typeof TIPOCUENTA_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region TIPO ACTIVO
export const TIPOACTIVO_REQUEST = 'TIPOACTIVO_REQUEST';
export interface requestTipoActivoAction {
    type: typeof TIPOACTIVO_REQUEST;
    data: any;
    inProgress: boolean;
}
export const TIPOACTIVO_RESPONSE = 'TIPOACTIVO_RESPONSE';
export interface onTipoActivoResponseAction {
    type: typeof TIPOACTIVO_RESPONSE;
    data: Array<DatosTipoActivo>;
    message: string;
    inProgress: boolean;
}
export const TIPOACTIVO_FAILED = 'TIPOACTIVO_FAILED';
export interface getTipoActivoFailedAction {
    type: typeof TIPOACTIVO_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region SUBTIPO ACTIVO
export const SUBTIPOACTIVO_REQUEST = 'SUBTIPOACTIVO_REQUEST';
export interface requestSubtipoActivoAction {
    type: typeof SUBTIPOACTIVO_REQUEST;
    data: any;
    inProgress: boolean;
}
export const SUBTIPOACTIVO_RESPONSE = 'SUBTIPOACTIVO_RESPONSE';
export interface onSubtipoActivoResponseAction {
    type: typeof SUBTIPOACTIVO_RESPONSE;
    data: Array<DatosSubtipoActivo>;
    message: string;
    inProgress: boolean;
}
export const SUBTIPOACTIVO_FAILED = 'SUBTIPOACTIVO_FAILED';
export interface getSubtipoActivoFailedAction {
    type: typeof SUBTIPOACTIVO_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region TIPO ARRENDAMIENTO
export const TIPOARRENDAMIENTO_REQUEST = 'TIPOARRENDAMIENTO_REQUEST';
export interface requestTipoArrendamientoAction {
    type: typeof TIPOARRENDAMIENTO_REQUEST;
    data: any;
    inProgress: boolean;
}
export const TIPOARRENDAMIENTO_RESPONSE = 'TIPOARRENDAMIENTO_RESPONSE';
export interface onTipoArrendamientoResponseAction {
    type: typeof TIPOARRENDAMIENTO_RESPONSE;
    data: Array<DatosTipoArrendamiento>;
    message: string;
    inProgress: boolean;
}
export const TIPOARRENDAMIENTO_FAILED = 'TIPOARRENDAMIENTO_FAILED';
export interface getTipoArrendamientoFailedAction {
    type: typeof TIPOARRENDAMIENTO_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region TIPO PAGO AP
export const TIPOPAGOAP_REQUEST = 'TIPOPAGOAP_REQUEST';
export interface requestTipoPagoAPAction {
    type: typeof TIPOPAGOAP_REQUEST;
    data: any;
    inProgress: boolean;
}
export const TIPOPAGOAP_RESPONSE = 'TIPOPAGOAP_RESPONSE';
export interface onTipoPagoAPResponseAction {
    type: typeof TIPOPAGOAP_RESPONSE;
    data: Array<DatosTipoPagoAP>;
    message: string;
    inProgress: boolean;
}
export const TIPOPAGOAP_FAILED = 'TIPOPAGOAP_FAILED';
export interface getTipoPagoAPFailedAction {
    type: typeof TIPOPAGOAP_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region PERIODICIDAD
export const PERIODICIDAD_REQUEST = 'PERIODICIDAD_REQUEST';
export interface requestPeriodicidadAction {
    type: typeof PERIODICIDAD_REQUEST;
    data: any;
    inProgress: boolean;
}
export const PERIODICIDAD_RESPONSE = 'PERIODICIDAD_RESPONSE';
export interface onPeriodicidadResponseAction {
    type: typeof PERIODICIDAD_RESPONSE;
    data: Array<DatosPeriodicidad>;
    message: string;
    inProgress: boolean;
}
export const PERIODICIDAD_FAILED = 'PERIODICIDAD_FAILED';
export interface getPeriodicidadFailedAction {
    type: typeof PERIODICIDAD_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region TIPO PERSONA
export const TIPOPERSONA_REQUEST = 'TIPOPERSONA_REQUEST';
export interface requestTipoPersonaAction {
    type: typeof TIPOPERSONA_REQUEST;
    data: any;
    inProgress: boolean;
}
export const TIPOPERSONA_RESPONSE = 'TIPOPERSONA_RESPONSE';
export interface onTipoPersonaResponseAction {
    type: typeof TIPOPERSONA_RESPONSE;
    data: Array<DatosTipoPersona>;
    message: string;
    inProgress: boolean;
}
export const TIPOPERSONA_FAILED = 'TIPOPERSONA_FAILED';
export interface getTipoPersonaFailedAction {
    type: typeof TIPOPERSONA_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region TIPO TASA
export const TIPOTASA_REQUEST = 'TIPOTASA_REQUEST';
export interface requestTipoTasaAction {
    type: typeof TIPOTASA_REQUEST;
    data: any;
    inProgress: boolean;
}
export const TIPOTASA_RESPONSE = 'TIPOTASA_RESPONSE';
export interface onTipoTasaResponseAction {
    type: typeof TIPOTASA_RESPONSE;
    data: Array<DatosTipoTasa>;
    message: string;
    inProgress: boolean;
}
export const TIPOTASA_FAILED = 'TIPOTASA_FAILED';
export interface getTipoTasaFailedAction {
    type: typeof TIPOTASA_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region TIPO TASA MORATORIA
export const TIPOTASAMORATORIA_REQUEST = 'TIPOTASAMORATORIA_REQUEST';
export interface requestTipoTasaMoratoriaAction {
    type: typeof TIPOTASAMORATORIA_REQUEST;
    data: any;
    inProgress: boolean;
}
export const TIPOTASAMORATORIA_RESPONSE = 'TIPOTASAMORATORIA_RESPONSE';
export interface onTipoTasaMoratoriaResponseAction {
    type: typeof TIPOTASAMORATORIA_RESPONSE;
    data: Array<DatosTipoTasaMoratoria>;
    message: string;
    inProgress: boolean;
}
export const TIPOTASAMORATORIA_FAILED = 'TIPOTASAMORATORIA_FAILED';
export interface getTipoTasaMoratoriaFailedAction {
    type: typeof TIPOTASAMORATORIA_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region TIPO CALCULO
export const TIPOCALCULO_REQUEST = 'TIPOCALCULO_REQUEST';
export interface requestTipoCalculoAction {
    type: typeof TIPOCALCULO_REQUEST;
    data: any;
    inProgress: boolean;
}
export const TIPOCALCULO_RESPONSE = 'TIPOCALCULO_RESPONSE';
export interface onTipoCalculoResponseAction {
    type: typeof TIPOCALCULO_RESPONSE;
    data: Array<DatosTipoCalculo>;
    message: string;
    inProgress: boolean;
}
export const TIPOCALCULO_FAILED = 'TIPOCALCULO_FAILED';
export interface getTipoCalculoFailedAction {
    type: typeof TIPOCALCULO_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region VALOR TIIE ACTUAL
export const VALORTIIEACTUAL_REQUEST = 'VALORTIIEACTUAL_REQUEST';
export interface requestValorTiieActualAction {
    type: typeof VALORTIIEACTUAL_REQUEST;
    data: any;
    inProgress: boolean;
}
export const VALORTIIEACTUAL_RESPONSE = 'VALORTIIEACTUAL_RESPONSE';
export interface onValorTiieActualResponseAction {
    type: typeof VALORTIIEACTUAL_RESPONSE;
    data: number;
    message: string;
    inProgress: boolean;
}
export const VALORTIIEACTUAL_FAILED = 'VALORTIIEACTUAL_FAILED';
export interface getValorTiieActualFailedAction {
    type: typeof VALORTIIEACTUAL_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

// STP

//#region STP COMPANIES
export const STPCOMPANIES_REQUEST = 'STPCOMPANIES_REQUEST';
export interface requestStpCompaniesAction {
    type: typeof STPCOMPANIES_REQUEST;
    balanceResponseObj: IStpCompaniesRequest;
    inProgress: boolean;
}
export const STPCOMPANIES_RESPONSE = 'STPCOMPANIES_RESPONSE';
export interface onStpCompaniesResponseAction {
    type: typeof STPCOMPANIES_RESPONSE;
    balanceResponseObj: Array<DatosStpCompanies>;
    message: string;
    inProgress: boolean;
}
export const STPCOMPANIES_FAILED = 'STPCOMPANIES_FAILED';
export interface getStpCompaniesFailedAction {
    type: typeof STPCOMPANIES_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region SUBACCOUNTS
export const SUBACCOUNTS_REQUEST = 'SUBACCOUNTS_REQUEST';
export interface requestSubAccountsAction {
    type: typeof SUBACCOUNTS_REQUEST;
    dropdownDataObj: ISubAccountsRequest;
    inProgress: boolean;
}
export const SUBACCOUNTS_RESPONSE = 'SUBACCOUNTS_RESPONSE';
export interface onSubAccountsResponseAction {
    type: typeof SUBACCOUNTS_RESPONSE;
    dropdownDataObj: Array<DatosSubAccounts>;
    message: string;
    inProgress: boolean;
}
export const SUBACCOUNTS_FAILED = 'SUBACCOUNTS_FAILED';
export interface getSubAccountsFailedAction {
    type: typeof SUBACCOUNTS_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region SUBACCOUNT MOVIMIENTOS
export const SUBACCOUNTMOVIMIENTOS_REQUEST = 'SUBACCOUNTMOVIMIENTOS_REQUEST';
export interface requestSubAccountMovimientosAction {
    type: typeof SUBACCOUNTMOVIMIENTOS_REQUEST;
    transaccionResponseObj: ISubAccountMovimientosRequest;
    inProgress: boolean;
}
export const SUBACCOUNTMOVIMIENTOS_RESPONSE = 'SUBACCOUNTMOVIMIENTOS_RESPONSE';
export interface onSubAccountMovimientosResponseAction {
    type: typeof SUBACCOUNTMOVIMIENTOS_RESPONSE;
    transaccionResponseObj: Array<DatosSubAccountMovimientos>;
    message: string;
    inProgress: boolean;
}
export const SUBACCOUNTMOVIMIENTOS_FAILED = 'SUBACCOUNTMOVIMIENTOS_FAILED';
export interface getSubAccountMovimientosFailedAction {
    type: typeof SUBACCOUNTMOVIMIENTOS_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

//#region CHECK ACCOUNT BALANCE
export const CHECKACCOUNTBALANCE_REQUEST = 'CHECKACCOUNTBALANCE_REQUEST';
export interface requestCheckAccountBalanceAction {
    type: typeof CHECKACCOUNTBALANCE_REQUEST;
    data: ICheckAccountBalanceRequest;
    inProgress: boolean;
}
export const CHECKACCOUNTBALANCE_RESPONSE = 'CHECKACCOUNTBALANCE_RESPONSE';
export interface onCheckAccountBalanceResponseAction {
    type: typeof CHECKACCOUNTBALANCE_RESPONSE;
    data: ICheckAccountBalanceResponse;
    inProgress: boolean;
}
export const CHECKACCOUNTBALANCE_FAILED = 'CHECKACCOUNTBALANCE_FAILED';
export interface getCheckAccountBalanceFailedAction {
    type: typeof CHECKACCOUNTBALANCE_FAILED;
    message: string;
    inProgress: boolean;
}
//#endregion

export type RootAction =

    // CLIENTES //

    | getEnableLoaderAction
    | getDisableLoaderAction

    | setFiltersAction
    | resetFiltersAction
    | setFiltersFinanciamientoAction

    | requestFinanciamientosAction
    | onFinanciamientosResponseAction
    | getFinanciamientosFailedAction

    | requestDisposicionesAction
    | onDisposicionesResponseAction
    | getDisposicionesFailedAction

    | requestAmortizacionesAction
    | onAmortizacionesResponseAction
    | getAmortizacionesFailedAction

    | requestExportarArchivoAmortizacionesAction
    | onExportarArchivoAmortizacionesResponseAction
    | getExportarArchivoAmortizacionesFailedAction

    | requestExportarArchivoPagosRealizadosAction
    | onExportarArchivoPagosRealizadosResponseAction
    | getExportarArchivoPagosRealizadosFailedAction

    | requestExportarArchivoDisposicionesAction
    | onExportarArchivoDisposicionesResponseAction
    | getExportarArchivoDisposicionesFailedAction

    | requestExportarArchivoActivosAction
    | onExportarArchivoActivosResponseAction
    | getExportarArchivoActivosFailedAction

    | requestDatosFinanciamientoAction
    | onDatosFinanciamientoResponseAction
    | getDatosFinanciamientoFailedAction

    | requestPaquetesFactorajeAction
    | onPaquetesFactorajeResponseAction
    | getPaquetesFactorajeFailedAction

    | requestFacturasPaqueteAction
    | onFacturasPaqueteResponseAction
    | getFacturasPaqueteFailedAction

    | requestLoginAction
    | onLoginResponseAction
    | requestLoginByTokenAction
    | onLoginByTokenAction
    | getLoginFailedAction

    | requestLogoutAction
    | onLogoutResponseAction

    | requestPagosRealizadosAction
    | onPagosRealizadosResponseAction
    | getPagosRealizadosFailedAction

    | requestResumenCuentaCorrienteAction
    | onResumenCuentaCorrienteResponseAction
    | getResumenCuentaCorrienteFailedAction

    | requestActivosArrendamientoAction
    | onActivosArrendamientoResponseAction
    | getActivosArrendamientoFailedAction

    | requestAmortizacionesActivoAction
    | onAmortizacionesActivoResponseAction
    | getAmortizacionesActivoFailedAction

    | requestCuentaDepositoAction
    | onCuentaDepositoResponseAction
    | getCuentaDepositoFailedAction

    | requestPostCuentaDepositoAction
    | onPostCuentaDepositoResponseAction
    | getPostCuentaDepositoFailedAction

    | requestFindValidStatusAction
    | onFindValidStatusResponseAction
    | getFindValidStatusFailedAction

    | requestCredentialsAction
    | onCredentialsResponseAction
    | getCredentialsFailedAction

    | requestWebHookStatusAction
    | onWebHookStatusResponseAction
    | getWebHookStatusFailedAction
    | getWebHookStatusResetAction

    | requestAmortizacionesAPagarAction
    | onAmortizacionesAPagarResponseAction
    | getAmortizacionesAPagarFailedAction

    | requestSimularPagoAction
    | onSimularPagoResponseAction
    | getSimularPagoFailedAction

    | requestGenerarReferenciaPagoAction
    | onGenerarReferenciaPagoResponseAction
    | getGenerarReferenciaPagoFailedAction

    | requestGenerarReferenciaPagoFactorajeAction
    | onGenerarReferenciaPagoFactorajeResponseAction
    | getGenerarReferenciaPagoFactorajeFailedAction

    | requestGenerarReferenciaPagoActivosAction
    | onGenerarReferenciaPagoActivosResponseAction
    | getGenerarReferenciaPagoActivosFailedAction

    | requestPaquetesAPagarAction
    | onPaquetesAPagarResponseAction
    | getPaquetesAPagarFailedAction

    | requestOpcionesContratoAPAction
    | onOpcionesContratoAPResponseAction
    | getOpcionesContratoAPFailedAction

    | requestResultadoOpcionContratoAPAction
    | onResultadoOpcionContratoAPResponseAction
    | getResultadoOpcionContratoAPFailedAction

    // COTIZADOR

    | requestResultadoCotizadorAPAction
    | onResultadoCotizadorAPResponseAction
    | getResultadoCotizadorAPFailedAction

    | requestAmortizacionesCotizadorCSAction
    | onAmortizacionesCotizadorCSResponseAction
    | getAmortizacionesCotizadorCSFailedAction

    | requestRecalculoCotizadorCSAction
    | onRecalculoCotizadorCSResponseAction
    | getRecalculoCotizadorCSFailedAction

    | requestArchivoResultadoCotizadorCSAction
    | onArchivoResultadoCotizadorCSResponseAction
    | getArchivoResultadoCotizadorCSFailedAction

    | requestArchivoResultadoCotizadorAPAction
    | onArchivoResultadoCotizadorAPResponseAction
    | getArchivoResultadoCotizadorAPFailedAction

    // CATALOGOS //

    | requestInstitucionesBancariasAction
    | onInstitucionesBancariasResponseAction
    | getInstitucionesBancariasFailedAction

    | requestTipoCuentaAction
    | onTipoCuentaResponseAction
    | getTipoCuentaFailedAction

    | requestTipoActivoAction
    | onTipoActivoResponseAction
    | getTipoActivoFailedAction

    | requestSubtipoActivoAction
    | onSubtipoActivoResponseAction
    | getSubtipoActivoFailedAction

    | requestTipoArrendamientoAction
    | onTipoArrendamientoResponseAction
    | getTipoArrendamientoFailedAction

    | requestTipoPagoAPAction
    | onTipoPagoAPResponseAction
    | getTipoPagoAPFailedAction

    | requestPeriodicidadAction
    | onPeriodicidadResponseAction
    | getPeriodicidadFailedAction

    | requestTipoPersonaAction
    | onTipoPersonaResponseAction
    | getTipoPersonaFailedAction

    | requestTipoTasaAction
    | onTipoTasaResponseAction
    | getTipoTasaFailedAction

    | requestTipoTasaMoratoriaAction
    | onTipoTasaMoratoriaResponseAction
    | getTipoTasaMoratoriaFailedAction

    | requestTipoCalculoAction
    | onTipoCalculoResponseAction
    | getTipoCalculoFailedAction

    | requestValorTiieActualAction
    | onValorTiieActualResponseAction
    | getValorTiieActualFailedAction

    // STP

    | requestStpCompaniesAction
    | onStpCompaniesResponseAction
    | getStpCompaniesFailedAction

    | requestSubAccountsAction
    | onSubAccountsResponseAction
    | getSubAccountsFailedAction

    | requestSubAccountMovimientosAction
    | onSubAccountMovimientosResponseAction
    | getSubAccountMovimientosFailedAction

    | requestCheckAccountBalanceAction
    | onCheckAccountBalanceResponseAction
    | getCheckAccountBalanceFailedAction