import * as React from 'react';
import { useState, useCallback, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Image from 'mui-image';
import { useDispatch, useSelector } from "react-redux";
import * as credentialsActions from './redux/actions';
import * as R from 'ramda';
import { DatosWebHookStatus } from '../../models/IWebHookStatusRequest';
import { Alert, CircularProgress, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import logo from '../../content/images/pchcapital_cuadrado.svg';
import { env } from '../../api/env';
import { environment } from '../../config';
import { CredentialsState } from './redux/reducers';

export default function Credentials() {

    const dataCredentials: CredentialsState = useSelector((state: any) => state.credentials);
    const dataWHook: DatosWebHookStatus = useSelector((state: any) => state.webHookStatus.data);

    const [idCredential, setIdCredential] = useState(dataCredentials.data?.id);
    const [timeInterval, setTimeInterval] = useState(1200);

    const [reintentarPeticion, setReintentarPeticion] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [error, setError] = useState("");
    const [msgAlert, setMsgAlert] = useState("");
    const [rfc, setRFC] = useState('');
    const [password, setPassword] = useState('');
    const [autorizaConsulta, setAutorizaConsulta] = useState(false);
    const [envioCIEC, setEnvioCIEC] = useState(false);
    const typeCredential = 'ciec';

    const dispatch = useDispatch();
    const credentialsRequest = useCallback(() =>
        dispatch(credentialsActions.requestCredentials(typeCredential, rfc, password, autorizaConsulta)),
        [dispatch, typeCredential, rfc, password, autorizaConsulta]
    );
    const statusWebHookRequest = useCallback((id: string) => dispatch(credentialsActions.requestWebHookStatus(id)), [dispatch, idCredential]);
    const statusWebHookReset = useCallback(() => dispatch(credentialsActions.onWebHookStatusReset()), []);

    const regexExp = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;

    const submitCIEC = (event: any) => {
        event.preventDefault();
        setEnvioCIEC(true);
        setError('');
        setMsgAlert('');
        setReintentarPeticion(0);
        statusWebHookReset();
        let rfcValid = regexExp.test(rfc + '');
        if (!rfcValid) {
            setError("Ingrese un RFC válido.");
        }
        else if (password.length < 8) {
            setError("Ingrese una clave CIEC válida.")
        }
        else {
            credentialsRequest();
        }
    };

    useEffect(() => {
        const interval = setInterval(() => setSeconds(seconds => seconds + 1), 9000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        // if (dataCredentials.data != null) {
        //     console.log('dataCredentials => ', JSON.stringify(dataCredentials.data));
        // }
        if (dataCredentials.errors.length > 0 && envioCIEC) {
            setEnvioCIEC(false);
        }
    }, [dataCredentials]);

    useEffect(() => {
        if (dataWHook != null) {
            // console.log(dataWHook);
            if (envioCIEC && !R.isNil(dataWHook) && dataWHook.status === 'valid') {
                setEnvioCIEC(false);
                setRFC('');
                setPassword('');
                setError('');
                setMsgAlert('Credenciales validas, se procederá a realizar un preanálisis.');
                setTimeout(() => window.location.replace(env.URL_PCHSF), 12000);
            }
            if (envioCIEC && !R.isNil(dataWHook) && dataWHook.status === 'invalid') {
                setEnvioCIEC(false);
                setPassword('');
                setReintentarPeticion(100);
                setError('La credenciales proporcionadas no son validas, favor de revisar.');
                setMsgAlert('');
            }
        }
    }, [dataWHook]);

    useEffect(() => {
        if (error.length === 0 && msgAlert.length === 0 && envioCIEC) {
            if (!R.isNil(dataCredentials.data) && dataCredentials.data.status === 'pending') {
                if (R.isNil(dataWHook) || (!R.isNil(dataWHook) && dataWHook.status === 'pending')) {
                    if (reintentarPeticion >= 20) {
                        setError('Error al procesar la solicitud.');
                        setMsgAlert('El tiempo de respuesta para esta comprobación fue excedido, su ejecutivo se podrá en contacto para notificarle sobre el estatus de sus credenciales.')
                    } else {
                        setReintentarPeticion(reint => reint + 1);
                        const { id } = dataCredentials.data;
                        statusWebHookRequest(id + '');
                    }
                } else if (envioCIEC && rfc.length > 0 && password.length > 0) {
                    if (dataCredentials != null && dataCredentials.data != null) {
                        setReintentarPeticion(reint => reint + 1);
                        const { id } = dataCredentials.data;
                        statusWebHookRequest(id + '');
                    }
                }
            }
        }
    }, [seconds]);

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            component="main" >
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '50vh' }}>
                <Grid item xs={3}>
                    <Grid container component="main">
                        <Container maxWidth='xs' style={{ backgroundColor: '#f2f6fc', border: '1px solid black', marginTop: '33px', marginBottom: '33px', opacity: "0.9" }}>
                            <Box id="boxFormGetCIEC"
                                sx={{
                                    m: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}>

                                <Image src={logo} height="60%" width="60%" style={{ margin: '50px 0 50px 0' }} />

                                <Typography align="center" component="h1" variant="h6" color="#0D79BF">
                                    Ingrese sus credenciales
                                </Typography>

                                <Box component="form" onSubmit={(e) => submitCIEC(e)} sx={{ mt: 0 }}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="rfc"
                                        label="RFC"
                                        autoComplete="off"
                                        onChange={e => setRFC(e.target.value)} />

                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="ciec"
                                        label="CIEC"
                                        type="password"
                                        id="ciec"
                                        autoComplete="current-ciec"
                                        onChange={e => setPassword(e.target.value)} />

                                    <FormControlLabel
                                        sx={{ mt: 1 }}
                                        control={<Checkbox id="autorizaConsulta" value="autorizaConsulta" onChange={e => setAutorizaConsulta(e.target.checked)} />}
                                        label="Autorizo consulta a Buró de Crédito" />

                                    <Button
                                        id="sendButton"
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 2, mb: 2 }}
                                        disabled={error.length === 0 && msgAlert.length === 0 && envioCIEC} >
                                        Enviar
                                    </Button >

                                    {error.length === 0 && msgAlert.length === 0 && envioCIEC && <Typography align="center"><CircularProgress /></Typography>}

                                    {error.length === 0 && msgAlert.length === 0 && !R.isNil(dataCredentials.message) && dataCredentials.message.length > 1 && <Alert variant="outlined" sx={{ mb: 1 }} severity="warning">{dataCredentials.message}</Alert>}

                                    {error.length > 0 && <Alert variant="outlined" sx={{ mb: 1 }} severity="error">{error}</Alert>}

                                    {msgAlert.length > 0 && <Alert variant="outlined" sx={{ mb: 1 }} severity={error.length === 0 ? 'success' : 'info'}>{msgAlert}</Alert>}

                                    {error.length === 0 && !envioCIEC && dataCredentials.errors.length > 0 &&
                                        <Typography align="center" sx={{ fontSize: "14px", mt: 0 }} component={'div'} color="error.main" >
                                            <List>
                                                {dataCredentials.errors.map((value: string, index: number) => (
                                                    <ListItem disablePadding key={index}>
                                                        <ListItemButton>
                                                            <ListItemText primary={value} />
                                                        </ListItemButton>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </Typography>
                                    }

                                    <Typography align="center" sx={{ fontSize: "14px", mt: 2 }}>
                                        Al seleccionar "Enviar", usted acepta nuestros <Link>Términos de Uso</Link> y <Link>Aviso de Privacidad</Link>.
                                    </Typography>

                                </Box>
                            </Box>
                        </Container>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}