import { DatosFinanciamiento } from '../../../../models/IDatosFinanciamientoResponse';
import * as types from '../../../../system/types'

export interface DatosFinanciamientoState {
    id: string,
    // errors: [],
    inProgress: boolean,
    data: DatosFinanciamiento | null,
    message?: string | null
}

const DatosFinanciamientoInitialState: DatosFinanciamientoState = {
    id: '',
    // errors: [],
    data: null,
    inProgress: false
};

export default function datosFinanciamientoReducer(
    state: DatosFinanciamientoState = DatosFinanciamientoInitialState,
    action: types.RootAction
): DatosFinanciamientoState {
    switch (action.type) {
        case types.DATOSFINANCIAMIENTO_REQUEST:
            return {
                ...state,
                message: '',
                inProgress: true
            }
        case types.DATOSFINANCIAMIENTO_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                inProgress: false
            }
        case types.DATOSFINANCIAMIENTO_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}