
import React from 'react'
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import configureStore from "./store";
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { Grid, ThemeProvider } from '@mui/material';
import theme from './theme';

const back = {
  heroContainer: {
    width: '100vw',
    height: '100vh',
    spacing: 0,
    justify: 'space-around',
    fit: 'cover'
  }
};

const container = document.getElementById('root')!;
const root = createRoot(container);
const { store } = configureStore();

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Grid style={back.heroContainer} sx={{ background: '#eee' }}>
          <App />
        </Grid>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();