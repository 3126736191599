import { DatosFinanciamientos } from '../../../models/IFinanciamientosResponse';
import * as types from '../../../system/types'

export interface FinanciamientosState {
    id: string,
    // userName: string,
    // email: string,
    // jwToken: string,
    // nombreUsuario: string,
    // token: string,
    // response: [],
    // errors: [],
    data: Array<DatosFinanciamientos> | null,
    inProgress: boolean,
    // isLoggedIn: boolean,
    // isVerified: boolean,
    message?: string | null
}

const financiamientosInitialState: FinanciamientosState = {
    id: '',
    // userName: '',
    // email: '',
    // jwToken: '',
    // nombreUsuario: '',
    // token: '',
    // response: [],
    // errors: [],
    data: null,
    inProgress: false,
    // isLoggedIn: false,
    // isVerified: false
};

export default function financiamientosReducer(
    state: FinanciamientosState = financiamientosInitialState,
    action: types.RootAction
): FinanciamientosState {
    switch (action.type) {
        case types.FINANCIAMIENTOS_REQUEST:
            return {
                ...state,
                message: '',
                inProgress: true
            }
        case types.FINANCIAMIENTOS_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                inProgress: false
            }
        case types.FINANCIAMIENTOS_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}