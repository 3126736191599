import { IAmortizacionesRequest } from '../../../../models/IAmortizacionesRequest';
import { DatosAmortizaciones, IAmortizacionesResponse } from '../../../../models/IAmortizacionesResponse';
import { IExportarArchivoAmortizacionesRequest } from '../../../../models/IExportarArchivoRequest';
import * as type from '../../../../system/types';
import * as actions from '../../../../system/types';

//#region AMORTIZACIONES
export function requestAmortizaciones(data: IAmortizacionesRequest): actions.requestAmortizacionesAction {
    return {
        type: type.AMORTIZACIONES_REQUEST,
        inProgress: true,
        data
    };
}

export function onAmortizacionesResponse(
    data: Array<DatosAmortizaciones>,
    message: string
): actions.onAmortizacionesResponseAction {
    return {
        type: type.AMORTIZACIONES_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onAmortizacionesFailed(
    data: any,
    message: string
): actions.getAmortizacionesFailedAction {
    return {
        type: type.AMORTIZACIONES_FAILED,
        message,
        inProgress: false
    };
}
//#endregion

//#region EXPORTAR ARCHIVO
export function requestExportarArchivoAmortizaciones(data: IExportarArchivoAmortizacionesRequest): actions.requestExportarArchivoAmortizacionesAction {
    return {
        type: type.EXPORTARARCHIVOAMORTIZACIONES_REQUEST,
        inProgress: true,
        succeeded: false,
        data
    };
}

export function onExportarArchivoAmortizacionesResponse(
    data: any,
    message: string,
    succeeded: boolean
): actions.onExportarArchivoAmortizacionesResponseAction {
    return {
        type: type.EXPORTARARCHIVOAMORTIZACIONES_RESPONSE,
        data,
        message,
        succeeded,
        inProgress: false
    };
}

export function onExportarArchivoAmortizacionesFailed(
    data: any,
    message: string
): actions.getExportarArchivoAmortizacionesFailedAction {
    return {
        type: type.EXPORTARARCHIVOAMORTIZACIONES_FAILED,
        message,
        succeeded: false,
        inProgress: false
    };
}
//#endregion