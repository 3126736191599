export const env : { [key: string]: string } = {
    name:  String(process.env.REACT_APP_name),
    NODE_ENV:  String(process.env.REACT_APP_NODE_ENV),

    BASE_URL: String(process.env.REACT_APP_BASE_URL),
    BASE_URL_SECURE: String(process.env.REACT_APP_BASE_URL_SECURE),
    URL_POWER_FINANCE: String(process.env.REACT_APP_URL_POWER_FINANCE),
    URL_PCHSF: String(process.env.REACT_APP_URL_PCHSF),
    URL_EXTERNAL: String(process.env.REACT_APP_URL_EXTERNAL),
    URL_ACCOUNT: String(process.env.REACT_APP_URL_ACCOUNT),

    PATH_PROTECTED: String(process.env.REACT_APP_PATH_PROTECTED),

    AUTORIZATION: String(process.env.REACT_APP_AUTORIZATION),
    KEY: String(process.env.REACT_APP_KEY),
    SECRET: String(process.env.REACT_APP_SECRET),

};