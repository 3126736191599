import { DatosTipoArrendamiento } from '../../../../models/catalogs/ITipoArrendamientoResponse';
import * as types from '../../../../system/types'

export interface TipoArrendamientoState {
    id: string,
    // errors: [],
    inProgress: boolean,
    data: Array<DatosTipoArrendamiento> | null,
    message?: string | null
}

const TipoArrendamientoInitialState: TipoArrendamientoState = {
    id: '',
    // errors: [],
    data: null,
    inProgress: false
};

export default function tipoArrendamientoReducer(
    state: TipoArrendamientoState = TipoArrendamientoInitialState,
    action: types.RootAction
): TipoArrendamientoState {
    switch (action.type) {
        case types.TIPOARRENDAMIENTO_REQUEST:
            return {
                ...state,
                message: '',
                inProgress: true
            }
        case types.TIPOARRENDAMIENTO_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                inProgress: false
            }
        case types.TIPOARRENDAMIENTO_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}