import { IFinanciamientosRequest } from '../../../models/IFinanciamientosRequest';
import { DatosFinanciamientos, IFinanciamientosResponse } from '../../../models/IFinanciamientosResponse';
import * as type from '../../../system/types';
import * as actions from '../../../system/types';

export function requestFinanciamientos(data: IFinanciamientosRequest): actions.requestFinanciamientosAction {
    return {
        type: type.FINANCIAMIENTOS_REQUEST,
        inProgress: true,
        data
    };
}

export function onFinanciamientosResponse(
    data: Array<DatosFinanciamientos>,
    message: string
): actions.onFinanciamientosResponseAction {
    return {
        type: type.FINANCIAMIENTOS_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onFinanciamientosFailed(
    data: any,
    message: string
): actions.getFinanciamientosFailedAction {
    return {
        type: type.FINANCIAMIENTOS_FAILED,
        message,
        inProgress: false
    };
}