import { IFacturasPaqueteRequest, IPaquetesFactorajeRequest } from '../../../../models/IPaquetesFactorajeRequest';
import { DatosFacturasPaquete, DatosPaquetesFactoraje, IPaquetesFactorajeResponse } from '../../../../models/IPaquetesFactorajeResponse';
import * as type from '../../../../system/types';
import * as actions from '../../../../system/types';

//#region PAQUETES
export function requestPaquetesFactoraje(data: IPaquetesFactorajeRequest): actions.requestPaquetesFactorajeAction {
    return {
        type: type.PAQUETESFACTORAJE_REQUEST,
        inProgress: true,
        data
    };
}

export function onPaquetesFactorajeResponse(
    data: Array<DatosPaquetesFactoraje>,
    message: string
): actions.onPaquetesFactorajeResponseAction {
    return {
        type: type.PAQUETESFACTORAJE_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onPaquetesFactorajeFailed(
    data: any,
    message: string
): actions.getPaquetesFactorajeFailedAction {
    return {
        type: type.PAQUETESFACTORAJE_FAILED,
        message,
        inProgress: false
    };
}
//#endregion

//#region FACTURAS
export function requestFacturasPaquete(data: IFacturasPaqueteRequest): actions.requestFacturasPaqueteAction {
    return {
        type: type.FACTURASPAQUETE_REQUEST,
        inProgress: true,
        data
    };
}

export function onFacturasPaqueteResponse(
    data: Array<DatosFacturasPaquete>,
    message: string
): actions.onFacturasPaqueteResponseAction {
    return {
        type: type.FACTURASPAQUETE_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onFacturasPaqueteFailed(
    data: any,
    message: string
): actions.getFacturasPaqueteFailedAction {
    return {
        type: type.FACTURASPAQUETE_FAILED,
        message,
        inProgress: false
    };
}
//#endregion