import { DatosAmortizacionesCotizadorCS } from '../../../../models/cotizador/IAmortizacionesCotizadorCSResponse';
import * as types from '../../../../system/types'

//#region AMORTIZACIONES COTIZADOR CS
export interface AmortizacionesCotizadorCSState {
    id: string,
    // errors: [],
    inProgress: boolean,
    data: Array<DatosAmortizacionesCotizadorCS> | null,
    message?: string | null
}

const AmortizacionesCotizadorCSInitialState: AmortizacionesCotizadorCSState = {
    id: '',
    // errors: [],
    data: null,
    inProgress: false
};

export function amortizacionesCotizadorCSReducer(
    state: AmortizacionesCotizadorCSState = AmortizacionesCotizadorCSInitialState,
    action: types.RootAction
): AmortizacionesCotizadorCSState {
    switch (action.type) {
        case types.AMORTIZACIONESCOTIZADORCS_REQUEST:
            return {
                ...state,
                message: '',
                inProgress: true
            }
        case types.AMORTIZACIONESCOTIZADORCS_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                inProgress: false
            }
        case types.AMORTIZACIONESCOTIZADORCS_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}
//#endregion

//#region RECALCULO COTIZADOR CS
export interface RecalculoCotizadorCSState {
    id: string,
    // errors: [],
    inProgress: boolean,
    data: Array<DatosAmortizacionesCotizadorCS> | null,
    message?: string | null
}

const RecalculoCotizadorCSInitialState: RecalculoCotizadorCSState = {
    id: '',
    // errors: [],
    data: null,
    inProgress: false
};

export function recalculoCotizadorCSReducer(
    state: RecalculoCotizadorCSState = RecalculoCotizadorCSInitialState,
    action: types.RootAction
): RecalculoCotizadorCSState {
    switch (action.type) {
        case types.RECALCULOCOTIZADORCS_REQUEST:
            return {
                ...state,
                message: '',
                inProgress: true
            }
        case types.RECALCULOCOTIZADORCS_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                inProgress: false
            }
        case types.RECALCULOCOTIZADORCS_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}
//#endregion

//#region ARCHIVO RESULTADO COTIZADOR CS
export interface ArchivoResultadoCotizadorCSState {
    id: string,
    // errors: [],
    inProgress: boolean,
    data: any | null,
    succeeded: boolean,
    message?: string | null
}

const ArchivoResultadoCotizadorCSInitialState: ArchivoResultadoCotizadorCSState = {
    id: '',
    // errors: [],
    data: null,
    succeeded: false,
    inProgress: false
};

export function archivoResultadoCotizadorCSReducer(
    state: ArchivoResultadoCotizadorCSState = ArchivoResultadoCotizadorCSInitialState,
    action: types.RootAction
): ArchivoResultadoCotizadorCSState {
    switch (action.type) {
        case types.ARCHIVORESULTADOCOTIZADORCS_REQUEST:
            return {
                ...state,
                message: '',
                succeeded: false,
                inProgress: true
            }
        case types.ARCHIVORESULTADOCOTIZADORCS_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                succeeded: action.succeeded,
                inProgress: false
            }
        case types.ARCHIVORESULTADOCOTIZADORCS_FAILED:
            return {
                ...state,
                message: action.message,
                succeeded: false,
                inProgress: false
            }
        default:
            return state;
    }
}
//#endregion