import { DatosTipoTasaMoratoria } from '../../../../models/catalogs/ITipoTasaMoratoriaResponse';
import * as type from '../../../../system/types';
import * as actions from '../../../../system/types';

export function requestTipoTasaMoratoria(data: any): actions.requestTipoTasaMoratoriaAction {
    return {
        type: type.TIPOTASAMORATORIA_REQUEST,
        inProgress: true,
        data
    };
}

export function onTipoTasaMoratoriaResponse(
    data: Array<DatosTipoTasaMoratoria>,
    message: string
): actions.onTipoTasaMoratoriaResponseAction {
    return {
        type: type.TIPOTASAMORATORIA_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onTipoTasaMoratoriaFailed(
    data: any,
    message: string
): actions.getTipoTasaMoratoriaFailedAction {
    return {
        type: type.TIPOTASAMORATORIA_FAILED,
        message,
        inProgress: false
    };
}