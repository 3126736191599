import { IDisposicionesRequest } from '../../../../models/IDisposicionesRequest';
import { DatosDisposiciones, IDisposicionesResponse } from '../../../../models/IDisposicionesResponse';
import { IExportarArchivoDisposicionesRequest } from '../../../../models/IExportarArchivoRequest';
import * as type from '../../../../system/types';
import * as actions from '../../../../system/types';

//#region DISPOSICIONES
export function requestDisposiciones(data: IDisposicionesRequest): actions.requestDisposicionesAction {
    return {
        type: type.DISPOSICIONES_REQUEST,
        inProgress: true,
        data
    };
}

export function onDisposicionesResponse(
    data: Array<DatosDisposiciones>,
    message: string
): actions.onDisposicionesResponseAction {
    return {
        type: type.DISPOSICIONES_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onDisposicionesFailed(
    data: any,
    message: string
): actions.getDisposicionesFailedAction {
    return {
        type: type.DISPOSICIONES_FAILED,
        message,
        inProgress: false
    };
}
//#endregion

//#region EXPORTAR ARCHIVO
export function requestExportarArchivoDisposiciones(data: IExportarArchivoDisposicionesRequest): actions.requestExportarArchivoDisposicionesAction {
    return {
        type: type.EXPORTARARCHIVODISPOSICIONES_REQUEST,
        inProgress: true,
        succeeded: false,
        data
    };
}

export function onExportarArchivoDisposicionesResponse(
    data: any,
    message: string,
    succeeded: boolean
): actions.onExportarArchivoDisposicionesResponseAction {
    return {
        type: type.EXPORTARARCHIVODISPOSICIONES_RESPONSE,
        data,
        message,
        succeeded,
        inProgress: false
    };
}

export function onExportarArchivoDisposicionesFailed(
    data: any,
    message: string
): actions.getExportarArchivoDisposicionesFailedAction {
    return {
        type: type.EXPORTARARCHIVODISPOSICIONES_FAILED,
        message,
        succeeded: false,
        inProgress: false
    };
}
//#endregion