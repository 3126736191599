import * as types from './types';

export interface IFilters {
    idCliente: string,
    idTipoPersona: string,
    idFinanciamientoSolicitado?: string | null,
    idForoCondiciones?: string | null
}

export interface rootState {
    totalLoaded: number,
    typeUser: string,
    navigation: {
        previous: any,
        current: any,
    },
    isLoading: boolean,
    statusBarVisibility: boolean,
    statusBarStyle: string,
    statusBarTransition: string,
    filters: IFilters
}

const rootInitialState: rootState = {
    totalLoaded: 0,
    typeUser: '',
    navigation: {
        previous: null,
        current: null,
    },
    isLoading: false,
    statusBarVisibility: true,
    statusBarStyle: '',
    statusBarTransition: '',
    filters: {
        idCliente: '',
        idTipoPersona: ''
    }
};

export function rootReducer(
    state: rootState = rootInitialState,
    action: types.RootAction
): rootState {
    switch (action.type) {
        case types.ENABLE_LOADER:
            return {
                ...state,
                isLoading: true,
                totalLoaded: state.totalLoaded + action.totalLoaded,
            };
        case types.DISABLE_LOADER:
            return {
                ...state,
                isLoading: (state.totalLoaded > 0 ? state.totalLoaded - action.totalLoaded : 0) !== 0,
                totalLoaded: state.totalLoaded > 0 ? state.totalLoaded - action.totalLoaded : 0,
            };
        case types.SET_FILTERS:
            return {
                ...state,
                filters: action.filters
            };
        case types.SET_FILTERS_FINANCIAMIENTO:
            return {
                ...state,
                filters: {
                    idCliente: state.filters.idCliente,
                    idTipoPersona: state.filters.idTipoPersona,
                    idFinanciamientoSolicitado: action.idFinanciamientoSolicitado,
                    idForoCondiciones: action.idForoCondiciones
                }
            };
        case types.RESET_FILTERS:
            return {
                ...state,
                filters: {
                    idCliente: state.filters.idCliente,
                    idTipoPersona: state.filters.idTipoPersona,
                    idFinanciamientoSolicitado: null,
                    idForoCondiciones: null
                }
            };
        default:
            return state;
    }
}

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;