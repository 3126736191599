import { DatosTipoActivo } from '../../../../models/catalogs/ITipoActivoResponse';
import * as types from '../../../../system/types'

export interface TipoActivoState {
    id: string,
    // errors: [],
    inProgress: boolean,
    data: Array<DatosTipoActivo> | null,
    message?: string | null
}

const TipoActivoInitialState: TipoActivoState = {
    id: '',
    // errors: [],
    data: null,
    inProgress: false
};

export default function tipoActivoReducer(
    state: TipoActivoState = TipoActivoInitialState,
    action: types.RootAction
): TipoActivoState {
    switch (action.type) {
        case types.TIPOACTIVO_REQUEST:
            return {
                ...state,
                message: '',
                inProgress: true
            }
        case types.TIPOACTIVO_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                inProgress: false
            }
        case types.TIPOACTIVO_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}