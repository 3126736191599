import React from 'react';
import logo from './logo.svg';
import { FunctionComponent } from "react";
import NavigationRoutes from "./navigations/App";
import './App.css';
import { BrowserRouter, Outlet } from 'react-router-dom';
import { Container, ThemeProvider } from '@mui/material';
import theme from './theme';

const App: FunctionComponent = props => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <NavigationRoutes />
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;