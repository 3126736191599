import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import * as paquetesFactorajeActions from './redux/actions';
import { IFacturasPaqueteRequest, IPaquetesFactorajeRequest } from '../../../models/IPaquetesFactorajeRequest';
import * as R from 'ramda';
import { DatosFacturasPaquete, DatosPaquetesFactoraje } from '../../../models/IPaquetesFactorajeResponse';
import { getIconBoolean, getNumberToCurrency } from '../../../utils';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import theme from '../../../theme';
import { TableLoader } from '../../loader/TableLoader';
import { TableNotFoundData } from '../../notFoundData/TableNotFoundData';
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

export default function PaquetesFactoraje(dataSend: IPaquetesFactorajeRequest) {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover
        },
        '&:last-child td, &:last-child th': {
            border: 0
        },
    }));

    let datos = <TableLoader colums={10} text={'Buscando paquetes...'} />;

    let datosFP = <TableLoader colums={5} text={'Buscando facturas...'} />;

    let idPaqueteSend: IFacturasPaqueteRequest = {
        IdFactorajePaquete: ''
    };
    const dispatch = useDispatch();
    const paquetesFactorajeRequest = useCallback(() => dispatch(paquetesFactorajeActions.requestPaquetesFactoraje(dataSend)), [dispatch, dataSend]);
    const facturasPaqueteRequest = useCallback(() => dispatch(paquetesFactorajeActions.requestFacturasPaquete(idPaqueteSend)), [dispatch, idPaqueteSend]);
    const [openFacturas, setOpenFacturas] = React.useState(false);

    React.useEffect(() => {
        paquetesFactorajeRequest();
    }, []);

    const getFacturasPaquete = (idPaquete: string) => {
        idPaqueteSend = {
            IdFactorajePaquete: idPaquete
        };
        facturasPaqueteRequest();
    }

    const datosPaquetesFactoraje: any = useSelector((state: any) => state.paquetesFactoraje.data);
    const inProgressPaquetesFactoraje: any = useSelector((state: any) => state.paquetesFactoraje.inProgress);
    const datosFacturasPaquete: any = useSelector((state: any) => state.facturasPaquete.data);
    const inProgressFacturasPaquete: any = useSelector((state: any) => state.facturasPaquete.inProgress);

    function PopUpFacturas() {
        return (
            <Dialog
                key={'facturasPaquete'}
                open={openFacturas}
                fullWidth={true}
                maxWidth={'lg'}>
                <DialogContent sx={{ m: 2, textAlign: 'center' }} id="dialogContent">
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="customized table">
                            <TableBody>
                                {datosFP}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions sx={{ mb: 2, mr: 4 }} id="dialogActions">
                    <Button onClick={() => setOpenFacturas(false)}>Cerrar</Button>
                </DialogActions>
            </Dialog >
        );
    }

    if (!R.isNil(datosFacturasPaquete) && !inProgressFacturasPaquete) {
        datosFP = datosFacturasPaquete.map((factura: DatosFacturasPaquete) =>
            <StyledTableRow key={factura.id}>
                <StyledTableCell>
                    <Typography variant="body2" sx={{ mt: 1, mb: 1 }}><b>Cliente</b></Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>{factura.nombreReceptor}</Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}><b>Número de factura</b></Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>{factura.numeroFactura}</Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}><b>Monto total de la factura</b></Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>{getNumberToCurrency(factura.totalFactura)}</Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}><b>Moneda</b></Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>{factura.moneda}</Typography>
                </StyledTableCell>
                <StyledTableCell>
                    <Typography variant="body2" sx={{ mt: 1, mb: 1 }}><b>Tipo de cambio</b></Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>{getNumberToCurrency(factura.tipoDeCambio)}</Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}><b>Monto de la factura en MXN</b></Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>{getNumberToCurrency(factura.totalFacturaMxn)}</Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}><b>Depósito de PCH a {factura.nombreEmisor}</b></Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>{getNumberToCurrency(factura.depositoDePchaCliente)}</Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}><b>Fecha de depósito</b></Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>{factura.fechaDeposito != null ? moment(factura.fechaDeposito).format('dddd LL') : ""}</Typography>
                </StyledTableCell>
                <StyledTableCell>
                    <Typography variant="body2" sx={{ mt: 1, mb: 1 }}><b>Fecha de pago</b></Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>{factura.fechaPago != null ? moment(factura.fechaPago).format('dddd LL') : "Sin pagar"}</Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}><b>Fecha de vencimiento</b></Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>{factura.fechaVencimiento != null ? moment(factura.fechaVencimiento).format('dddd LL') : ""}</Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}><b>Días transcurridos</b></Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>{factura.diasInteresMoratorio ?? 0}</Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}><b>Intereses cobrados</b></Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>{getNumberToCurrency(factura.interesMoratorio)}</Typography>
                </StyledTableCell>
                <StyledTableCell>
                    <Typography variant="body2" sx={{ mt: 1, mb: 1 }}><b>Fecha del tipo de cambio</b></Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>{factura.fechaTipocambio != null ? moment(factura.fechaTipocambio).format('dddd LL') : "S/F"}</Typography>
                    <Typography variant="body2" sx={{ mt: 1 }}><b>Estatus</b></Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>{factura.estatusTransaccion}</Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}><b>Aforo</b></Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>{factura.aforo}</Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}><b>Clave de rastreo</b></Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>{factura.claveDeRastreo}</Typography>
                </StyledTableCell>
                <StyledTableCell>
                    <Typography variant="body2" sx={{ mt: 1, mb: 1 }}><b>RFC</b></Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>{factura.rfcReceptor}</Typography>
                    <Typography variant="body2" sx={{ mt: 1 }}><b>Comisión por cobro anticipado</b></Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>{getNumberToCurrency(factura.comisionCobroAnticipado)}</Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}><b>Estatus XML</b></Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>{factura.estatusXml}</Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}><b>Comisión aplicada real</b></Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>{factura.comisionAplicadaReal ?? "S/C"}</Typography>
                </StyledTableCell>
            </StyledTableRow>)
    }
    if (inProgressFacturasPaquete) {
        datosFP = <TableLoader colums={5} text={'Buscando facturas...'} />;
    }
    if (datosFacturasPaquete?.length == 0) {
        datosFP = <TableNotFoundData colums={10} text={'No se encontraron facturas.'} />;
    }

    if (!R.isNil(datosPaquetesFactoraje) && !inProgressPaquetesFactoraje) {
        datos = datosPaquetesFactoraje.map((paquete: DatosPaquetesFactoraje) =>
            <><StyledTableRow
                key={paquete.idPaquete}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <StyledTableCell>
                    <Typography variant="body2" sx={{ mb: 2 }}><b>Número: </b>{paquete.numeroPaquete}</Typography>
                    <Typography variant="body2" sx={{ mb: 2 }}><b>Clave interna: </b>{paquete.claveProductoInterna}</Typography>
                    <Typography variant="body2"><b>Fecha de carga: </b>{paquete.fechaCarga != null ? moment(paquete.fechaCarga).format('dddd LL') : ""}</Typography>
                </StyledTableCell>
                <StyledTableCell>{paquete.fechaVencimiento != null ? moment(paquete.fechaVencimiento).format('dddd LL') : ""}</StyledTableCell>
                <StyledTableCell>{paquete.facturasVigentes}</StyledTableCell>
                <StyledTableCell>{paquete.facturasCanceladas}</StyledTableCell>
                <StyledTableCell>{getNumberToCurrency(paquete.montoDispersado)}</StyledTableCell>
                <StyledTableCell>{paquete.claveDeRastreo}</StyledTableCell>
                <StyledTableCell>{paquete.estatusTransaccion}</StyledTableCell>
                <StyledTableCell>{getIconBoolean(paquete.dispersado)}</StyledTableCell>
                <StyledTableCell>{getIconBoolean(paquete.paquetePagado)}</StyledTableCell>
                <StyledTableCell style={{ width: '10%' }}>
                    <Button color='info'
                        onClick={() => (setOpenFacturas(true), getFacturasPaquete(paquete.idPaquete))}
                        startIcon={<VisibilityOutlinedIcon />}>Facturas</Button>
                </StyledTableCell>
            </StyledTableRow>
            </>)
    }
    if (inProgressPaquetesFactoraje) {
        datos = <TableLoader colums={10} text={'Buscando paquetes...'} />;
    }
    if (datosPaquetesFactoraje?.length == 0) {
        datos = <TableNotFoundData colums={10} text={'No se encontraron paquetes.'} />;
    }

    function TablaPaquetesFactoraje() {
        return (
            <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
                <Table sx={{ minWidth: 650 }} aria-label="customized table">
                    <TableBody>
                        <TableRow>
                            <TableCell><b>Datos del paquete</b></TableCell>
                            <TableCell><b>Fecha de vencimiento</b></TableCell>
                            <TableCell><b>Facturas vigentes</b></TableCell>
                            <TableCell><b>Facturas canceladas</b></TableCell>
                            <TableCell><b>Monto dispersión</b></TableCell>
                            <TableCell><b>Clave de rastreo</b></TableCell>
                            <TableCell><b>Estatus de transacción</b></TableCell>
                            <TableCell><b>Dispersión</b></TableCell>
                            <TableCell><b>Paquete pagado</b></TableCell>
                            <TableCell><b>Acciones</b></TableCell>
                        </TableRow>
                        {datos}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    return (
        <><Typography variant="h6" color="#2E2D2E" sx={{ mb: 4, mt: 2 }}>Paquetes</Typography>
            <TablaPaquetesFactoraje />
            <PopUpFacturas />
        </>
    );
}