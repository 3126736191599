const API = {
    CLIENTS: '/clients/',
    CATALOGS: '/catalogs/',
    COTIZADOR: '/cotizador/',
    SATWS: '/api/v1/Credentials/',
    WEBHOOK: '/api/v1/webHook/',
    SATWS_API: '/satws/',
    STP: '/stp/'
};

const RootApiConstants = {
    // CLIENTES 
    DATOS_CLIENTE: API.CLIENTS + 'datosGenerales',
    FINANCIAMIENTOS: API.CLIENTS + 'financiamientos',
    DISPOSICIONES: API.CLIENTS + 'disposiciones',
    AMORTIZACIONES: API.CLIENTS + 'amortizacionesDisposicionCero',
    EXPORTAR_ARCHIVO_AMORTIZACIONES: API.CLIENTS + 'exportarArchivoAmortizaciones',
    EXPORTAR_ARCHIVO_PAGOSREALIZADOS: API.CLIENTS + 'exportarArchivoPagosRealizados',
    EXPORTAR_ARCHIVO_DISPOSICIONES: API.CLIENTS + 'exportarArchivoDisposiciones',
    EXPORTAR_ARCHIVO_ACTIVOS: API.CLIENTS + 'exportarArchivoActivos',
    DATOS_FINANCIAMIENTO: API.CLIENTS + 'datosFinanciamiento',
    PAQUETES_FACTORAJE: API.CLIENTS + 'paquetesFactoraje',
    FACTURAS_PAQUETE: API.CLIENTS + 'facturasPaquete',
    VERIFICAR_USUARIO: API.CLIENTS + 'verificarUsuario',
    PAGOS_REALIZADOS: API.CLIENTS + 'pagosRealizados',
    RESUMEN_CUENTA_CORRIENTE: API.CLIENTS + 'resumenCuentaCorriente',
    ACTIVOS_ARRENDAMIENTO: API.CLIENTS + 'activosArrendamiento',
    AMORTIZACIONES_ACTIVO: API.CLIENTS + 'amortizacionesActivo',
    CUENTA_DEPOSITO: API.CLIENTS + 'cuentaDeposito',
    POST_CUENTA_DEPOSITO: API.CLIENTS + 'postCuentaDeposito',
    CONSULTA_FIND_VALID_STATUS: API.SATWS_API + 'getValidStatusResponse',
    CONSULTA_CIEC: API.SATWS_API + 'postCredentials',
    CONSULTA_WEBHOOK_STATUS: API.SATWS_API + 'getStatusWebHookResponse',
    AMORTIZACIONES_A_PAGAR: API.CLIENTS + 'amortizacionesAPagar',
    SIMULAR_PAGO: API.CLIENTS + 'simularPago',
    GENERAR_REFERENCIA_PAGO: API.CLIENTS + 'crearNumeroReferencia',
    GENERAR_REFERENCIA_PAGO_FACTORAJE: API.CLIENTS + 'crearNumeroReferenciaFactoraje',
    GENERAR_REFERENCIA_PAGO_ACTIVOS: API.CLIENTS + 'crearNumeroReferenciaActivos',
    PAQUETES_A_PAGAR: API.CLIENTS + 'paquetesAPagar',
    OPCIONES_CONTRATO_AP: API.CLIENTS + 'opcionesContratoAP',
    RESULTADO_OPCION_CONTRATO_AP: API.CLIENTS + 'resultadoOpcionContratoAP',
    // COTIZADOR
    RESULTADO_COTIZADOR_AP: API.COTIZADOR + 'resultadoCotizadorAP',
    AMORTIZACIONES_COTIZADOR_CS: API.COTIZADOR + 'amortizacionesCotizadorCS',
    RECALCULO_COTIZADOR_CS: API.COTIZADOR + 'recalculoCotizadorCS',
    ARCHIVO_RESULTADO_COTIZADOR_CS: API.COTIZADOR + 'archivoResultadoCotizadorCS',
    ARCHIVO_RESULTADO_COTIZADOR_AP: API.COTIZADOR + 'archivoResultadoCotizadorAP',
    // CATALOGOS 
    INSTITUCIONES_BANCARIAS: API.CATALOGS + 'institucionesBancarias',
    TIPO_CUENTA: API.CATALOGS + 'tipoCuenta',
    TIPO_ACTIVO: API.CATALOGS + 'tipoActivo',
    SUBTIPO_ACTIVO: API.CATALOGS + 'subtipoActivo',
    TIPO_ARRENDAMIENTO: API.CATALOGS + 'tipoArrendamiento',
    TIPO_PAGO_AP: API.CATALOGS + 'tipoPagoAP',
    PERIODICIDAD: API.CATALOGS + 'periodicidad',
    TIPO_PERSONA: API.CATALOGS + 'tipoPersona',
    TIPO_TASA: API.CATALOGS + 'tipoTasa',
    TIPO_TASA_MORATORIA: API.CATALOGS + 'tipoTasaMoratoria',
    TIPO_CALCULO: API.CATALOGS + 'tipoCalculo',
    VALOR_TIIE_ACTUAL: API.CATALOGS + 'valorTiieActual',
    // STP
    CONSULTA_COMPANIES: API.STP + 'getConsultaSaldos',
    CONSULTA_SUBCUENTAS: API.STP + 'getSubcuentas',
    CONSULTA_MOVIMIENTOS_SUBCUENTA: API.STP + 'getMovimientosSubcuenta',
    CHECK_ACCOUNT_BALANCE: API.STP + 'checkAccountBalance'
}

export default RootApiConstants;