import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import * as R from 'ramda';
import * as tipoPersonaActions from '../../../components/catalogs/TipoPersona/redux/actions'
import * as cotizadorCSActions from '../../../components/common/CotizadorCS/redux/actions'
import { Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, FormControlLabel, FormGroup, InputLabel, MenuItem, Radio, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { useParams } from 'react-router-dom';
import $ from 'jquery';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import BadParameters from '../../../screens/NotFound/BadParameters';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { getIconBoolean, getNumberToCurrency, PlazoArrendamiento, TasaM, TipoTasa } from '../../../utils';
import { StyledTableCell, StyledTableRow } from '../../styledTables/StyledTables';
import { ICondicionesCotizadorCS } from '../../../models/cotizador/ICondicionesCotizador';
import { DatosPeriodicidad } from '../../../models/catalogs/IPeriodicidadResponse';
import { IPlazoMeses } from '../../../models/cotizador/IPlazoMeses';
import { DatosResultadoCotizadorAP } from '../../../models/cotizador/IResultadoCotizadorAPResponse';
import { DatosAmortizacionesActivo } from '../../../models/IAmortizacionesActivoResponse';
import { IAmortizacionesCotizadorCSRequest, IArchivoResultadoCotizadorCSRequest, IDatosAdelantoCapital, IRecalculoCotizadorCSRequest } from '../../../models/cotizador/IAmortizacionesCotizadorCSRequest';
import moment from 'moment';
import 'moment/locale/es';
import { DatosAmortizacionesCotizadorCS } from '../../../models/cotizador/IAmortizacionesCotizadorCSResponse';
moment.locale('es');

export default function CotizadorCS() {
    const [condiciones, setCondiciones] = useState<ICondicionesCotizadorCS>();

    const { idCliente } = useParams();
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    let idClienteValid = regexExp.test(idCliente + '');
    const steps = ['Establecer condiciones de cotización', 'Otorgar gracia en cotización', 'Resultado'];
    const [activeStep, setActiveStep] = useState(0);

    let dataSendAmortizaciones: IAmortizacionesCotizadorCSRequest = {
        IdCliente: undefined,
        Condiciones: undefined
    };

    let dataSendRecalculo: IRecalculoCotizadorCSRequest = {
        IdCliente: undefined,
        Condiciones: undefined,
        PlazoCapital: undefined,
        PlazoInteres: undefined,
        AdelantoCapital: undefined
    };

    let dataSendArchivoResultado: IArchivoResultadoCotizadorCSRequest = {
        IdCliente: undefined,
        Condiciones: undefined,
        Amortizaciones: undefined
    };

    const dispatch = useDispatch();
    const catalogTipoPersonaRequest: any = useCallback(() => dispatch(tipoPersonaActions.requestTipoPersona('')), [dispatch, '']);
    const getAmortizacionesCotizadorCSRequest: any = useCallback(() => dispatch(cotizadorCSActions.requestAmortizacionesCotizadorCS(dataSendAmortizaciones)), [dispatch, dataSendAmortizaciones]);
    const getRecalculoCotizadorCSRequest: any = useCallback(() => dispatch(cotizadorCSActions.requestRecalculoCotizadorCS(dataSendRecalculo)), [dispatch, dataSendRecalculo]);
    const getArchivoResultadoCotizadorCSRequest: any = useCallback(() => dispatch(cotizadorCSActions.requestArchivoResultadoCotizadorCS(dataSendArchivoResultado)), [dispatch, dataSendArchivoResultado]);

    // CONDICIONES

    function FormularioCondicionesCotizador() {
        const [tipoPersona, setTipoPersona] = useState('');
        const [numeroPagos, setNumeroPagos] = useState('');
        const [periodicidad, setPeriodicidad] = useState('');
        const [comisionApertura, setComisionApertura] = useState('');
        const [cat, setCat] = useState('');
        const [tipoTasaOrdinaria, setTipoTasaOrdinaria] = useState('');
        const [tasaBaseOrdinaria, setTasaBaseOrdinaria] = useState('');
        const [puntosOrdinarios, setPuntosOrdinarios] = useState('');
        const [tasaNominalOrdinaria, setTasaNominalOrdinaria] = useState('');
        const [tasa, setTasa] = useState('');
        const [factor, setFactor] = useState('');
        const [tasaNominalMoratoria, setTasaNominalMoratoria] = useState('');
        const [tipoTasaMoratoria, setTipoTasaMoratoria] = useState('');
        const [tipoCalculo, setTipoCalculo] = useState('');
        const [tasaBaseMoratoria, setTasaBaseMoratoria] = useState('');
        const [puntosMoratorios, setPuntosMoratorios] = useState('');
        const [tasaNominalDefinir, setTasaNominalDefinir] = useState('');
        const [montoCredito, setMontoCredito] = useState('');

        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        const [fechaDisposicion, setFechaDisposicion] = useState(`${year}-${month < 10 ? `0${month}` : `${month}`}-${date < 10 ? `0${date}` : `${date}`}`);

        const [mostrarFactor, setMostrarFactor] = useState(false);
        const [mostrarDefinir, setMostrarDefinir] = useState(false);

        let datosTP = <MenuItem value=''>Seleccione el tipo de persona</MenuItem>
        let datosTT = <MenuItem value=''>Seleccione el tipo de tasa</MenuItem>
        let datosTTM = <MenuItem value=''>Seleccione el tipo de tasa moratoria</MenuItem>
        let datosP = <MenuItem value=''>Seleccione la periodicidad</MenuItem>
        let datosTC = <MenuItem value=''>Seleccione el tipo de cálculo</MenuItem>

        const datosTipoPersona: any = useSelector((state: any) => state.tipoPersona.data);
        const datosTipoTasa: any = useSelector((state: any) => state.tipoTasa.data);
        const datosTipoTasaMoratoria: any = useSelector((state: any) => state.tipoTasaMoratoria.data);
        const datosPeriodicidad: any = useSelector((state: any) => state.periodicidad.data);
        const datosTipoCalculo: any = useSelector((state: any) => state.tipoCalculo.data);
        const valorTiieActual: any = useSelector((state: any) => state.valorTiieActual.data);

        useEffect(() => {
            if (idClienteValid) {
                catalogTipoPersonaRequest();
            }
        }, []);

        if (!R.isNil(datosTipoPersona)) {
            datosTP = datosTipoPersona.map((tipo: DatosPeriodicidad) =>
                <MenuItem key={tipo.id} value={tipo.id}>{tipo.descripcion}</MenuItem>);
        }
        if (!R.isNil(datosTipoTasa)) {
            datosTT = datosTipoTasa.map((tipo: DatosPeriodicidad) =>
                <MenuItem key={tipo.id} value={tipo.id}>{tipo.descripcion}</MenuItem>);
        }
        if (!R.isNil(datosTipoTasaMoratoria)) {
            datosTTM = datosTipoTasaMoratoria.map((tipo: DatosPeriodicidad) =>
                <MenuItem key={tipo.id} value={tipo.id}>{tipo.descripcion}</MenuItem>);
        }
        if (!R.isNil(datosPeriodicidad)) {
            datosP = datosPeriodicidad.map((tipo: DatosPeriodicidad) =>
                <MenuItem key={tipo.id} value={tipo.id}>{tipo.descripcion}</MenuItem>);
        }
        if (!R.isNil(datosTipoCalculo)) {
            datosTC = datosTipoCalculo.map((tipo: DatosPeriodicidad) =>
                <MenuItem key={tipo.id} value={tipo.id}>{tipo.descripcion}</MenuItem>);
        }

        const submitCondiciones = (event: any) => {
            event.preventDefault();
            if (tasa != TasaM['Seleccionar']) {
                let condiciones: ICondicionesCotizadorCS = {
                    tipoPersona: tipoPersona,
                    numeroPagos: Number(numeroPagos),
                    periodicidad: periodicidad,
                    comisionApertura: Number(comisionApertura),
                    cat: Number(cat),
                    tipoTasaOrdinaria: tipoTasaOrdinaria,
                    tasaBaseOrdinaria: Number(tasaBaseOrdinaria),
                    puntosOrdinario: Number(puntosOrdinarios),
                    tasaNominalOrdinaria: Number(tasaNominalOrdinaria),
                    tasa: tasa,
                    factor: Number(factor),
                    tasaNominalMoratoria: Number(tasaNominalMoratoria),
                    tipoTasaMoratoria: tipoTasaMoratoria.length > 0 ? tipoTasaMoratoria : null,
                    tipoCalculo: tipoCalculo.length > 0 ? tipoCalculo : null,
                    tasaBaseMoratoria: Number(tasaBaseMoratoria),
                    puntosMoratorios: Number(puntosMoratorios),
                    tasaNominalDefinir: Number(tasaNominalDefinir),
                    montoCredito: Number(montoCredito),
                    fechaDisposicion: fechaDisposicion
                };
                setCondiciones(condiciones);
            }
        };

        const handleTipoTasaOrdinaria = (value: string) => {
            setTipoTasaOrdinaria(value)
            if (value == TipoTasa['Fijo']) {
                setTasaBaseOrdinaria('0');
                setTasaNominalOrdinaria('0');
            }
            else if (value == TipoTasa['Variable'] || value == TipoTasa['Dinamico']) {
                setTasaBaseOrdinaria(valorTiieActual);
                setTasaNominalOrdinaria(valorTiieActual);
            }
            setPuntosOrdinarios('');
        }

        const handleTasaBaseOrdinaria = (value: string) => {
            setTasaNominalOrdinaria((Number(value) + Number(puntosOrdinarios)).toFixed(2).toString())
            setTasaBaseOrdinaria(value);
            setTasaNominalMoratoria(((Number(value) + Number(puntosOrdinarios)) * Number(factor)).toFixed(2).toString())
        }

        const handlePuntosOrdinarios = (value: string) => {
            setTasaNominalOrdinaria((Number(tasaBaseOrdinaria) + Number(value)).toFixed(2).toString())
            setPuntosOrdinarios(value);
            setTasaNominalMoratoria(((Number(tasaBaseOrdinaria) + Number(value)) * Number(factor)).toFixed(2).toString())
        }

        const handleTasa = (value: string) => {
            setTasa(value)
            if (value == TasaM['Seleccionar']) {
                setMostrarFactor(false);
                setMostrarDefinir(false);
            }
            else if (value == TasaM['Factor']) {
                setMostrarDefinir(false);
                setMostrarFactor(true);
            }
            else if (value == TasaM['Definir']) {
                setMostrarFactor(false);
                setMostrarDefinir(true);
            }
        }

        const handleFactor = (value: string) => {
            setTasaNominalMoratoria((Number(tasaNominalOrdinaria) * Number(value)).toFixed(2).toString())
            setFactor(value);
        }

        const handleTipoTasaMoratoria = (value: string) => {
            setTipoTasaMoratoria(value)
            if (value == TipoTasa['Fijo']) {
                setTasaBaseMoratoria('0');
                setTasaNominalDefinir('0');
            }
            else if (value == TipoTasa['Variable'] || value == TipoTasa['Dinamico']) {
                setTasaBaseMoratoria(valorTiieActual);
                setTasaNominalDefinir(valorTiieActual);
            }
            setPuntosMoratorios('');
        }

        const handleTasaBaseMoratoria = (value: string) => {
            setTasaNominalDefinir((Number(value) + Number(puntosOrdinarios)).toFixed(2).toString())
            setTasaBaseMoratoria(value);
        }

        const handlePuntosMoratorios = (value: string) => {
            setTasaNominalDefinir((Number(tasaBaseOrdinaria) + Number(value)).toFixed(2).toString())
            setPuntosMoratorios(value);
        }

        function PopUpCatalogsCondiciones() {
            const inProgressTP: any = useSelector((state: any) => state.tipoPersona.inProgress);

            return (<Dialog
                open={(inProgressTP)}
                fullWidth={true}
                maxWidth={'xs'} >
                <DialogContent sx={{ m: 2, textAlign: 'center' }} id="dialogContent">
                    <DialogContentText>Cargando...</DialogContentText>
                </DialogContent>
            </Dialog>);
        }

        return (
            <Paper variant="elevation" sx={{ my: { xs: 4, md: 4 }, p: { xs: 4, md: 4 } }}>
                <Typography align="left" sx={{ mb: 4 }}><b>Establecer condiciones de cotización</b></Typography>
                <Typography align="left" sx={{ mb: 4 }}>Generales:</Typography>
                <Box component="form" onSubmit={(e) => submitCondiciones(e)}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth variant="standard">
                                <InputLabel id="labelIB">Tipo de persona *</InputLabel>
                                <Select
                                    required
                                    id="tipoPersona"
                                    label="Tipo de persona *"
                                    value={tipoPersona}
                                    onChange={(e) => setTipoPersona(e.target.value)}>
                                    {datosTP}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={8}></Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                required
                                id="numeroPagos"
                                label="Número de pagos"
                                fullWidth
                                autoComplete="off"
                                variant="standard"
                                type="number"
                                value={numeroPagos}
                                onChange={(e) => setNumeroPagos(e.target.value)} />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth variant="standard">
                                <InputLabel id="labelIB">Periodicidad *</InputLabel>
                                <Select
                                    required
                                    id="periodicidad"
                                    label="Periodicidad *"
                                    value={periodicidad}
                                    onChange={(e) => setPeriodicidad(e.target.value)}>
                                    {datosP}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                required
                                id="comisionApertura"
                                label="Comisión por apertura"
                                fullWidth
                                autoComplete="off"
                                variant="standard"
                                type="number"
                                value={comisionApertura}
                                onChange={(e) => setComisionApertura(e.target.value)}
                                error={Number(comisionApertura) > 100 ? true : false}
                                helperText={
                                    Number(comisionApertura) > 100 ?
                                        'La comisión por apertura no debe ser mayor a 100' : ''} />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                required
                                id="cat"
                                label="CAT"
                                fullWidth
                                autoComplete="off"
                                variant="standard"
                                type="number"
                                value={cat}
                                onChange={(e) => setCat(e.target.value)} />
                        </Grid>
                        <Grid item xs={12} sm={12}>Tasa ordinaria:</Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth variant="standard">
                                <InputLabel id="labelIB">Tipo de tasa *</InputLabel>
                                <Select
                                    required
                                    id="tipoTasaOrdinaria"
                                    label="Tipo de tasa *"
                                    value={tipoTasaOrdinaria}
                                    onChange={(e) => handleTipoTasaOrdinaria(e.target.value)}>
                                    {datosTT}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={8}></Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                required
                                disabled={tipoTasaOrdinaria == TipoTasa['Variable'] || tipoTasaOrdinaria == TipoTasa['Dinamico'] ? true : false}
                                id="tasaBaseOrdinaria"
                                label="Tasa base (TIIE a 28 días)"
                                fullWidth
                                autoComplete="off"
                                variant="standard"
                                type="number"
                                value={tasaBaseOrdinaria}
                                onChange={(e) => handleTasaBaseOrdinaria(e.target.value)} />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                required
                                id="puntosOrdinarios"
                                label="Puntos"
                                fullWidth
                                autoComplete="off"
                                variant="standard"
                                type="number"
                                value={puntosOrdinarios}
                                onChange={(e) => handlePuntosOrdinarios(e.target.value)} />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                required
                                disabled
                                id="tasaNominal"
                                label="Tasa nominal"
                                fullWidth
                                autoComplete="off"
                                variant="standard"
                                type="number"
                                value={tasaNominalOrdinaria}
                                onChange={(e) => setTasaNominalOrdinaria(e.target.value)} />
                        </Grid>
                        <Grid item xs={12} sm={12}>Tasa moratoria:</Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth variant="standard">
                                <InputLabel id="labelIB">Tasa *</InputLabel>
                                <Select
                                    required
                                    id="tasa"
                                    label="Tasa *"
                                    value={tasa}
                                    onChange={(e) => handleTasa(e.target.value)}>
                                    {datosTTM}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={8}></Grid>
                        <Grid item xs={12} sm={3} sx={{ display: mostrarFactor ? 'block' : 'none' }}>
                            <TextField
                                required={mostrarFactor}
                                id="factor"
                                label="Factor"
                                fullWidth
                                autoComplete="off"
                                variant="standard"
                                type="number"
                                value={factor}
                                onChange={(e) => handleFactor(e.target.value)} />
                        </Grid>
                        <Grid item xs={12} sm={3} sx={{ display: mostrarFactor ? 'block' : 'none' }}>
                            <TextField
                                required={mostrarFactor}
                                disabled
                                id="tasaNominalMoratoria"
                                label="Tasa nominal moratoria"
                                fullWidth
                                autoComplete="off"
                                variant="standard"
                                type="number"
                                value={tasaNominalMoratoria}
                                onChange={(e) => setTasaNominalMoratoria(e.target.value)} />
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ display: mostrarFactor ? 'block' : 'none' }}></Grid>
                        <Grid item xs={12} sm={3} sx={{ display: mostrarDefinir ? 'block' : 'none' }}>
                            <FormControl fullWidth variant="standard">
                                <InputLabel id="labelIB">Tipo de tasa *</InputLabel>
                                <Select
                                    required={mostrarDefinir}
                                    id="tipoTasaMoratoria"
                                    label="Tipo de tasa *"
                                    value={tipoTasaMoratoria}
                                    onChange={(e) => handleTipoTasaMoratoria(e.target.value)}>
                                    {datosTT}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3} sx={{ display: mostrarDefinir ? 'block' : 'none' }}>
                            <FormControl fullWidth variant="standard">
                                <InputLabel id="labelIB">Tipo de cálculo *</InputLabel>
                                <Select
                                    required={mostrarDefinir}
                                    id="tipoCalculo"
                                    label="Tipo de cálculo *"
                                    value={tipoCalculo}
                                    onChange={(e) => setTipoCalculo(e.target.value)}>
                                    {datosTC}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ display: mostrarDefinir ? 'block' : 'none' }}></Grid>
                        <Grid item xs={12} sm={3} sx={{ display: mostrarDefinir ? 'block' : 'none' }}>
                            <TextField
                                required={mostrarDefinir}
                                disabled={tipoTasaMoratoria == TipoTasa['Variable'] || tipoTasaMoratoria == TipoTasa['Dinamico'] ? true : false}
                                id="tasaBaseMoratoria"
                                label="Tasa base"
                                fullWidth
                                autoComplete="off"
                                variant="standard"
                                type="number"
                                value={tasaBaseMoratoria}
                                onChange={(e) => handleTasaBaseMoratoria(e.target.value)} />
                        </Grid>
                        <Grid item xs={12} sm={3} sx={{ display: mostrarDefinir ? 'block' : 'none' }}>
                            <TextField
                                required={mostrarDefinir}
                                id="puntosMoratorios"
                                label="Puntos"
                                fullWidth
                                autoComplete="off"
                                variant="standard"
                                type="number"
                                value={puntosMoratorios}
                                onChange={(e) => handlePuntosMoratorios(e.target.value)} />
                        </Grid>
                        <Grid item xs={12} sm={3} sx={{ display: mostrarDefinir ? 'block' : 'none' }}>
                            <TextField
                                required={mostrarDefinir}
                                disabled
                                id="tasaNominalDefinir"
                                label="Tasa nominal"
                                fullWidth
                                autoComplete="off"
                                variant="standard"
                                type="number"
                                value={tasaNominalDefinir}
                                onChange={(e) => setTasaNominalDefinir(e.target.value)} />
                        </Grid>
                        <Grid item xs={12} sm={3} sx={{ display: mostrarDefinir ? 'block' : 'none' }}></Grid>
                        <Grid item xs={12} sm={12}>Disposición de la cotización:</Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                required
                                id="montoCredito"
                                label="Monto del crédito"
                                fullWidth
                                autoComplete="off"
                                variant="standard"
                                type="number"
                                value={montoCredito}
                                onChange={(e) => setMontoCredito(e.target.value)} />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                required
                                id="fechaDisposicion"
                                label="Fecha de la disposición"
                                fullWidth
                                autoComplete="off"
                                variant="standard"
                                type="date"
                                value={fechaDisposicion}
                                onChange={(e) => setFechaDisposicion(e.target.value)} />
                        </Grid>
                        <Grid item xs={12} sm={3}></Grid>
                        <Grid item xs={12} sm={10}></Grid>
                        <Grid item xs={12} sm={2}>
                            <Button
                                variant="contained"
                                type="submit"
                                sx={{ backgroundColor: '#16a0ff', mt: 1.5 }}
                                fullWidth>Guardar</Button>
                        </Grid>
                    </Grid>
                </Box>
                <PopUpCatalogsCondiciones />
            </Paper>
        );
    }

    // GRACIA EN COTIZACIÓN

    function OtorgarGraciaCotizacion() {
        const [checkedStateGraciaCapital, setCheckedStateGraciaCapital] = useState(new Array(0));
        let [listGraciaCapital, setListGraciaCapital] = useState(new Array(0));
        const [checkedStateGraciaInteres, setCheckedStateGraciaInteres] = useState(new Array(0));
        let [listGraciaInteres, setListGraciaInteres] = useState(new Array(0));
        const [adelantoCapital, setAdelantoCapital] = useState(new Array(0));
        let [listAdelantoCapital, setListAdelantoCapital] = useState(new Array(0));

        let datosAmortizacionesCotizadorCS = useSelector((state: any) => state.amortizacionesCotizadorCS.data);
        const datosRecalculoCotizadorCS = useSelector((state: any) => state.recalculoCotizadorCS.data);

        useEffect(() => {
            dataSendAmortizaciones = {
                IdCliente: idCliente,
                Condiciones: condiciones
            };
            getAmortizacionesCotizadorCSRequest();
        }, []);

        const submitGraciaCotizacion = (e: any) => {
            e.preventDefault();
            var hash: any = {};
            listGraciaCapital = listGraciaCapital.filter(function (current) {
                var exists = !hash[current];
                hash[current] = true;
                return exists;
            });
            listGraciaInteres = listGraciaInteres.filter(function (current) {
                var exists = !hash[current.id];
                hash[current.id] = true;
                return exists;
            });
            listAdelantoCapital = listAdelantoCapital.filter(function (current) {
                var exists = !hash[current.numeroPago];
                hash[current.numeroPago] = true;
                return exists;
            });
            dataSendRecalculo = {
                IdCliente: idCliente,
                Condiciones: condiciones,
                PlazoCapital: listGraciaCapital,
                PlazoInteres: listGraciaInteres,
                AdelantoCapital: listAdelantoCapital
            };
            getRecalculoCotizadorCSRequest();
        }

        useEffect(() => {
            checkedStateGraciaCapital?.map((checked: boolean, index: number) => {
                if (checked) {
                    setListGraciaCapital(prev => [...prev, (index + 1).toString()]);
                }
            });
        }, [checkedStateGraciaCapital]);

        useEffect(() => {
            checkedStateGraciaInteres?.map((checked: boolean, index: number) => {
                if (checked) {
                    setListGraciaInteres(prev => [...prev, (index + 1).toString()]);
                }
            });
        }, [checkedStateGraciaInteres]);

        useEffect(() => {
            adelantoCapital?.map((value: string, index: number) => {
                if (Number(value) > 0) {
                    let datos: IDatosAdelantoCapital = {
                        numeroPago: index + 1,
                        motoAdelanto: Number(value)
                    };
                    setListAdelantoCapital(prev => [...prev, datos]);
                }
            });
        }, [adelantoCapital]);

        function PopUpAmortizacionesCotizador() {
            const inProgressAmortizacionesCotizadorCS = useSelector((state: any) => state.amortizacionesCotizadorCS.inProgress);
            const inProgressRecalculoCotizadorCS = useSelector((state: any) => state.recalculoCotizadorCS.inProgress);

            return (<Dialog
                open={(inProgressAmortizacionesCotizadorCS || inProgressRecalculoCotizadorCS)}
                fullWidth={true}
                maxWidth={'xs'} >
                <DialogContent sx={{ m: 2, textAlign: 'center' }} id="dialogContent">
                    <DialogContentText>Cargando...</DialogContentText>
                </DialogContent>
            </Dialog>);
        }

        useEffect(() => {
            setCheckedStateGraciaCapital(new Array(datosAmortizacionesCotizadorCS?.length).fill(false))
            setCheckedStateGraciaInteres(new Array(datosAmortizacionesCotizadorCS?.length).fill(false))
            setAdelantoCapital(new Array(datosAmortizacionesCotizadorCS?.length).fill(''))
        }, [!R.isNil(datosAmortizacionesCotizadorCS)]);

        const handleOnChangeGraciaCapital = (position: number) => {
            const updatedCheckedStateGraciaCapital = checkedStateGraciaCapital.map((item, index) =>
                index === position ? !item : item
            );
            setCheckedStateGraciaCapital(updatedCheckedStateGraciaCapital);
        }

        const handleOnChangeGraciaInteres = (position: number) => {
            const updatedCheckedStateGraciaInteres = checkedStateGraciaInteres.map((item, index) =>
                index === position ? !item : item
            );
            setCheckedStateGraciaInteres(updatedCheckedStateGraciaInteres);
        }

        const handleAdelantoCapital = (position: number, value: string) => {
            const updatedAdelantoCapital = adelantoCapital.map((item, index) =>
                index === position ? value : item
            );
            setAdelantoCapital(updatedAdelantoCapital);
        }

        if (!R.isNil(datosRecalculoCotizadorCS) && datosRecalculoCotizadorCS.length > 0) {
            datosAmortizacionesCotizadorCS = datosRecalculoCotizadorCS;
        }

        return (
            <Paper variant="elevation" sx={{ my: { xs: 4, md: 4 }, p: { xs: 4, md: 4 } }}>
                <Typography align="left" sx={{ mb: 4 }}><b>Otorgar gracia en cotización</b></Typography>
                <Box component="form" onSubmit={(e) => submitGraciaCotizacion(e)}>
                    <TableContainer>
                        <Table sx={{ minWidth: 650 }} aria-label="customized table">
                            <TableBody>
                                <TableRow>
                                    <TableCell><b>Pago</b></TableCell>
                                    <TableCell><b>Fecha</b></TableCell>
                                    <TableCell><b>Capital</b></TableCell>
                                    <TableCell><b>Intereses</b></TableCell>
                                    <TableCell><b>IVA de intereses</b></TableCell>
                                    <TableCell><b>Condiciones del periodo</b></TableCell>
                                    <TableCell><b>Pago total</b></TableCell>
                                    <TableCell><b>Capital pendiente</b></TableCell>
                                    <TableCell><b>Gracia de capital</b></TableCell>
                                    <TableCell><b>Gracia de interés</b></TableCell>
                                    <TableCell><b>Adelanto a capital</b></TableCell>
                                </TableRow>
                                {!R.isNil(datosAmortizacionesCotizadorCS) && datosAmortizacionesCotizadorCS.length > 0 &&
                                    <TableRow sx={{ backgroundColor: '#bae3ff' }}>
                                        <TableCell><b>Totales:</b></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell><b>{getNumberToCurrency(datosAmortizacionesCotizadorCS.reduce((a: number, b: DatosAmortizacionesCotizadorCS) => a + (b.capitalApagar ?? 0), 0))}</b></TableCell>
                                        <TableCell><b>{getNumberToCurrency(datosAmortizacionesCotizadorCS.reduce((a: number, b: DatosAmortizacionesCotizadorCS) => a + (b.interesApagar ?? 0), 0))}</b></TableCell>
                                        <TableCell><b>{getNumberToCurrency(datosAmortizacionesCotizadorCS.reduce((a: number, b: DatosAmortizacionesCotizadorCS) => a + (b.ivaDeInteresesApagar ?? 0), 0))}</b></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell><b>{getNumberToCurrency(datosAmortizacionesCotizadorCS.reduce((a: number, b: DatosAmortizacionesCotizadorCS) => a + (b.pagoTotal ?? 0), 0))}</b></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                }
                                {!R.isNil(datosAmortizacionesCotizadorCS) && datosAmortizacionesCotizadorCS.length > 0 &&
                                    datosAmortizacionesCotizadorCS.map((amortizacion: DatosAmortizacionesCotizadorCS, index: number) =>
                                        <StyledTableRow key={amortizacion.id}>
                                            <StyledTableCell>{amortizacion.numeroPago}</StyledTableCell>
                                            <StyledTableCell>{amortizacion.fechaPago != null ? moment(amortizacion.fechaPago).format('dddd LL') : ""}</StyledTableCell>
                                            <StyledTableCell>{getNumberToCurrency(amortizacion.capitalApagar)}</StyledTableCell>
                                            <StyledTableCell>{getNumberToCurrency(amortizacion.interesApagar)}</StyledTableCell>
                                            <StyledTableCell>{getNumberToCurrency(amortizacion.ivaDeInteresesApagar)}</StyledTableCell>
                                            <StyledTableCell>
                                                <Typography sx={{ mb: 1, fontSize: 12 }}>TO = {condiciones?.tasaNominalOrdinaria}</Typography>
                                                <Typography sx={{ fontSize: 12 }}>TM = {Number(condiciones?.tasaNominalMoratoria) > 0 ? condiciones?.tasaNominalMoratoria : condiciones?.tasaNominalDefinir}</Typography>
                                            </StyledTableCell>
                                            <StyledTableCell>{getNumberToCurrency(amortizacion.pagoTotal)}</StyledTableCell>
                                            <StyledTableCell>{getNumberToCurrency(amortizacion.capitalPendiente)}</StyledTableCell>
                                            <StyledTableCell>
                                                {index != (datosAmortizacionesCotizadorCS.length - 1) &&
                                                    <Checkbox
                                                        checked={checkedStateGraciaCapital[index]}
                                                        onChange={() => handleOnChangeGraciaCapital(index)} />
                                                }
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {index != (datosAmortizacionesCotizadorCS.length - 1) &&
                                                    <Checkbox
                                                        checked={checkedStateGraciaInteres[index]}
                                                        onChange={() => handleOnChangeGraciaInteres(index)} />
                                                }
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {index != (datosAmortizacionesCotizadorCS.length - 1) &&
                                                    <TextField
                                                        label="$"
                                                        fullWidth
                                                        autoComplete="off"
                                                        variant="standard"
                                                        type="number"
                                                        value={adelantoCapital[index]}
                                                        onChange={(e) => handleAdelantoCapital(index, e.target.value)} />
                                                }
                                            </StyledTableCell>
                                        </StyledTableRow>)
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid container spacing={6}>
                        <Grid item xs={12} sm={10}></Grid>
                        <Grid item xs={12} sm={2}>
                            <Button
                                variant="contained"
                                type='submit'
                                sx={{ backgroundColor: '#16a0ff', mt: 4 }}
                                fullWidth>Calcular</Button>
                        </Grid>
                    </Grid>
                    <PopUpAmortizacionesCotizador />
                </Box>
            </Paper>
        );
    }

    // RESULTADO

    function ResultadoCotizador() {
        let datosAmortizacionesCotizadorCS = useSelector((state: any) => state.amortizacionesCotizadorCS.data);
        const datosRecalculoCotizadorCS = useSelector((state: any) => state.recalculoCotizadorCS.data);

        const datosArchivoResultadoCotizadorCS = useSelector((state: any) => state.archivoResultadoCotizadorCS);
        const inProgressArchivoResultadoCotizadorCS = useSelector((state: any) => state.archivoResultadoCotizadorCS.inPorgress);
        const [openGeneracionArchivo, setOpenGeneracionArchivo] = React.useState(false);

        if (!R.isNil(datosRecalculoCotizadorCS) && datosRecalculoCotizadorCS.length > 0) {
            datosAmortizacionesCotizadorCS = datosRecalculoCotizadorCS;
        }

        const exportCotizacionCS = (e: any) => {
            e.preventDefault();
            dataSendArchivoResultado = {
                IdCliente: idCliente,
                Condiciones: condiciones,
                Amortizaciones: datosAmortizacionesCotizadorCS
            };

            setOpenGeneracionArchivo(true)
            getArchivoResultadoCotizadorCSRequest();
        };

        let mensajeGeneracionArchivo = <Typography variant='body1'>Generando...</Typography>

        if (!R.isNil(datosArchivoResultadoCotizadorCS.data) && !inProgressArchivoResultadoCotizadorCS) {
            mensajeGeneracionArchivo =
                <Typography sx={{ color: datosArchivoResultadoCotizadorCS.succeeded ? 'success.main' : 'error.main' }}>{datosArchivoResultadoCotizadorCS.message}</Typography>
            setTimeout(() => {
                setOpenGeneracionArchivo(false);
            }, 3000);
        }

        return (
            <Paper variant="elevation" sx={{ my: { xs: 4, md: 4 }, p: { xs: 4, md: 4 } }}>
                <Typography align="left" sx={{ mb: 4 }}><b>Resultado</b></Typography>
                <Box component="form" onSubmit={(e) => exportCotizacionCS(e)}>
                    <TableContainer>
                        <Table sx={{ minWidth: 650 }} aria-label="customized table">
                            <TableBody>
                                <TableRow>
                                    <TableCell><b>Pago</b></TableCell>
                                    <TableCell><b>Fecha</b></TableCell>
                                    <TableCell><b>Capital</b></TableCell>
                                    <TableCell><b>Intereses</b></TableCell>
                                    <TableCell><b>IVA de intereses</b></TableCell>
                                    <TableCell><b>Pago total</b></TableCell>
                                    <TableCell><b>Capital pendiente</b></TableCell>
                                </TableRow>
                                {!R.isNil(datosAmortizacionesCotizadorCS) && datosAmortizacionesCotizadorCS.length > 0 &&
                                    <TableRow sx={{ backgroundColor: '#bae3ff' }}>
                                        <TableCell><b>Totales:</b></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell><b>{getNumberToCurrency(datosAmortizacionesCotizadorCS.reduce((a: number, b: DatosAmortizacionesCotizadorCS) => a + (b.capitalApagar ?? 0), 0))}</b></TableCell>
                                        <TableCell><b>{getNumberToCurrency(datosAmortizacionesCotizadorCS.reduce((a: number, b: DatosAmortizacionesCotizadorCS) => a + (b.interesApagar ?? 0), 0))}</b></TableCell>
                                        <TableCell><b>{getNumberToCurrency(datosAmortizacionesCotizadorCS.reduce((a: number, b: DatosAmortizacionesCotizadorCS) => a + (b.ivaDeInteresesApagar ?? 0), 0))}</b></TableCell>
                                        <TableCell><b>{getNumberToCurrency(datosAmortizacionesCotizadorCS.reduce((a: number, b: DatosAmortizacionesCotizadorCS) => a + (b.pagoTotal ?? 0), 0))}</b></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                }
                                {!R.isNil(datosAmortizacionesCotizadorCS) && datosAmortizacionesCotizadorCS.length > 0 &&
                                    datosAmortizacionesCotizadorCS.map((amortizacion: DatosAmortizacionesCotizadorCS, index: number) =>
                                        <StyledTableRow key={amortizacion.id}>
                                            <StyledTableCell>{amortizacion.numeroPago}</StyledTableCell>
                                            <StyledTableCell>{amortizacion.fechaPago != null ? moment(amortizacion.fechaPago).format('dddd LL') : ""}</StyledTableCell>
                                            <StyledTableCell>{getNumberToCurrency(amortizacion.capitalApagar)}</StyledTableCell>
                                            <StyledTableCell>{getNumberToCurrency(amortizacion.interesApagar)}</StyledTableCell>
                                            <StyledTableCell>{getNumberToCurrency(amortizacion.ivaDeInteresesApagar)}</StyledTableCell>
                                            <StyledTableCell>{getNumberToCurrency(amortizacion.pagoTotal)}</StyledTableCell>
                                            <StyledTableCell>{getNumberToCurrency(amortizacion.capitalPendiente)}</StyledTableCell>
                                        </StyledTableRow>)
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid container spacing={6}>
                        <Grid item xs={12} sm={10}></Grid>
                        <Grid item xs={12} sm={2}>
                            <Button
                                variant="contained"
                                type='submit'
                                sx={{ backgroundColor: '#16a0ff', mt: 4 }}
                                fullWidth>Exportar a PDF</Button>
                        </Grid>
                    </Grid>
                </Box>
                <Dialog
                    key={'archivoExportado'}
                    open={openGeneracionArchivo}
                    fullWidth={true}
                    maxWidth={'xs'}>
                    <DialogContent sx={{ m: 2, textAlign: 'center' }} id="dialogContent">
                        <DialogContentText>
                            {mensajeGeneracionArchivo}
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </Paper>
        );
    }

    if (!idClienteValid) {
        return (<BadParameters />);
    }

    return (
        <Box sx={{ width: '100%', mt: 2 }}>
            <Stepper activeStep={activeStep}>
                {steps.map((label) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                        optional?: React.ReactNode;
                    } = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep == 0 &&
                <><FormularioCondicionesCotizador />
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button
                            variant='contained'
                            disabled
                            onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
                            sx={{ mr: 2 }}>
                            Atrás
                        </Button>
                        <Button
                            disabled={!R.isNil(condiciones) ? false : true}
                            onClick={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
                            variant='contained'>
                            {activeStep == steps.length - 1 ? 'Terminar' : 'Siguiente'}
                        </Button>
                    </Box></>
            }
            {activeStep == 1 &&
                <><OtorgarGraciaCotizacion />
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button
                            variant='contained'
                            onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
                            sx={{ mr: 2 }}>
                            Atrás
                        </Button>
                        <Button
                            onClick={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
                            variant='contained'>
                            {activeStep == steps.length - 1 ? 'Terminar' : 'Siguiente'}
                        </Button>
                    </Box></>
            }
            {activeStep == 2 &&
                <><ResultadoCotizador />
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button
                            variant='contained'
                            onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
                            sx={{ mr: 2 }}>
                            Atrás
                        </Button>
                        {/* <Button
                            onClick={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
                            variant='contained'>
                            {activeStep == steps.length - 1 ? 'Terminar' : 'Siguiente'}
                        </Button> */}
                    </Box></>
            }
            {/* {activeStep == steps.length &&
                <><Typography sx={{ mt: 2, mb: 1 }}>
                    Terminado
                </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                    </Box></>
            } */}
        </Box>
    );
}