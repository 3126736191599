import { DatosAmortizaciones } from '../../../../models/IAmortizacionesResponse';
import * as types from '../../../../system/types'

//#region AMORTIZACIONES
export interface AmortizacionesState {
    id: string,
    // errors: [],
    data: Array<DatosAmortizaciones> | null,
    inProgress: boolean,
    message?: string | null
}

const AmortizacionesInitialState: AmortizacionesState = {
    id: '',
    // errors: [],
    data: null,
    inProgress: false
};

export function amortizacionesReducer(
    state: AmortizacionesState = AmortizacionesInitialState,
    action: types.RootAction
): AmortizacionesState {
    switch (action.type) {
        case types.AMORTIZACIONES_REQUEST:
            return {
                ...state,
                message: '',
                inProgress: true
            }
        case types.AMORTIZACIONES_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                inProgress: false
            }
        case types.AMORTIZACIONES_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}
//#endregion

//#region EXPORTAR ARCHIVO
export interface ExportarArchivoAmortizacionesState {
    id: string,
    // errors: [],
    data: any | null,
    inProgress: boolean,
    succeeded: boolean,
    message?: string | null
}

const ExportarArchivoAmortizacionesInitialState: ExportarArchivoAmortizacionesState = {
    id: '',
    // errors: [],
    data: null,
    succeeded: false,
    inProgress: false
};

export function exportarArchivoAmortizacionesReducer(
    state: ExportarArchivoAmortizacionesState = ExportarArchivoAmortizacionesInitialState,
    action: types.RootAction
): ExportarArchivoAmortizacionesState {
    switch (action.type) {
        case types.EXPORTARARCHIVOAMORTIZACIONES_REQUEST:
            return {
                ...state,
                message: '',
                succeeded: false,
                inProgress: true
            }
        case types.EXPORTARARCHIVOAMORTIZACIONES_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                succeeded: action.succeeded,
                inProgress: false
            }
        case types.EXPORTARARCHIVOAMORTIZACIONES_FAILED:
            return {
                ...state,
                message: action.message,
                succeeded: false,
                inProgress: false
            }
        default:
            return state;
    }
}
//#endregion