import { take, put, call, fork, select, takeEvery, all } from 'redux-saga/effects';
import * as sagaController from '../../../../controllers/sagaController';
import * as types from '../../../../system/types';
import * as R from 'ramda';
import * as actions from './actions';
import * as rootActions from '../../../../system/actions';
import { Login } from '../../../../api/Clients';
import { IResponse } from '../../../../models/IResponse';
import { AxiosResponse } from 'axios';
import { ILoginResponse } from '../../../../models/ILoginResponse';
import { useAuth } from '../../../../navigations/App';
import { GetOToken } from '../../../../utils';

export function* loginRequestAsync(action: types.requestLoginAction) {
    const response: ILoginResponse = yield call(Login, action.data);
    // console.log(response);
    try {
        const {
            data,
            code,
            status,
            statusText,
            // headers,
            // config,
            // request,
        } = response;
        if (!R.isNil(data)) {
            // let partir = data.jwToken.split('.');
            // if(partir.length == 3){
            //     let token = partir[1];
            //     // let deserealizacion = global.Buffer.from(partir[1], 'base64').toString('ascii');
            //     data.token = token;
            // }
            yield sagaController.controlledStates(response, action.type, status);
        } else {
            yield sagaController.controlledStates(response, action.type, 400);
        }
        // const { response, error } = yield call(LoginUser, data);
        // if (R.isNil(error)) {
        //     console.log("OK")
        //     const { status, data } = response;
        //     //    let oResponse = {
        //     //      token:''
        //     //    };
        //     if (response) {
        //         //     let resSplit = data.data.jwToken.split('.');
        //         //     if (resSplit.length == 3) {
        //         //       oResponse = { ...data.data, ...JSON.parse(global.Buffer.from(resSplit[1], 'base64').toString('ascii')) };
        //         //       oResponse.token = resSplit[1];
        //         //     }
        //     }
        //     //   oResponse.message = data.message;
        //     //   oResponse.errors = data.errors;
        //     //   oResponse.isLoggedIn = data.succeeded;
        //     // yield sagaController.controlledStates(response, types.LOGIN_RESPONSE, 200);
        // } else {
        //     // yield sagaController.controlledStates(response, types.LOGIN_FAILED, 400);
        // }
    } catch (error) {
        console.log(error);
    }
}
export function* singinRequestAsync(action: types.requestLoginByTokenAction) {
    if (!R.isNil(action.token)) {
        let authorization = GetOToken(action.token);
        let sToken = localStorage.getItem('token')
        if (sToken == undefined) {
            localStorage.setItem('token', action.token);
        }
        yield sagaController.controlledStates(authorization, action.type, 200);
    } else {
        yield sagaController.controlledStates(null, action.type, 400);
    }
}

export function* logoutRequestAsync(action: types.requestLogoutAction) {
    localStorage.setItem('token', '');
    yield sagaController.controlledStates(null, action.type, 200);
}

export default function* loginSaga() {
    yield all([
        takeEvery(types.LOGIN_REQUEST, loginRequestAsync),
        takeEvery(types.LOGIN_BY_TOKEN_REQUEST, singinRequestAsync),
        takeEvery(types.LOGOUT_REQUEST, logoutRequestAsync)
    ])
}