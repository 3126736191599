import { DatosTipoCalculo } from '../../../../models/catalogs/ITipoCalculoResponse';
import * as types from '../../../../system/types'

export interface TipoCalculoState {
    id: string,
    // errors: [],
    inProgress: boolean,
    data: Array<DatosTipoCalculo> | null,
    message?: string | null
}

const TipoCalculoInitialState: TipoCalculoState = {
    id: '',
    // errors: [],
    data: null,
    inProgress: false
};

export default function tipoCalculoReducer(
    state: TipoCalculoState = TipoCalculoInitialState,
    action: types.RootAction
): TipoCalculoState {
    switch (action.type) {
        case types.TIPOCALCULO_REQUEST:
            return {
                ...state,
                message: '',
                inProgress: true
            }
        case types.TIPOCALCULO_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                inProgress: false
            }
        case types.TIPOCALCULO_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}