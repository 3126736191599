import * as React from 'react';
import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import Header from '../../components/common/AppBar';
import * as R from 'ramda';
import Footer from '../../components/common/Footer';
import { allProps, GuidValid, TabPanel, TipoFinanciamiento } from '../../utils';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import BadParameters from '../NotFound/BadParameters';
import CotizadorAP from '../../components/common/CotizadorAP/CotizadorAP';
import CotizadorCS from '../../components/common/CotizadorCS/CotizadorCS';

export default function Cotizador() {
    const idCliente: string = useSelector((state: any) => state.login.id);

    let idClienteValid = GuidValid(idCliente);

    function Cotizadores() {
        const [parent, setParent] = React.useState(0);

        const changeParent = (event: React.SyntheticEvent, newValue: number) => {
            setParent(newValue);
        };

        return (
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={parent} onChange={changeParent} aria-label="basic tabs example">
                        <Tab label="Arrendamiento Puro" {...allProps(0)} />
                        <Tab label="Crédito Simple" {...allProps(1)} />
                    </Tabs>
                </Box>
                <TabPanel value={parent} index={0}>
                    <CotizadorAP />
                </TabPanel>
                <TabPanel value={parent} index={1}>
                    <CotizadorCS />
                </TabPanel>
            </Box>
        );
    }

    if (!idClienteValid) {
        return (<BadParameters />);
    }

    return (
        <Box sx={{ m: 4 }}>
            <Cotizadores />
        </Box>
    );
}