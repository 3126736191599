import { DatosResultadoCotizadorAP } from '../../../../models/cotizador/IResultadoCotizadorAPResponse';
import * as types from '../../../../system/types'

//#region RESULTADO COTIZADOR AP
export interface ResultadoCotizadorAPState {
    id: string,
    // errors: [],
    inProgress: boolean,
    data: Array<DatosResultadoCotizadorAP> | null,
    message?: string | null
}

const ResultadoCotizadorAPInitialState: ResultadoCotizadorAPState = {
    id: '',
    // errors: [],
    data: null,
    inProgress: false
};

export function resultadoCotizadorAPReducer(
    state: ResultadoCotizadorAPState = ResultadoCotizadorAPInitialState,
    action: types.RootAction
): ResultadoCotizadorAPState {
    switch (action.type) {
        case types.RESULTADOCOTIZADORAP_REQUEST:
            return {
                ...state,
                message: '',
                inProgress: true
            }
        case types.RESULTADOCOTIZADORAP_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                inProgress: false
            }
        case types.RESULTADOCOTIZADORAP_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}
//#endregion

//#region ARCHIVO RESULTADO COTIZADOR AP
export interface ArchivoResultadoCotizadorAPState {
    id: string,
    // errors: [],
    inProgress: boolean,
    data: any | null,
    succeeded: boolean,
    message?: string | null
}

const ArchivoResultadoCotizadorAPInitialState: ArchivoResultadoCotizadorAPState = {
    id: '',
    // errors: [],
    data: null,
    succeeded: false,
    inProgress: false
};

export function archivoResultadoCotizadorAPReducer(
    state: ArchivoResultadoCotizadorAPState = ArchivoResultadoCotizadorAPInitialState,
    action: types.RootAction
): ArchivoResultadoCotizadorAPState {
    switch (action.type) {
        case types.ARCHIVORESULTADOCOTIZADORAP_REQUEST:
            return {
                ...state,
                message: '',
                succeeded: false,
                inProgress: true
            }
        case types.ARCHIVORESULTADOCOTIZADORAP_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                succeeded: action.succeeded,
                inProgress: false
            }
        case types.ARCHIVORESULTADOCOTIZADORAP_FAILED:
            return {
                ...state,
                message: action.message,
                succeeded: false,
                inProgress: false
            }
        default:
            return state;
    }
}
//#endregion