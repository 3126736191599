import { env } from "../api/env/index";
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import ToysOutlinedIcon from '@mui/icons-material/ToysOutlined';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import DoNotDisturbAltOutlinedIcon from '@mui/icons-material/DoNotDisturbAltOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Box, Typography } from "@mui/material";
import { PATH_SECURE } from "../system/selector";
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export const GetResponseErrors = (response: any) => {
    let errors = new Array<string>;
    let rootProperties = Object.keys(response);
    if (rootProperties.find((key) => key == 'errors') != undefined) {
        if (response.errors != undefined && !Array.isArray(response.errors)) {
            for (const nameElement in response.errors) {
                response.errors[nameElement].map((msg: string, i: number) => errors.push(msg))
            }
        }
        else if (Array.isArray(response.errors)) {
            response.errors.map((msg: string, i: number) => errors.push(msg))
        }
    }
    return errors;
}

export function getNumberToCurrency(numero: number) {
    if (numero == null) {
        return '$0.00';
    }
    return numero?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
    });
}

export function convertSecondsToDateTime(seconds: number) {
    const output = new Date((seconds * 1000) - 21600000).toLocaleString('es-MX', { hour12: true });
    return output;
}

export function convertSecondsToDate(seconds: number) {
    const output = new Date((seconds * 1000) - 21600000).toLocaleDateString('es-MX', { hour12: true });
    return output;
}

export function convertSecondsToTime(seconds: number) {
    const output = new Date((seconds * 1000) - 21600000).toLocaleTimeString('es-MX', { hour12: true });
    return output;
}

export function getPagoEnganche(idCobranza: string, enganche: number) {
    if (idCobranza == null) {
        return '$0.00';
    }
    else if (idCobranza == '00000000-0000-0000-0000-000000000000')
        return enganche?.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
        });
}

export function GuidValid(guid: string) {
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    return regexExp.test(guid);
}

export function getLinkFinanciamiento(tipo: string, idForoCondiciones: string, idFinanciamientoSolicitado: string) {
    return (
        PATH_SECURE + '/' + tipo.replace(' ', '') + '?idFC=' + idForoCondiciones + '&idFS=' + idFinanciamientoSolicitado
    ).toString();
}

export function getIconFinanciamiento(idTipoFinanciamiento: string) {
    switch (idTipoFinanciamiento) {
        case '00000000-0000-0000-0000-000000000001':
            return (<ToysOutlinedIcon sx={{ mr: 2, color: 'primary.main' }} />);
        case '00000000-0000-0000-0000-000000000003':
            return (<PaymentsOutlinedIcon sx={{ mr: 2, color: 'primary.main' }} />);
        case '00000000-0000-0000-0000-000000000004':
            return (<CreditCardOutlinedIcon sx={{ mr: 2, color: 'primary.main' }} />);
        case '00000000-0000-0000-0000-000000000005':
            return (<AddCardOutlinedIcon sx={{ mr: 2, color: 'primary.main' }} />);
    }
}

export function getTipoPersona(idTipoPersona: string) {
    switch (idTipoPersona) {
        case '00000000-0000-0000-0000-000000000001':
            return 'Persona Moral';
        case '00000000-0000-0000-0000-000000000002':
            return 'Persona Física';
        case '00000000-0000-0000-0000-000000000003':
            return 'Persona Física con Actividad Empresarial';
        case '00000000-0000-0000-0000-000000000004':
            return 'Fideicomiso';
    }
}

export function getIconIO(tipoTransaccion: string) {
    switch (tipoTransaccion) {
        case 'I':
            return (<ArrowDownwardOutlinedIcon />);
        case 'O':
            return (<ArrowUpwardOutlinedIcon />);
    }
}

export function getColorStatus(status: number) {
    switch (status) {
        case 0:
            return (<FiberManualRecordIcon sx={{ color: '#fbb042' }} fontSize='small' />);
        case 1:
            return (<FiberManualRecordIcon sx={{ color: '#4fc1b7' }} fontSize='small' />);
    }
}

export function getIconBoolean(valor: boolean) {
    switch (valor) {
        case null:
        case false:
            return (<DoNotDisturbAltOutlinedIcon sx={{ color: 'warning.main' }} />);
        case true:
            return (<TaskAltOutlinedIcon sx={{ color: 'success.main' }} />);
    }
}

export function getStatusWebHook(status: string) {
    switch (status) {
        case 'valid':
            return (<><Typography align="center"><TaskAltOutlinedIcon sx={{ color: 'success.main' }} /></Typography>
                <Typography align="center" color='success.main'>Credenciales válidas.</Typography>
                <Typography align="center" color='success.main'>En breve, nuestro sistema se encargará de recabar la información para su trámite.</Typography></>);
        case 'invalid':
            return (<><Typography align="center"><ErrorOutlineOutlinedIcon sx={{ color: 'error.main' }} /></Typography>
                <Typography align="center" color='error.main'>Credenciales inválidas.</Typography>
                <Typography align="center" color='error.main'>Vuelva a intentarlo.</Typography></>);
    }
}

export interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export function allProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export function GetOToken(token: string) {
    let oToken: any = {};
    try {
        oToken = JSON.parse(decodeURIComponent(escape(atob(token))));
    } catch (error: any) {

    }
    return oToken;
}

export let TipoFinanciamiento = {
    'ArrendamientoPuro': '00000000-0000-0000-0000-000000000001',
    'FactorajeFinanciero': '00000000-0000-0000-0000-000000000003',
    'CreditoSimple': '00000000-0000-0000-0000-000000000004',
    'CuentaCorriente': '00000000-0000-0000-0000-000000000005'
};

export let EstatusTransaccion = {
    'Pendiente': '00000000-0000-0000-0000-000000000001',
    'Liberado': '00000000-0000-0000-0000-000000000002'
};

export let TipoTasa = {
    'Fijo': '00000000-0000-0000-0000-000000000001',
    'Variable': '00000000-0000-0000-0000-000000000002',
    'Dinamico': '00000000-0000-0000-0000-000000000003'
};

export let TasaM = {
    'Seleccionar': '00000000-0000-0000-0000-000000000000',
    'Factor': '00000000-0000-0000-0000-000000000001',
    'Definir': '00000000-0000-0000-0000-000000000002'
};

export let OpcionesContrato = {
    'DevolverActivo': '00000000-0000-0000-0000-000000000001',
    'ComprarActivo': '00000000-0000-0000-0000-000000000002',
    'RenovarContrato': '00000000-0000-0000-0000-000000000003',
    'LiquidarArrendamiento': '00000000-0000-0000-0000-000000000004'
};

export let PlazoArrendamiento = {
    '12': '00000000-0000-0000-0000-000000000001',
    '18': '00000000-0000-0000-0000-000000000002',
    '24': '00000000-0000-0000-0000-000000000003',
    '36': '00000000-0000-0000-0000-000000000004',
    '48': '00000000-0000-0000-0000-000000000005',
    '60': '00000000-0000-0000-0000-000000000006',
    '6': '00000000-0000-0000-0000-000000000011',
};