import { IAmortizacionesCotizadorCSRequest, IArchivoResultadoCotizadorCSRequest, IRecalculoCotizadorCSRequest } from '../../../../models/cotizador/IAmortizacionesCotizadorCSRequest';
import { DatosAmortizacionesCotizadorCS, IAmortizacionesCotizadorCSResponse } from '../../../../models/cotizador/IAmortizacionesCotizadorCSResponse';
import * as type from '../../../../system/types';
import * as actions from '../../../../system/types';

//#region AMORTIZACIONES COTIZADOR CS
export function requestAmortizacionesCotizadorCS(data: IAmortizacionesCotizadorCSRequest): actions.requestAmortizacionesCotizadorCSAction {
    return {
        type: type.AMORTIZACIONESCOTIZADORCS_REQUEST,
        inProgress: true,
        data
    };
}

export function onAmortizacionesCotizadorCSResponse(
    data: Array<DatosAmortizacionesCotizadorCS>,
    message: string
): actions.onAmortizacionesCotizadorCSResponseAction {
    return {
        type: type.AMORTIZACIONESCOTIZADORCS_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onAmortizacionesCotizadorCSFailed(
    data: any,
    message: string
): actions.getAmortizacionesCotizadorCSFailedAction {
    return {
        type: type.AMORTIZACIONESCOTIZADORCS_FAILED,
        message,
        inProgress: false
    };
}
//#endregion

//#region RECALCULO COTIZADOR CS
export function requestRecalculoCotizadorCS(data: IRecalculoCotizadorCSRequest): actions.requestRecalculoCotizadorCSAction {
    return {
        type: type.RECALCULOCOTIZADORCS_REQUEST,
        inProgress: true,
        data
    };
}

export function onRecalculoCotizadorCSResponse(
    data: Array<DatosAmortizacionesCotizadorCS>,
    message: string
): actions.onRecalculoCotizadorCSResponseAction {
    return {
        type: type.RECALCULOCOTIZADORCS_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onRecalculoCotizadorCSFailed(
    data: any,
    message: string
): actions.getRecalculoCotizadorCSFailedAction {
    return {
        type: type.RECALCULOCOTIZADORCS_FAILED,
        message,
        inProgress: false
    };
}
//#endregion

//#region ARCHIVO RESULTADO COTIZADOR CS
export function requestArchivoResultadoCotizadorCS(data: IArchivoResultadoCotizadorCSRequest): actions.requestArchivoResultadoCotizadorCSAction {
    return {
        type: type.ARCHIVORESULTADOCOTIZADORCS_REQUEST,
        inProgress: true,
        succeeded: false,
        data
    };
}

export function onArchivoResultadoCotizadorCSResponse(
    data: any,
    message: string,
    succeeded: boolean
): actions.onArchivoResultadoCotizadorCSResponseAction {
    return {
        type: type.ARCHIVORESULTADOCOTIZADORCS_RESPONSE,
        data,
        message,
        succeeded,
        inProgress: false
    };
}

export function onArchivoResultadoCotizadorCSFailed(
    data: any,
    message: string
): actions.getArchivoResultadoCotizadorCSFailedAction {
    return {
        type: type.ARCHIVORESULTADOCOTIZADORCS_FAILED,
        message,
        succeeded: false,
        inProgress: false
    };
}
//#endregion