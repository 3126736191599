import { DatosSubtipoActivo } from '../../../../models/catalogs/ISubtipoActivoResponse';
import * as types from '../../../../system/types'

export interface SubtipoActivoState {
    id: string,
    // errors: [],
    inProgress: boolean,
    data: Array<DatosSubtipoActivo> | null,
    message?: string | null
}

const SubtipoActivoInitialState: SubtipoActivoState = {
    id: '',
    // errors: [],
    data: null,
    inProgress: false
};

export default function subtipoActivoReducer(
    state: SubtipoActivoState = SubtipoActivoInitialState,
    action: types.RootAction
): SubtipoActivoState {
    switch (action.type) {
        case types.SUBTIPOACTIVO_REQUEST:
            return {
                ...state,
                message: '',
                inProgress: true
            }
        case types.SUBTIPOACTIVO_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                inProgress: false
            }
        case types.SUBTIPOACTIVO_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}