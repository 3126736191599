import { DatosPagosRealizados } from '../../../../models/IPagosRealizadosResponse';
import * as types from '../../../../system/types'

//#region PAGOS REALIZADOS
export interface PagosRealizadosState {
    id: string,
    // errors: [],
    inProgress: boolean,
    data: Array<DatosPagosRealizados> | null,
    message?: string | null
}

const PagosRealizadosInitialState: PagosRealizadosState = {
    id: '',
    // errors: [],
    data: null,
    inProgress: false
};

export function pagosRealizadosReducer(
    state: PagosRealizadosState = PagosRealizadosInitialState,
    action: types.RootAction
): PagosRealizadosState {
    switch (action.type) {
        case types.PAGOSREALIZADOS_REQUEST:
            return {
                ...state,
                message: '',
                inProgress: true
            }
        case types.PAGOSREALIZADOS_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                inProgress: false
            }
        case types.PAGOSREALIZADOS_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}
//#endregion

//#region EXPORTAR ARCHIVO
export interface ExportarArchivoPagosRealizadosState {
    id: string,
    // errors: [],
    data: any | null,
    inProgress: boolean,
    succeeded: boolean,
    message?: string | null
}

const ExportarArchivoPagosRealizadosInitialState: ExportarArchivoPagosRealizadosState = {
    id: '',
    // errors: [],
    data: null,
    succeeded: false,
    inProgress: false
};

export function exportarArchivoPagosRealizadosReducer(
    state: ExportarArchivoPagosRealizadosState = ExportarArchivoPagosRealizadosInitialState,
    action: types.RootAction
): ExportarArchivoPagosRealizadosState {
    switch (action.type) {
        case types.EXPORTARARCHIVOPAGOSREALIZADOS_REQUEST:
            return {
                ...state,
                message: '',
                succeeded: false,
                inProgress: true
            }
        case types.EXPORTARARCHIVOPAGOSREALIZADOS_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                succeeded: action.succeeded,
                inProgress: false
            }
        case types.EXPORTARARCHIVOPAGOSREALIZADOS_FAILED:
            return {
                ...state,
                message: action.message,
                succeeded: false,
                inProgress: false
            }
        default:
            return state;
    }
}
//#endregion