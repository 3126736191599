import { IPaquetesAPagarRequest } from '../../../../models/IPaquetesAPagarRequest';
import { DatosPaquetesAPagar } from '../../../../models/IPaquetesAPagarResponse';
import { IGRPFRequest } from '../../../../models/ISimularPagoRequest';
import { DatosGRP } from '../../../../models/ISimularPagoResponse';
import * as type from '../../../../system/types';
import * as actions from '../../../../system/types';

//#region PAQUETES A PAGAR
export function requestPaquetesAPagar(data: IPaquetesAPagarRequest): actions.requestPaquetesAPagarAction {
    return {
        type: type.PAQUETESAPAGAR_REQUEST,
        inProgress: true,
        data
    };
}

export function onPaquetesAPagarResponse(
    data: Array<DatosPaquetesAPagar>,
    message: string
): actions.onPaquetesAPagarResponseAction {
    return {
        type: type.PAQUETESAPAGAR_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onPaquetesAPagarFailed(
    data: any,
    message: string
): actions.getPaquetesAPagarFailedAction {
    return {
        type: type.PAQUETESAPAGAR_FAILED,
        message,
        inProgress: false
    };
}
//#endregion

//#region GENERAR REFERENCIA DE PAGO FACTORAJE
export function requestGenerarReferenciaPagoFactoraje(data: IGRPFRequest): actions.requestGenerarReferenciaPagoFactorajeAction {
    return {
        type: type.GENERARREFERENCIAPAGOFACTORAJE_REQUEST,
        inProgress: true,
        data
    };
}

export function onGenerarReferenciaPagoFactorajeResponse(
    data: DatosGRP,
    message: string
): actions.onGenerarReferenciaPagoFactorajeResponseAction {
    return {
        type: type.GENERARREFERENCIAPAGOFACTORAJE_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onGenerarReferenciaPagoFactorajeFailed(
    data: any,
    message: string
): actions.getGenerarReferenciaPagoFactorajeFailedAction {
    return {
        type: type.GENERARREFERENCIAPAGOFACTORAJE_FAILED,
        message,
        inProgress: false
    };
}
//#endregion