import { DatosPaquetesAPagar } from '../../../../models/IPaquetesAPagarResponse';
import { DatosGRP, DatosSimularPago } from '../../../../models/ISimularPagoResponse';
import * as types from '../../../../system/types'

//#region PAQUETES A PAGAR
export interface PaquetesAPagarState {
    id: string,
    // errors: [],
    inProgress: boolean,
    data: Array<DatosPaquetesAPagar> | null,
    message?: string | null
}

const PaquetesAPagarInitialState: PaquetesAPagarState = {
    id: '',
    // errors: [],
    data: null,
    inProgress: false
};

export function paquetesAPagarReducer(
    state: PaquetesAPagarState = PaquetesAPagarInitialState,
    action: types.RootAction
): PaquetesAPagarState {
    switch (action.type) {
        case types.PAQUETESAPAGAR_REQUEST:
            return {
                ...state,
                message: '',
                inProgress: true
            }
        case types.PAQUETESAPAGAR_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                inProgress: false
            }
        case types.PAQUETESAPAGAR_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}
//#endregion

//#region GENERAR REFERENCIA DE PAGO FACTORAJE
export interface GenerarReferenciaPagoFactorajeState {
    id: string,
    // errors: [],
    inProgress: boolean,
    data: DatosGRP | null,
    message?: string | null
}

const GenerarReferenciaPagoFactorajeInitialState: GenerarReferenciaPagoFactorajeState = {
    id: '',
    // errors: [],
    data: null,
    inProgress: false
};

export function generarReferenciaPagoFactorajeReducer(
    state: GenerarReferenciaPagoFactorajeState = GenerarReferenciaPagoFactorajeInitialState,
    action: types.RootAction
): GenerarReferenciaPagoFactorajeState {
    switch (action.type) {
        case types.GENERARREFERENCIAPAGOFACTORAJE_REQUEST:
            return {
                ...state,
                message: '',
                inProgress: true
            }
        case types.GENERARREFERENCIAPAGOFACTORAJE_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                inProgress: false
            }
        case types.GENERARREFERENCIAPAGOFACTORAJE_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}
//#endregion