import { DatosCuentaDeposito, DatosPostCuentaDeposito } from '../../../models/ICuentaDepositoResponse';
import * as types from '../../../system/types'


//#region GET
export interface CuentaDepositoState {
    id: string,
    // errors: [],
    data: DatosCuentaDeposito | null,
    inProgress: boolean,
    message?: string | null
}

const CuentaDepositoInitialState: CuentaDepositoState = {
    id: '',
    // errors: [],
    data: null,
    inProgress: true
};

export function cuentaDepositoReducer(
    state: CuentaDepositoState = CuentaDepositoInitialState,
    action: types.RootAction
): CuentaDepositoState {
    switch (action.type) {
        case types.CUENTADEPOSITO_REQUEST:
            return {
                ...state,
                message: '',
                inProgress: true
            }
        case types.CUENTADEPOSITO_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                inProgress: false
            }
        case types.CUENTADEPOSITO_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}
//#endregion

//#region POST
export interface PostCuentaDepositoState {
    id: string,
    // errors: [],
    data: DatosPostCuentaDeposito | null,
    inProgress: boolean,
    message?: string | null
}

const PostCuentaDepositoInitialState: PostCuentaDepositoState = {
    id: '',
    // errors: [],
    data: null,
    inProgress: false
};

export function postCuentaDepositoReducer(
    state: PostCuentaDepositoState = PostCuentaDepositoInitialState,
    action: types.RootAction
): PostCuentaDepositoState {
    switch (action.type) {
        case types.POSTCUENTADEPOSITO_REQUEST:
            return {
                ...state,
                message: '',
                inProgress: true
            }
        case types.POSTCUENTADEPOSITO_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                inProgress: false
            }
        case types.POSTCUENTADEPOSITO_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}
//#endregion