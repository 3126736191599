import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import * as activosArrendamientoActions from './redux/actions';
import { IActivosArrendamientoRequest } from '../../../models/IActivosArrendamientoRequest';
import * as R from 'ramda';
import { DatosActivosArrendamiento } from '../../../models/IActivosArrendamientoResponse';
import { getIconBoolean, getNumberToCurrency, getPagoEnganche } from '../../../utils';
import theme from '../../../theme';
import Checkbox from '@mui/material/Checkbox';
import $ from 'jquery';
import { TableLoader } from '../../loader/TableLoader';
import { TableNotFoundData } from '../../notFoundData/TableNotFoundData';
import { StyledTableCell, StyledTableRow } from '../../styledTables/StyledTables';
import { DatosGRP } from '../../../models/ISimularPagoResponse';
import { IGRPARequest } from '../../../models/ISimularPagoRequest';

export default function ActivosPendienteEnganche(dataSend: IActivosArrendamientoRequest) {
    const [monto, setMonto] = useState('');
    const idCliente = useSelector((state: any) => state.login.id);
    const [checkedStateActivos, setCheckedStateActivos] = useState(new Array(0));
    const [openConfirmReferenciaPago, setOpenConfirmReferenciaPago] = useState(false);
    const [openAlertGRPA, setOpenAlertGRPA] = useState(false);
    const [openResultGRPA, setOpenResultGRPA] = useState(false);
    const [listaidActivosSeleccionados, setListaidActivosSeleccionados] = useState<Array<string>>([]);

    const datosActivosPendienteEnganche: any = useSelector((state: any) => state.activosArrendamiento.data);
    const inProgress: any = useSelector((state: any) => state.activosArrendamiento.inProgress);
    const datosGRPA: DatosGRP = useSelector((state: any) => state.generarReferenciaPagoActivos.data);
    const inProgressGRPA: any = useSelector((state: any) => state.generarReferenciaPagoActivos.inProgress);

    let GRPAmessage = <Typography variant='body1'>Generando número de referencia...</Typography>;

    let GRPASend: IGRPARequest = {
        IdActivos: [],
        IdCliente: idCliente,
        IdForoCondiciones: dataSend.IdForoCondiciones
    }

    const dispatch = useDispatch();
    const generarReferenciaPagoRequest = useCallback(() => dispatch(activosArrendamientoActions.requestGenerarReferenciaPagoActivos(GRPASend)), [dispatch, GRPASend]);

    const getMontoAPagar = (checked: boolean, pago: number, index: number) => {
        if ($('#monto').val() == '') {
            $('#monto').val('0');
        }
        if (checked) {
            $('#monto').val((parseFloat($('#monto').val() + '') + pago).toString());
        }
        else {
            $('#monto').val((parseFloat($('#monto').val() + '') - pago).toString());
        }
        setMonto($('#monto').val() + '');
        handleOnChangeActivos(index);
    }

    const generarReferenciaPago = () => {
        setOpenConfirmReferenciaPago(false)
        if ($('#monto').val() == null || $('#monto').val() == undefined || $('#monto').val() == '') {
            setOpenAlertGRPA(true);
            setTimeout(() => {
                setOpenAlertGRPA(false);
            }, 1000);
        }
        else {
            setOpenResultGRPA(true);
            GRPASend.IdActivos = listaidActivosSeleccionados;
            generarReferenciaPagoRequest();
        }
    }

    if (!R.isNil(datosGRPA) && !inProgressGRPA) {
        GRPAmessage = <Typography variant='body1' sx={{ color: datosGRPA.generado ? 'success.main' : 'error.main' }}>{datosGRPA.message}</Typography>
        setTimeout(() => {
            setOpenResultGRPA(false);
        }, 5000);
    }

    function PopUpConfirmReferenciaPago() {
        return (<Dialog
            open={openConfirmReferenciaPago}
            fullWidth={true}
            maxWidth={'sm'}>
            <DialogContent sx={{ mt: 2, textAlign: 'center' }} id="dialogContent">
                <DialogContentText>
                    ¿Está seguro de que desea generar el número de referencia?
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ m: 2 }} id="dialogActions">
                <Button
                    onClick={() => setOpenConfirmReferenciaPago(false)}>
                    Cancelar
                </Button>
                <Button variant="contained"
                    onClick={generarReferenciaPago}
                    sx={{ backgroundColor: '#16a0ff' }}>Si, generar</Button>
            </DialogActions>
        </Dialog>);
    }

    function PopUpAlertGRP() {
        return (<Dialog
            open={openAlertGRPA}
            fullWidth={true}
            maxWidth={'xs'}>
            <DialogContent sx={{ m: 2, textAlign: 'center' }} id="dialogContent">
                <DialogContentText sx={{ color: 'error.main' }}>
                    Ingrese el monto a pagar.
                </DialogContentText>
            </DialogContent>
        </Dialog>);
    }

    function PopUpGRP() {
        return (<Dialog
            open={openResultGRPA}
            fullWidth={true}
            maxWidth={'sm'}>
            <DialogContent sx={{ m: 2, textAlign: 'center' }} id="dialogContent">
                <DialogContentText>
                    {GRPAmessage}
                </DialogContentText>
            </DialogContent>
        </Dialog>);
    }

    const handleOnChangeActivos = (position: number) => {
        const updatedCheckedStateActivos = checkedStateActivos.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedStateActivos(updatedCheckedStateActivos);
    }

    const handleAddActivosList = () => {
        let datosAPE = datosActivosPendienteEnganche?.filter((e: DatosActivosArrendamiento) => e.idCobranzaPagoEnganche == null);
        checkedStateActivos.map((checked: boolean, index: number) => {
            if (checked) {
                datosAPE.filter((e: DatosActivosArrendamiento) => {
                    if (e.id == datosAPE[index].id)
                        setListaidActivosSeleccionados(prev => [...prev, e.id]);
                });
            }
        });
        setOpenConfirmReferenciaPago(true);
    }

    useEffect(() => {
        setCheckedStateActivos(new Array(datosActivosPendienteEnganche?.filter((e: DatosActivosArrendamiento) => e.idCobranzaPagoEnganche == null).length).fill(false))
    }, [!R.isNil(datosActivosPendienteEnganche)]);

    let totalesEnganche: any = [];
    let datos = <TableLoader colums={9} text={'Buscando activos pendientes de pago de enganche...'} />;
    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    if (!R.isNil(datosActivosPendienteEnganche) && !inProgress) {
        totalesEnganche = datosActivosPendienteEnganche.filter((e: DatosActivosArrendamiento) => e.idCobranzaPagoEnganche == null);
        datos = datosActivosPendienteEnganche.filter((e: DatosActivosArrendamiento) => e.idCobranzaPagoEnganche == null).map((activo: DatosActivosArrendamiento, index: number) =>
            <StyledTableRow
                key={activo.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <StyledTableCell align='center'>
                    <Checkbox
                        checked={checkedStateActivos[index]}
                        onChange={e => (getMontoAPagar(e.target.checked, activo.enganche, index))} />
                </StyledTableCell>
                <StyledTableCell>
                    <Typography variant="body2" sx={{ mb: 2 }}><b>{activo.tipoActivo}</b></Typography>
                    <Typography variant="body2" sx={{ mb: 2 }}><b>Descripción: </b>{activo.descripcion}</Typography>
                    <Typography variant="body2" sx={{ mb: 2 }}><b>Marca: </b>{activo.marca}</Typography>
                    <Typography variant="body2"><b>Modelo: </b>{activo.modelo}</Typography>
                </StyledTableCell>
                <StyledTableCell>{getNumberToCurrency(activo.precioReferencia)}</StyledTableCell>
                <StyledTableCell>{getNumberToCurrency(activo.montoAfinanciar)}</StyledTableCell>
                <StyledTableCell>{getNumberToCurrency(activo.montoAdispersar)}</StyledTableCell>
                <StyledTableCell>{getNumberToCurrency(activo.valorResidual)}</StyledTableCell>
                <StyledTableCell>{getNumberToCurrency(activo.enganche)}</StyledTableCell>
                <StyledTableCell>{getNumberToCurrency(activo.capitalAamortizar)}</StyledTableCell>
                <StyledTableCell>{getPagoEnganche(activo.idCobranzaPagoEnganche, activo.enganche)}</StyledTableCell>
            </StyledTableRow>)
    }
    if (inProgress) {
        totalesEnganche = [];
        datos = <TableLoader colums={9} text={'Buscando activos pendientes de pago de enganche...'} />;
    }
    if (datosActivosPendienteEnganche?.length == 0) {
        datos = <TableNotFoundData colums={9} text={'No se encontraron activos pendientes de pago de enganche.'} />;
    }

    function TablaActivosArrendamiento() {
        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 800 }} aria-label="customized table">
                    <TableBody>
                        <TableRow>
                            <TableCell sx={{ color: '#16a0ff' }}><b>Seleccionar</b></TableCell>
                            <TableCell><b>Información</b></TableCell>
                            <TableCell><b>Precio de referencia (con IVA)</b></TableCell>
                            <TableCell><b>Monto a financiar</b></TableCell>
                            <TableCell><b>Monto a dispersar</b></TableCell>
                            <TableCell><b>Valor residual</b></TableCell>
                            <TableCell><b>Enganche</b></TableCell>
                            <TableCell><b>Capital a amortizar</b></TableCell>
                            <TableCell><b>Pago de enganche</b></TableCell>
                        </TableRow>
                        <TableRow sx={{ backgroundColor: '#bae3ff' }}>
                            <TableCell><b>Totales:</b></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell><b>{getNumberToCurrency(totalesEnganche.reduce((a: number, b: DatosActivosArrendamiento) => a + b.enganche, 0))}</b></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        {datos}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    function TablaPendientesEnganche() {
        return (
            <><Typography sx={{ mb: 2, fontSize: 14 }}>Seleccione los activos cuyo enganche desee pagar en el número de referencia que se generará.</Typography>
                <TablaActivosArrendamiento />
                <Box sx={{ mt: 2 }} component="form" id="boxFormReferenciaPago">
                    <Grid container spacing={3}>
                        <Grid item xs={2} sm={2}>
                            <TextField
                                required
                                disabled
                                id="monto"
                                size='small'
                                fullWidth
                                autoFocus={true}
                                defaultValue={monto}
                                autoComplete="off" />
                        </Grid>
                        <Grid item xs={2} sm={2}>
                            <TextField
                                required
                                disabled
                                type='date'
                                id="fecha"
                                size='small'
                                fullWidth
                                autoComplete="fecha"
                                defaultValue={`${year}-${month < 10 ? `0${month}` : `${month}`}-${date < 10 ? `0${date}` : `${date}`}`} />
                        </Grid>
                        <Grid item xs={4} sm={4}>
                            <Button
                                disabled={inProgress}
                                variant="contained"
                                sx={{ backgroundColor: 'info.main' }}
                                onClick={() => handleAddActivosList()}>Generar número de referencia</Button>
                        </Grid>
                    </Grid>
                </Box></>
        );
    }

    return (
        <><Typography variant="h6" color="#2E2D2E" sx={{ mb: 2, mt: 2 }}>Activos pendientes de pago de enganche</Typography>
            <TablaPendientesEnganche />
            <PopUpConfirmReferenciaPago />
            <PopUpAlertGRP />
            <PopUpGRP /></>
    );
}