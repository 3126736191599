import { DatosPeriodicidad } from '../../../../models/catalogs/IPeriodicidadResponse';
import * as types from '../../../../system/types'

export interface PeriodicidadState {
    id: string,
    // errors: [],
    inProgress: boolean,
    data: Array<DatosPeriodicidad> | null,
    message?: string | null
}

const PeriodicidadInitialState: PeriodicidadState = {
    id: '',
    // errors: [],
    data: null,
    inProgress: false
};

export default function periodicidadReducer(
    state: PeriodicidadState = PeriodicidadInitialState,
    action: types.RootAction
): PeriodicidadState {
    switch (action.type) {
        case types.PERIODICIDAD_REQUEST:
            return {
                ...state,
                message: '',
                inProgress: true
            }
        case types.PERIODICIDAD_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                inProgress: false
            }
        case types.PERIODICIDAD_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}