import { DatosTipoPersona } from '../../../../models/catalogs/ITipoPersonaResponse';
import * as types from '../../../../system/types'

export interface TipoPersonaState {
    id: string,
    // errors: [],
    inProgress: boolean,
    data: Array<DatosTipoPersona> | null,
    message?: string | null
}

const TipoPersonaInitialState: TipoPersonaState = {
    id: '',
    // errors: [],
    data: null,
    inProgress: false
};

export default function tipoPersonaReducer(
    state: TipoPersonaState = TipoPersonaInitialState,
    action: types.RootAction
): TipoPersonaState {
    switch (action.type) {
        case types.TIPOPERSONA_REQUEST:
            return {
                ...state,
                message: '',
                inProgress: true
            }
        case types.TIPOPERSONA_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                inProgress: false
            }
        case types.TIPOPERSONA_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}