import { IArchivoResultadoCotizadorAPRequest, IResultadoCotizadorAPRequest } from '../../../../models/cotizador/IResultadoCotizadorAPRequest';
import { DatosResultadoCotizadorAP, IResultadoCotizadorAPResponse } from '../../../../models/cotizador/IResultadoCotizadorAPResponse';
import * as type from '../../../../system/types';
import * as actions from '../../../../system/types';

//#region RESULTADO COTIZADOR AP
export function requestResultadoCotizadorAP(data: IResultadoCotizadorAPRequest): actions.requestResultadoCotizadorAPAction {
    return {
        type: type.RESULTADOCOTIZADORAP_REQUEST,
        inProgress: true,
        data
    };
}

export function onResultadoCotizadorAPResponse(
    data: Array<DatosResultadoCotizadorAP>,
    message: string
): actions.onResultadoCotizadorAPResponseAction {
    return {
        type: type.RESULTADOCOTIZADORAP_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onResultadoCotizadorAPFailed(
    data: any,
    message: string
): actions.getResultadoCotizadorAPFailedAction {
    return {
        type: type.RESULTADOCOTIZADORAP_FAILED,
        message,
        inProgress: false
    };
}
//#endregion

//#region ARCHIVO RESULTADO COTIZADOR CS
export function requestArchivoResultadoCotizadorAP(data: IArchivoResultadoCotizadorAPRequest): actions.requestArchivoResultadoCotizadorAPAction {
    return {
        type: type.ARCHIVORESULTADOCOTIZADORAP_REQUEST,
        inProgress: true,
        succeeded: false,
        data
    };
}

export function onArchivoResultadoCotizadorAPResponse(
    data: any,
    message: string,
    succeeded: boolean
): actions.onArchivoResultadoCotizadorAPResponseAction {
    return {
        type: type.ARCHIVORESULTADOCOTIZADORAP_RESPONSE,
        data,
        message,
        succeeded,
        inProgress: false
    };
}

export function onArchivoResultadoCotizadorAPFailed(
    data: any,
    message: string
): actions.getArchivoResultadoCotizadorAPFailedAction {
    return {
        type: type.ARCHIVORESULTADOCOTIZADORAP_FAILED,
        message,
        succeeded: false,
        inProgress: false
    };
}
//#endregion