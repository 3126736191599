import { DatosTipoTasaMoratoria } from '../../../../models/catalogs/ITipoTasaMoratoriaResponse';
import * as types from '../../../../system/types'

export interface TipoTasaMoratoriaState {
    id: string,
    // errors: [],
    inProgress: boolean,
    data: Array<DatosTipoTasaMoratoria> | null,
    message?: string | null
}

const TipoTasaMoratoriaInitialState: TipoTasaMoratoriaState = {
    id: '',
    // errors: [],
    data: null,
    inProgress: false
};

export default function tipoTasaMoratoriaReducer(
    state: TipoTasaMoratoriaState = TipoTasaMoratoriaInitialState,
    action: types.RootAction
): TipoTasaMoratoriaState {
    switch (action.type) {
        case types.TIPOTASAMORATORIA_REQUEST:
            return {
                ...state,
                message: '',
                inProgress: true
            }
        case types.TIPOTASAMORATORIA_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                inProgress: false
            }
        case types.TIPOTASAMORATORIA_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}