import { IExportarArchivoPagosRealizadosRequest } from '../../../../models/IExportarArchivoRequest';
import { IPagosRealizadosRequest } from '../../../../models/IPagosRealizadosRequest';
import { DatosPagosRealizados, IPagosRealizadosResponse } from '../../../../models/IPagosRealizadosResponse';
import * as type from '../../../../system/types';
import * as actions from '../../../../system/types';

//#region PAGOS REALIZADOS
export function requestPagosRealizados(data: IPagosRealizadosRequest): actions.requestPagosRealizadosAction {
    return {
        type: type.PAGOSREALIZADOS_REQUEST,
        inProgress: true,
        data
    };
}

export function onPagosRealizadosResponse(
    data: Array<DatosPagosRealizados>,
    message: string
): actions.onPagosRealizadosResponseAction {
    return {
        type: type.PAGOSREALIZADOS_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onPagosRealizadosFailed(
    data: any,
    message: string
): actions.getPagosRealizadosFailedAction {
    return {
        type: type.PAGOSREALIZADOS_FAILED,
        message,
        inProgress: false
    };
}
//#endregion

//#region EXPORTAR ARCHIVO
export function requestExportarArchivoPagosRealizados(data: IExportarArchivoPagosRealizadosRequest): actions.requestExportarArchivoPagosRealizadosAction {
    return {
        type: type.EXPORTARARCHIVOPAGOSREALIZADOS_REQUEST,
        inProgress: true,
        succeeded: false,
        data
    };
}

export function onExportarArchivoPagosRealizadosResponse(
    data: any,
    message: string,
    succeeded: boolean
): actions.onExportarArchivoPagosRealizadosResponseAction {
    return {
        type: type.EXPORTARARCHIVOPAGOSREALIZADOS_RESPONSE,
        data,
        message,
        succeeded,
        inProgress: false
    };
}

export function onExportarArchivoPagosRealizadosFailed(
    data: any,
    message: string
): actions.getExportarArchivoPagosRealizadosFailedAction {
    return {
        type: type.EXPORTARARCHIVOPAGOSREALIZADOS_FAILED,
        message,
        succeeded: false,
        inProgress: false
    };
}
//#endregion