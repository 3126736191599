import { DatosInstitucionesBancarias, IInstitucionesBancariasResponse } from '../../../../models/catalogs/IInstitucionesBancariasResponse';
import * as type from '../../../../system/types';
import * as actions from '../../../../system/types';

export function requestInstitucionesBancarias(data: any): actions.requestInstitucionesBancariasAction {
    return {
        type: type.INSTITUCIONESBANCARIAS_REQUEST,
        inProgress: true,
        data
    };
}

export function onInstitucionesBancariasResponse(
    data: Array<DatosInstitucionesBancarias>,
    message: string
): actions.onInstitucionesBancariasResponseAction {
    return {
        type: type.INSTITUCIONESBANCARIAS_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onInstitucionesBancariasFailed(
    data: any,
    message: string
): actions.getInstitucionesBancariasFailedAction {
    return {
        type: type.INSTITUCIONESBANCARIAS_FAILED,
        message,
        inProgress: false
    };
}