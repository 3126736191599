import { take, put, call, fork, select, takeEvery, all } from 'redux-saga/effects';
import * as sagaController from '../../../../controllers/sagaController';
import * as types from '../../../../system/types';
import * as R from 'ramda';
import * as actions from './actions';
import * as rootActions from '../../../../system/actions';
import { ActivosArrendamiento, AmortizacionesActivo, ExportarArchivoActivos, GenerarReferenciaPagoActivos } from '../../../../api/Clients';
import { IResponse } from '../../../../models/IResponse';
import { AxiosResponse } from 'axios';
import { IActivosArrendamientoResponse } from '../../../../models/IActivosArrendamientoResponse';
import { IAmortizacionesActivoResponse } from '../../../../models/IAmortizacionesActivoResponse';
import { IExportarArchivoResponse } from '../../../../models/IExportarArchivoResponse';
import { IGRPResponse } from '../../../../models/ISimularPagoResponse';

//#region ACTIVOS
export function* activosArrendamientoRequestAsync(action: types.requestActivosArrendamientoAction) {
    const response: IActivosArrendamientoResponse = yield call(ActivosArrendamiento, action.data);
    // console.log(response);
    try {
        const {
            data,
            code,
            status,
            statusText,
            // headers,
            // config,
            // request,
        } = response;
        if (!R.isNil(data)) {
            yield sagaController.controlledStates(response, action.type, status);
        } else {
            yield sagaController.controlledStates(response, action.type, 400);
        }
    } catch (error) {
        console.log(error);
    }
}

export function* activosArrendamientoSaga() {
    yield all([
        takeEvery(types.ACTIVOSARRENDAMIENTO_REQUEST, activosArrendamientoRequestAsync)
    ])
}
//#endregion

//#region AMORTIZACIONES
export function* amortizacionesActivoRequestAsync(action: types.requestAmortizacionesActivoAction) {
    const response: IAmortizacionesActivoResponse = yield call(AmortizacionesActivo, action.data);
    // console.log(response);
    try {
        const {
            data,
            code,
            status,
            statusText,
            // headers,
            // config,
            // request,
        } = response;
        if (!R.isNil(data)) {
            yield sagaController.controlledStates(response, action.type, status);
        } else {
            yield sagaController.controlledStates(response, action.type, 400);
        }
    } catch (error) {
        console.log(error);
    }
}

export function* amortizacionesActivoSaga() {
    yield all([
        takeEvery(types.AMORTIZACIONESACTIVO_REQUEST, amortizacionesActivoRequestAsync)
    ])
}
//#endregion

//#region EXPORTAR ARCHIVO
export function* exportarArchivoActivosRequestAsync(action: types.requestExportarArchivoActivosAction) {
    const response: IExportarArchivoResponse = yield call(ExportarArchivoActivos, action.data);
    // console.log(response);
    try {
        const {
            data,
            code,
            status,
            statusText,
            // headers,
            // config,
            // request,
        } = response;
        if (!R.isNil(data)) {
            yield sagaController.controlledStates(response, action.type, status);
        } else {
            yield sagaController.controlledStates(response, action.type, 400);
        }
    } catch (error) {
        console.log(error);
    }
}

export function* exportarArchivoActivosSaga() {
    yield all([
        takeEvery(types.EXPORTARARCHIVOACTIVOS_REQUEST, exportarArchivoActivosRequestAsync)
    ])
}
//#endregion

//#region GENERAR REFERENCIA DE PAGO ACTIVOS
export function* generarReferenciaPagoActivosRequestAsync(action: types.requestGenerarReferenciaPagoActivosAction) {
    const response: IGRPResponse = yield call(GenerarReferenciaPagoActivos, action.data);
    // console.log(response);
    try {
        const {
            data,
            code,
            status,
            statusText,
            // headers,
            // config,
            // request,
        } = response;
        if (!R.isNil(data)) {
            yield sagaController.controlledStates(response, action.type, status);
        } else {
            yield sagaController.controlledStates(response, action.type, 400);
        }
    } catch (error) {
        console.log(error);
    }
}

export function* generarReferenciaPagoActivosSaga() {
    yield all([
        takeEvery(types.GENERARREFERENCIAPAGOACTIVOS_REQUEST, generarReferenciaPagoActivosRequestAsync)
    ])
}
//#endregion