import { take, put, call, fork, select, takeEvery, all } from 'redux-saga/effects';
import * as sagaController from '../../../../controllers/sagaController';
import * as types from '../../../../system/types';
import * as R from 'ramda';
import * as actions from './actions';
import * as rootActions from '../../../../system/actions';
import { Amortizaciones, ExportarArchivoAmortizaciones } from '../../../../api/Clients';
import { IResponse } from '../../../../models/IResponse';
import { AxiosResponse } from 'axios';
import { IAmortizacionesResponse } from '../../../../models/IAmortizacionesResponse';
import { IExportarArchivoResponse } from '../../../../models/IExportarArchivoResponse';

//#region AMORTIZACIONES
export function* amortizacionesRequestAsync(action: types.requestAmortizacionesAction) {
    const response: IAmortizacionesResponse = yield call(Amortizaciones, action.data);
    // console.log(response);
    try {
        const {
            data,
            code,
            status,
            statusText,
            // headers,
            // config,
            // request,
        } = response;
        if (!R.isNil(data)) {
            yield sagaController.controlledStates(response, action.type, status);
        } else {
            yield sagaController.controlledStates(response, action.type, 400);
        }
    } catch (error) {
        console.log(error);
    }
}

export function* amortizacionesSaga() {
    yield all([
        takeEvery(types.AMORTIZACIONES_REQUEST, amortizacionesRequestAsync)
    ])
}
//#endregion

//#region EXPORTAR ARCHIVO
export function* exportarArchivoAmortizacionesRequestAsync(action: types.requestExportarArchivoAmortizacionesAction) {
    const response: IExportarArchivoResponse = yield call(ExportarArchivoAmortizaciones, action.data);
    // console.log(response);
    try {
        const {
            data,
            code,
            status,
            statusText,
            // headers,
            // config,
            // request,
        } = response;
        if (!R.isNil(data)) {
            yield sagaController.controlledStates(response, action.type, status);
        } else {
            yield sagaController.controlledStates(response, action.type, 400);
        }
    } catch (error) {
        console.log(error);
    }
}

export function* exportarArchivoAmortizacionesSaga() {
    yield all([
        takeEvery(types.EXPORTARARCHIVOAMORTIZACIONES_REQUEST, exportarArchivoAmortizacionesRequestAsync)
    ])
}
//#endregion