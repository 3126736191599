import * as React from 'react';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import Header from '../../components/common/AppBar';
import * as R from 'ramda';
import Footer from '../../components/common/Footer';
import DatosFinanciamiento from '../../components/common/DatosFinanciamiento/DatosFinanciamiento';
import { allProps, GuidValid, TabPanel, TipoFinanciamiento } from '../../utils';
import PaquetesFactoraje from '../../components/common/PaquetesFactoraje/PaquetesFactoraje';
import PagosRealizados from '../../components/common/PagosRealizados/PagosRealizados';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import BadParameters from '../NotFound/BadParameters';
import GenerarReferenciaFactoraje from '../../components/common/GenerarReferenciaFactoraje/GenerarReferenciaFactoraje';
import PagosPendientes from '../../components/common/PagosRealizados/PagosPendientes';

export default function FactorajeFinanciero() {
    const [searchParams, setSearchParams] = useSearchParams();
    const idForoCondiciones = searchParams.get("idFC") + '';
    const idFinanciamientoSolicitado = searchParams.get("idFS") + '';
    const idCliente: string = useSelector((state: any) => state.login.id);
    const idTipoPersona: any = useSelector((state: any) => state.login.idTipoPersona);

    let idForoCondicionesValid = GuidValid(idForoCondiciones);
    let idFinanciamientoSolicitadoValid = GuidValid(idFinanciamientoSolicitado);

    function MenusFinanciamiento() {
        const [parent, setParent] = React.useState(0);
        const [children, setChildren] = React.useState(0);

        const changeParent = (event: React.SyntheticEvent, newValue: number) => {
            setParent(newValue);
        };

        const changeChildren = (event: React.SyntheticEvent, newValue: number) => {
            setChildren(newValue);
        };

        return (
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={parent} onChange={changeParent} aria-label="basic tabs example">
                        <Tab label="Paquetes" {...allProps(0)} />
                        <Tab label="Pagos realizados" {...allProps(1)} />
                        <Tab label="Generar referencia de pago" {...allProps(2)} />
                    </Tabs>
                </Box>
                <TabPanel value={parent} index={0}>
                    <PaquetesFactoraje IdForoCondiciones={idForoCondiciones} />
                </TabPanel>
                <TabPanel value={parent} index={1}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={children} onChange={changeChildren} aria-label="basic tabs example">
                            <Tab label="Pagos realizados" {...allProps(0)} />
                            <Tab label="Pagos pendientes" {...allProps(1)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={children} index={0}>
                        <PagosRealizados IdForoCondiciones={idForoCondiciones} IdTipoFinanciamiento={TipoFinanciamiento['FactorajeFinanciero']} />
                    </TabPanel>
                    <TabPanel value={children} index={1}>
                        <PagosPendientes IdForoCondiciones={idForoCondiciones} IdTipoFinanciamiento={TipoFinanciamiento['FactorajeFinanciero']} />
                    </TabPanel>
                </TabPanel>
                <TabPanel value={parent} index={2}>
                    <GenerarReferenciaFactoraje IdCliente={idCliente} IdForoCondiciones={idForoCondiciones} />
                </TabPanel>
            </Box>
        );
    }

    if (!idForoCondicionesValid || !idFinanciamientoSolicitadoValid) {
        return (<BadParameters />);
    }

    return (
        <Grid sx={{ m: 2 }}>
            <DatosFinanciamiento IdCliente={idCliente} IdForoCondiciones={idForoCondiciones} />
            <MenusFinanciamiento />
        </Grid>
    );
}