import { take, put, call, fork, select, takeEvery, all } from 'redux-saga/effects';
import * as sagaController from '../../../../controllers/sagaController';
import * as types from '../../../../system/types';
import * as R from 'ramda';
import * as actions from './actions';
import * as rootActions from '../../../../system/actions';
import { PaquetesAPagar, GenerarReferenciaPagoFactoraje } from '../../../../api/Clients';
import { IResponse } from '../../../../models/IResponse';
import { AxiosResponse } from 'axios';
import { IPaquetesAPagarResponse } from '../../../../models/IPaquetesAPagarResponse';
import { IGRPResponse } from '../../../../models/ISimularPagoResponse';

//#region PAQUETES A PAGAR
export function* paquetesAPagarRequestAsync(action: types.requestPaquetesAPagarAction) {
    const response: IPaquetesAPagarResponse = yield call(PaquetesAPagar, action.data);
    // console.log(response);
    try {
        const {
            data,
            code,
            status,
            statusText,
            // headers,
            // config,
            // request,
        } = response;
        if (!R.isNil(data)) {
            yield sagaController.controlledStates(response, action.type, status);
        } else {
            yield sagaController.controlledStates(response, action.type, 400);
        }
    } catch (error) {
        console.log(error);
    }
}

export function* paquetesAPagarSaga() {
    yield all([
        takeEvery(types.PAQUETESAPAGAR_REQUEST, paquetesAPagarRequestAsync)
    ])
}
//#endregion

//#region GENERAR REFERENCIA DE PAGO FACTORAJE
export function* generarReferenciaPagoFactorajeRequestAsync(action: types.requestGenerarReferenciaPagoFactorajeAction) {
    const response: IGRPResponse = yield call(GenerarReferenciaPagoFactoraje, action.data);
    // console.log(response);
    try {
        const {
            data,
            code,
            status,
            statusText,
            // headers,
            // config,
            // request,
        } = response;
        if (!R.isNil(data)) {
            yield sagaController.controlledStates(response, action.type, status);
        } else {
            yield sagaController.controlledStates(response, action.type, 400);
        }
    } catch (error) {
        console.log(error);
    }
}

export function* generarReferenciaPagoFactorajeSaga() {
    yield all([
        takeEvery(types.GENERARREFERENCIAPAGOFACTORAJE_REQUEST, generarReferenciaPagoFactorajeRequestAsync)
    ])
}
//#endregion