import Axios, { AxiosError, AxiosResponse } from 'axios';
import * as Config from './env';
import { Buffer } from 'buffer';
import { IResponse } from '../models/IResponse';
import * as R from 'ramda';
import { GetResponseErrors } from '../utils';

global.Buffer = Buffer;
const encodedToken = Buffer.from(
  `${Config.env.KEY}:${Config.env.SECRET}`,
  'utf8',
).toString('base64');

const client = Axios.create({
  baseURL: Config.env.BASE_URL_SECURE,
  timeout: 60000,
  headers: {
    'Cache-Control': 'no-cache',
    'x-apikey': '59a7ad19f5a9fa0808f11931',
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

const get = async (path: string, params?: any): Promise<IResponse> => {
  const config = {
    url: `${Config.env.BASE_URL_SECURE}${path}`,
    baseURL: Config.env.BASE_URL_SECURE,
    method: 'GET',
    params: params,
  };
  return await GetData(config);
};

const getFile = async (path: string, params?: any): Promise<IResponse> => {
  const config = {
    url: `${Config.env.BASE_URL_SECURE}${path}`,
    baseURL: Config.env.BASE_URL_SECURE,
    method: 'GET',
    params: params,
    responseType: 'blob'
  };
  return await GetFile(config);
};

const postFile = async (
  path: string,
  params?: any,
  data?: any,
): Promise<IResponse> => {
  var url = `${Config.env.BASE_URL_SECURE}${path}`;
  var baseUrl = Config.env.BASE_URL_SECURE;
  const config = {
    url: url,
    baseURL: baseUrl,
    method: 'POST',
    params: params,
    data: data,
    responseType: 'blob'
  };
  return await GetFile(config);
};

const postSatWS = async (
  path: string,
  data: any
): Promise<IResponse> => {

  var url = `${Config.env.URL_EXTERNAL}${path}`;
  var baseUrl = Config.env.URL_EXTERNAL;

  const config = {
    url: url,
    baseURL: baseUrl,
    method: 'POST',
    data: data
  };

  return await new Promise<IResponse>((resolve, reject) => {
    client.request(config)
      .then((response: AxiosResponse<IResponse>) => {
        console.log(JSON.parse(response.request.response));
        const newResponse: IResponse = response.data;
        newResponse.data = JSON.parse(response.request.response);
        newResponse.code = response?.status.toString();
        newResponse.status = R.isNil(response?.status) ? 0 : Number(response?.status.toString());
        newResponse.statusText = (R.isNil(response?.statusText) ? '' : response?.statusText) + '';
        resolve(newResponse);
      })
      .catch((resultError: AxiosError<IResponse>) => {
        const { code, message, response, request } = resultError;
        let newResponse: IResponse;
        const auxData = response?.data;
        if (!R.isNil(auxData)) {
          const errors = GetResponseErrors(auxData);
          newResponse = auxData;
          if (errors.length > 0) {
            newResponse.errors = errors;
          }
          newResponse.code = response?.status.toString();
          newResponse.status = R.isNil(response?.status) ? 0 : Number(response?.status.toString());
          newResponse.statusText = (R.isNil(response?.statusText) ? '' : response?.statusText) + '';
          resolve(newResponse);
        } else {
          newResponse = {
            code: code,
            status: R.isNil(request?.status) ? 0 : Number(request?.status.toString()),
            statusText: (R.isNil(request?.statusText) ? '' : request?.statusText) + '',
            data: null,
            errors: [],
            message: message,
            succeeded: false
          }
          return resolve(newResponse);
        }
      });
  });

};

const post = async (
  path: string,
  params?: any,
  data?: any,
  appendInForm: boolean = false,
): Promise<IResponse> => {
  var url = `${Config.env.BASE_URL_SECURE}${path}`;
  var baseUrl = Config.env.BASE_URL_SECURE;
  const config = {
    url: url,
    baseURL: baseUrl,
    method: 'POST',
    params: params,
    data: data
  };
  if (!R.isNil(params)) {
    client.defaults.headers.common['pchsfRoute'] = params.route;
    client.defaults.headers.common['pchsfOrigin'] = params.origin;
    client.defaults.headers.common['SubaccountIdInt32'] = params.SubaccountIdInt32;
  }
  return await GetData(config);
};

const put = async (path: string, data?: any): Promise<IResponse> => {
  const config = {
    url: `${Config.env.BASE_URL_SECURE}${path}`,
    baseURL: Config.env.BASE_URL_SECURE,
    method: 'PUT',
    data: data,
  };
  return await GetData(config);
};

const remove = async (path: string, data?: any): Promise<IResponse> => {
  const config = {
    url: `${Config.env.BASE_URL_SECURE}${path}`,
    baseURL: Config.env.BASE_URL_SECURE,
    method: 'DELETE',
    data: data,
  };
  return await GetData(config);
};

const GetData = async (config: any): Promise<IResponse> => {
  return await new Promise<IResponse>((resolve, reject) => {
    client.request(config)
      .then((response: AxiosResponse<IResponse>) => {
        const newResponse: IResponse = response.data;
        newResponse.code = response?.status.toString();
        newResponse.status = R.isNil(response?.status) ? 0 : Number(response?.status.toString());
        newResponse.statusText = (R.isNil(response?.statusText) ? '' : response?.statusText) + '';
        resolve(newResponse);
      })
      .catch((resultError: AxiosError<IResponse>) => {
        const { code, message, response, request } = resultError;
        let newResponse: IResponse;
        const auxData = response?.data;
        if (!R.isNil(auxData)) {
          if (R.isEmpty(auxData)) {
            newResponse = {
              code: response?.status.toString(),
              status: R.isNil(response?.status) ? 0 : Number(response?.status.toString()),
              statusText: (R.isNil(response?.statusText) ? '' : response?.statusText) + '',
              data: null,
              errors: [],
              message: '',
              succeeded: false
            }
          } else {
            newResponse = auxData;
          }
          const errors = GetResponseErrors(auxData);
          if (errors.length > 0) {
            newResponse.errors = errors;
          }
          newResponse.code = response?.status.toString();
          newResponse.status = R.isNil(response?.status) ? 0 : Number(response?.status.toString());
          newResponse.statusText = (R.isNil(response?.statusText) ? '' : response?.statusText) + '';

          return resolve(newResponse);
        } else {
          newResponse = {
            code: code,
            status: R.isNil(request?.status) ? 0 : Number(request?.status.toString()),
            statusText: (R.isNil(request?.statusText) ? '' : request?.statusText) + '',
            data: null,
            errors: [],
            message: message,
            succeeded: false
          }
          return resolve(newResponse);
        }
      });
  });
}

const GetFile = async (config: any): Promise<IResponse> => {
  return await new Promise<IResponse>((resolve, reject) => {
    client.request(config)
      .then((response: AxiosResponse<Blob>) => {
        try {
          var URL = window.URL.createObjectURL(response.data);
          const tempLink = document.createElement('a');
          tempLink.href = URL;

          if (response.data.type == 'application/pdf') {
            tempLink.setAttribute('download', 'ResultadoTablaExportada.pdf');
          }
          else if (response.data.type == 'application/vnd.ms-excel') {
            tempLink.setAttribute('download', 'ResultadoTablaExportada.xlsx');
          }

          tempLink.click();

          let newResponse: IResponse;
          newResponse = {
            succeeded: true,
            data: response.data,
            errors: [],
            message: "Archivo generado correctamente.",
            code: "200",
            status: 200,
            statusText: "200"
          }

          resolve(newResponse);
        }
        catch (error) {
        }
      })
      .catch((resultError: AxiosError<IResponse>) => {
        let newResponse: IResponse;
        newResponse = {
          succeeded: false,
          data: null,
          errors: [],
          message: 'No se pudo generar el archivo.',
          code: "406",
          status: 406,
          statusText: "406"
        }

        resolve(newResponse);
      });
  });
}

export const updateAuthHeader = (token: string) => {
  client.defaults.headers.common = {
    Authorization: 'Bearer ' + token,
  };
  return token;
};

export default {
  postSatWS,
  get,
  getFile,
  postFile,
  post,
  put,
  remove,
};