import { DatosStpCompanies, DatosSubAccountMovimientos, DatosSubAccounts, ICheckAccountBalanceResponse } from '../../../models/IStpCompaniesResponse';
import * as types from '../../../system/types'

//#region STP COMPANIES
export interface StpCompaniesState {
    id: string,
    // errors: [],
    balanceResponseObj: Array<DatosStpCompanies> | null,
    inProgress: boolean,
    message?: string | null
}

const StpCompaniesInitialState: StpCompaniesState = {
    id: '',
    // errors: [],
    balanceResponseObj: null,
    inProgress: false
};

export function stpCompaniesReducer(
    state: StpCompaniesState = StpCompaniesInitialState,
    action: types.RootAction
): StpCompaniesState {
    switch (action.type) {
        case types.STPCOMPANIES_REQUEST:
            return {
                ...state,
                message: '',
                inProgress: true
            }
        case types.STPCOMPANIES_RESPONSE:
            return {
                ...state,
                balanceResponseObj: action.balanceResponseObj,
                message: action.message,
                inProgress: false
            }
        case types.STPCOMPANIES_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}
//#endregion

//#region SUBACCOUNTS
export interface SubAccountsState {
    id: string,
    // errors: [],
    dropdownDataObj: Array<DatosSubAccounts> | null,
    inProgress: boolean,
    message?: string | null
}

const SubAccountsInitialState: SubAccountsState = {
    id: '',
    // errors: [],
    dropdownDataObj: null,
    inProgress: false
};

export function subAccountsReducer(
    state: SubAccountsState = SubAccountsInitialState,
    action: types.RootAction
): SubAccountsState {
    switch (action.type) {
        case types.SUBACCOUNTS_REQUEST:
            return {
                ...state,
                message: '',
                inProgress: true
            }
        case types.SUBACCOUNTS_RESPONSE:
            return {
                ...state,
                dropdownDataObj: action.dropdownDataObj,
                message: action.message,
                inProgress: false
            }
        case types.SUBACCOUNTS_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}
//#endregion

//#region SUBACCOUNT MOVIMIENTOS
export interface SubAccountMovimientosState {
    id: string,
    // errors: [],
    transaccionResponseObj: Array<DatosSubAccountMovimientos> | null,
    inProgress: boolean,
    message?: string | null
}

const SubAccountMovimientosInitialState: SubAccountMovimientosState = {
    id: '',
    // errors: [],
    transaccionResponseObj: null,
    inProgress: false
};

export function subAccountMovimientosReducer(
    state: SubAccountMovimientosState = SubAccountMovimientosInitialState,
    action: types.RootAction
): SubAccountMovimientosState {
    switch (action.type) {
        case types.SUBACCOUNTMOVIMIENTOS_REQUEST:
            return {
                ...state,
                message: '',
                inProgress: true
            }
        case types.SUBACCOUNTMOVIMIENTOS_RESPONSE:
            return {
                ...state,
                transaccionResponseObj: action.transaccionResponseObj,
                message: action.message,
                inProgress: false
            }
        case types.SUBACCOUNTMOVIMIENTOS_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}
//#endregion

//#region CHECK ACCOUNT BALANCE
export interface CheckAccountBalanceState {
    id: string,
    // errors: [],
    data: ICheckAccountBalanceResponse | null,
    inProgress: boolean,
    message?: string | null
}

const CheckAccountBalanceInitialState: CheckAccountBalanceState = {
    id: '',
    // errors: [],
    data: null,
    inProgress: false
};

export function checkAccountBalanceReducer(
    state: CheckAccountBalanceState = CheckAccountBalanceInitialState,
    action: types.RootAction
): CheckAccountBalanceState {
    switch (action.type) {
        case types.CHECKACCOUNTBALANCE_REQUEST:
            return {
                ...state,
                data: null,
                inProgress: true
            }
        case types.CHECKACCOUNTBALANCE_RESPONSE:
            return {
                ...state,
                data: action.data,
                inProgress: false
            }
        case types.CHECKACCOUNTBALANCE_FAILED:
            return {
                ...state,
                inProgress: false
            }
        default:
            return state;
    }
}
//#endregion