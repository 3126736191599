import { DatosTipoCuenta } from '../../../../models/catalogs/ITipoCuentaResponse';
import * as types from '../../../../system/types'

export interface TipoCuentaState {
    id: string,
    // errors: [],
    inProgress: boolean,
    data: Array<DatosTipoCuenta> | null,
    message?: string | null
}

const TipoCuentaInitialState: TipoCuentaState = {
    id: '',
    // errors: [],
    data: null,
    inProgress: false
};

export default function tipoCuentaReducer(
    state: TipoCuentaState = TipoCuentaInitialState,
    action: types.RootAction
): TipoCuentaState {
    switch (action.type) {
        case types.TIPOCUENTA_REQUEST:
            return {
                ...state,
                message: '',
                inProgress: true
            }
        case types.TIPOCUENTA_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                inProgress: false
            }
        case types.TIPOCUENTA_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}