import { DatosTipoTasa } from '../../../../models/catalogs/ITipoTasaResponse';
import * as types from '../../../../system/types'

export interface TipoTasaState {
    id: string,
    // errors: [],
    inProgress: boolean,
    data: Array<DatosTipoTasa> | null,
    message?: string | null
}

const TipoTasaInitialState: TipoTasaState = {
    id: '',
    // errors: [],
    data: null,
    inProgress: false
};

export default function tipoTasaReducer(
    state: TipoTasaState = TipoTasaInitialState,
    action: types.RootAction
): TipoTasaState {
    switch (action.type) {
        case types.TIPOTASA_REQUEST:
            return {
                ...state,
                message: '',
                inProgress: true
            }
        case types.TIPOTASA_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                inProgress: false
            }
        case types.TIPOTASA_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}