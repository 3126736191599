import { IActivosArrendamientoRequest } from '../../../../models/IActivosArrendamientoRequest';
import { DatosActivosArrendamiento, IActivosArrendamientoResponse } from '../../../../models/IActivosArrendamientoResponse';
import { IAmortizacionesActivoRequest } from '../../../../models/IAmortizacionesActivoRequest';
import { DatosAmortizacionesActivo } from '../../../../models/IAmortizacionesActivoResponse';
import { IExportarArchivoActivosRequest } from '../../../../models/IExportarArchivoRequest';
import { IGRPARequest } from '../../../../models/ISimularPagoRequest';
import { DatosGRP } from '../../../../models/ISimularPagoResponse';
import * as type from '../../../../system/types';
import * as actions from '../../../../system/types';

//#region ACTIVOS
export function requestActivosArrendamiento(data: IActivosArrendamientoRequest): actions.requestActivosArrendamientoAction {
    return {
        type: type.ACTIVOSARRENDAMIENTO_REQUEST,
        inProgress: true,
        data
    };
}

export function onActivosArrendamientoResponse(
    data: Array<DatosActivosArrendamiento>,
    message: string
): actions.onActivosArrendamientoResponseAction {
    return {
        type: type.ACTIVOSARRENDAMIENTO_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onActivosArrendamientoFailed(
    data: any,
    message: string
): actions.getActivosArrendamientoFailedAction {
    return {
        type: type.ACTIVOSARRENDAMIENTO_FAILED,
        message,
        inProgress: false
    };
}
//#endregion

//#region AMORTIZACIONES
export function requestAmortizacionesActivo(data: IAmortizacionesActivoRequest): actions.requestAmortizacionesActivoAction {
    return {
        type: type.AMORTIZACIONESACTIVO_REQUEST,
        inProgress: true,
        data
    };
}

export function onAmortizacionesActivoResponse(
    data: Array<DatosAmortizacionesActivo>,
    message: string
): actions.onAmortizacionesActivoResponseAction {
    return {
        type: type.AMORTIZACIONESACTIVO_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onAmortizacionesActivoFailed(
    data: any,
    message: string
): actions.getAmortizacionesActivoFailedAction {
    return {
        type: type.AMORTIZACIONESACTIVO_FAILED,
        message,
        inProgress: false
    };
}
//#endregion

//#region EXPORTAR ARCHIVO
export function requestExportarArchivoActivos(data: IExportarArchivoActivosRequest): actions.requestExportarArchivoActivosAction {
    return {
        type: type.EXPORTARARCHIVOACTIVOS_REQUEST,
        inProgress: true,
        succeeded: false,
        data
    };
}

export function onExportarArchivoActivosResponse(
    data: any,
    message: string,
    succeeded: boolean
): actions.onExportarArchivoActivosResponseAction {
    return {
        type: type.EXPORTARARCHIVOACTIVOS_RESPONSE,
        data,
        message,
        succeeded,
        inProgress: false
    };
}

export function onExportarArchivoActivosFailed(
    data: any,
    message: string
): actions.getExportarArchivoActivosFailedAction {
    return {
        type: type.EXPORTARARCHIVOACTIVOS_FAILED,
        message,
        succeeded: false,
        inProgress: false
    };
}
//#endregion

//#region GENERAR REFERENCIA DE PAGO ACTIVOS
export function requestGenerarReferenciaPagoActivos(data: IGRPARequest): actions.requestGenerarReferenciaPagoActivosAction {
    return {
        type: type.GENERARREFERENCIAPAGOACTIVOS_REQUEST,
        inProgress: true,
        data
    };
}

export function onGenerarReferenciaPagoActivosResponse(
    data: DatosGRP,
    message: string
): actions.onGenerarReferenciaPagoActivosResponseAction {
    return {
        type: type.GENERARREFERENCIAPAGOACTIVOS_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onGenerarReferenciaPagoActivosFailed(
    data: any,
    message: string
): actions.getGenerarReferenciaPagoActivosFailedAction {
    return {
        type: type.GENERARREFERENCIAPAGOACTIVOS_FAILED,
        message,
        inProgress: false
    };
}
//#endregion