import { take, put, call, fork, select, takeEvery, all } from 'redux-saga/effects';
import * as sagaController from '../../../../controllers/sagaController';
import * as types from '../../../../system/types';
import * as R from 'ramda';
import * as actions from './actions';
import * as rootActions from '../../../../system/actions';
import { FacturasPaquete, PaquetesFactoraje } from '../../../../api/Clients';
import { IResponse } from '../../../../models/IResponse';
import { AxiosResponse } from 'axios';
import { IFacturasPaqueteResponse, IPaquetesFactorajeResponse } from '../../../../models/IPaquetesFactorajeResponse';

//#region PAQUETES
export function* paquetesFactorajeRequestAsync(action: types.requestPaquetesFactorajeAction) {
    const response: IPaquetesFactorajeResponse = yield call(PaquetesFactoraje, action.data);
    // console.log(response);
    try {
        const {
            data,
            code,
            status,
            statusText,
            // headers,
            // config,
            // request,
        } = response;
        if (!R.isNil(data)) {
            yield sagaController.controlledStates(response, action.type, status);
        } else {
            yield sagaController.controlledStates(response, action.type, 400);
        }
    } catch (error) {
        console.log(error);
    }
}

export function* paquetesFactorajeSaga() {
    yield all([
        takeEvery(types.PAQUETESFACTORAJE_REQUEST, paquetesFactorajeRequestAsync)
    ])
}
//#endregion

//#region FACTURAS
export function* facturasPaqueteRequestAsync(action: types.requestFacturasPaqueteAction) {
    const response: IFacturasPaqueteResponse = yield call(FacturasPaquete, action.data);
    // console.log(response);
    try {
        const {
            data,
            code,
            status,
            statusText,
            // headers,
            // config,
            // request,
        } = response;
        if (!R.isNil(data)) {
            yield sagaController.controlledStates(response, action.type, status);
        } else {
            yield sagaController.controlledStates(response, action.type, 400);
        }
    } catch (error) {
        console.log(error);
    }
}

export function* facturasPaqueteSaga() {
    yield all([
        takeEvery(types.FACTURASPAQUETE_REQUEST, facturasPaqueteRequestAsync)
    ])
}
//#endregion