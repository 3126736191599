import {
  ICredentialsResponse,
  ICredentialsRequest,
  DatosCredentials,
} from "../../../models/ICredentials";
import { DatosFindValidStatus } from "../../../models/IFindValidStatusResponse";
import { IResponseCommon } from "../../../models/IResponseCommon";
import {
  DatosWebHookStatus,
  IWebHookStatusRequest,
} from "../../../models/IWebHookStatusRequest";
import * as type from "../../../system/types";
import * as actions from "../../../system/types";

//#region FIND VALID STATUS
export function requestFindValidStatus(
  data: string
): actions.requestFindValidStatusAction {
  return {
    type: type.FINDVALIDSTATUS_REQUEST,
    inProgress: true,
    data,
  };
}

export function onFindValidStatusResponse(
  data: DatosFindValidStatus,
  message: string
): actions.onFindValidStatusResponseAction {
  return {
    type: type.FINDVALIDSTATUS_RESPONSE,
    data,
    message,
    inProgress: false,
  };
}

export function onFindValidStatusFailed(
  message: string
): actions.getFindValidStatusFailedAction {
  return {
    type: type.FINDVALIDSTATUS_FAILED,
    message,
    inProgress: false,
  };
}
//#endregion

//#region CREDENTIALS
export function requestCredentials(
  typeCredential: string,
  rfc: string,
  password: string,
  autorizaConsulta: boolean
): actions.requestCredentialsAction {
  return {
    type: type.CREDENTIALS_REQUEST,
    inProgress: true,
    message: "Enviando credenciales...",
    data: {
      type: typeCredential,
      rfc,
      password,
      autorizaConsulta,
    },
  };
}

export function onCredentialsResponse(
  data: DatosCredentials,
  message: string
): actions.onCredentialsResponseAction {
  return {
    type: type.CREDENTIALS_RESPONSE,
    data,
    message,
    inProgress: false,
  };
}

export function onCredentialsFailed(
  data: IResponseCommon,
  message: string
): actions.getCredentialsFailedAction {
  var newData: ICredentialsResponse = {
    succeeded: false,
    errors: data.errors,
    message: message,
    data: data.data,
    code: data.code,
    status: data.status,
    statusText: data.statusText,
  };
  return {
    type: type.CREDENTIALS_FAILED,
    message,
    inProgress: false,
    data: newData,
  };
}
//#endregion

//#region WEBHOOK STATUS RESPONSE
export function requestWebHookStatus(
  idCredential: string | undefined | null
): actions.requestWebHookStatusAction {
  return {
    type: type.WEBHOOKSTATUS_REQUEST,
    inProgress: true,
    data: {
      idCredential:
        idCredential == null || idCredential === undefined ? "" : idCredential,
    },
  };
}

export function onWebHookStatusResponse(
  data: DatosWebHookStatus,
  message: string
): actions.onWebHookStatusResponseAction {
  return {
    type: type.WEBHOOKSTATUS_RESPONSE,
    data,
    message,
    inProgress: false,
  };
}

export function onWebHookStatusFailed(
  message: string
): actions.getWebHookStatusFailedAction {
  return {
    type: type.WEBHOOKSTATUS_FAILED,
    message,
    inProgress: false,
  };
}

export function onWebHookStatusReset(): actions.getWebHookStatusResetAction {
  return {
    type: type.WEBHOOKSTATUS_RESET,
    inProgress: false,
  };
}
//#endregion
