import { IResumenCuentaCorrienteRequest } from '../../../../models/IResumenCuentaCorrienteRequest';
import { DatosResumenCuentaCorriente, IResumenCuentaCorrienteResponse } from '../../../../models/IResumenCuentaCorrienteResponse';
import * as type from '../../../../system/types';
import * as actions from '../../../../system/types';

export function requestResumenCuentaCorriente(data: IResumenCuentaCorrienteRequest): actions.requestResumenCuentaCorrienteAction {
    return {
        type: type.RESUMENCUENTACORRIENTE_REQUEST,
        inProgress: true,
        data
    };
}

export function onResumenCuentaCorrienteResponse(
    data: Array<DatosResumenCuentaCorriente>,
    message: string
): actions.onResumenCuentaCorrienteResponseAction {
    return {
        type: type.RESUMENCUENTACORRIENTE_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onResumenCuentaCorrienteFailed(
    data: any,
    message: string
): actions.getResumenCuentaCorrienteFailedAction {
    return {
        type: type.RESUMENCUENTACORRIENTE_FAILED,
        message,
        inProgress: false
    };
}