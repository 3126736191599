import { DatosTipoCuenta, ITipoCuentaResponse } from '../../../../models/catalogs/ITipoCuentaResponse';
import * as type from '../../../../system/types';
import * as actions from '../../../../system/types';

export function requestTipoCuenta(data: any): actions.requestTipoCuentaAction {
    return {
        type: type.TIPOCUENTA_REQUEST,
        inProgress: true,
        data
    };
}

export function onTipoCuentaResponse(
    data: Array<DatosTipoCuenta>,
    message: string
): actions.onTipoCuentaResponseAction {
    return {
        type: type.TIPOCUENTA_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onTipoCuentaFailed(
    data: any,
    message: string
): actions.getTipoCuentaFailedAction {
    return {
        type: type.TIPOCUENTA_FAILED,
        message,
        inProgress: false
    };
}