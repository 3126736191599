import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import * as R from 'ramda';
import * as tipoActivoActions from '../../../components/catalogs/TipoActivo/redux/actions'
import * as tipoPagoAPActions from '../../../components/catalogs/TipoPagoAP/redux/actions'
import * as cotizadorAPActions from '../../../components/common/CotizadorAP/redux/actions'
import { Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, FormControlLabel, FormGroup, InputLabel, MenuItem, Radio, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { useParams } from 'react-router-dom';
import $ from 'jquery';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import BadParameters from '../../../screens/NotFound/BadParameters';
import { DatosTipoActivo } from '../../../models/catalogs/ITipoActivoResponse';
import { DatosSubtipoActivo } from '../../../models/catalogs/ISubtipoActivoResponse';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { IActivosCotizador } from '../../../models/cotizador/IActivosCotizador';
import { getIconBoolean, getNumberToCurrency, PlazoArrendamiento } from '../../../utils';
import { StyledTableCell, StyledTableRow } from '../../styledTables/StyledTables';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { ICondicionesCotizadorAP } from '../../../models/cotizador/ICondicionesCotizador';
import { DatosTipoArrendamiento } from '../../../models/catalogs/ITipoArrendamientoResponse';
import { DatosTipoPagoAP } from '../../../models/catalogs/ITipoPagoAPResponse';
import { DatosPeriodicidad } from '../../../models/catalogs/IPeriodicidadResponse';
import { IPlazoMeses } from '../../../models/cotizador/IPlazoMeses';
import { IArchivoResultadoCotizadorAPRequest, IResultadoCotizadorAPRequest } from '../../../models/cotizador/IResultadoCotizadorAPRequest';
import { DatosResultadoCotizadorAP } from '../../../models/cotizador/IResultadoCotizadorAPResponse';
import { DatosAmortizacionesActivo } from '../../../models/IAmortizacionesActivoResponse';
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

export default function CotizadorAP() {
    const [listActivosCotizados, setListActivosCotizados] = useState<Array<IActivosCotizador>>([]);
    const [condiciones, setCondiciones] = useState<ICondicionesCotizadorAP>();

    const { idCliente } = useParams();
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    let idClienteValid = regexExp.test(idCliente + '');
    const steps = ['Agregar activos', 'Establecer condiciones de cotización', 'Resultado'];
    const [activeStep, setActiveStep] = useState(0);

    let dataSend: IResultadoCotizadorAPRequest = {
        IdCliente: undefined,
        Condiciones: undefined,
        Activos: undefined,
        PlazoMeses: undefined
    };

    let dataSendArchivoResultado: IArchivoResultadoCotizadorAPRequest = {
        IdCliente: undefined,
        Condiciones: undefined,
        Activos: undefined
    };

    const dispatch = useDispatch();
    const catalogTipoActivoRequest: any = useCallback(() => dispatch(tipoActivoActions.requestTipoActivo('')), [dispatch, '']);
    const catalogTipoPagoAPRequest: any = useCallback(() => dispatch(tipoPagoAPActions.requestTipoPagoAP('')), [dispatch, '']);
    const getResultadoCotizadorAPRequest: any = useCallback(() => dispatch(cotizadorAPActions.requestResultadoCotizadorAP(dataSend)), [dispatch, dataSend]);

    // ACTIVOS 

    function FormularioActivosCotizador() {
        const [precioReferencia, setPrecioReferencia] = useState('0');
        const [enganche, setEnganche] = useState('0');
        const [montoFinanciar, setMontoFinanciar] = useState('');
        const [tipoActivo, setTipoActivo] = useState('');
        const [subtipoActivo, setSubtipoActivo] = useState('');
        const [marca, setMarca] = useState('');
        const [modelo, setModelo] = useState('');
        const [descripcion, setDescripcion] = useState('');
        const [agrupado, setAgrupado] = useState(false);

        let datosTA = <MenuItem value=''>Seleccione un tipo de activo</MenuItem>
        let datosSA = <MenuItem value=''>Seleccione un subtipo de activo</MenuItem>
        const datosTipoActivo: any = useSelector((state: any) => state.tipoActivo.data);
        const datosSubtipoActivo: any = useSelector((state: any) => state.subtipoActivo.data);

        useEffect(() => {
            if (idClienteValid) {
                catalogTipoActivoRequest();
            }
        }, []);

        if (!R.isNil(datosTipoActivo)) {
            datosTA = datosTipoActivo.map((tipo: DatosTipoActivo) =>
                <MenuItem key={tipo.id} value={tipo.id}>{tipo.descripcion}</MenuItem>);
        }

        if (!R.isNil(datosSubtipoActivo)) {
            datosSA = datosSubtipoActivo.map((tipo: DatosSubtipoActivo) =>
                <MenuItem key={tipo.id} value={tipo.id}>{tipo.descripcion}</MenuItem>);
        }

        const submitActivos = (event: any) => {
            event.preventDefault();
            if (Number(precioReferencia) > 0 && Number(enganche) >= 0 && Number(montoFinanciar) >= Number(enganche)) {
                let activoCotizado: IActivosCotizador = {
                    precioReferencia: Number(precioReferencia),
                    enganche: Number(enganche),
                    montoFinanciar: Number(montoFinanciar),
                    idTipoActivo: tipoActivo,
                    tipoActivo: datosTipoActivo.filter((item: any) => { return item.id == tipoActivo; })[0].descripcion,
                    idSubtipoActivo: tipoActivo,
                    subtipoActivo: datosSubtipoActivo.filter((item: any) => { return item.id == subtipoActivo; })[0].descripcion,
                    marca: marca,
                    modelo: modelo,
                    descripcion: descripcion,
                    agrupado: agrupado,
                    porcentajeValorResidual: null,
                    valorResidual: null,
                    capitalAmortizar: null,
                    amortizacionesAP: null
                }
                setListActivosCotizados(prev => [...prev, activoCotizado]);
            }
        };

        const handlePrecioReferencia = (value: string) => {
            setMontoFinanciar(((Number(value) / 1.16) - Number(enganche)).toFixed(2).toString())
            setPrecioReferencia(value);
        }

        const handleEnganche = (value: string) => {
            setMontoFinanciar(((Number(precioReferencia) / 1.16) - Number(value)).toFixed(2).toString())
            setEnganche(value);
        }

        function PopUpCatalogsActivos() {
            const inProgressTA: any = useSelector((state: any) => state.tipoActivo.inProgress);
            return (<Dialog
                open={(inProgressTA)}
                fullWidth={true}
                maxWidth={'xs'} >
                <DialogContent sx={{ m: 2, textAlign: 'center' }} id="dialogContent">
                    <DialogContentText>Cargando...</DialogContentText>
                </DialogContent>
            </Dialog>);
        }

        return (
            <Paper variant="elevation" sx={{ my: { xs: 4, md: 4 }, p: { xs: 4, md: 4 } }}>
                <Typography align="left" sx={{ mb: 4 }}><b>Agregar activos</b></Typography>
                <Box component="form" onSubmit={(e) => submitActivos(e)}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                id="precioReferencia"
                                label="Precio de referencia (con IVA)"
                                fullWidth
                                autoComplete="off"
                                variant="standard"
                                type="number"
                                value={precioReferencia}
                                onChange={(e) => handlePrecioReferencia(e.target.value)}
                                error={Number(precioReferencia) < 0 ? true : false}
                                helperText={
                                    Number(precioReferencia) <= 0 ?
                                        'El precio de referencia debe ser mayor a 0' : ''} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                id="enganche"
                                label="Enganche"
                                fullWidth
                                autoComplete="off"
                                variant="standard"
                                type="number"
                                value={enganche}
                                onChange={(e) => handleEnganche(e.target.value)}
                                error={Number(enganche) < 0 ? true : false}
                                helperText={
                                    Number(enganche) < 0 ?
                                        'El enganche debe ser mayor o igual a 0' : ''} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                disabled
                                id="montoFinanciar"
                                label="Monto a financiar"
                                fullWidth
                                autoComplete="off"
                                variant="standard"
                                type="number"
                                value={montoFinanciar}
                                error={Number(montoFinanciar) < Number(enganche) ? true : false}
                                helperText={
                                    Number(montoFinanciar) < Number(enganche) ?
                                        'El valor del monto a financiar debe de ser mayor al del enganche' : ''} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth variant="standard">
                                <InputLabel id="labelIB">Tipo de activo *</InputLabel>
                                <Select
                                    required
                                    id="tipoActivo"
                                    label="Tipo de activo *"
                                    value={tipoActivo}
                                    onChange={(e) => setTipoActivo(e.target.value)}>
                                    {datosTA}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth variant="standard">
                                <InputLabel id="labelIB">Subtipo de activo *</InputLabel>
                                <Select
                                    required
                                    id="subtipoActivo"
                                    label="Subtipo de activo *"
                                    value={subtipoActivo}
                                    onChange={(e) => setSubtipoActivo(e.target.value)}>
                                    {datosSA}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                id="marca"
                                label="Marca"
                                fullWidth
                                autoComplete="off"
                                variant="standard"
                                value={marca}
                                onChange={(e) => setMarca(e.target.value)} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                id="modelo"
                                label="Modelo"
                                fullWidth
                                autoComplete="off"
                                variant="standard"
                                value={modelo}
                                onChange={(e) => setModelo(e.target.value)} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                id="descripcion"
                                label="Descripción"
                                fullWidth
                                autoComplete="off"
                                variant="standard"
                                value={descripcion}
                                onChange={(e) => setDescripcion(e.target.value)} />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <FormGroup sx={{ mt: 1.5 }}>
                                <FormControlLabel control={
                                    <Checkbox
                                        id="agrupado"
                                        value={agrupado}
                                        onChange={(e) => setAgrupado(e.target.checked)} />}
                                    label="Agrupado" />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Button
                                variant="contained"
                                type="submit"
                                sx={{ backgroundColor: '#16a0ff', mt: 1.5 }}
                                fullWidth>Agregar</Button>
                        </Grid>
                    </Grid>
                </Box>
                <DetalleActivosCotizador />
                <PopUpCatalogsActivos />
            </Paper>
        );
    }

    function DetalleActivosCotizador() {
        return (
            <TableContainer sx={{ mt: 4 }}>
                <Table sx={{ minWidth: 800 }} aria-label="customized table">
                    <TableBody>
                        <TableRow>
                            <TableCell><b>Tipo de activo</b></TableCell>
                            <TableCell><b>Subtipo de activo</b></TableCell>
                            <TableCell><b>Precio de referencia</b></TableCell>
                            <TableCell><b>Monto a financiar</b></TableCell>
                            <TableCell><b>Enganche</b></TableCell>
                            <TableCell><b>Agrupado</b></TableCell>
                            <TableCell><b>Eliminar</b></TableCell>
                        </TableRow>
                        <TableRow sx={{ backgroundColor: '#bae3ff' }}>
                            <TableCell><b>Totales:</b></TableCell>
                            <TableCell></TableCell>
                            <TableCell><b>{getNumberToCurrency(listActivosCotizados.reduce((a: number, b: IActivosCotizador) => a + (b.precioReferencia ?? 0), 0))}</b></TableCell>
                            <TableCell><b>{getNumberToCurrency(listActivosCotizados.reduce((a: number, b: IActivosCotizador) => a + (b.montoFinanciar ?? 0), 0))}</b></TableCell>
                            <TableCell><b>{getNumberToCurrency(listActivosCotizados.reduce((a: number, b: IActivosCotizador) => a + (b.enganche ?? 0), 0))}</b></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        {!R.isNil(listActivosCotizados) && listActivosCotizados.length > 0 &&
                            listActivosCotizados.map((activo: IActivosCotizador, index: number) =>
                                <StyledTableRow key={activo.descripcion}>
                                    <StyledTableCell>{activo.tipoActivo}</StyledTableCell>
                                    <StyledTableCell>{activo.subtipoActivo}</StyledTableCell>
                                    <StyledTableCell>{getNumberToCurrency(activo.precioReferencia)}</StyledTableCell>
                                    <StyledTableCell>{getNumberToCurrency(activo.montoFinanciar)}</StyledTableCell>
                                    <StyledTableCell>{getNumberToCurrency(activo.enganche)}</StyledTableCell>
                                    <StyledTableCell align='center'>{getIconBoolean(activo.agrupado)}</StyledTableCell>
                                    <StyledTableCell align="center">
                                        <IconButton
                                            sx={{ color: 'error.main' }}
                                            onClick={() =>
                                                setListActivosCotizados(listActivosCotizados.filter((item, idx) => {
                                                    return idx != index;
                                                }))}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </StyledTableCell>
                                </StyledTableRow>
                            )}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    // CONDICIONES

    function FormularioCondicionesCotizador() {
        const [tasaInteres, setTasaInteres] = useState('0');
        const [tasaMoratoria, setTasaMoratoria] = useState('0');
        const [comisionApertura, setComisionApertura] = useState('');
        const [seguro, setSeguro] = useState('');
        const [tipoSeguro, setTipoSeguro] = useState('');
        const [gps, setGps] = useState('');
        const [tipoGps, setTipoGps] = useState('');
        const [tramites, setTramites] = useState('');
        const [gastosNotariales, setGastosNotariales] = useState('');
        const [otrosGastos, setOtrosGastos] = useState('');
        const [gastosGenerales, setGastosGenerales] = useState('0');
        const [tipoArrendamiento, setTipoArrendamiento] = useState('');
        const [tipoGastosGenerales, setTipoGastosGenerales] = useState('');
        const [depositoGarantia, setDepositoGarantia] = useState('');
        const [periodicidad, setPeriodicidad] = useState('');
        const [porcentajeVR1, setPorcentajeVR1] = useState('0');
        const [plazo6, setPlazo6] = useState(false);
        const [porcentajeVR2, setPorcentajeVR2] = useState('70');
        const [plazo12, setPlazo12] = useState(false);
        const [porcentajeVR3, setPorcentajeVR3] = useState('50');
        const [plazo24, setPlazo24] = useState(false);
        const [porcentajeVR4, setPorcentajeVR4] = useState('35');
        const [plazo36, setPlazo36] = useState(false);
        const [porcentajeVR5, setPorcentajeVR5] = useState('25');
        const [plazo48, setPlazo48] = useState(false);

        let datosTA = <MenuItem value=''>Seleccione un tipo de arrendamiento</MenuItem>
        let datosTPAP = <MenuItem value=''>Seleccione un tipo de pago</MenuItem>
        let datosP = <MenuItem value=''>Seleccione la periodicidad</MenuItem>
        let datosTipoArrendamiento: any = useSelector((state: any) => state.tipoArrendamiento.data);
        let datosTipoPagoAP: any = useSelector((state: any) => state.tipoPagoAP.data);
        let datosPeriodicidad: any = useSelector((state: any) => state.periodicidad.data);

        useEffect(() => {
            if (idClienteValid) {
                catalogTipoPagoAPRequest();
            }
        }, []);

        if (!R.isNil(datosTipoArrendamiento)) {
            datosTA = datosTipoArrendamiento.map((tipo: DatosTipoArrendamiento) =>
                <MenuItem key={tipo.id} value={tipo.id}>{tipo.descripcion}</MenuItem>);
        }

        if (!R.isNil(datosTipoPagoAP)) {
            datosTPAP = datosTipoPagoAP.map((tipo: DatosTipoPagoAP) =>
                <MenuItem key={tipo.id} value={tipo.id}>{tipo.descripcion}</MenuItem>);
        }

        if (!R.isNil(datosPeriodicidad)) {
            datosP = datosPeriodicidad.map((tipo: DatosPeriodicidad) =>
                <MenuItem key={tipo.id} value={tipo.id}>{tipo.descripcion}</MenuItem>);
        }

        const submitCondiciones = (event: any) => {
            event.preventDefault();
            if (Number(tasaInteres) > 0 && Number(tasaMoratoria) >= 0 && Number(comisionApertura) < 100 && Number(gastosGenerales) >= 0 &&
                (plazo6 == true || plazo12 == true || plazo24 == true || plazo36 == true || plazo48 == true)) {
                let condiciones: ICondicionesCotizadorAP = {
                    tasaInteres: Number(tasaInteres),
                    tasaMoratoria: Number(tasaMoratoria),
                    comisionApertura: Number(comisionApertura),
                    seguro: Number(seguro),
                    tipoSeguro: tipoSeguro,
                    gps: Number(gps),
                    tipoGps: tipoGps,
                    tramites: Number(tramites),
                    gastosNotariales: Number(gastosNotariales),
                    otrosGastos: Number(otrosGastos),
                    gastosGenerales: Number(gastosGenerales),
                    tipoGastosGenerales: tipoGastosGenerales,
                    tipoArrendamiento: tipoArrendamiento,
                    depositoGarantia: Number(depositoGarantia),
                    peridicidad: periodicidad,
                    porcentajeVR1: Number(porcentajeVR1),
                    plazo6: plazo6,
                    porcentajeVR2: Number(porcentajeVR2),
                    plazo12: plazo12,
                    porcentajeVR3: Number(porcentajeVR3),
                    plazo24: plazo24,
                    porcentajeVR4: Number(porcentajeVR4),
                    plazo36: plazo36,
                    porcentajeVR5: Number(porcentajeVR5),
                    plazo48: plazo48,
                    catPlazoArrendamiento: null
                };

                setCondiciones(condiciones);
            }
        };

        const handleTasaInteres = (value: string) => {
            setTasaMoratoria(((Number(value) * 2)).toFixed(2).toString())
            setTasaInteres(value);
        }

        const handleTramites = (value: string) => {
            setGastosGenerales((Number(value) + Number(gastosNotariales) + Number(otrosGastos)).toFixed(2).toString())
            setTramites(value);
        }

        const handleGastosNotariales = (value: string) => {
            setGastosGenerales((Number(tramites) + Number(value) + Number(otrosGastos)).toFixed(2).toString())
            setGastosNotariales(value);
        }

        const handleOtrosGastos = (value: string) => {
            setGastosGenerales((Number(tramites) + Number(gastosNotariales) + Number(value)).toFixed(2).toString())
            setOtrosGastos(value);
        }

        function PopUpCatalogsCondiciones() {
            const inProgressPAP: any = useSelector((state: any) => state.tipoPagoAP.inProgress);
            return (<Dialog
                open={(inProgressPAP)}
                fullWidth={true}
                maxWidth={'xs'} >
                <DialogContent sx={{ m: 2, textAlign: 'center' }} id="dialogContent">
                    <DialogContentText>Cargando...</DialogContentText>
                </DialogContent>
            </Dialog>);
        }

        return (
            <Paper variant="elevation" sx={{ my: { xs: 4, md: 4 }, p: { xs: 4, md: 4 } }}>
                <Typography align="left" sx={{ mb: 4 }}><b>Establecer condiciones de cotización</b></Typography>
                <Typography align="left" sx={{ mb: 4 }}>Generales:</Typography>
                <Box component="form" onSubmit={(e) => submitCondiciones(e)}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                id="tasaInteres"
                                label="Tasa de interés"
                                fullWidth
                                autoComplete="off"
                                variant="standard"
                                type="number"
                                value={tasaInteres}
                                onChange={(e) => handleTasaInteres(e.target.value)}
                                error={Number(tasaInteres) < 0 ? true : false}
                                helperText={
                                    Number(tasaInteres) <= 0 ?
                                        'La tasa de interés debe ser mayor a 0' : ''} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                disabled
                                id="tasaMoratoria"
                                label="Tasa de interés moratoria"
                                fullWidth
                                autoComplete="off"
                                variant="standard"
                                type="number"
                                value={tasaMoratoria}
                                error={Number(tasaMoratoria) < 0 ? true : false}
                                helperText={
                                    Number(tasaMoratoria) < 0 ?
                                        'El valor de la tasa moratoria debe ser mayor a 0' : ''} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                id="comisionApertura"
                                label="Comisión por apertura"
                                fullWidth
                                autoComplete="off"
                                variant="standard"
                                type="number"
                                value={comisionApertura}
                                onChange={(e) => setComisionApertura(e.target.value)}
                                error={Number(comisionApertura) > 100 ? true : false}
                                helperText={
                                    Number(comisionApertura) > 100 ?
                                        'La comisión por apertura no debe ser mayor a 100' : ''} />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                required
                                id="seguro"
                                label="Seguro (sin IVA)"
                                fullWidth
                                autoComplete="off"
                                variant="standard"
                                type="number"
                                value={seguro}
                                onChange={(e) => setSeguro(e.target.value)} />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth variant="standard">
                                <InputLabel id="labelIB">Tipo de seguro *</InputLabel>
                                <Select
                                    required
                                    id="tipoSeguro"
                                    label="Tipo de seguro *"
                                    value={tipoSeguro}
                                    onChange={(e) => setTipoSeguro(e.target.value)}>
                                    {datosTPAP}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                required
                                id="gps"
                                label="GPS (sin IVA)"
                                fullWidth
                                autoComplete="off"
                                variant="standard"
                                type="number"
                                value={gps}
                                onChange={(e) => setGps(e.target.value)} />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth variant="standard">
                                <InputLabel id="labelIB">Tipo de GPS *</InputLabel>
                                <Select
                                    required
                                    id="tipoGps"
                                    label="Tipo de GPS *"
                                    value={tipoGps}
                                    onChange={(e) => setTipoGps(e.target.value)}>
                                    {datosTPAP}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                required
                                id="tramites"
                                label="Tramites (sin IVA)"
                                fullWidth
                                autoComplete="off"
                                variant="standard"
                                type="number"
                                value={tramites}
                                onChange={(e) => handleTramites(e.target.value)} />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                required
                                id="gastosNotariales"
                                label="Gastos notariales (sin IVA)"
                                fullWidth
                                autoComplete="off"
                                variant="standard"
                                type="number"
                                value={gastosNotariales}
                                onChange={(e) => handleGastosNotariales(e.target.value)} />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                required
                                id="otrosGastos"
                                label="Otros gastos (sin IVA)"
                                fullWidth
                                autoComplete="off"
                                variant="standard"
                                type="number"
                                value={otrosGastos}
                                onChange={(e) => handleOtrosGastos(e.target.value)} />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                required
                                disabled
                                id="gastosGenerales"
                                label="Gastos generales (sin IVA)"
                                fullWidth
                                autoComplete="off"
                                variant="standard"
                                type="number"
                                value={gastosGenerales}
                                error={Number(gastosGenerales) < 0 ? true : false}
                                helperText={
                                    Number(gastosGenerales) < 0 ?
                                        'Los gastos generales deben ser mayor o igual a 0' : ''} />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth variant="standard">
                                <InputLabel id="labelIB">Tipo de gastos generales *</InputLabel>
                                <Select
                                    required
                                    id="tipoGastosGenerales"
                                    label="Tipo de gastos generales *"
                                    value={tipoGastosGenerales}
                                    onChange={(e) => setTipoGastosGenerales(e.target.value)}>
                                    {datosTPAP}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth variant="standard">
                                <InputLabel id="labelIB">Tipo de arrendamiento *</InputLabel>
                                <Select
                                    required
                                    id="tipoArrendamiento"
                                    label="Tipo de arrendamiento *"
                                    value={tipoArrendamiento}
                                    onChange={(e) => setTipoArrendamiento(e.target.value)}>
                                    {datosTA}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                required
                                id="depositoGarantia"
                                label="Depósito en garantía (número de pagos)"
                                fullWidth
                                autoComplete="off"
                                variant="standard"
                                type="number"
                                value={depositoGarantia}
                                onChange={(e) => setDepositoGarantia(e.target.value)} />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth variant="standard">
                                <InputLabel id="labelIB">Periodicidad *</InputLabel>
                                <Select
                                    required
                                    id="periodicidad"
                                    label="Periodicidad *"
                                    value={periodicidad}
                                    onChange={(e) => setPeriodicidad(e.target.value)}>
                                    {datosP}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12}>Plazos y porcentaje de valor residual:</Grid>
                        <Grid item xs={12} sm={2}>
                            <FormGroup>
                                <FormControlLabel control={
                                    <><Checkbox
                                        id="plazo6"
                                        value={plazo6}
                                        onChange={(e) => setPlazo6(e.target.checked)} />
                                        <TextField
                                            disabled={!plazo6}
                                            required
                                            fullWidth
                                            autoComplete="off"
                                            variant="standard"
                                            type="number"
                                            value={porcentajeVR1}
                                            onChange={(e) => setPorcentajeVR1(e.target.value)} /></>}
                                    label="6 meses"
                                    labelPlacement="top" />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <FormGroup>
                                <FormControlLabel control={
                                    <><Checkbox
                                        id="plazo12"
                                        value={plazo12}
                                        onChange={(e) => setPlazo12(e.target.checked)} />
                                        <TextField
                                            disabled={!plazo12}
                                            required
                                            fullWidth
                                            autoComplete="off"
                                            variant="standard"
                                            type="number"
                                            value={porcentajeVR2}
                                            onChange={(e) => setPorcentajeVR2(e.target.value)} /></>}
                                    label="12 meses"
                                    labelPlacement="top" />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <FormGroup>
                                <FormControlLabel control={
                                    <><Checkbox
                                        id="plazo24"
                                        value={plazo24}
                                        onChange={(e) => setPlazo24(e.target.checked)} />
                                        <TextField
                                            disabled={!plazo24}
                                            required
                                            fullWidth
                                            autoComplete="off"
                                            variant="standard"
                                            type="number"
                                            value={porcentajeVR3}
                                            onChange={(e) => setPorcentajeVR3(e.target.value)} /></>}
                                    label="24 meses"
                                    labelPlacement="top" />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <FormGroup>
                                <FormControlLabel control={
                                    <><Checkbox
                                        id="plazo36"
                                        value={plazo36}
                                        onChange={(e) => setPlazo36(e.target.checked)} />
                                        <TextField
                                            disabled={!plazo36}
                                            required
                                            fullWidth
                                            autoComplete="off"
                                            variant="standard"
                                            type="number"
                                            value={porcentajeVR4}
                                            onChange={(e) => setPorcentajeVR4(e.target.value)} /></>}
                                    label="36 meses"
                                    labelPlacement="top" />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <FormGroup>
                                <FormControlLabel control={
                                    <><Checkbox
                                        id="plazo48"
                                        value={plazo48}
                                        onChange={(e) => setPlazo48(e.target.checked)} />
                                        <TextField
                                            disabled={!plazo48}
                                            required
                                            fullWidth
                                            autoComplete="off"
                                            variant="standard"
                                            type="number"
                                            value={porcentajeVR5}
                                            onChange={(e) => setPorcentajeVR5(e.target.value)} /></>}
                                    label="48 meses"
                                    labelPlacement="top" />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Button
                                variant="contained"
                                type="submit"
                                sx={{ backgroundColor: '#16a0ff', mt: 1.5 }}
                                fullWidth>Guardar</Button>
                        </Grid>
                    </Grid>
                </Box>
                <PopUpCatalogsCondiciones />
            </Paper>
        );
    }

    // RESULTADO

    function ResultadoCotizador() {
        const [plazoMeses, setPlazoMeses] = useState('');
        const [openGeneracionArchivo, setOpenGeneracionArchivo] = React.useState(false);
        const datosArchivoResultadoCotizadorAP = useSelector((state: any) => state.archivoResultadoCotizadorAP);
        const inProgressArchivoResultadoCotizadorAP = useSelector((state: any) => state.archivoResultadoCotizadorAP.inPorgress);
        let plazos = new Array<IPlazoMeses>();
        const getArchivoResultadoCotizadorAPRequest: any = useCallback(() => dispatch(cotizadorAPActions.requestArchivoResultadoCotizadorAP(dataSendArchivoResultado)), [dispatch, dataSendArchivoResultado]);

        if (condiciones?.plazo6 == true) {
            let plazo = {
                idPlazoArrendamiento: PlazoArrendamiento['6'],
                meses: 6,
                porcentajeValorResidual: Number(condiciones.porcentajeVR1)
            }
            plazos.push(plazo);
        }
        if (condiciones?.plazo12 == true) {
            let plazo = {
                idPlazoArrendamiento: PlazoArrendamiento['12'],
                meses: 12,
                porcentajeValorResidual: Number(condiciones.porcentajeVR2)
            }
            plazos.push(plazo);
        }
        if (condiciones?.plazo24 == true) {
            let plazo = {
                idPlazoArrendamiento: PlazoArrendamiento['24'],
                meses: 24,
                porcentajeValorResidual: Number(condiciones.porcentajeVR3)
            }
            plazos.push(plazo);
        }
        if (condiciones?.plazo36 == true) {
            let plazo = {
                idPlazoArrendamiento: PlazoArrendamiento['36'],
                meses: 36,
                porcentajeValorResidual: Number(condiciones.porcentajeVR4)
            }
            plazos.push(plazo);
        }
        if (condiciones?.plazo48 == true) {
            let plazo = {
                idPlazoArrendamiento: PlazoArrendamiento['48'],
                meses: 48,
                porcentajeValorResidual: Number(condiciones.porcentajeVR5)
            }
            plazos.push(plazo);
        }

        const handleGetResultadoCotizador = (value: any) => {
            setPlazoMeses(value);
            let plazo = (plazos.filter((item) => { return item.porcentajeValorResidual == value }))[0];
            dataSend = {
                IdCliente: idCliente,
                Condiciones: condiciones,
                Activos: listActivosCotizados,
                PlazoMeses: plazo
            }
            getResultadoCotizadorAPRequest();
        }

        const exportCotizacionAP = (e: any) => {
            e.preventDefault();
            dataSendArchivoResultado = {
                IdCliente: idCliente,
                Condiciones: condiciones,
                Activos: listActivosCotizados
            };

            setOpenGeneracionArchivo(true)
            getArchivoResultadoCotizadorAPRequest();
        };

        let mensajeGeneracionArchivo = <Typography variant='body1'>Generando...</Typography>

        if (!R.isNil(datosArchivoResultadoCotizadorAP.data) && !inProgressArchivoResultadoCotizadorAP) {
            mensajeGeneracionArchivo =
                <Typography sx={{ color: datosArchivoResultadoCotizadorAP.succeeded ? 'success.main' : 'error.main' }}>{datosArchivoResultadoCotizadorAP.message}</Typography>
            setTimeout(() => {
                setOpenGeneracionArchivo(false);
            }, 3000);
        }

        return (
            <Paper variant="elevation" sx={{ my: { xs: 4, md: 4 }, p: { xs: 4, md: 4 } }}>
                <Typography align="left" sx={{ mb: 4 }}><b>Resultado</b></Typography>
                <Box component="form" onSubmit={(e) => exportCotizacionAP(e)}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth variant="standard">
                                <InputLabel id="labelIB">Plazo (meses) *</InputLabel>
                                <Select
                                    required
                                    id="plazoMeses"
                                    label="Plazo (meses) *"
                                    value={plazoMeses}
                                    onChange={(e) => handleGetResultadoCotizador(e.target.value)}>
                                    {plazos.length > 0 && plazos.map((plazo: IPlazoMeses) =>
                                        <MenuItem key={plazo.idPlazoArrendamiento} value={plazo.porcentajeValorResidual}>{plazo.meses}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <DetalleResultadoCotizador />
                    <Grid container spacing={6}>
                        <Grid item xs={12} sm={10}></Grid>
                        <Grid item xs={12} sm={2}>
                            <Button
                                variant="contained"
                                type='submit'
                                sx={{ backgroundColor: '#16a0ff', mt: 4 }}
                                fullWidth>Exportar a PDF</Button>
                        </Grid>
                    </Grid>
                </Box>
                <Dialog
                    key={'archivoExportado'}
                    open={openGeneracionArchivo}
                    fullWidth={true}
                    maxWidth={'xs'}>
                    <DialogContent sx={{ m: 2, textAlign: 'center' }} id="dialogContent">
                        <DialogContentText>
                            {mensajeGeneracionArchivo}
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </Paper>
        );
    }

    function DetalleResultadoCotizador() {
        const datosResultadoCotizadorAP = useSelector((state: any) => state.resultadoCotizadorAP.data);
        const datosTipoActivo: any = useSelector((state: any) => state.tipoActivo.data);
        const [radioValue, setRadioValue] = useState("");
        const [amortizaciones, setAmortizaciones] = useState<Array<DatosAmortizacionesActivo>>();
        const [openAmortizacionesActivo, setOpenAmortizacionesActivo] = React.useState(false);

        const amortizacionesCotizadas = (id: string) => {
            datosResultadoCotizadorAP.filter((e: DatosResultadoCotizadorAP) => {
                if (e.id == id) {
                    setAmortizaciones(e.amortizacionesAP);
                }
            });
        }

        function PopUpResultCotizador() {
            const inProgressResultadoCotizadorAP = useSelector((state: any) => state.resultadoCotizadorAP.inProgress);
            return (<Dialog
                open={(inProgressResultadoCotizadorAP)}
                fullWidth={true}
                maxWidth={'xs'} >
                <DialogContent sx={{ m: 2, textAlign: 'center' }} id="dialogContent">
                    <DialogContentText>Cargando...</DialogContentText>
                </DialogContent>
            </Dialog>);
        }

        return (
            <><TableContainer sx={{ mt: 4 }}>
                <Table sx={{ minWidth: 800 }} aria-label="customized table">
                    <TableBody>
                        <TableRow>
                            <TableCell><b>Descripción</b></TableCell>
                            <TableCell><b>Tipo de activo</b></TableCell>
                            <TableCell><b>Precio de referencia (sin IVA)</b></TableCell>
                            <TableCell><b>Monto a financiar</b></TableCell>
                            <TableCell><b>Valor residual</b></TableCell>
                            <TableCell><b>Enganche</b></TableCell>
                            <TableCell><b>Capital a amortizar</b></TableCell>
                            <TableCell><b>Tabla de amortización</b></TableCell>
                        </TableRow>
                        {!R.isNil(datosResultadoCotizadorAP) && datosResultadoCotizadorAP.length > 0 &&
                            <TableRow sx={{ backgroundColor: '#bae3ff' }}>
                                <TableCell><b>Totales:</b></TableCell>
                                <TableCell></TableCell>
                                <TableCell><b>{getNumberToCurrency(datosResultadoCotizadorAP.reduce((a: number, b: DatosResultadoCotizadorAP) => a + (b.precioReferencia ?? 0), 0))}</b></TableCell>
                                <TableCell><b>{getNumberToCurrency(datosResultadoCotizadorAP.reduce((a: number, b: DatosResultadoCotizadorAP) => a + (b.montoAfinanciar ?? 0), 0))}</b></TableCell>
                                <TableCell><b>{getNumberToCurrency(datosResultadoCotizadorAP.reduce((a: number, b: DatosResultadoCotizadorAP) => a + (b.valorResidual ?? 0), 0))}</b></TableCell>
                                <TableCell><b>{getNumberToCurrency(datosResultadoCotizadorAP.reduce((a: number, b: DatosResultadoCotizadorAP) => a + (b.enganche ?? 0), 0))}</b></TableCell>
                                <TableCell><b>{getNumberToCurrency(datosResultadoCotizadorAP.reduce((a: number, b: DatosResultadoCotizadorAP) => a + (b.capitalAamortizar ?? 0), 0))}</b></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        }
                        {!R.isNil(datosResultadoCotizadorAP) && datosResultadoCotizadorAP.length > 0 &&
                            datosResultadoCotizadorAP.map((activo: DatosResultadoCotizadorAP) =>
                                <StyledTableRow key={activo.descripcion}>
                                    <StyledTableCell>{activo.descripcion}</StyledTableCell>
                                    <StyledTableCell>{datosTipoActivo.filter((item: any) => { return item.id == activo.idCAT_TipoActivo })[0].descripcion}</StyledTableCell>
                                    <StyledTableCell>{getNumberToCurrency(activo.precioReferencia)}</StyledTableCell>
                                    <StyledTableCell>{getNumberToCurrency(activo.montoAfinanciar)}</StyledTableCell>
                                    <StyledTableCell>{getNumberToCurrency(activo.valorResidual)} ({activo.porcentajeValorResidual}%)</StyledTableCell>
                                    <StyledTableCell>{getNumberToCurrency(activo.enganche)}</StyledTableCell>
                                    <StyledTableCell>{getNumberToCurrency(activo.capitalAamortizar)}</StyledTableCell>
                                    <StyledTableCell align='center'>
                                        <Button color='info'
                                            onClick={() => (setOpenAmortizacionesActivo(true), amortizacionesCotizadas(activo.id))}
                                            startIcon={<VisibilityOutlinedIcon />}>Amortizaciones</Button>
                                    </StyledTableCell>
                                </StyledTableRow>
                            )}
                    </TableBody>
                </Table>
            </TableContainer>
                <PopUpResultCotizador />
                <Dialog
                    key={'amortizacionesActivo'}
                    open={openAmortizacionesActivo}
                    fullWidth={true}
                    maxWidth={'lg'}>
                    <DialogContent sx={{ m: 2, textAlign: 'center' }} id="dialogContent">
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="customized table">
                                <TableBody>
                                    <TableRow>
                                        <TableCell><b>Pago</b></TableCell>
                                        <TableCell><b>Fecha</b></TableCell>
                                        <TableCell><b>Renta</b></TableCell>
                                        <TableCell><b>Seguro</b></TableCell>
                                        <TableCell><b>GPS</b></TableCell>
                                        <TableCell><b>Gastos generales</b></TableCell>
                                        <TableCell><b>IVA</b></TableCell>
                                        <TableCell><b>Pago total</b></TableCell>
                                    </TableRow>
                                    {!R.isNil(amortizaciones) && amortizaciones.length > 0 &&
                                        <TableRow sx={{ backgroundColor: '#bae3ff' }}>
                                            <TableCell><b>Totales:</b></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell><b>{getNumberToCurrency(amortizaciones.reduce((a: number, b: DatosAmortizacionesActivo) => a + ((b.capitalApagar ?? 0) + (b.interesApagar ?? 0)), 0))}</b></TableCell>
                                            <TableCell><b>{getNumberToCurrency(amortizaciones.reduce((a: number, b: DatosAmortizacionesActivo) => a + (b.seguro ?? 0), 0))}</b></TableCell>
                                            <TableCell><b>{getNumberToCurrency(amortizaciones.reduce((a: number, b: DatosAmortizacionesActivo) => a + (b.gps ?? 0), 0))}</b></TableCell>
                                            <TableCell><b>{getNumberToCurrency(amortizaciones.reduce((a: number, b: DatosAmortizacionesActivo) => a + (b.gastosGenerales ?? 0), 0))}</b></TableCell>
                                            <TableCell><b>{getNumberToCurrency(amortizaciones.reduce((a: number, b: DatosAmortizacionesActivo) => a + (b.ivaDeInteresesApagar ?? 0), 0))}</b></TableCell>
                                            <TableCell><b>{getNumberToCurrency(amortizaciones.reduce((a: number, b: DatosAmortizacionesActivo) => a + (b.pagoTotal ?? 0), 0))}</b></TableCell>
                                        </TableRow>
                                    }
                                    {!R.isNil(amortizaciones) && amortizaciones.length > 0 &&
                                        amortizaciones.map((amortizacion: DatosAmortizacionesActivo) =>
                                            <StyledTableRow key={amortizacion.id}>
                                                <StyledTableCell>{amortizacion.numeroPago}</StyledTableCell>
                                                <StyledTableCell>{amortizacion.fechaPago != null ? moment(amortizacion.fechaPago).format('dddd LL') : ""}</StyledTableCell>
                                                <StyledTableCell>{getNumberToCurrency(((amortizacion.capitalApagar ?? 0) + (amortizacion.interesApagar ?? 0)))}</StyledTableCell>
                                                <StyledTableCell>{getNumberToCurrency(amortizacion.seguro)}</StyledTableCell>
                                                <StyledTableCell>{getNumberToCurrency(amortizacion.gps)}</StyledTableCell>
                                                <StyledTableCell>{getNumberToCurrency(amortizacion.gastosGenerales)}</StyledTableCell>
                                                <StyledTableCell>{getNumberToCurrency(amortizacion.ivaDeInteresesApagar)}</StyledTableCell>
                                                <StyledTableCell>{getNumberToCurrency(amortizacion.pagoTotal)}</StyledTableCell>
                                            </StyledTableRow>)
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                    <DialogActions sx={{ mb: 2, mr: 4 }} id="dialogActions">
                        <Button onClick={() => setOpenAmortizacionesActivo(false)}>Cerrar</Button>
                    </DialogActions>
                </Dialog></>
        );
    }

    if (!idClienteValid) {
        return (<BadParameters />);
    }

    return (
        <Box sx={{ width: '100%', mt: 2 }}>
            <Stepper activeStep={activeStep}>
                {steps.map((label) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                        optional?: React.ReactNode;
                    } = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep == 0 &&
                <><FormularioActivosCotizador />
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button
                            variant='contained'
                            disabled
                            onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
                            sx={{ mr: 2 }}>
                            Atrás
                        </Button>
                        <Button
                            disabled={(!R.isNil(listActivosCotizados) && listActivosCotizados.length > 0) ? false : true}
                            onClick={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
                            variant='contained'>
                            {activeStep == steps.length - 1 ? 'Terminar' : 'Siguiente'}
                        </Button>
                    </Box></>
            }
            {activeStep == 1 &&
                <><FormularioCondicionesCotizador />
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button
                            variant='contained'
                            onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
                            sx={{ mr: 2 }}>
                            Atrás
                        </Button>
                        <Button
                            disabled={!R.isNil(condiciones) ? false : true}
                            onClick={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
                            variant='contained'>
                            {activeStep == steps.length - 1 ? 'Terminar' : 'Siguiente'}
                        </Button>
                    </Box></>
            }
            {activeStep == 2 &&
                <><ResultadoCotizador />
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button
                            variant='contained'
                            onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
                            sx={{ mr: 2 }}>
                            Atrás
                        </Button>
                        {/* <Button
                            onClick={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
                            variant='contained'>
                            {activeStep == steps.length - 1 ? 'Terminar' : 'Siguiente'}
                        </Button> */}
                    </Box></>
            }
            {/* {activeStep == steps.length &&
                <><Typography sx={{ mt: 2, mb: 1 }}>
                    Terminado
                </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                    </Box></>
            } */}
        </Box>
    );
}