import { Box, Button, Container, Dialog, DialogContent, DialogContentText, Grid, Paper, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableRow, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../navigations/App";
import * as R from 'ramda';
import { TableLoader } from "../../components/loader/TableLoader";
import { useParams } from "react-router-dom";
import { IStpCompaniesRequest } from "../../models/IStpCompaniesRequest";
import { useCallback, useEffect, useState } from "react";
import * as stpCompaniesActions from '../Subcuentas/redux/actions';
import { DatosStpCompanies } from "../../models/IStpCompaniesResponse";
import { convertSecondsToDateTime, getNumberToCurrency, getTipoPersona } from "../../utils";
import { TableNotFoundData } from "../../components/notFoundData/TableNotFoundData";
import BadParameters from "../NotFound/BadParameters";
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import { StyledTableCell, StyledTableRow } from "../../components/styledTables/StyledTables";

export default function Dashboard() {
    const { token } = useAuth();
    const nombreCliente: boolean = useSelector((state: any) => state.login.nombreUsuario);
    const email: boolean = useSelector((state: any) => state.login.email);
    let datos = <TableLoader colums={4} text={'Buscando empresas...'} />;

    const idCliente = useSelector((state: any) => state.login.id);
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    let idClienteValid = regexExp.test(idCliente + '');

    let dataSend: IStpCompaniesRequest = {
        get: ''
    }

    const datosStpCompanies: any = useSelector((state: any) => state.stpCompanies.balanceResponseObj);
    let datosCheckAccountBalance: any = useSelector((state: any) => state.checkAccountBalance.data);
    const inProgressStpCompanies: any = useSelector((state: any) => state.stpCompanies.inProgress);
    const inPorgressCheckAccountBalance: any = useSelector((state: any) => state.checkAccountBalance.inProgress);
    const rfc: any = useSelector((state: any) => state.login.rfc);
    const idTipoPersona: any = useSelector((state: any) => state.login.idTipoPersona);

    const dispatch = useDispatch();
    const stpCompaniesRequest = useCallback(() => dispatch(stpCompaniesActions.requestStpCompanies(dataSend)), [dispatch, dataSend]);
    const checkAccountBalanceRequest = useCallback(() => dispatch(stpCompaniesActions.requestCheckAccountBalance(dataSend)), [dispatch, dataSend]);

    useEffect(() => {
        if (idClienteValid) {
            stpCompaniesRequest();
        }
    }, []);

    const checkAccountBalance = () => {
        checkAccountBalanceRequest();
    }

    const [openConsulta, setOpenConsulta] = useState(false);

    const handleClickOpenConsulta = () => {
        setOpenConsulta(true);
    };

    const handleCloseConsulta = () => {
        setOpenConsulta(false);
    };

    let estatusConsulta = <DialogContentText>Consultando...</DialogContentText>;

    if (!R.isNil(datosCheckAccountBalance) && !inPorgressCheckAccountBalance) {
        if (datosCheckAccountBalance.isSuccess) {
            estatusConsulta = <DialogContentText sx={{ color: 'success.main' }}>{datosCheckAccountBalance.messageStr}</DialogContentText>
            datosCheckAccountBalance = null;
            setTimeout(() =>
                (handleCloseConsulta()), 1000);
        }
        else {
            estatusConsulta = <DialogContentText sx={{ color: 'error.main' }}>No se pudo realizar la consulta, inténtelo más tarde</DialogContentText>
        }
    }

    if (!R.isNil(datosStpCompanies) && !inProgressStpCompanies) {
        if (rfc == "EGM210216UV7") {
            datos = datosStpCompanies.map((companies: DatosStpCompanies) =>
                <StyledTableRow
                    key={companies.idCCInt}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <StyledTableCell>
                        <Typography variant='body2' sx={{ mb: 1 }}>{companies.completeCompanyNameStr}</Typography>
                        <Typography variant='body2' sx={{ color: 'gray' }}>{companies.stpCompanyNameStr}</Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                        <Typography variant='body2' sx={{ mb: 1 }}>{companies.stpAccountStr}</Typography>
                        <Typography variant='body2' sx={{ color: 'gray' }}>{companies.accountTypeDescriptionStr}</Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                        <Typography variant='body2' sx={{ mb: 1 }}>{getNumberToCurrency(companies.lastStpCurrencyDbl)}</Typography>
                        <Typography variant='body2' sx={{ color: 'gray' }}>{convertSecondsToDateTime(companies.lastConsultingStpDate.seconds)}</Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                        <Button variant='contained' disabled={inPorgressCheckAccountBalance}
                            onClick={() => (handleClickOpenConsulta(), checkAccountBalance())}>
                            <CachedOutlinedIcon />
                        </Button>
                    </StyledTableCell>
                </StyledTableRow>)
        }
        else {
            datos = datos = <TableNotFoundData colums={5} text={'No se encontraron empresas.'} />;
        }
    }
    if (inProgressStpCompanies) {
        datos = <TableLoader colums={4} text={'Buscando empresas...'} />;
    }
    if (datosStpCompanies?.length == 0) {
        datos = <TableNotFoundData colums={4} text={'No se encontraron empresas.'} />;
    }

    function PopUpEstatusConsulta() {
        return (
            <Dialog
                open={openConsulta}
                onClose={handleCloseConsulta}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogContent sx={{ m: 2, textAlign: 'center' }} id="dialogContent">
                    <DialogContentText>
                        {estatusConsulta}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }

    function TablaConsultaSaldos() {
        return (
            <TableContainer component={Paper} elevation={4}>
                <Table sx={{ minWidth: 650 }} aria-label="customized table">
                    <TableBody>
                        <TableRow><TableCell colSpan={4}>
                            <Typography variant="h6">
                                <b>Consulta de saldo</b>
                            </Typography></TableCell></TableRow>
                        <TableRow>
                            <TableCell><b>Nombre completo</b></TableCell>
                            <TableCell><b>Información STP</b></TableCell>
                            <TableCell><b>Última consulta</b></TableCell>
                            <TableCell><b>Acciones</b></TableCell>
                        </TableRow>
                        {datos}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    if (!idClienteValid) {
        return (<BadParameters />);
    }

    return (
        <Box sx={{ m: 4 }}>
            <Grid container spacing={4}>
                <Grid item xs={6} sm={6}>
                    <Paper elevation={4} sx={{ p: 2 }}>
                        <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
                            <b>Información</b>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <b>Nombre:</b> {nombreCliente}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <b>Id:</b> {token}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <b>Correo:</b>  {email}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <b>RFC:</b>  {rfc}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <b>Tipo persona:</b>  {getTipoPersona(idTipoPersona)}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TablaConsultaSaldos />
                    <PopUpEstatusConsulta />
                </Grid>
            </Grid>
        </Box>
    );
}