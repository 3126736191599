import { DatosInstitucionesBancarias } from '../../../../models/catalogs/IInstitucionesBancariasResponse';
import * as types from '../../../../system/types'

export interface InstitucionesBancariasState {
    id: string,
    // errors: [],
    inProgress: boolean,
    data: Array<DatosInstitucionesBancarias> | null,
    message?: string | null
}

const InstitucionesBancariasInitialState: InstitucionesBancariasState = {
    id: '',
    // errors: [],
    data: null,
    inProgress: false
};

export default function institucionesBancariasReducer(
    state: InstitucionesBancariasState = InstitucionesBancariasInitialState,
    action: types.RootAction
): InstitucionesBancariasState {
    switch (action.type) {
        case types.INSTITUCIONESBANCARIAS_REQUEST:
            return {
                ...state,
                message: '',
                inProgress: true
            }
        case types.INSTITUCIONESBANCARIAS_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                inProgress: false
            }
        case types.INSTITUCIONESBANCARIAS_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}