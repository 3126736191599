import { all, put, fork, select } from "redux-saga/effects";
import * as financiamientosActions from "../screens/Financiamientos/redux/actions";
import * as disposicionesActions from "../components/common/Disposiciones/redux/actions";
import * as amortizacionesActions from "../components/common/Amortizaciones/redux/actions";
import * as datosFinanciamientoActions from "../components/common/DatosFinanciamiento/redux/actions";
import * as paquetesFactorajeActions from "../components/common/PaquetesFactoraje/redux/actions";
import * as loginActions from "../components/common/Login/redux/actions";
import * as pagosRealizadosActions from "../components/common/PagosRealizados/redux/actions";
import * as resumenCuentaCorrienteActions from "../components/common/ResumenCuentaCorriente/redux/actions";
import * as activosArrendamientoActions from "../components/common/ActivosArrendamiento/redux/actions";
import * as cuentaDepositoActions from "../screens/CuentaDeposito/redux/actions";
import * as cotizadorAPActions from "../components/common/CotizadorAP/redux/actions";
import * as cotizadorCSActions from "../components/common/CotizadorCS/redux/actions";
import * as institucionesBancariasActions from "../components/catalogs/InstitucionesBancarias/redux/actions";
import * as tipoCuentaActions from "../components/catalogs/TipoCuenta/redux/actions";
import * as tipoActivoActions from "../components/catalogs/TipoActivo/redux/actions";
import * as subtipoActivoActions from "../components/catalogs/SubtipoActivo/redux/actions";
import * as tipoArrendamientoActions from "../components/catalogs/TipoArrendamiento/redux/actions";
import * as tipoPagoAPActions from "../components/catalogs/TipoPagoAP/redux/actions";
import * as periodicidadActions from "../components/catalogs/Periodicidad/redux/actions";
import * as tipoPersonaActions from "../components/catalogs/TipoPersona/redux/actions";
import * as tipoTasaActions from "../components/catalogs/TipoTasa/redux/actions";
import * as tipoTasaMoratoriaActions from "../components/catalogs/TipoTasaMoratoria/redux/actions";
import * as tipoCalculoActions from "../components/catalogs/TipoCalculo/redux/actions";
import * as valorTiieActualActions from "../components/catalogs/ValorTiieActual/redux/actions";
import * as credentialsActions from "../screens/Credenciales/redux/actions";
import * as stpCompaniesActions from "../screens/Subcuentas/redux/actions";
import * as generarReferenciaPagoActions from "../components/common/GenerarReferenciaPago/redux/actions";
import * as generarReferenciaFactorajeActions from "../components/common/GenerarReferenciaFactoraje/redux/actions";
import * as types from "../system/types";
import { IResponseCommon } from "../models/IResponseCommon";
import * as R from "ramda";
import { IFinanciamientosResponse } from "../models/IFinanciamientosResponse";
import { IDisposicionesResponse } from "../models/IDisposicionesResponse";
import { IAmortizacionesResponse } from "../models/IAmortizacionesResponse";
import { IDatosFinanciamientoResponse } from "../models/IDatosFinanciamientoResponse";
import {
  IFacturasPaqueteResponse,
  IPaquetesFactorajeResponse,
} from "../models/IPaquetesFactorajeResponse";
import { ILoginResponse } from "../models/ILoginResponse";
import { IPagosRealizadosResponse } from "../models/IPagosRealizadosResponse";
import { IResumenCuentaCorrienteResponse } from "../models/IResumenCuentaCorrienteResponse";
import { IActivosArrendamientoResponse } from "../models/IActivosArrendamientoResponse";
import {
  ICuentaDepositoResponse,
  IPostCuentaDepositoResponse,
} from "../models/ICuentaDepositoResponse";
import { IInstitucionesBancariasResponse } from "../models/catalogs/IInstitucionesBancariasResponse";
import { ITipoCuentaResponse } from "../models/catalogs/ITipoCuentaResponse";
import { DatosCredentials, ICredentialsResponse } from "../models/ICredentials";
import { IWebHookStatusResponse } from "../models/IWebHookStatusRequest";
import { IAmortizacionesActivoResponse } from "../models/IAmortizacionesActivoResponse";
import { IFindValidStatusResponse } from "../models/IFindValidStatusResponse";
import { IAmortizacionesAPagarResponse } from "../models/IAmortizacionesAPagarResponse";
import {
  ICheckAccountBalanceResponse,
  IStpCompaniesResponse,
  ISubAccountMovimientosResponse,
  ISubAccountsResponse,
} from "../models/IStpCompaniesResponse";
import {
  IGRPResponse,
  ISimularPagoResponse,
} from "../models/ISimularPagoResponse";
import { IExportarArchivoResponse } from "../models/IExportarArchivoResponse";
import { IPaquetesAPagarResponse } from "../models/IPaquetesAPagarResponse";
import { ITipoActivoResponse } from "../models/catalogs/ITipoActivoResponse";
import { ISubtipoActivoResponse } from "../models/catalogs/ISubtipoActivoResponse";
import { ITipoArrendamientoResponse } from "../models/catalogs/ITipoArrendamientoResponse";
import { ITipoPagoAPResponse } from "../models/catalogs/ITipoPagoAPResponse";
import { IPeriodicidadResponse } from "../models/catalogs/IPeriodicidadResponse";
import { IResultadoCotizadorAPResponse } from "../models/cotizador/IResultadoCotizadorAPResponse";
import { ITipoPersonaResponse } from "../models/catalogs/ITipoPersonaResponse";
import { ITipoTasaResponse } from "../models/catalogs/ITipoTasaResponse";
import { ITipoTasaMoratoriaResponse } from "../models/catalogs/ITipoTasaMoratoriaResponse";
import { ITipoCalculoResponse } from "../models/catalogs/ITipoCalculoResponse";
import { IValorTiieActualResponse } from "../models/catalogs/IValorTiieActualResponse";
import { IAmortizacionesCotizadorCSResponse } from "../models/cotizador/IAmortizacionesCotizadorCSResponse";
import {
  IOpcionesContratoAPResponse,
  IResultadoOpcionContratoAPResponse,
} from "../models/IOpcionesContratoAPResponse";
import * as rootActions from "../system/actions";
import * as rootSelector from "../system/selector";
import { TipoFinanciamiento } from "../utils";

const StatusCode = Object.freeze({
  SUCCESS: 200,
  BAD_REQUEST: 400,
  INVALID_CREDENTIALS: 404,
  BAD_GATEWAY: 500,
  ERR_NETWORK: 0,
});

let statusCode: number = 0;

export function* controlledStates(
  response: any,
  responseType: string,
  code: number,
  showLogs = true
) {
  statusCode = code;
  response && showLogs && displayLogs(response, responseType);
  switch (responseType) {
    // CLIENTES
    case types.FINANCIAMIENTOS_REQUEST:
      yield onFinanciamientosRequest(response);
      break;
    case types.DISPOSICIONES_REQUEST:
      yield onDisposicionesRequest(response);
      break;
    case types.AMORTIZACIONES_REQUEST:
      yield onAmortizacionesRequest(response);
      break;
    case types.EXPORTARARCHIVOAMORTIZACIONES_REQUEST:
      yield onExportarArchivoAmortizacionesRequest(response);
      break;
    case types.EXPORTARARCHIVOPAGOSREALIZADOS_REQUEST:
      yield onExportarArchivoPagosRealizadosRequest(response);
      break;
    case types.EXPORTARARCHIVODISPOSICIONES_REQUEST:
      yield onExportarArchivoDisposicionesRequest(response);
      break;
    case types.EXPORTARARCHIVOACTIVOS_REQUEST:
      yield onExportarArchivoActivosRequest(response);
      break;
    case types.DATOSFINANCIAMIENTO_REQUEST:
      yield onDatosFinanciamientoRequest(response);
      break;
    case types.PAQUETESFACTORAJE_REQUEST:
      yield onPaquetesFactorajeRequest(response);
      break;
    case types.FACTURASPAQUETE_REQUEST:
      yield onFacturasPaqueteRequest(response);
      break;
    case types.LOGIN_REQUEST:
      yield onLoginRequest(response);
      break;
    case types.LOGOUT_REQUEST:
      yield onLogoutRequest(response);
      break;
    case types.LOGIN_BY_TOKEN_REQUEST:
      yield onLoginByTotkenRequest(response);
      break;
    case types.PAGOSREALIZADOS_REQUEST:
      yield onPagosRealizadosRequest(response);
      break;
    case types.RESUMENCUENTACORRIENTE_REQUEST:
      yield onResumenCuentaCorrienteRequest(response);
      break;
    case types.ACTIVOSARRENDAMIENTO_REQUEST:
      yield onActivosArrendamientoRequest(response);
      break;
    case types.AMORTIZACIONESACTIVO_REQUEST:
      yield onAmortizacionesActivoRequest(response);
      break;
    case types.CUENTADEPOSITO_REQUEST:
      yield onCuentaDepositoRequest(response);
      break;
    case types.POSTCUENTADEPOSITO_REQUEST:
      yield onPostCuentaDepositoRequest(response);
      break;
    case types.FINDVALIDSTATUS_REQUEST:
      yield onFindValidStatusRequest(response);
      break;
    case types.CREDENTIALS_REQUEST:
      yield onCredentialsRequest(response);
      break;
    case types.WEBHOOKSTATUS_REQUEST:
      yield onWebHookStatusRequest(response);
      break;
    case types.AMORTIZACIONESAPAGAR_REQUEST:
      yield onAmortizacionesAPagarRequest(response);
      break;
    case types.SIMULARPAGO_REQUEST:
      yield onSimularPagoRequest(response);
      break;
    case types.GENERARREFERENCIAPAGO_REQUEST:
      yield onGenerarReferenciaPagoRequest(response);
      break;
    case types.GENERARREFERENCIAPAGOFACTORAJE_REQUEST:
      yield onGenerarReferenciaPagoFactorajeRequest(response);
      break;
    case types.GENERARREFERENCIAPAGOACTIVOS_REQUEST:
      yield onGenerarReferenciaPagoActivosRequest(response);
      break;
    case types.PAQUETESAPAGAR_REQUEST:
      yield onPaquetesAPagarRequest(response);
      break;
    case types.OPCIONESCONTRATOAP_REQUEST:
      yield onOpcionesContratoAPRequest(response);
      break;
    case types.RESULTADOOPCIONCONTRATOAP_REQUEST:
      yield onResultadoOpcionContratoAPRequest(response);
      break;
    // COTIZADOR
    case types.RESULTADOCOTIZADORAP_REQUEST:
      yield onResultadoCotizadorAPRequest(response);
      break;
    case types.AMORTIZACIONESCOTIZADORCS_REQUEST:
      yield onAmortizacionesCotizadorCSRequest(response);
      break;
    case types.RECALCULOCOTIZADORCS_REQUEST:
      yield onRecalculoCotizadorCSRequest(response);
      break;
    case types.ARCHIVORESULTADOCOTIZADORCS_REQUEST:
      yield onArchivoResultadoCotizadorCSRequest(response);
      break;
    case types.ARCHIVORESULTADOCOTIZADORAP_REQUEST:
      yield onArchivoResultadoCotizadorAPRequest(response);
      break;
    // CATALOGOS
    case types.INSTITUCIONESBANCARIAS_REQUEST:
      yield onInstitucionesBancariasRequest(response);
      break;
    case types.TIPOCUENTA_REQUEST:
      yield onTipoCuentaRequest(response);
      break;
    case types.TIPOACTIVO_REQUEST:
      yield onTipoActivoRequest(response);
      break;
    case types.SUBTIPOACTIVO_REQUEST:
      yield onSubtipoActivoRequest(response);
      break;
    case types.TIPOARRENDAMIENTO_REQUEST:
      yield onTipoArrendamientoRequest(response);
      break;
    case types.TIPOPAGOAP_REQUEST:
      yield onTipoPagoAPRequest(response);
      break;
    case types.PERIODICIDAD_REQUEST:
      yield onPeriodicidadRequest(response);
      break;
    case types.TIPOPERSONA_REQUEST:
      yield onTipoPersonaRequest(response);
      break;
    case types.TIPOTASA_REQUEST:
      yield onTipoTasaRequest(response);
      break;
    case types.TIPOTASAMORATORIA_REQUEST:
      yield onTipoTasaMoratoriaRequest(response);
      break;
    case types.TIPOCALCULO_REQUEST:
      yield onTipoCalculoRequest(response);
      break;
    case types.VALORTIIEACTUAL_REQUEST:
      yield onValorTiieActualRequest(response);
      break;
    // STP
    case types.STPCOMPANIES_REQUEST:
      yield onStpCompaniesRequest(response);
      break;
    case types.SUBACCOUNTS_REQUEST:
      yield onSubAccountsRequest(response);
      break;
    case types.SUBACCOUNTMOVIMIENTOS_REQUEST:
      yield onSubAccountMovimientosRequest(response);
      break;
    case types.CHECKACCOUNTBALANCE_REQUEST:
      yield onCheckAccountBalanceRequest(response);
      break;
    default:
      alert("CONTROLLER[" + responseType + "] - TYPE NOT DEFINED");
      break;
  }
}

function displayLogs(response: any, type: string) {
  const data = JSON.stringify(response?.data);
}

function commonResponse(response: IResponseCommon) {
  switch (response.status) {
    case StatusCode.SUCCESS:
      if (R.isNil(response.errors)) {
      }
      break;
    case StatusCode.BAD_REQUEST:
      // response.message = 'Ocurrieron errores.'
      break;
    case StatusCode.INVALID_CREDENTIALS:
      response.message = "Credenciales de usuario incorrectas.";
      break;
    case StatusCode.BAD_GATEWAY:
      if (R.isNil(response.message)) {
        response.message = "Problema de conexión.";
      }
      break;
    case StatusCode.ERR_NETWORK:
      response.message =
        "Servicio no disponible, fuera de línea [" + response.code + "].";
      break;
    default:
      response.message = "Operación denegada, favor de inténtarlo más tarde.";
      break;
  }
  return response;
}

//#region CLIENTES
function* onFinanciamientosRequest(response: IFinanciamientosResponse) {
  const common: IResponseCommon = response;
  commonResponse(common);
  if (common.status == StatusCode.SUCCESS) {
    response.succeeded = true;
  }
  yield put(
    financiamientosActions.onFinanciamientosResponse(
      response.data,
      common.message
    )
  );
}

function* onDisposicionesRequest(response: IDisposicionesResponse) {
  const common: IResponseCommon = response;
  commonResponse(common);
  if (common.status == StatusCode.SUCCESS) {
    response.succeeded = true;
  }
  yield put(
    disposicionesActions.onDisposicionesResponse(response.data, common.message)
  );
}

function* onAmortizacionesRequest(response: IAmortizacionesResponse) {
  const common: IResponseCommon = response;
  commonResponse(common);
  if (common.status == StatusCode.SUCCESS) {
    response.succeeded = true;
  }
  yield put(
    amortizacionesActions.onAmortizacionesResponse(
      response.data,
      response.message
    )
  );
}

function* onExportarArchivoAmortizacionesRequest(
  response: IExportarArchivoResponse
) {
  const common: IResponseCommon = response;
  commonResponse(common);
  if (common.status == StatusCode.SUCCESS) {
    response.succeeded = true;
  }
  yield put(
    amortizacionesActions.onExportarArchivoAmortizacionesResponse(
      response.data,
      response.message,
      response.succeeded
    )
  );
}

function* onExportarArchivoPagosRealizadosRequest(
  response: IExportarArchivoResponse
) {
  const common: IResponseCommon = response;
  commonResponse(common);
  if (common.status == StatusCode.SUCCESS) {
    response.succeeded = true;
  }
  yield put(
    pagosRealizadosActions.onExportarArchivoPagosRealizadosResponse(
      response.data,
      response.message,
      response.succeeded
    )
  );
}

function* onExportarArchivoDisposicionesRequest(
  response: IExportarArchivoResponse
) {
  const common: IResponseCommon = response;
  commonResponse(common);
  if (common.status == StatusCode.SUCCESS) {
    response.succeeded = true;
  }
  yield put(
    disposicionesActions.onExportarArchivoDisposicionesResponse(
      response.data,
      response.message,
      response.succeeded
    )
  );
}

function* onExportarArchivoActivosRequest(response: IExportarArchivoResponse) {
  const common: IResponseCommon = response;
  commonResponse(common);
  if (common.status == StatusCode.SUCCESS) {
    response.succeeded = true;
  }
  yield put(
    activosArrendamientoActions.onExportarArchivoActivosResponse(
      response.data,
      response.message,
      response.succeeded
    )
  );
}

function* onDatosFinanciamientoRequest(response: IDatosFinanciamientoResponse) {
  const common: IResponseCommon = response;
  commonResponse(common);
  if (common.status == StatusCode.SUCCESS) {
    response.succeeded = true;
  }
  yield put(
    datosFinanciamientoActions.onDatosFinanciamientoResponse(
      response.data,
      common.message
    )
  );
}

function* onPaquetesFactorajeRequest(response: IPaquetesFactorajeResponse) {
  const common: IResponseCommon = response;
  commonResponse(common);
  if (common.status == StatusCode.SUCCESS) {
    response.succeeded = true;
  }
  yield put(
    paquetesFactorajeActions.onPaquetesFactorajeResponse(
      response.data,
      common.message
    )
  );
}

function* onFacturasPaqueteRequest(response: IFacturasPaqueteResponse) {
  const common: IResponseCommon = response;
  commonResponse(common);
  if (common.status == StatusCode.SUCCESS) {
    response.succeeded = true;
  }
  yield put(
    paquetesFactorajeActions.onFacturasPaqueteResponse(
      response.data,
      common.message
    )
  );
}

function* onLoginRequest(response: ILoginResponse) {
  const common: IResponseCommon = response;
  commonResponse(common);
  if (common.status == StatusCode.SUCCESS) {
    response.succeeded = true;
  }
  yield put(loginActions.onLoginResponse(response.data, common.message));
}

function* onLogoutRequest(response: ILoginResponse) {
  yield put(
    loginActions.onLogoutResponse(null, null, null, null, null, null, false)
  );
}

function* onLoginByTotkenRequest(authorization: any) {
  if (
    authorization.app != undefined &&
    authorization.uid != undefined &&
    authorization.email != undefined &&
    authorization.nombreUsuario != undefined
  ) {
    yield all([
      put(
        rootActions.setFilters({
          idCliente: authorization.uid,
          idTipoPersona: authorization.idTipoPersona,
        })
      ),
      put(
        loginActions.onLoginByTokenResponse(
          authorization.app,
          authorization.uid,
          authorization.email,
          authorization.nombreUsuario,
          authorization.rfc,
          authorization.idTipoPersona,
          true
        )
      ),
    ]);
  } else {
    yield put(
      loginActions.onLoginByTokenResponse("", "", "", "", "", "", false)
    );
  }
}

function* onPagosRealizadosRequest(response: IPagosRealizadosResponse) {
  const common: IResponseCommon = response;
  commonResponse(common);
  if (common.status == StatusCode.SUCCESS) {
    response.succeeded = true;
  }
  yield put(
    pagosRealizadosActions.onPagosRealizadosResponse(
      response.data,
      common.message
    )
  );
}

function* onResumenCuentaCorrienteRequest(
  response: IResumenCuentaCorrienteResponse
) {
  const common: IResponseCommon = response;
  commonResponse(common);
  if (common.status == StatusCode.SUCCESS) {
    response.succeeded = true;
  }
  yield put(
    resumenCuentaCorrienteActions.onResumenCuentaCorrienteResponse(
      response.data,
      common.message
    )
  );
}

function* onActivosArrendamientoRequest(
  response: IActivosArrendamientoResponse
) {
  const common: IResponseCommon = response;
  commonResponse(common);
  if (common.status == StatusCode.SUCCESS) {
    response.succeeded = true;
  }
  yield put(
    activosArrendamientoActions.onActivosArrendamientoResponse(
      response.data,
      common.message
    )
  );
}

function* onAmortizacionesActivoRequest(
  response: IAmortizacionesActivoResponse
) {
  const common: IResponseCommon = response;
  commonResponse(common);
  if (common.status == StatusCode.SUCCESS) {
    response.succeeded = true;
  }
  yield put(
    activosArrendamientoActions.onAmortizacionesActivoResponse(
      response.data,
      common.message
    )
  );
}

function* onCuentaDepositoRequest(response: ICuentaDepositoResponse) {
  const common: IResponseCommon = response;
  commonResponse(common);
  if (common.status == StatusCode.SUCCESS) {
    response.succeeded = true;
  }
  yield all([
    put(tipoCuentaActions.requestTipoCuenta("")),
    put(institucionesBancariasActions.requestInstitucionesBancarias("")),
    put(
      cuentaDepositoActions.onCuentaDepositoResponse(
        response.data,
        response.message
      )
    ),
  ]);
}

function* onPostCuentaDepositoRequest(response: IPostCuentaDepositoResponse) {
  const common: IResponseCommon = response;
  commonResponse(common);
  if (common.status == StatusCode.SUCCESS) {
    response.succeeded = true;
  }
  yield put(
    cuentaDepositoActions.onPostCuentaDepositoResponse(
      response.data,
      response.message
    )
  );
}

function* onFindValidStatusRequest(response: IFindValidStatusResponse) {
  const common: IResponseCommon = response;
  commonResponse(common);

  if (common.status == StatusCode.SUCCESS) {
    // response.succeeded = true;
    yield put(
      credentialsActions.onFindValidStatusResponse(
        response.data,
        response.message
      )
    );
  }
  if (common.status == StatusCode.BAD_REQUEST) {
    yield put(credentialsActions.onFindValidStatusFailed(response.message));
  }
}

function* onCredentialsRequest(response: ICredentialsResponse) {
  const common: IResponseCommon = response;
  commonResponse(common);
  if (common.status == StatusCode.SUCCESS) {
    response.succeeded = true;
    yield put(
      credentialsActions.onCredentialsResponse(
        response.data,
        "Validando datos de acceso, espere un momento por favor..."
      )
    );
  } else {
    if (common.message.length > 0) {
      common.errors.push(common.message);
    }
    yield put(
      credentialsActions.onCredentialsFailed(
        common,
        "No fue posible validar su credenciales, posibles errores..."
      )
    );
  }
}

function* onWebHookStatusRequest(response: IWebHookStatusResponse) {
  const common: IResponseCommon = response;
  commonResponse(common);
  if (common.status == StatusCode.SUCCESS) {
    // response.succeeded = true;
    yield put(
      credentialsActions.onWebHookStatusResponse(
        response.data,
        response.message
      )
    );
  }
  if (common.status == StatusCode.BAD_REQUEST) {
    yield put(credentialsActions.onWebHookStatusFailed(response.message));
  }
}

function* onAmortizacionesAPagarRequest(
  response: IAmortizacionesAPagarResponse
) {
  const common: IResponseCommon = response;
  commonResponse(common);

  if (common.status == StatusCode.SUCCESS) {
    // response.succeeded = true;
    yield put(
      generarReferenciaPagoActions.onAmortizacionesAPagarResponse(
        response.data,
        response.message
      )
    );
  }
  if (common.status == StatusCode.BAD_REQUEST) {
    yield put(
      generarReferenciaPagoActions.onAmortizacionesAPagarFailed(
        response.data,
        response.message
      )
    );
  }
}

function* onSimularPagoRequest(response: ISimularPagoResponse) {
  const common: IResponseCommon = response;
  commonResponse(common);

  if (common.status == StatusCode.SUCCESS) {
    // response.succeeded = true;
    yield put(
      generarReferenciaPagoActions.onSimularPagoResponse(
        response.data,
        response.message
      )
    );
  }
  if (common.status == StatusCode.BAD_REQUEST) {
    yield put(
      generarReferenciaPagoActions.onSimularPagoFailed(
        response.data,
        response.message
      )
    );
  }
}

function* onGenerarReferenciaPagoRequest(response: IGRPResponse) {
  const common: IResponseCommon = response;
  commonResponse(common);

  if (common.status == StatusCode.SUCCESS) {
    // response.succeeded = true;
    yield put(
      generarReferenciaPagoActions.onGenerarReferenciaPagoResponse(
        response.data,
        response.message
      )
    );
  }
  if (common.status == StatusCode.BAD_REQUEST) {
    yield put(
      generarReferenciaPagoActions.onGenerarReferenciaPagoFailed(
        response.data,
        response.message
      )
    );
  }
}

function* onGenerarReferenciaPagoFactorajeRequest(response: IGRPResponse) {
  const common: IResponseCommon = response;
  commonResponse(common);

  if (common.status == StatusCode.SUCCESS) {
    // response.succeeded = true;
    yield put(
      generarReferenciaFactorajeActions.onGenerarReferenciaPagoFactorajeResponse(
        response.data,
        response.message
      )
    );
  }
  if (common.status == StatusCode.BAD_REQUEST) {
    yield put(
      generarReferenciaFactorajeActions.onGenerarReferenciaPagoFactorajeFailed(
        response.data,
        response.message
      )
    );
  }
}

function* onGenerarReferenciaPagoActivosRequest(response: IGRPResponse) {
  const common: IResponseCommon = response;
  commonResponse(common);

  if (common.status == StatusCode.SUCCESS) {
    // response.succeeded = true;
    yield put(
      activosArrendamientoActions.onGenerarReferenciaPagoActivosResponse(
        response.data,
        response.message
      )
    );
  }
  if (common.status == StatusCode.BAD_REQUEST) {
    yield put(
      activosArrendamientoActions.onGenerarReferenciaPagoActivosFailed(
        response.data,
        response.message
      )
    );
  }
}

function* onPaquetesAPagarRequest(response: IPaquetesAPagarResponse) {
  const common: IResponseCommon = response;
  commonResponse(common);

  if (common.status == StatusCode.SUCCESS) {
    // response.succeeded = true;
    yield put(
      generarReferenciaFactorajeActions.onPaquetesAPagarResponse(
        response.data,
        response.message
      )
    );
  }
  if (common.status == StatusCode.BAD_REQUEST) {
    yield put(
      generarReferenciaFactorajeActions.onPaquetesAPagarFailed(
        response.data,
        response.message
      )
    );
  }
}

function* onOpcionesContratoAPRequest(response: IOpcionesContratoAPResponse) {
  const common: IResponseCommon = response;
  commonResponse(common);

  if (common.status == StatusCode.SUCCESS) {
    // response.succeeded = true;
    let idTipoPersona: string = yield select(rootSelector.ID_TIPO_PERSONA);
    let idForoCondiciones: string = yield select(
      rootSelector.ID_FORO_CONDICIONES
    );
    yield all([
      put(
        generarReferenciaPagoActions.requestAmortizacionesAPagar({
          IdForoCondiciones: idForoCondiciones,
          IdTipoFinanciamiento: TipoFinanciamiento["ArrendamientoPuro"],
          IdTipoPersona: idTipoPersona,
        })
      ),
      put(
        generarReferenciaPagoActions.onOpcionesContratoAPResponse(
          response.data,
          response.message
        )
      ),
    ]);
  }
  if (common.status == StatusCode.BAD_REQUEST) {
    yield put(
      generarReferenciaPagoActions.onOpcionesContratoAPFailed(
        response.data,
        response.message
      )
    );
  }
}

function* onResultadoOpcionContratoAPRequest(
  response: IResultadoOpcionContratoAPResponse
) {
  const common: IResponseCommon = response;
  commonResponse(common);

  if (common.status == StatusCode.SUCCESS) {
    // response.succeeded = true;
    yield put(
      generarReferenciaPagoActions.onResultadoOpcionContratoAPResponse(
        response.data,
        response.message
      )
    );
  }
  if (common.status == StatusCode.BAD_REQUEST) {
    yield put(
      generarReferenciaPagoActions.onResultadoOpcionContratoAPFailed(
        response.data,
        response.message
      )
    );
  }
}
//#endregion

//#region COTIZADOR
function* onResultadoCotizadorAPRequest(
  response: IResultadoCotizadorAPResponse
) {
  const common: IResponseCommon = response;
  commonResponse(common);
  if (common.status == StatusCode.SUCCESS) {
    response.succeeded = true;
  }
  yield put(
    cotizadorAPActions.onResultadoCotizadorAPResponse(
      response.data,
      common.message
    )
  );
}

function* onAmortizacionesCotizadorCSRequest(
  response: IAmortizacionesCotizadorCSResponse
) {
  const common: IResponseCommon = response;
  commonResponse(common);
  if (common.status == StatusCode.SUCCESS) {
    response.succeeded = true;
  }
  yield put(
    cotizadorCSActions.onAmortizacionesCotizadorCSResponse(
      response.data,
      common.message
    )
  );
}

function* onRecalculoCotizadorCSRequest(
  response: IAmortizacionesCotizadorCSResponse
) {
  const common: IResponseCommon = response;
  commonResponse(common);
  if (common.status == StatusCode.SUCCESS) {
    response.succeeded = true;
  }
  yield put(
    cotizadorCSActions.onRecalculoCotizadorCSResponse(
      response.data,
      common.message
    )
  );
}

function* onArchivoResultadoCotizadorCSRequest(
  response: IExportarArchivoResponse
) {
  const common: IResponseCommon = response;
  commonResponse(common);
  if (common.status == StatusCode.SUCCESS) {
    response.succeeded = true;
  }
  yield put(
    cotizadorCSActions.onArchivoResultadoCotizadorCSResponse(
      response.data,
      response.message,
      response.succeeded
    )
  );
}

function* onArchivoResultadoCotizadorAPRequest(
  response: IExportarArchivoResponse
) {
  const common: IResponseCommon = response;
  commonResponse(common);
  if (common.status == StatusCode.SUCCESS) {
    response.succeeded = true;
  }
  yield put(
    cotizadorAPActions.onArchivoResultadoCotizadorAPResponse(
      response.data,
      response.message,
      response.succeeded
    )
  );
}
//#endregion

//#region CATALOGOS
function* onInstitucionesBancariasRequest(
  response: IInstitucionesBancariasResponse
) {
  const common: IResponseCommon = response;
  commonResponse(common);
  if (common.status == StatusCode.SUCCESS) {
    response.succeeded = true;
  }
  yield put(
    institucionesBancariasActions.onInstitucionesBancariasResponse(
      response.data,
      common.message
    )
  );
}

function* onTipoCuentaRequest(response: ITipoCuentaResponse) {
  const common: IResponseCommon = response;
  commonResponse(common);
  if (common.status == StatusCode.SUCCESS) {
    response.succeeded = true;
  }
  yield put(
    tipoCuentaActions.onTipoCuentaResponse(response.data, common.message)
  );
}

function* onTipoActivoRequest(response: ITipoActivoResponse) {
  const common: IResponseCommon = response;
  commonResponse(common);
  if (common.status == StatusCode.SUCCESS) {
    response.succeeded = true;
  }
  yield all([
    put(subtipoActivoActions.requestSubtipoActivo("")),
    put(tipoActivoActions.onTipoActivoResponse(response.data, common.message)),
  ]);
}

function* onSubtipoActivoRequest(response: ISubtipoActivoResponse) {
  const common: IResponseCommon = response;
  commonResponse(common);
  if (common.status == StatusCode.SUCCESS) {
    response.succeeded = true;
  }
  yield put(
    subtipoActivoActions.onSubtipoActivoResponse(response.data, common.message)
  );
}

function* onTipoPagoAPRequest(response: ITipoPagoAPResponse) {
  const common: IResponseCommon = response;
  commonResponse(common);
  if (common.status == StatusCode.SUCCESS) {
    response.succeeded = true;
  }
  yield all([
    put(tipoArrendamientoActions.requestTipoArrendamiento("")),
    put(periodicidadActions.requestPeriodicidad("")),
    put(tipoPagoAPActions.onTipoPagoAPResponse(response.data, common.message)),
  ]);
}

function* onTipoArrendamientoRequest(response: ITipoArrendamientoResponse) {
  const common: IResponseCommon = response;
  commonResponse(common);
  if (common.status == StatusCode.SUCCESS) {
    response.succeeded = true;
  }
  yield put(
    tipoArrendamientoActions.onTipoArrendamientoResponse(
      response.data,
      common.message
    )
  );
}

function* onPeriodicidadRequest(response: IPeriodicidadResponse) {
  const common: IResponseCommon = response;
  commonResponse(common);
  if (common.status == StatusCode.SUCCESS) {
    response.succeeded = true;
  }
  yield put(
    periodicidadActions.onPeriodicidadResponse(response.data, common.message)
  );
}

function* onTipoPersonaRequest(response: ITipoPersonaResponse) {
  const common: IResponseCommon = response;
  commonResponse(common);
  if (common.status == StatusCode.SUCCESS) {
    response.succeeded = true;
  }
  yield all([
    put(periodicidadActions.requestPeriodicidad("")),
    put(tipoTasaActions.requestTipoTasa("")),
    put(tipoTasaMoratoriaActions.requestTipoTasaMoratoria("")),
    put(tipoCalculoActions.requestTipoCalculo("")),
    put(valorTiieActualActions.requestValorTiieActual("")),
    put(
      tipoPersonaActions.onTipoPersonaResponse(response.data, common.message)
    ),
  ]);
}

function* onTipoTasaRequest(response: ITipoTasaResponse) {
  const common: IResponseCommon = response;
  commonResponse(common);
  if (common.status == StatusCode.SUCCESS) {
    response.succeeded = true;
  }
  yield put(tipoTasaActions.onTipoTasaResponse(response.data, common.message));
}

function* onTipoTasaMoratoriaRequest(response: ITipoTasaMoratoriaResponse) {
  const common: IResponseCommon = response;
  commonResponse(common);
  if (common.status == StatusCode.SUCCESS) {
    response.succeeded = true;
  }
  yield put(
    tipoTasaMoratoriaActions.onTipoTasaMoratoriaResponse(
      response.data,
      common.message
    )
  );
}

function* onTipoCalculoRequest(response: ITipoCalculoResponse) {
  const common: IResponseCommon = response;
  commonResponse(common);
  if (common.status == StatusCode.SUCCESS) {
    response.succeeded = true;
  }
  yield put(
    tipoCalculoActions.onTipoCalculoResponse(response.data, common.message)
  );
}

function* onValorTiieActualRequest(response: IValorTiieActualResponse) {
  const common: IResponseCommon = response;
  commonResponse(common);
  if (common.status == StatusCode.SUCCESS) {
    response.succeeded = true;
  }
  yield put(
    valorTiieActualActions.onValorTiieActualResponse(
      response.data,
      common.message
    )
  );
}
//#endregion

//#region STP
function* onStpCompaniesRequest(response: IStpCompaniesResponse) {
  const common: IResponseCommon = response;
  commonResponse(common);
  if (common.status == StatusCode.SUCCESS) {
    response.succeeded = true;
  }
  yield put(
    stpCompaniesActions.onStpCompaniesResponse(
      response.balanceResponseObj,
      common.message
    )
  );
}

function* onSubAccountsRequest(response: ISubAccountsResponse) {
  const common: IResponseCommon = response;
  commonResponse(common);
  if (common.status == StatusCode.SUCCESS) {
    response.succeeded = true;
  }
  yield put(
    stpCompaniesActions.onSubAccountsResponse(
      response.dropdownDataObj,
      common.message
    )
  );
}

function* onSubAccountMovimientosRequest(
  response: ISubAccountMovimientosResponse
) {
  const common: IResponseCommon = response;
  commonResponse(common);
  if (common.status == StatusCode.SUCCESS) {
    response.succeeded = true;
  }
  yield put(
    stpCompaniesActions.onSubAccountMovimientosResponse(
      response.transaccionResponseObj,
      common.message
    )
  );
}

function* onCheckAccountBalanceRequest(response: ICheckAccountBalanceResponse) {
  yield put(stpCompaniesActions.onCheckAccountBalanceResponse(response));
  if (response.isSuccess) {
    yield put(stpCompaniesActions.requestStpCompanies({ get: "" }));
  }
}
//#endregion
