import { combineReducers } from "redux";
import rootReducer from '../system/reducers';
import financiamientosReducer from "../screens/Financiamientos/redux/reducers";
import { disposicionesReducer, exportarArchivoDisposicionesReducer } from "../components/common/Disposiciones/redux/reducers";
import { amortizacionesReducer, exportarArchivoAmortizacionesReducer } from "../components/common/Amortizaciones/redux/reducers";
import datosFinanciamientoReducer from "../components/common/DatosFinanciamiento/redux/reducers";
import { facturasPaqueteReducer, paquetesFactorajeReducer } from "../components/common/PaquetesFactoraje/redux/reducers";
import loginReducer from "../components/common/Login/redux/reducers";
import { exportarArchivoPagosRealizadosReducer, pagosRealizadosReducer } from "../components/common/PagosRealizados/redux/reducers";
import resumenCuentaCorrienteReducer from "../components/common/ResumenCuentaCorriente/redux/reducers";
import { activosArrendamientoReducer, amortizacionesActivoReducer, exportarArchivoActivosReducer, generarReferenciaPagoActivosReducer } from "../components/common/ActivosArrendamiento/redux/reducers";
import { cuentaDepositoReducer, postCuentaDepositoReducer } from "../screens/CuentaDeposito/redux/reducers";
import storage from 'redux-persist/lib/storage';
import { persistReducer } from "redux-persist";
import institucionesBancariasReducer from "../components/catalogs/InstitucionesBancarias/redux/reducers";
import tipoCuentaReducer from "../components/catalogs/TipoCuenta/redux/reducers";
import { credentialsReducer, findValidStatusReducer, webHookStatusReducer } from "../screens/Credenciales/redux/reducers";
import { amortizacionesAPagarReducer, generarReferenciaPagoReducer, opcionesContratoAPReducer, resultadoOpcionContratoAPReducer, simularPagoReducer } from "../components/common/GenerarReferenciaPago/redux/reducers";
import { checkAccountBalanceReducer, stpCompaniesReducer, subAccountMovimientosReducer, subAccountsReducer } from "../screens/Subcuentas/redux/reducers";
import { generarReferenciaPagoFactorajeReducer, paquetesAPagarReducer } from "../components/common/GenerarReferenciaFactoraje/redux/reducers";
import tipoActivoReducer from "../components/catalogs/TipoActivo/redux/reducers";
import subtipoActivoReducer from "../components/catalogs/SubtipoActivo/redux/reducers";
import tipoArrendamientoReducer from "../components/catalogs/TipoArrendamiento/redux/reducers";
import tipoPagoAPReducer from "../components/catalogs/TipoPagoAP/redux/reducers";
import periodicidadReducer from "../components/catalogs/Periodicidad/redux/reducers";
import { resultadoCotizadorAPReducer, archivoResultadoCotizadorAPReducer } from "../components/common/CotizadorAP/redux/reducers";
import tipoPersonaReducer from "../components/catalogs/TipoPersona/redux/reducers";
import tipoTasaReducer from "../components/catalogs/TipoTasa/redux/reducers";
import tipoTasaMoratoriaReducer from "../components/catalogs/TipoTasaMoratoria/redux/reducers";
import tipoCalculoReducer from "../components/catalogs/TipoCalculo/redux/reducers";
import valorTiieActualReducer from "../components/catalogs/ValorTiieActual/redux/reducers";
import { amortizacionesCotizadorCSReducer, archivoResultadoCotizadorCSReducer, recalculoCotizadorCSReducer } from "../components/common/CotizadorCS/redux/reducers";

const basePersistConfig = {
    key: 'base',
    storage: storage,
    blacklist: ['auth', 'checkAccountBalance', 'simularPago']
}

const authPersistConfig = {
    key: 'auth',
    storage: storage
}

//#region CLIENTES
const financiamientosPersistConfig = {
    key: 'financiamientos',
    storage: storage,
    blacklist: ['data']
}

const disposicionesPersistConfig = {
    key: 'disposiciones',
    storage: storage,
    blacklist: ['data']
}

const amortizacionesPersistConfig = {
    key: 'amortizaciones',
    storage: storage,
    blacklist: ['data']
}

const exportarArchivoAmortizacionesPersistConfig = {
    key: 'exportarArchivoAmortizaciones',
    storage: storage,
    blacklist: ['data']
}

const exportarArchivoPagosRealizadosPersistConfig = {
    key: 'exportarArchivoPagosRealizados',
    storage: storage,
    blacklist: ['data']
}

const exportarArchivoDisposicionesPersistConfig = {
    key: 'exportarArchivoDisposiciones',
    storage: storage,
    blacklist: ['data']
}

const exportarArchivoActivosPersistConfig = {
    key: 'exportarArchivoActivos',
    storage: storage,
    blacklist: ['data']
}

const datosFinanciamientoPersistConfig = {
    key: 'datosFinanciamiento',
    storage: storage,
    blacklist: ['data']
}

const paquetesFactorajePersistConfig = {
    key: 'paquetesFactoraje',
    storage: storage,
    blacklist: ['data']
}

const facturasPaquetePersistConfig = {
    key: 'facturasPaquete',
    storage: storage,
    blacklist: ['data']
}

const pagosRealizadosPersistConfig = {
    key: 'pagosRealizados',
    storage: storage,
    blacklist: ['data']
}

const resumenCuentaCorrientePersistConfig = {
    key: 'resumenCuentaCorriente',
    storage: storage,
    blacklist: ['data']
}

const activosArrendamientoPersistConfig = {
    key: 'activosArrendamiento',
    storage: storage,
    blacklist: ['data']
}

const amortizacionesActivoPersistConfig = {
    key: 'amortizacionesActivo',
    storage: storage,
    blacklist: ['data']
}

const cuentaDepositoPersistConfig = {
    key: 'cuentaDeposito',
    storage: storage,
    blacklist: ['data']
}

const postCuentaDepositoPersistConfig = {
    key: 'postCuentaDeposito',
    storage: storage,
    blacklist: ['data']
}

const findValidStatusPersistConfig = {
    key: 'findValidStatus',
    storage: storage,
    blacklist: ['data']
}

const credentialsPersistConfig = {
    key: 'credentials',
    storage: storage,
    blacklist: ['data']
}

const webHookStatusPersistConfig = {
    key: 'webHookStatus',
    storage: storage,
    blacklist: ['data']
}

const amortizacionesAPagarPersistConfig = {
    key: 'amortizacionesAPagar',
    storage: storage,
    blacklist: ['data']
}

const simularPagoPersistConfig = {
    key: 'simularPago',
    storage: storage,
    blacklist: ['data']
}

const generarReferenciaPagoPersistConfig = {
    key: 'generarReferenciaPago',
    storage: storage,
    blacklist: ['data']
}

const paquetesAPagarPersistConfig = {
    key: 'paquetesAPagar',
    storage: storage,
    blacklist: ['data']
}
//#endregion

//#region COTIZADOR
const resultadoCotizadorAPPersistConfig = {
    key: 'resultadoCotizadorAP',
    storage: storage
}

const amortizacionesCotizadorCSPersistConfig = {
    key: 'amortizacionesCotizadorCS',
    storage: storage
}

const recalculoCotizadorCSPersistConfig = {
    key: 'recalculoCotizadorCS',
    storage: storage
}

const archivoResultadoCotizadorCSPersistConfig = {
    key: 'archivoResultadoCotizadorCS',
    storage: storage
}

const archivoResultadoCotizadorAPPersistConfig = {
    key: 'archivoResultadoCotizadorAP',
    storage: storage
}

const opcionesContratoAPPersistConfig = {
    key: 'opcionesContratoAP',
    storage: storage
}

const resultadoOpcionContratoAPPersistConfig = {
    key: 'resultadoOpcionContratoAP',
    storage: storage
}
//#endregion

//#region CATALOGOS
const institucionesBancariasPersistConfig = {
    key: 'institucionesBancarias',
    storage: storage
}

const tipoCuentaPersistConfig = {
    key: 'tipoCuenta',
    storage: storage
}

const tipoActivoPersistConfig = {
    key: 'tipoActivo',
    storage: storage
}

const subtipoActivoPersistConfig = {
    key: 'subtipoActivo',
    storage: storage
}

const tipoArrendamientoPersistConfig = {
    key: 'tipoArrendamiento',
    storage: storage
}

const tipoPagoAPPersistConfig = {
    key: 'tipoPagoAP',
    storage: storage
}

const periodicidadPersistConfig = {
    key: 'periodicidad',
    storage: storage
}

const tipoPersonaPersistConfig = {
    key: 'tipoPersona',
    storage: storage
}

const tipoTasaPersistConfig = {
    key: 'tipoTasa',
    storage: storage
}

const tipoTasaMoratoriaPersistConfig = {
    key: 'tipoTasaMoratoria',
    storage: storage
}

const tipoCalculoPersistConfig = {
    key: 'tipoCalculo',
    storage: storage
}

const valorTiieActualPersistConfig = {
    key: 'valorTiieActual',
    storage: storage
}
//#endregion

//#region STP
const stpCompaniesPersistConfig = {
    key: 'stpCompanies',
    storage: storage
}

const subAccountsPersistConfig = {
    key: 'subAccounts',
    storage: storage
}

const subAccountMovimientosPersistConfig = {
    key: 'subAccountMovimientos',
    storage: storage
}

const checkAccountBalancePersistConfig = {
    key: 'checkAccountBalance',
    storage: storage
}
//#endregion

const globalReducer = combineReducers({
    root: persistReducer(basePersistConfig, rootReducer),
    login: persistReducer(authPersistConfig, loginReducer),
    // CLIENTES
    financiamientos: persistReducer(financiamientosPersistConfig, financiamientosReducer),
    disposiciones: persistReducer(disposicionesPersistConfig, disposicionesReducer),
    amortizaciones: persistReducer(amortizacionesPersistConfig, amortizacionesReducer),
    datosFinanciamiento: persistReducer(datosFinanciamientoPersistConfig, datosFinanciamientoReducer),
    paquetesFactoraje: persistReducer(paquetesFactorajePersistConfig, paquetesFactorajeReducer),
    facturasPaquete: persistReducer(facturasPaquetePersistConfig, facturasPaqueteReducer),
    pagosRealizados: persistReducer(pagosRealizadosPersistConfig, pagosRealizadosReducer),
    resumenCuentaCorriente: persistReducer(resumenCuentaCorrientePersistConfig, resumenCuentaCorrienteReducer),
    activosArrendamiento: persistReducer(activosArrendamientoPersistConfig, activosArrendamientoReducer),
    amortizacionesActivo: persistReducer(amortizacionesActivoPersistConfig, amortizacionesActivoReducer),
    cuentaDeposito: persistReducer(cuentaDepositoPersistConfig, cuentaDepositoReducer),
    postCuentaDeposito: persistReducer(postCuentaDepositoPersistConfig, postCuentaDepositoReducer),
    amortizacionesAPagar: persistReducer(amortizacionesAPagarPersistConfig, amortizacionesAPagarReducer),
    paquetesAPagar: persistReducer(paquetesAPagarPersistConfig, paquetesAPagarReducer),
    opcionesContratoAP: persistReducer(opcionesContratoAPPersistConfig, opcionesContratoAPReducer),
    exportarArchivoAmortizaciones: exportarArchivoAmortizacionesReducer,
    exportarArchivoPagosRealizados: exportarArchivoPagosRealizadosReducer,
    exportarArchivoDisposiciones: exportarArchivoDisposicionesReducer,
    exportarArchivoActivos: exportarArchivoActivosReducer,
    findValidStatus: findValidStatusReducer,
    credentials: credentialsReducer,
    webHookStatus: webHookStatusReducer,
    simularPago: simularPagoReducer,
    generarReferenciaPago: generarReferenciaPagoReducer,
    generarReferenciaPagoFactoraje: generarReferenciaPagoFactorajeReducer,
    generarReferenciaPagoActivos: generarReferenciaPagoActivosReducer,
    resultadoOpcionContratoAP: resultadoOpcionContratoAPReducer,
    //COTIZADOR
    resultadoCotizadorAP: resultadoCotizadorAPReducer,
    amortizacionesCotizadorCS: amortizacionesCotizadorCSReducer,
    recalculoCotizadorCS: recalculoCotizadorCSReducer,
    archivoResultadoCotizadorCS: archivoResultadoCotizadorCSReducer,
    archivoResultadoCotizadorAP: archivoResultadoCotizadorAPReducer,
    // CATALOGOS
    institucionesBancarias: persistReducer(institucionesBancariasPersistConfig, institucionesBancariasReducer),
    tipoCuenta: persistReducer(tipoCuentaPersistConfig, tipoCuentaReducer),
    tipoActivo: persistReducer(tipoActivoPersistConfig, tipoActivoReducer),
    subtipoActivo: persistReducer(subtipoActivoPersistConfig, subtipoActivoReducer),
    tipoArrendamiento: persistReducer(tipoArrendamientoPersistConfig, tipoArrendamientoReducer),
    tipoPagoAP: persistReducer(tipoPagoAPPersistConfig, tipoPagoAPReducer),
    periodicidad: persistReducer(periodicidadPersistConfig, periodicidadReducer),
    tipoPersona: persistReducer(tipoPersonaPersistConfig, tipoPersonaReducer),
    tipoTasa: persistReducer(tipoTasaPersistConfig, tipoTasaReducer),
    tipoTasaMoratoria: persistReducer(tipoTasaMoratoriaPersistConfig, tipoTasaMoratoriaReducer),
    tipoCalculo: persistReducer(tipoCalculoPersistConfig, tipoCalculoReducer),
    valorTiieActual: persistReducer(valorTiieActualPersistConfig, valorTiieActualReducer),
    // STP
    stpCompanies: persistReducer(stpCompaniesPersistConfig, stpCompaniesReducer),
    subAccounts: subAccountsReducer,
    subAccountMovimientos: subAccountMovimientosReducer,
    checkAccountBalance: checkAccountBalanceReducer
});

export type AppState = ReturnType<typeof globalReducer>;
export default globalReducer;