import { DatosActivosArrendamiento } from '../../../../models/IActivosArrendamientoResponse';
import { DatosAmortizacionesActivo } from '../../../../models/IAmortizacionesActivoResponse';
import { DatosGRP } from '../../../../models/ISimularPagoResponse';
import * as types from '../../../../system/types'

//#region ACTIVOS
export interface ActivosArrendamientoState {
    id: string,
    // errors: [],
    inProgress: boolean,
    data: Array<DatosActivosArrendamiento> | null,
    message?: string | null
}

const ActivosArrendamientoInitialState: ActivosArrendamientoState = {
    id: '',
    // errors: [],
    data: null,
    inProgress: false
};

export function activosArrendamientoReducer(
    state: ActivosArrendamientoState = ActivosArrendamientoInitialState,
    action: types.RootAction
): ActivosArrendamientoState {
    switch (action.type) {
        case types.ACTIVOSARRENDAMIENTO_REQUEST:
            return {
                ...state,
                message: '',
                inProgress: true
            }
        case types.ACTIVOSARRENDAMIENTO_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                inProgress: false
            }
        case types.ACTIVOSARRENDAMIENTO_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}
//#endregion

//#region AMORTIZACIONES
export interface AmortizacionesActivoState {
    id: string,
    // errors: [],
    inProgress: boolean,
    data: Array<DatosAmortizacionesActivo> | null,
    message?: string | null
}

const AmortizacionesActivoInitialState: AmortizacionesActivoState = {
    id: '',
    // errors: [],
    data: null,
    inProgress: false
};

export function amortizacionesActivoReducer(
    state: AmortizacionesActivoState = AmortizacionesActivoInitialState,
    action: types.RootAction
): AmortizacionesActivoState {
    switch (action.type) {
        case types.AMORTIZACIONESACTIVO_REQUEST:
            return {
                ...state,
                message: '',
                inProgress: true
            }
        case types.AMORTIZACIONESACTIVO_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                inProgress: false
            }
        case types.AMORTIZACIONESACTIVO_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}
//#endregion

//#region EXPORTAR ARCHIVO
export interface ExportarArchivoActivosState {
    id: string,
    // errors: [],
    data: any | null,
    inProgress: boolean,
    succeeded: boolean,
    message?: string | null
}

const ExportarArchivoActivosInitialState: ExportarArchivoActivosState = {
    id: '',
    // errors: [],
    data: null,
    succeeded: false,
    inProgress: false
};

export function exportarArchivoActivosReducer(
    state: ExportarArchivoActivosState = ExportarArchivoActivosInitialState,
    action: types.RootAction
): ExportarArchivoActivosState {
    switch (action.type) {
        case types.EXPORTARARCHIVOACTIVOS_REQUEST:
            return {
                ...state,
                message: '',
                succeeded: false,
                inProgress: true
            }
        case types.EXPORTARARCHIVOACTIVOS_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                succeeded: action.succeeded,
                inProgress: false
            }
        case types.EXPORTARARCHIVOACTIVOS_FAILED:
            return {
                ...state,
                message: action.message,
                succeeded: false,
                inProgress: false
            }
        default:
            return state;
    }
}
//#endregion

//#region GENERAR REFERENCIA DE PAGO ACTIVOS
export interface GenerarReferenciaPagoActivosState {
    id: string,
    // errors: [],
    inProgress: boolean,
    data: DatosGRP | null,
    message?: string | null
}

const GenerarReferenciaPagoActivosInitialState: GenerarReferenciaPagoActivosState = {
    id: '',
    // errors: [],
    data: null,
    inProgress: false
};

export function generarReferenciaPagoActivosReducer(
    state: GenerarReferenciaPagoActivosState = GenerarReferenciaPagoActivosInitialState,
    action: types.RootAction
): GenerarReferenciaPagoActivosState {
    switch (action.type) {
        case types.GENERARREFERENCIAPAGOACTIVOS_REQUEST:
            return {
                ...state,
                message: '',
                inProgress: true
            }
        case types.GENERARREFERENCIAPAGOACTIVOS_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                inProgress: false
            }
        case types.GENERARREFERENCIAPAGOACTIVOS_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}
//#endregion