import { take, put, call, fork, select, takeEvery, all } from 'redux-saga/effects';
import * as sagaController from '../../../controllers/sagaController';
import * as types from '../../../system/types';
import * as R from 'ramda';
import * as actions from './actions';
import * as rootActions from '../../../system/actions';
import { CheckAccountBalance, StpCompanies, SubAccountMovimientos, SubAccounts } from '../../../api/Clients';
import { IResponse } from '../../../models/IResponse';
import { AxiosResponse } from 'axios';
import { ICheckAccountBalanceResponse, IStpCompaniesResponse, ISubAccountMovimientosResponse, ISubAccountsResponse } from '../../../models/IStpCompaniesResponse';

//#region STP COMPANIES
export function* stpCompaniesRequestAsync(action: types.requestStpCompaniesAction) {
    const response: IStpCompaniesResponse = yield call(StpCompanies, action.balanceResponseObj);
    // console.log(response);
    try {
        const {
            balanceResponseObj,
            code,
            status,
            statusText,
            // headers,
            // config,
            // request,
        } = response;
        if (!R.isNil(balanceResponseObj)) {
            yield sagaController.controlledStates(response, action.type, status);
        } else {
            yield sagaController.controlledStates(response, action.type, 400);
        }
    } catch (error) {
        console.log(error);
    }
}

export function* stpCompaniesSaga() {
    yield all([
        takeEvery(types.STPCOMPANIES_REQUEST, stpCompaniesRequestAsync)
    ])
}
//#endregion

//#region SUBACCOUNTS
export function* subAccountsRequestAsync(action: types.requestSubAccountsAction) {
    const response: ISubAccountsResponse = yield call(SubAccounts, action.dropdownDataObj);
    // console.log(response);
    try {
        const {
            dropdownDataObj,
            code,
            status,
            statusText,
            // headers,
            // config,
            // request,
        } = response;
        if (!R.isNil(dropdownDataObj)) {
            yield sagaController.controlledStates(response, action.type, status);
        } else {
            yield sagaController.controlledStates(response, action.type, 400);
        }
    } catch (error) {
        console.log(error);
    }
}

export function* subAccountsSaga() {
    yield all([
        takeEvery(types.SUBACCOUNTS_REQUEST, subAccountsRequestAsync)
    ])
}
//#endregion

//#region SUBACCOUNT MOVIMIENTOS
export function* subAccountMovimientosRequestAsync(action: types.requestSubAccountMovimientosAction) {
    const response: ISubAccountMovimientosResponse = yield call(SubAccountMovimientos, action.transaccionResponseObj);
    // console.log(response);
    try {
        const {
            transaccionResponseObj,
            code,
            status,
            statusText,
            // headers,
            // config,
            // request,
        } = response;
        if (!R.isNil(transaccionResponseObj)) {
            yield sagaController.controlledStates(response, action.type, status);
        } else {
            yield sagaController.controlledStates(response, action.type, 400);
        }
    } catch (error) {
        console.log(error);
    }
}

export function* subAccountMovimientosSaga() {
    yield all([
        takeEvery(types.SUBACCOUNTMOVIMIENTOS_REQUEST, subAccountMovimientosRequestAsync)
    ])
}
//#endregion

//#region CHECK ACCOUNT BALANCE
export function* checkAccountBalanceRequestAsync(action: types.requestCheckAccountBalanceAction) {
    const response: ICheckAccountBalanceResponse = yield call(CheckAccountBalance, action.data);
    // console.log(response);
    try {
        const {
            code,
            status,
            statusText,
            // headers,
            // config,
            // request,
        } = response;
        if (!R.isNil(response)) {
            yield sagaController.controlledStates(response, action.type, status);
        } else {
            yield sagaController.controlledStates(response, action.type, 400);
        }
    } catch (error) {
        console.log(error);
    }
}

export function* checkAccountBalanceSaga() {
    yield all([
        takeEvery(types.CHECKACCOUNTBALANCE_REQUEST, checkAccountBalanceRequestAsync)
    ])
}
//#endregion