import { IFilters } from './reducers';
import * as type from './types';

export function showLoader() {
    return {
        type: type.ENABLE_LOADER,
        totalLoaded: 1
    }
};

export function hideLoader() {
    return {
        type: type.DISABLE_LOADER,
        totalLoaded: -1
    }
};

export function setFilters(filters: IFilters) {
    return {
        type: type.SET_FILTERS,
        filters
    }
};

export function setFiltersFinanciamiento(idFinanciamientoSolicitado: string, idForoCondiciones: string) {
    return {
        type: type.SET_FILTERS_FINANCIAMIENTO,
        idFinanciamientoSolicitado,
        idForoCondiciones
    }
};

export function resetFilters() {
    return {
        type: type.RESET_FILTERS
    }
};