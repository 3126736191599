import { DatosTipoPersona } from '../../../../models/catalogs/ITipoPersonaResponse';
import * as type from '../../../../system/types';
import * as actions from '../../../../system/types';

export function requestTipoPersona(data: any): actions.requestTipoPersonaAction {
    return {
        type: type.TIPOPERSONA_REQUEST,
        inProgress: true,
        data
    };
}

export function onTipoPersonaResponse(
    data: Array<DatosTipoPersona>,
    message: string
): actions.onTipoPersonaResponseAction {
    return {
        type: type.TIPOPERSONA_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onTipoPersonaFailed(
    data: any,
    message: string
): actions.getTipoPersonaFailedAction {
    return {
        type: type.TIPOPERSONA_FAILED,
        message,
        inProgress: false
    };
}