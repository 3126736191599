import { take, put, call, fork, select, takeEvery, all } from 'redux-saga/effects';
import * as sagaController from '../../../../controllers/sagaController';
import * as types from '../../../../system/types';
import * as R from 'ramda';
import * as actions from './actions';
import * as rootActions from '../../../../system/actions';
import { TipoActivo } from '../../../../api/Clients';
import { IResponse } from '../../../../models/IResponse';
import { AxiosResponse } from 'axios';
import { ITipoActivoResponse } from '../../../../models/catalogs/ITipoActivoResponse';

export function* tipoActivoRequestAsync(action: types.requestTipoActivoAction) {
    const response: ITipoActivoResponse = yield call(TipoActivo, action.data);
    // console.log(response);
    try {
        const {
            data,
            code,
            status,
            statusText,
            // headers,
            // config,
            // request,
        } = response;
        if (!R.isNil(data)) {
            yield sagaController.controlledStates(response, action.type, status);
        } else {
            yield sagaController.controlledStates(response, action.type, 400);
        }
    } catch (error) {
        console.log(error);
    }
}

export default function* tipoActivoSaga() {
    yield all([
        takeEvery(types.TIPOACTIVO_REQUEST, tipoActivoRequestAsync)
    ])
}