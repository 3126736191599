import { all, fork } from 'redux-saga/effects';
import financiamientosSaga from '../screens/Financiamientos/redux/saga';
import { disposicionesSaga, exportarArchivoDisposicionesSaga } from '../components/common/Disposiciones/redux/saga';
import { amortizacionesSaga, exportarArchivoAmortizacionesSaga } from '../components/common/Amortizaciones/redux/saga';
import datosFinanciamientoSaga from '../components/common/DatosFinanciamiento/redux/saga';
import { facturasPaqueteSaga, paquetesFactorajeSaga } from '../components/common/PaquetesFactoraje/redux/saga';
import loginSaga from '../components/common/Login/redux/saga';
import { exportarArchivoPagosRealizadosSaga, pagosRealizadosSaga } from '../components/common/PagosRealizados/redux/saga';
import resumenCuentaCorrienteSaga from '../components/common/ResumenCuentaCorriente/redux/saga';
import { activosArrendamientoSaga, amortizacionesActivoSaga, exportarArchivoActivosSaga, generarReferenciaPagoActivosSaga } from '../components/common/ActivosArrendamiento/redux/saga';
import { cuentaDepositoSaga, postCuentaDepositoSaga } from '../screens/CuentaDeposito/redux/saga';
import institucionesBancariasSaga from '../components/catalogs/InstitucionesBancarias/redux/saga';
import tipoCuentaSaga from '../components/catalogs/TipoCuenta/redux/saga';
import { credentialsSaga, findValidStatusSaga, webHookStatusSaga } from '../screens/Credenciales/redux/saga';
import { amortizacionesAPagarSaga, generarReferenciaPagoSaga, opcionesContratoAPSaga, resultadoOpcionContratoAPSaga, simularPagoSaga } from '../components/common/GenerarReferenciaPago/redux/saga';
import { stpCompaniesSaga, checkAccountBalanceSaga, subAccountsSaga, subAccountMovimientosSaga } from '../screens/Subcuentas/redux/saga';
import { generarReferenciaPagoFactorajeSaga, paquetesAPagarSaga } from '../components/common/GenerarReferenciaFactoraje/redux/saga';
import tipoActivoSaga from '../components/catalogs/TipoActivo/redux/saga';
import subtipoActivoSaga from '../components/catalogs/SubtipoActivo/redux/saga';
import tipoArrendamientoSaga from '../components/catalogs/TipoArrendamiento/redux/saga';
import tipoPagoAPSaga from '../components/catalogs/TipoPagoAP/redux/saga';
import periodicidadSaga from '../components/catalogs/Periodicidad/redux/saga';
import { resultadoCotizadorAPSaga, archivoResultadoCotizadorAPSaga } from '../components/common/CotizadorAP/redux/saga';
import tipoPersonaSaga from '../components/catalogs/TipoPersona/redux/saga';
import tipoTasaSaga from '../components/catalogs/TipoTasa/redux/saga';
import tipoTasaMoratoriaSaga from '../components/catalogs/TipoTasaMoratoria/redux/saga';
import tipoCalculoSaga from '../components/catalogs/TipoCalculo/redux/saga';
import valorTiieActualSaga from '../components/catalogs/ValorTiieActual/redux/saga';
import { amortizacionesCotizadorCSSaga, archivoResultadoCotizadorCSSaga, recalculoCotizadorCSSaga } from '../components/common/CotizadorCS/redux/saga';

export default function* rootSagas() {
    // CLIENTES
    yield all([fork(financiamientosSaga)])
    yield all([fork(disposicionesSaga)])
    yield all([fork(amortizacionesSaga)])
    yield all([fork(exportarArchivoAmortizacionesSaga)])
    yield all([fork(exportarArchivoPagosRealizadosSaga)])
    yield all([fork(exportarArchivoDisposicionesSaga)])
    yield all([fork(exportarArchivoActivosSaga)])
    yield all([fork(datosFinanciamientoSaga)])
    yield all([fork(paquetesFactorajeSaga)])
    yield all([fork(facturasPaqueteSaga)])
    yield all([fork(loginSaga)])
    yield all([fork(pagosRealizadosSaga)])
    yield all([fork(resumenCuentaCorrienteSaga)])
    yield all([fork(activosArrendamientoSaga)])
    yield all([fork(amortizacionesActivoSaga)])
    yield all([fork(cuentaDepositoSaga)])
    yield all([fork(postCuentaDepositoSaga)])
    yield all([fork(findValidStatusSaga)])
    yield all([fork(credentialsSaga)])
    yield all([fork(webHookStatusSaga)])
    yield all([fork(amortizacionesAPagarSaga)])
    yield all([fork(simularPagoSaga)])
    yield all([fork(generarReferenciaPagoSaga)])
    yield all([fork(generarReferenciaPagoFactorajeSaga)])
    yield all([fork(generarReferenciaPagoActivosSaga)])
    yield all([fork(paquetesAPagarSaga)])
    yield all([fork(opcionesContratoAPSaga)])
    yield all([fork(resultadoOpcionContratoAPSaga)])
    // COTIZADOR
    yield all([fork(resultadoCotizadorAPSaga)])
    yield all([fork(amortizacionesCotizadorCSSaga)])
    yield all([fork(recalculoCotizadorCSSaga)])
    yield all([fork(archivoResultadoCotizadorCSSaga)])
    yield all([fork(archivoResultadoCotizadorAPSaga)])
    // CATALOGOS
    yield all([fork(institucionesBancariasSaga)])
    yield all([fork(tipoCuentaSaga)])
    yield all([fork(tipoActivoSaga)])
    yield all([fork(subtipoActivoSaga)])
    yield all([fork(tipoArrendamientoSaga)])
    yield all([fork(tipoPagoAPSaga)])
    yield all([fork(periodicidadSaga)])
    yield all([fork(tipoPersonaSaga)])
    yield all([fork(tipoTasaSaga)])
    yield all([fork(tipoTasaMoratoriaSaga)])
    yield all([fork(tipoCalculoSaga)])
    yield all([fork(valorTiieActualSaga)])
    // STP
    yield all([fork(stpCompaniesSaga)])
    yield all([fork(subAccountsSaga)])
    yield all([fork(subAccountMovimientosSaga)])
    yield all([fork(checkAccountBalanceSaga)])
}