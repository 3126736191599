import { DatosTipoArrendamiento } from '../../../../models/catalogs/ITipoArrendamientoResponse';
import * as type from '../../../../system/types';
import * as actions from '../../../../system/types';

export function requestTipoArrendamiento(data: any): actions.requestTipoArrendamientoAction {
    return {
        type: type.TIPOARRENDAMIENTO_REQUEST,
        inProgress: true,
        data
    };
}

export function onTipoArrendamientoResponse(
    data: Array<DatosTipoArrendamiento>,
    message: string
): actions.onTipoArrendamientoResponseAction {
    return {
        type: type.TIPOARRENDAMIENTO_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onTipoArrendamientoFailed(
    data: any,
    message: string
): actions.getTipoArrendamientoFailedAction {
    return {
        type: type.TIPOARRENDAMIENTO_FAILED,
        message,
        inProgress: false
    };
}