import { take, put, call, fork, select, takeEvery, all } from 'redux-saga/effects';
import * as sagaController from '../../../../controllers/sagaController';
import * as types from '../../../../system/types';
import * as R from 'ramda';
import * as actions from './actions';
import * as rootActions from '../../../../system/actions';
import { ValorTiieActual } from '../../../../api/Clients';
import { IResponse } from '../../../../models/IResponse';
import { AxiosResponse } from 'axios';
import { IValorTiieActualResponse } from '../../../../models/catalogs/IValorTiieActualResponse';

export function* valorTiieActualRequestAsync(action: types.requestValorTiieActualAction) {
    const response: IValorTiieActualResponse = yield call(ValorTiieActual, action.data);
    // console.log(response);
    try {
        const {
            data,
            code,
            status,
            statusText,
            // headers,
            // config,
            // request,
        } = response;
        if (!R.isNil(data)) {
            yield sagaController.controlledStates(response, action.type, status);
        } else {
            yield sagaController.controlledStates(response, action.type, 400);
        }
    } catch (error) {
        console.log(error);
    }
}

export default function* valorTiieActualSaga() {
    yield all([
        takeEvery(types.VALORTIIEACTUAL_REQUEST, valorTiieActualRequestAsync)
    ])
}