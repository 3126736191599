import { take, put, call, fork, select, takeEvery, all } from 'redux-saga/effects';
import * as sagaController from '../../../../controllers/sagaController';
import * as types from '../../../../system/types';
import * as R from 'ramda';
import * as actions from './actions';
import * as rootActions from '../../../../system/actions';
import { ExportarArchivoPagosRealizados, PagosRealizados } from '../../../../api/Clients';
import { IResponse } from '../../../../models/IResponse';
import { AxiosResponse } from 'axios';
import { IPagosRealizadosResponse } from '../../../../models/IPagosRealizadosResponse';
import { IExportarArchivoResponse } from '../../../../models/IExportarArchivoResponse';

//#region PAGOS REALIZADOS
export function* pagosRealizadosRequestAsync(action: types.requestPagosRealizadosAction) {
    const response: IPagosRealizadosResponse = yield call(PagosRealizados, action.data);
    // console.log(response);
    try {
        const {
            data,
            code,
            status,
            statusText,
            // headers,
            // config,
            // request,
        } = response;
        if (!R.isNil(data)) {
            yield sagaController.controlledStates(response, action.type, status);
        } else {
            yield sagaController.controlledStates(response, action.type, 400);
        }
    } catch (error) {
        console.log(error);
    }
}

export function* pagosRealizadosSaga() {
    yield all([
        takeEvery(types.PAGOSREALIZADOS_REQUEST, pagosRealizadosRequestAsync)
    ])
}
//#endregion

//#region EXPORTAR ARCHIVO
export function* exportarArchivoPagosRealizadosRequestAsync(action: types.requestExportarArchivoPagosRealizadosAction) {
    const response: IExportarArchivoResponse = yield call(ExportarArchivoPagosRealizados, action.data);
    // console.log(response);
    try {
        const {
            data,
            code,
            status,
            statusText,
            // headers,
            // config,
            // request,
        } = response;
        if (!R.isNil(data)) {
            yield sagaController.controlledStates(response, action.type, status);
        } else {
            yield sagaController.controlledStates(response, action.type, 400);
        }
    } catch (error) {
        console.log(error);
    }
}

export function* exportarArchivoPagosRealizadosSaga() {
    yield all([
        takeEvery(types.EXPORTARARCHIVOPAGOSREALIZADOS_REQUEST, exportarArchivoPagosRealizadosRequestAsync)
    ])
}
//#endregion