import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { styled, ThemeProvider } from '@mui/material/styles';
import theme from '../../theme';
import Header from '../../components/common/AppBar';
import Paper from '@mui/material/Paper';
import { ButtonBase, Stack } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { Link } from 'react-router-dom';
import { PATH_SECURE } from '../../system/selector';
import { useSelector } from 'react-redux';

export default function NotFound() {
    const Img = styled('img')({
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    });
    
    const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const idCliente = useSelector((state: any) => state.login.id);

    return (
        <ThemeProvider theme={theme}>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
                component="main" >
                <Paper
                    sx={{
                        p: 2,
                        margin: 2,
                        maxWidth: 350,
                        flexGrow: 1,
                    }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm container>
                            <Grid item xs container direction="column" spacing={2}>
                                <Grid item xs>
                                    <Typography gutterBottom component="h1" variant="h5" color="#C96B12" sx={{ mt: 3, mb: 5 }}>
                                        La url solicitada no existe.
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        ID: {idCliente}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={2}
                                container
                                direction="column"
                                alignItems="center"
                                justifyContent="space-around"
                                style={{ padding: 3 }}>
                                <Link to='/redirect' style={{ padding: 5 }}>
                                    <HomeIcon />
                                </Link>
                                <Link to={PATH_SECURE} style={{ padding: 5 }}>
                                    <MenuOpenIcon />
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </ThemeProvider>
    );
}