import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, CircularProgress, Dialog, DialogContent, DialogContentText, Typography, Tooltip, DialogActions } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useState } from 'react';
import * as pagosRealizadosActions from './redux/actions';
import { IPagosRealizadosRequest } from '../../../models/IPagosRealizadosRequest';
import * as R from 'ramda';
import { DatosPagosRealizados } from '../../../models/IPagosRealizadosResponse';
import { EstatusTransaccion, getNumberToCurrency, TipoFinanciamiento } from '../../../utils';
import theme from '../../../theme';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { IExportarArchivoPagosRealizadosRequest } from '../../../models/IExportarArchivoRequest';
import { IExportarArchivoResponse } from '../../../models/IExportarArchivoResponse';
import { TableLoader } from '../../loader/TableLoader';
import { TableNotFoundData } from '../../notFoundData/TableNotFoundData';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import moment from 'moment';
import 'moment/locale/es';
import { StyledTableCell, StyledTableRow } from '../../styledTables/StyledTables';
moment.locale('es');

export default function PagosRealizados(dataSend: IPagosRealizadosRequest) {
    let totalesPagosRealizados: any = [];
    let numRegistros: Array<any> = [];
    let datos = <TableLoader colums={11} text={'Buscando pagos realizados...'} />;
    let exportarArchivoSend: IExportarArchivoPagosRealizadosRequest = {
        TipoExportacion: '',
        IdForoCondiciones: '',
        IdTipoFinanciamiento: ''
    }

    const [openFacturas, setOpenFacturas] = useState(false);
    const [datosFP, setDatosFP] = useState<any>(<></>);

    const dispatch = useDispatch();
    const pagosRealizadosRequest = useCallback(() => dispatch(pagosRealizadosActions.requestPagosRealizados(dataSend)), [dispatch, dataSend]);
    const archivoPagosRealizadosRequest = useCallback(() => dispatch(pagosRealizadosActions.requestExportarArchivoPagosRealizados(exportarArchivoSend)), [dispatch, exportarArchivoSend]);

    React.useEffect(() => {
        pagosRealizadosRequest();
    }, []);

    const showFacturasPagadas: any = (parcialidades: string) => {
        let facturas = parcialidades.split(',');
        setDatosFP(facturas.map((factura: string) =>
            <StyledTableRow>
                <StyledTableCell align='center'>
                    {factura}
                </StyledTableCell>
            </StyledTableRow>
        ));
        setOpenFacturas(true);
    };

    function PopUpFacturas() {
        return (
            <Dialog
                key={'facturasPaquete'}
                open={openFacturas}
                fullWidth={true}
                maxWidth={'xs'}>
                <DialogContent sx={{ m: 2, textAlign: 'center' }} id="dialogContent">
                    <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                            <TableBody>
                                {datosFP}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions sx={{ mb: 2, mr: 4 }} id="dialogActions">
                    <Button onClick={() => setOpenFacturas(false)}>Cerrar</Button>
                </DialogActions>
            </Dialog>
        );
    }

    const datosPagosRealizados: any = useSelector((state: any) => state.pagosRealizados.data);
    const inProgress = useSelector((state: any) => state.pagosRealizados.inProgress);
    const datosArchivo: IExportarArchivoResponse = useSelector((state: any) => state.exportarArchivoPagosRealizados);
    const inProgressArchivo: any = useSelector((state: any) => state.exportarArchivoPagosRealizados.inProgress);

    if (!R.isNil(datosPagosRealizados) && !inProgress) {
        numRegistros = datosPagosRealizados.filter((e: DatosPagosRealizados) => e.idCAT_EstatusTransaccion == EstatusTransaccion['Liberado']);
        totalesPagosRealizados = datosPagosRealizados.filter((e: DatosPagosRealizados) => e.idCAT_EstatusTransaccion == EstatusTransaccion['Liberado']);
        datos = datosPagosRealizados.filter((e: DatosPagosRealizados) => e.idCAT_EstatusTransaccion == EstatusTransaccion['Liberado']).map((pago: DatosPagosRealizados) =>
            <StyledTableRow
                key={pago.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <StyledTableCell>{pago.numeroReferencia}</StyledTableCell>
                <StyledTableCell>{pago.fechaGeneracion != null ? moment(pago.fechaGeneracion).format('dddd LL') : ""}</StyledTableCell>
                {dataSend.IdTipoFinanciamiento != TipoFinanciamiento['ArrendamientoPuro'] &&
                    <><StyledTableCell>{getNumberToCurrency(pago.montoDeposito)}</StyledTableCell>
                        <StyledTableCell>{getNumberToCurrency(pago.interes)}</StyledTableCell></>
                }
                {dataSend.IdTipoFinanciamiento == TipoFinanciamiento['ArrendamientoPuro'] &&
                    <StyledTableCell>{getNumberToCurrency((pago.montoDeposito ?? 0) + (pago.interes ?? 0))}</StyledTableCell>
                }
                <StyledTableCell>{getNumberToCurrency(pago.interesMoratorio)}</StyledTableCell>
                <StyledTableCell>{getNumberToCurrency(pago.iva)}</StyledTableCell>
                <StyledTableCell>{getNumberToCurrency(pago.montoTotalApagar)}</StyledTableCell>
                <StyledTableCell>{pago.fechaPago != null ? moment(pago.fechaPago).format('dddd LL') : ""}</StyledTableCell>
                <StyledTableCell>{getNumberToCurrency(pago.montoPagado)}</StyledTableCell>
                {dataSend.IdTipoFinanciamiento != TipoFinanciamiento['FactorajeFinanciero'] &&
                    <StyledTableCell>{pago.parcialidades}</StyledTableCell>
                }
                {dataSend.IdTipoFinanciamiento == TipoFinanciamiento['FactorajeFinanciero'] &&
                    <StyledTableCell>
                        <Button color='info'
                            onClick={() => showFacturasPagadas(pago.parcialidades)}
                            startIcon={<VisibilityOutlinedIcon />}>Facturas</Button>
                    </StyledTableCell>
                }
                <StyledTableCell>{pago.estatusTransaccion}</StyledTableCell>
            </StyledTableRow>)
    }
    if (inProgress) {
        totalesPagosRealizados = [];
        numRegistros = [];
        datos = <TableLoader colums={11} text={'Buscando pagos realizados...'} />;
    }
    if (datosPagosRealizados?.length == 0) {
        datos = <TableNotFoundData colums={11} text={'No se encontraron pagos realizados.'} />;
    }

    let mensajeGeneracionArchivo = <Typography variant='body1'>Generando...</Typography>
    const [openGeneracionArchivo, setOpenGeneracionArchivo] = React.useState(false);

    const exportarArchivoPagosRealizados = (tipoExportacion: string) => {
        exportarArchivoSend = {
            TipoExportacion: tipoExportacion,
            IdForoCondiciones: dataSend.IdForoCondiciones,
            IdTipoFinanciamiento: dataSend.IdTipoFinanciamiento
        }
        setOpenGeneracionArchivo(true)
        archivoPagosRealizadosRequest();
    }

    if (!R.isNil(datosArchivo) && !inProgressArchivo) {
        mensajeGeneracionArchivo =
            <Typography sx={{ color: datosArchivo.succeeded ? 'success.main' : 'error.main' }}>{datosArchivo.message}</Typography>
        setTimeout(() => {
            setOpenGeneracionArchivo(false);
        }, 3000);
    }

    function TablaPagosRealizados() {
        return (
            <><Box sx={{ mb: 2, float: 'right' }}>
                <Tooltip arrow title={numRegistros.length > 0 ? '' : 'No se podrá exportar la tabla si no hay pagos realizados.'}>
                    <Button sx={{ ml: 2, color: numRegistros.length > 0 ? '#16a0ff' : 'error.main' }}
                        disabled={inProgress}
                        onClick={() => exportarArchivoPagosRealizados('XLS')}>
                        <FileDownloadOutlinedIcon sx={{ mr: 1 }} />Exportar excel</Button>
                </Tooltip>
                <Tooltip arrow title={numRegistros.length > 0 ? '' : 'No se podrá exportar la tabla si no hay pagos realizados.'}>
                    <Button sx={{ ml: 2, color: numRegistros.length > 0 ? '#16a0ff' : 'error.main' }}
                        disabled={inProgress}
                        onClick={() => exportarArchivoPagosRealizados('PDF')}>
                        <FileDownloadOutlinedIcon sx={{ mr: 1 }} />Exportar PDF</Button>
                </Tooltip>
            </Box>
                <Dialog
                    key={'archivoExportado'}
                    open={openGeneracionArchivo}
                    fullWidth={true}
                    maxWidth={'xs'}>
                    <DialogContent sx={{ m: 2, textAlign: 'center' }} id="dialogContent">
                        <DialogContentText>
                            {mensajeGeneracionArchivo}
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
                    <Table sx={{ minWidth: 800 }} aria-label="customized table">
                        <TableBody>
                            <TableRow>
                                <TableCell><b>Número de referencia</b></TableCell>
                                <TableCell><b>Fecha de generación</b></TableCell>
                                {dataSend.IdTipoFinanciamiento != TipoFinanciamiento['ArrendamientoPuro'] &&
                                    <><TableCell><b>Capital</b></TableCell>
                                        <TableCell><b>Intereses</b></TableCell></>
                                }
                                {dataSend.IdTipoFinanciamiento == TipoFinanciamiento['ArrendamientoPuro'] &&
                                    <TableCell><b>Renta</b></TableCell>
                                }
                                <TableCell><b>Interés moratorio</b></TableCell>
                                <TableCell><b>IVA</b></TableCell>
                                <TableCell><b>Monto a pagar</b></TableCell>
                                <TableCell><b>Fecha de pago</b></TableCell>
                                <TableCell><b>Monto pagado</b></TableCell>
                                <TableCell><b>Parcialidad / Factura</b></TableCell>
                                <TableCell><b>Estatus</b></TableCell>
                            </TableRow>
                            <TableRow sx={{ backgroundColor: '#bae3ff' }}>
                                <TableCell><b>Totales:</b></TableCell>
                                <TableCell></TableCell>
                                {dataSend.IdTipoFinanciamiento != TipoFinanciamiento['ArrendamientoPuro'] &&
                                    <><TableCell><b>{getNumberToCurrency(totalesPagosRealizados.reduce((a: number, b: DatosPagosRealizados) => a + b.montoDeposito, 0))}</b></TableCell>
                                        <TableCell><b>{getNumberToCurrency(totalesPagosRealizados.reduce((a: number, b: DatosPagosRealizados) => a + b.interes, 0))}</b></TableCell></>
                                }
                                {dataSend.IdTipoFinanciamiento == TipoFinanciamiento['ArrendamientoPuro'] &&
                                    <TableCell><b>{getNumberToCurrency(totalesPagosRealizados.reduce((a: number, b: DatosPagosRealizados) => a + ((b.montoDeposito) + (b.interes)), 0))}</b></TableCell>
                                }
                                <TableCell><b>{getNumberToCurrency(totalesPagosRealizados.reduce((a: number, b: DatosPagosRealizados) => a + b.interesMoratorio, 0))}</b></TableCell>
                                <TableCell><b>{getNumberToCurrency(totalesPagosRealizados.reduce((a: number, b: DatosPagosRealizados) => a + b.iva, 0))}</b></TableCell>
                                <TableCell><b>{getNumberToCurrency(totalesPagosRealizados.reduce((a: number, b: DatosPagosRealizados) => a + b.montoTotalApagar, 0))}</b></TableCell>
                                <TableCell></TableCell>
                                <TableCell><b>{getNumberToCurrency(totalesPagosRealizados.reduce((a: number, b: DatosPagosRealizados) => a + b.montoPagado, 0))}</b></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            {datos}
                        </TableBody>
                    </Table>
                </TableContainer></>
        );
    }

    return (
        <><Typography variant="h6" color="#2E2D2E" sx={{ mt: 2 }}>Pagos realizados</Typography>
            <TablaPagosRealizados />
            <PopUpFacturas /></>
    );
}