import { DatosAmortizacionesAPagar } from '../../../../models/IAmortizacionesAPagarResponse';
import { DatosOpcionesContratoAP, DatosResultadoOpcionContratoAP } from '../../../../models/IOpcionesContratoAPResponse';
import { DatosGRP, DatosSimularPago } from '../../../../models/ISimularPagoResponse';
import * as types from '../../../../system/types'

//#region AMORTIZACIONES A PAGAR
export interface AmortizacionesAPagarState {
    id: string,
    // errors: [],
    inProgress: boolean,
    data: Array<DatosAmortizacionesAPagar> | null,
    message?: string | null
}

const AmortizacionesAPagarInitialState: AmortizacionesAPagarState = {
    id: '',
    // errors: [],
    data: null,
    inProgress: false
};

export function amortizacionesAPagarReducer(
    state: AmortizacionesAPagarState = AmortizacionesAPagarInitialState,
    action: types.RootAction
): AmortizacionesAPagarState {
    switch (action.type) {
        case types.AMORTIZACIONESAPAGAR_REQUEST:
            return {
                ...state,
                message: '',
                inProgress: true
            }
        case types.AMORTIZACIONESAPAGAR_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                inProgress: false
            }
        case types.AMORTIZACIONESAPAGAR_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}
//#endregion

//#region OPCIONES CONTRATO AP
export interface OpcionesContratoAPState {
    id: string,
    // errors: [],
    inProgress: boolean,
    data: Array<DatosOpcionesContratoAP> | null,
    message?: string | null
}

const OpcionesContratoAPInitialState: OpcionesContratoAPState = {
    id: '',
    // errors: [],
    data: null,
    inProgress: false
};

export function opcionesContratoAPReducer(
    state: OpcionesContratoAPState = OpcionesContratoAPInitialState,
    action: types.RootAction
): OpcionesContratoAPState {
    switch (action.type) {
        case types.OPCIONESCONTRATOAP_REQUEST:
            return {
                ...state,
                message: '',
                inProgress: true
            }
        case types.OPCIONESCONTRATOAP_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                inProgress: false
            }
        case types.OPCIONESCONTRATOAP_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}
//#endregion

//#region RESULTADO OPCION CONTRATO AP
export interface ResultadoOpcionContratoAPState {
    id: string,
    // errors: [],
    inProgress: boolean,
    data: Array<DatosResultadoOpcionContratoAP> | null,
    message?: string | null
}

const ResultadoOpcionContratoAPInitialState: ResultadoOpcionContratoAPState = {
    id: '',
    // errors: [],
    data: null,
    inProgress: false
};

export function resultadoOpcionContratoAPReducer(
    state: ResultadoOpcionContratoAPState = ResultadoOpcionContratoAPInitialState,
    action: types.RootAction
): ResultadoOpcionContratoAPState {
    switch (action.type) {
        case types.RESULTADOOPCIONCONTRATOAP_REQUEST:
            return {
                ...state,
                message: '',
                inProgress: true
            }
        case types.RESULTADOOPCIONCONTRATOAP_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                inProgress: false
            }
        case types.RESULTADOOPCIONCONTRATOAP_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}
//#endregion

//#region SIMULAR PAGO
export interface SimularPagoState {
    id: string,
    // errors: [],
    inProgress: boolean,
    data: DatosSimularPago | null,
    message?: string | null
}

const SimularPagoInitialState: SimularPagoState = {
    id: '',
    // errors: [],
    data: null,
    inProgress: false
};

export function simularPagoReducer(
    state: SimularPagoState = SimularPagoInitialState,
    action: types.RootAction
): SimularPagoState {
    switch (action.type) {
        case types.SIMULARPAGO_REQUEST:
            return {
                ...state,
                message: '',
                inProgress: true
            }
        case types.SIMULARPAGO_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                inProgress: false
            }
        case types.SIMULARPAGO_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}
//#endregion

//#region GENERAR REFERENCIA DE PAGO
export interface GenerarReferenciaPagoState {
    id: string,
    // errors: [],
    inProgress: boolean,
    data: DatosGRP | null,
    message?: string | null
}

const GenerarReferenciaPagoInitialState: GenerarReferenciaPagoState = {
    id: '',
    // errors: [],
    data: null,
    inProgress: false
};

export function generarReferenciaPagoReducer(
    state: GenerarReferenciaPagoState = GenerarReferenciaPagoInitialState,
    action: types.RootAction
): GenerarReferenciaPagoState {
    switch (action.type) {
        case types.GENERARREFERENCIAPAGO_REQUEST:
            return {
                ...state,
                message: '',
                inProgress: true
            }
        case types.GENERARREFERENCIAPAGO_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                inProgress: false
            }
        case types.GENERARREFERENCIAPAGO_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}
//#endregion