import { DatosSubtipoActivo } from '../../../../models/catalogs/ISubtipoActivoResponse';
import * as type from '../../../../system/types';
import * as actions from '../../../../system/types';

export function requestSubtipoActivo(data: any): actions.requestSubtipoActivoAction {
    return {
        type: type.SUBTIPOACTIVO_REQUEST,
        inProgress: true,
        data
    };
}

export function onSubtipoActivoResponse(
    data: Array<DatosSubtipoActivo>,
    message: string
): actions.onSubtipoActivoResponseAction {
    return {
        type: type.SUBTIPOACTIVO_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onSubtipoActivoFailed(
    data: any,
    message: string
): actions.getSubtipoActivoFailedAction {
    return {
        type: type.SUBTIPOACTIVO_FAILED,
        message,
        inProgress: false
    };
}