import * as React from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import * as datosFinanciamientoActions from './redux/actions';
import * as R from 'ramda';
import { IDatosFinanciamientoRequest } from '../../../models/IDatosFinanciamientoRequest';
import { getIconFinanciamiento, getNumberToCurrency } from '../../../utils';
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

export default function DatosFinanciamiento(dataSend: IDatosFinanciamientoRequest) {
    const dispatch = useDispatch();
    const datosFinanciamientoRequest = useCallback(() => dispatch(datosFinanciamientoActions.requestDatosFinanciamiento(dataSend)), [dispatch, dataSend]);
    React.useEffect(() => {
        datosFinanciamientoRequest();
    }, []);

    const datosFinanciamiento: any = useSelector((state: any) => state.datosFinanciamiento.data);

    let datos = <Table></Table>
    if (!R.isNil(datosFinanciamiento)) {
        datos = (
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell style={{ width: '25%' }} align='left'>
                            <Typography variant="h5" color="#db5d18" sx={{ mb: 4 }}>{getIconFinanciamiento(datosFinanciamiento.idTipoFinanciamiento)}{datosFinanciamiento.tipoFinanciamiento}</Typography>
                            <Typography variant="body2" sx={{ mb: 1 }}><b>Clave de producto: </b>{datosFinanciamiento.claveProducto}</Typography>
                            <Typography variant="body2" sx={{ mb: 1 }}><b>Fecha de inicio: </b>{datosFinanciamiento.fechaInicio != null ? moment(datosFinanciamiento.fechaInicio).format('dddd LL') : ""}</Typography>
                        </TableCell>
                        <TableCell style={{ width: '25%' }} align='right'>
                            <Typography sx={{ mb: 8 }}></Typography>
                            <Typography variant="body2" sx={{ mb: 1 }}><b>Línea de crédito: </b>{getNumberToCurrency(datosFinanciamiento.lineaCredito)}</Typography>
                            <Typography variant="body2" sx={{ mb: 1 }}><b>Línea de crédito disponible: </b>{getNumberToCurrency(datosFinanciamiento.lineaCreditoDisponible)}</Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    }
    return (
        <TableContainer>
            {datos}
        </TableContainer>
    );
}