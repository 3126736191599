import { take, put, call, fork, select, takeEvery, all } from 'redux-saga/effects';
import * as sagaController from '../../../controllers/sagaController';
import * as types from '../../../system/types';
import * as R from 'ramda';
import * as rootActions from '../../../system/actions';
import { CredentialsUser, FindValidStatus, WebHookStatus } from '../../../api/Clients';
import { IResponse } from '../../../models/IResponse';
import { AxiosResponse } from 'axios';
import { IWebHookStatusResponse } from '../../../models/IWebHookStatusRequest';
import { ICredentialsResponse } from '../../../models/ICredentials';
import { IFindValidStatusResponse } from '../../../models/IFindValidStatusResponse';

//#region WEBHOOK STATUS
export function* findValidStatusRequestAsync(action: types.requestFindValidStatusAction) {
    const response: IFindValidStatusResponse = yield call(FindValidStatus, action.data);
    // console.log(response);
    try {
        const {
            data,
            code,
            status,
            statusText,
            // headers,
            // config,
            // request,
        } = response;
        if (!R.isNil(data)) {
            yield sagaController.controlledStates(response, action.type, status);
        } else {
            yield sagaController.controlledStates(response, action.type, 400);
        }
    } catch (error) {
        console.log(error);
    }
}

export function* findValidStatusSaga() {
    yield all([
        takeEvery(types.FINDVALIDSTATUS_REQUEST, findValidStatusRequestAsync)
    ])
}
//#endregion

//#region CREDENTIALS
export function* credentialsRequestAsync(action: types.requestCredentialsAction) {
    const response: ICredentialsResponse = yield call(CredentialsUser, action.data);
    // console.log(response);
    try {
        const {
            data,
            code,
            status,
            statusText,
            // headers,
            // config,
            // request,
        } = response;
        if (!R.isNil(data)) {
            yield sagaController.controlledStates(response, action.type, status);
        } else {
            response.data = {
                rfc: action.data.rfc,
                status: '400',
            }
            yield sagaController.controlledStates(response, action.type, status);
        }
    } catch (error) {
        console.log(error);
    }
}

export function* credentialsSaga() {
    yield all([
        takeEvery(types.CREDENTIALS_REQUEST, credentialsRequestAsync)
    ])
}
//#endregion

//#region WEBHOOK STATUS
export function* webHookStatusRequestAsync(action: types.requestWebHookStatusAction) {
    const response: IWebHookStatusResponse = yield call(WebHookStatus, action.data);
    // console.log(response);
    try {
        const {
            data,
            code,
            status,
            statusText,
            // headers,
            // config,
            // request,
        } = response;
        if (!R.isNil(data)) {
            yield sagaController.controlledStates(response, action.type, status);
        } else {
            yield sagaController.controlledStates(response, action.type, 400);
        }
    } catch (error) {
        console.log(error);
    }
}

export function* webHookStatusSaga() {
    yield all([
        takeEvery(types.WEBHOOKSTATUS_REQUEST, webHookStatusRequestAsync)
    ])
}
//#endregion