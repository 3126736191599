import { DatosTipoCalculo } from '../../../../models/catalogs/ITipoCalculoResponse';
import * as type from '../../../../system/types';
import * as actions from '../../../../system/types';

export function requestTipoCalculo(data: any): actions.requestTipoCalculoAction {
    return {
        type: type.TIPOCALCULO_REQUEST,
        inProgress: true,
        data
    };
}

export function onTipoCalculoResponse(
    data: Array<DatosTipoCalculo>,
    message: string
): actions.onTipoCalculoResponseAction {
    return {
        type: type.TIPOCALCULO_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onTipoCalculoFailed(
    data: any,
    message: string
): actions.getTipoCalculoFailedAction {
    return {
        type: type.TIPOCALCULO_FAILED,
        message,
        inProgress: false
    };
}