import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import { Avatar, CssBaseline, Divider, List, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import { createTheme, styled, ThemeProvider, } from '@mui/material/styles';
// import MailIcon from '@mui/icons-material/Mail';
// import NotificationsIcon from '@mui/icons-material/Notifications';
import logoPCH from '../../content/images/pchcapital_capital_fff_trans.png';
import Image from 'mui-image';
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import SpaOutlinedIcon from '@mui/icons-material/SpaOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
// import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
// import HeadsetMicOutlinedIcon from '@mui/icons-material/HeadsetMicOutlined';
// import FingerprintOutlinedIcon from '@mui/icons-material/FingerprintOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PATH_SECURE } from '../../system/selector';
import { useAuth } from '../../navigations/App';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import theme from '../../theme';
import Dashboard from '../../screens/Dashboard/Index';

type Props = {
    component: JSX.Element,
    title: string
}

function Header(props: Props) {
    const { token, onLogout } = useAuth();

    const idCliente = useSelector((state: any) => state.login.id);
    const nombreUsuario = useSelector((state: any) => state.login.nombreUsuario);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openAnchorEl = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const drawerWidth: number = 240;
    interface AppBarProps extends MuiAppBarProps {
        open?: boolean;
    }

    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
    })<AppBarProps>(({ theme, open }) => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));

    const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
        ({ theme, open }) => ({
            '& .MuiDrawer-paper': {
                position: 'relative',
                whiteSpace: 'nowrap',
                width: drawerWidth,
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                boxSizing: 'border-box',
                ...(!open && {
                    overflowX: 'hidden',
                    transition: theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
                    width: theme.spacing(7),
                    [theme.breakpoints.up('sm')]: {
                        width: theme.spacing(9),
                    },
                }),
            },
        }),
    );

    const [open, setOpen] = React.useState(true);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="absolute" open={open}>
                    <Toolbar sx={{ pr: '40px' }}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{ marginRight: '40px', ...(open && { display: 'none' }) }}>
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ flexGrow: 1 }}>
                            {props.title}
                        </Typography>
                        <Box>
                            <Typography sx={{ mr: 2, fontSize: 14 }}>Bienvenido,</Typography>
                            <Typography sx={{ mr: 2, fontSize: 14 }}>{((nombreUsuario).length > 20) ?
                                (((nombreUsuario).substring(0, 20 - 3)) + '...') :
                                nombreUsuario}</Typography>
                        </Box>
                        <Tooltip title="Opciones">
                            <IconButton id="basic-button"
                                aria-controls={openAnchorEl ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openAnchorEl ? 'true' : undefined}
                                onClick={handleClick}>
                                <Avatar sx={{ bgcolor: 'secondary.main', width: 50, height: 50 }}></Avatar>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px', ml: '82%' }}
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openAnchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}>
                            <Link to={PATH_SECURE + "/cuentadeposito/" + idCliente} style={{ textDecoration: 'none', color: '#1c3648' }} onClick={() => handleClose()}>
                                <ListItemButton sx={{ fontSize: 14 }}>
                                    <ListItemIcon sx={{ mr: -2 }}>
                                        <SavingsOutlinedIcon fontSize='small' />
                                    </ListItemIcon>
                                    Cuenta de depósito
                                </ListItemButton>
                            </Link>
                            <Link to={"/AccountsSignInUi/"} style={{ textDecoration: 'none', color: '#1c3648' }} onClick={() => handleClose()}>
                                <ListItemButton sx={{ fontSize: 14 }}>
                                    <ListItemIcon sx={{ mr: -2 }}>
                                        <LogoutOutlinedIcon fontSize='small' />
                                    </ListItemIcon>
                                    Cerrar sesión
                                </ListItemButton>
                            </Link>
                        </Menu>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1]
                        }}>
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Toolbar>
                    <Divider />
                    <List component="nav" sx={{ ml: .5 }}>
                        <React.Fragment>
                            <Link to={PATH_SECURE} style={{ textDecoration: 'none', color: '#1c3648' }}>
                                <ListItemButton>
                                    <ListItemIcon sx={{ mr: -.5 }}>
                                        <DashboardOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Inicio" />
                                </ListItemButton>
                            </Link>
                            <Link to={PATH_SECURE + "/financiamientos/" + idCliente} style={{ textDecoration: 'none', color: '#1c3648' }}>
                                <ListItemButton>
                                    <ListItemIcon sx={{ mr: -.5 }}>
                                        <SpaOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Mis financiamientos" />
                                </ListItemButton>
                            </Link>
                            <Link to={PATH_SECURE + "/cuentas/" + idCliente} style={{ textDecoration: 'none', color: '#1c3648' }}>
                                <ListItemButton>
                                    <ListItemIcon sx={{ mr: -.5 }}>
                                        <AccountBalanceOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Mis cuentas" />
                                </ListItemButton>
                            </Link>
                            {/* <Link to={PATH_SECURE + "/cotizador/" + idCliente} style={{ textDecoration: 'none', color: '#1c3648' }}>
                                <ListItemButton>
                                    <ListItemIcon sx={{ mr: -.5 }}>
                                        <AttachMoneyOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Cotizador" />
                                </ListItemButton>
                            </Link> */}
                        </React.Fragment>
                    </List>
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto'
                    }}>
                    <Toolbar />
                    {props.component}
                </Box>
                {/* <Container maxWidth="xl">
            <Toolbar disableGutters>
                <Image src={logoPCH} width="7%" duration={0} />
                <Typography
                    variant="h6"
                    noWrap
                    component="a"
                    href="/"
                    sx={{
                        ml: 5,
                        display: { xs: 'none', md: 'flex' },
                        fontFamily: 'monospace',
                        fontWeight: 700,
                        letterSpacing: '.3rem',
                        color: 'inherit',
                        textDecoration: 'none',
                    }}>
                </Typography>
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit">
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                        }}>
                        <MenuItem onClick={handleCloseNavMenu} >
                            <Button startIcon={<DashboardOutlinedIcon />}>Dashboard</Button>
                        </MenuItem>
                        <MenuItem onClick={handleCloseNavMenu} >
                                <Button startIcon={<SavingsOutlinedIcon />}>Cuenta de depósito</Button>
                            </MenuItem>
                        <MenuItem onClick={handleCloseNavMenu} >
                            <Button startIcon={<SpaOutlinedIcon />}>Mis financiamientos</Button>
                        </MenuItem>
                        <MenuItem onClick={handleCloseNavMenu} >
                            <Button startIcon={<AccountBalanceOutlinedIcon />}>Cuentas</Button>
                        </MenuItem>
                        <MenuItem onClick={handleCloseNavMenu} >
                                <Button startIcon={<FingerprintOutlinedIcon />}>Credenciales</Button>
                            </MenuItem>
                        <MenuItem onClick={handleCloseNavMenu} >
                                <Button startIcon={<SmsOutlinedIcon />}>Comunicación</Button>
                            </MenuItem>
                            <MenuItem onClick={handleCloseNavMenu} >
                                <Button startIcon={<ContactsOutlinedIcon />}>Mi cuenta</Button>
                            </MenuItem>
                            <MenuItem onClick={handleCloseNavMenu} >
                                <Button startIcon={<HeadsetMicOutlinedIcon />}>Contacto de asesor</Button>
                            </MenuItem>
                    </Menu>
                </Box>
                <Typography
                    variant="h5"
                    noWrap
                    component="a"
                    href=""
                    sx={{
                        mr: 2,
                        display: { xs: 'flex', md: 'none' },
                        flexGrow: 1,
                        fontFamily: 'monospace',
                        fontWeight: 700,
                        letterSpacing: '.3rem',
                        color: 'inherit',
                        textDecoration: 'none',
                    }}>
                </Typography>
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                    <Link to={PATH_SECURE} style={{ textDecoration: 'none' }}><Button sx={{ m: 2, color: 'white' }} startIcon={<DashboardOutlinedIcon />}>Dashboard</Button></Link>
                    <Link to={PATH_SECURE + "/cuentadeposito/" + idCliente} style={{ textDecoration: 'none' }}><Button sx={{ m: 2, color: 'white' }} startIcon={<SavingsOutlinedIcon />}>Cuenta de depósito</Button></Link>
                    <Link to={PATH_SECURE + "/financiamientos/" + idCliente} style={{ textDecoration: 'none' }}> <Button sx={{ m: 2, color: 'white' }} startIcon={<SpaOutlinedIcon />}>Mis financiamientos</Button></Link>
                    <Link to={PATH_SECURE + "/consultasaldo/" + idCliente} style={{ textDecoration: 'none' }}> <Button sx={{ m: 2, color: 'white' }} startIcon={<AccountBalanceOutlinedIcon />}>Cuentas</Button></Link>
                    <Link to={PATH_SECURE + "/credenciales/" + idCliente} style={{ textDecoration: 'none' }}> <Button sx={{ m: 2, color: 'white' }} startIcon={<FingerprintOutlinedIcon />}>Credenciales</Button></Link>
                    <Link to={PATH_SECURE + "/#/"} style={{ textDecoration: 'none' }}><Button sx={{ m: 2, color: 'white' }} startIcon={<SmsOutlinedIcon />}>Comunicación</Button></Link>
                    <Link to={PATH_SECURE + "/#/"} style={{ textDecoration: 'none' }}> <Button sx={{ m: 2, color: 'white' }} startIcon={<ContactsOutlinedIcon />}>Mi cuenta</Button></Link>
                    <Link to={PATH_SECURE + "/#/"} style={{ textDecoration: 'none' }}><Button sx={{ m: 2, color: 'white' }} startIcon={<HeadsetMicOutlinedIcon />}>Contacto de asesor</Button></Link>
                </Box>
                <MenuItem sx={{ pl: 0 }}>
                        <IconButton size="large" aria-label="Ver mensajes" color="inherit">
                            <Badge badgeContent={0} color="error">
                                <MailIcon />
                            </Badge>
                        </IconButton>
                    </MenuItem>
                    <MenuItem sx={{ pl: 0 }}>
                        <IconButton
                            size="large"
                            aria-label="Ver notificaciones"
                            color="inherit">
                            <Badge badgeContent={0} color="error">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                    </MenuItem>
                <Box>
                    <Typography sx={{ mr: 2, fontSize: 14 }}>Bienvenido,</Typography>
                    <Typography sx={{ mr: 2, fontSize: 14 }}>{((nombreUsuario).length > 20) ?
                        (((nombreUsuario).substring(0, 20 - 3)) + '...') :
                        nombreUsuario}</Typography>
                </Box>
                <Tooltip title="Opciones">
                    <IconButton onClick={handleOpenUserMenu}>
                        <Avatar sx={{ bgcolor: 'secondary.main', width: 50, height: 50 }}></Avatar>
                    </IconButton>
                </Tooltip>
                <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={() => handleCloseUserMenu(null)}>
                    <MenuItem sx={{ pt: 1, pb: 0, fontSize: 14 }}>
                        <Link to={PATH_SECURE + "/cuentadeposito/" + idCliente} style={{ textDecoration: 'none' }} onClick={() => handleCloseUserMenu(null)}>
                            <ListItemIcon sx={{ color: 'primary.main' }}>
                                <SavingsOutlinedIcon sx={{ mr: 1 }} fontSize="small" />
                                Cuenta de depósito
                            </ListItemIcon>
                        </Link>
                    </MenuItem>
                </Menu>
            </Toolbar>
        </Container> */}
            </Box>
        </ThemeProvider >);
}

export default Header;