import { DatosTipoPagoAP } from '../../../../models/catalogs/ITipoPagoAPResponse';
import * as types from '../../../../system/types'

export interface TipoPagoAPState {
    id: string,
    // errors: [],
    inProgress: boolean,
    data: Array<DatosTipoPagoAP> | null,
    message?: string | null
}

const TipoPagoAPInitialState: TipoPagoAPState = {
    id: '',
    // errors: [],
    data: null,
    inProgress: false
};

export default function tipoPagoAPReducer(
    state: TipoPagoAPState = TipoPagoAPInitialState,
    action: types.RootAction
): TipoPagoAPState {
    switch (action.type) {
        case types.TIPOPAGOAP_REQUEST:
            return {
                ...state,
                message: '',
                inProgress: true
            }
        case types.TIPOPAGOAP_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                inProgress: false
            }
        case types.TIPOPAGOAP_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}