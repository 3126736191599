import { DatosFacturasPaquete, DatosPaquetesFactoraje } from '../../../../models/IPaquetesFactorajeResponse';
import * as types from '../../../../system/types'

//#region PAQUETES 
export interface PaquetesFactorajeState {
    id: string,
    // errors: [],
    inProgress: boolean,
    data: Array<DatosPaquetesFactoraje> | null,
    message?: string | null
}

const PaquetesFactorajeInitialState: PaquetesFactorajeState = {
    id: '',
    // errors: [],
    data: null,
    inProgress: false
};

export function paquetesFactorajeReducer(
    state: PaquetesFactorajeState = PaquetesFactorajeInitialState,
    action: types.RootAction
): PaquetesFactorajeState {
    switch (action.type) {
        case types.PAQUETESFACTORAJE_REQUEST:
            return {
                ...state,
                message: '',
                inProgress: true
            }
        case types.PAQUETESFACTORAJE_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                inProgress: false
            }
        case types.PAQUETESFACTORAJE_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}
//#endregion 

//#region FACTURAS
export interface FacturasPaqueteState {
    id: string,
    // errors: [],
    inProgress: boolean,
    data: Array<DatosFacturasPaquete> | null,
    message?: string | null
}

const FacturasPaqueteInitialState: FacturasPaqueteState = {
    id: '',
    // errors: [],
    data: null,
    inProgress: false
};

export function facturasPaqueteReducer(
    state: FacturasPaqueteState = FacturasPaqueteInitialState,
    action: types.RootAction
): FacturasPaqueteState {
    switch (action.type) {
        case types.FACTURASPAQUETE_REQUEST:
            return {
                ...state,
                message: '',
                inProgress: true
            }
        case types.FACTURASPAQUETE_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                inProgress: false
            }
        case types.FACTURASPAQUETE_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}
//#endregion 