import { DatosTipoTasa } from '../../../../models/catalogs/ITipoTasaResponse';
import * as type from '../../../../system/types';
import * as actions from '../../../../system/types';

export function requestTipoTasa(data: any): actions.requestTipoTasaAction {
    return {
        type: type.TIPOTASA_REQUEST,
        inProgress: true,
        data
    };
}

export function onTipoTasaResponse(
    data: Array<DatosTipoTasa>,
    message: string
): actions.onTipoTasaResponseAction {
    return {
        type: type.TIPOTASA_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onTipoTasaFailed(
    data: any,
    message: string
): actions.getTipoTasaFailedAction {
    return {
        type: type.TIPOTASA_FAILED,
        message,
        inProgress: false
    };
}