import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import * as R from 'ramda';
import { ICuentaDepositoRequest, IPostCuentaDepositoRequest } from '../../models/ICuentaDepositoRequest';
import * as cuentaDepositoActions from './redux/actions';
import { DatosCuentaDeposito } from '../../models/ICuentaDepositoResponse';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { DatosInstitucionesBancarias, IInstitucionesBancariasResponse } from '../../models/catalogs/IInstitucionesBancariasResponse';
import { DatosTipoCuenta } from '../../models/catalogs/ITipoCuentaResponse';
import { useParams } from 'react-router-dom';
import $ from 'jquery';
import BadParameters from '../NotFound/BadParameters';

export default function CuentaDeposito() {
    const { idCliente } = useParams();
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    let idClienteValid = regexExp.test(idCliente + '');

    function FormularioCuentaDeposito() {
        const [showError, setShowError] = useState(false);
        const [error, setError] = useState('Email inválido — La dirección de correo electrónico no es válida!');

        const changeInstitucionBancaria = (event: SelectChangeEvent) => {
            setInstitucionBancaria(event.target.value);
        };

        const changeTipoCuenta = (event: SelectChangeEvent) => {
            setTipoCuenta(event.target.value);
        };

        let dataSend: ICuentaDepositoRequest = {
            IdCliente: idCliente
        }

        const datosCuentaDeposito: DatosCuentaDeposito = useSelector((state: any) => state.cuentaDeposito.data);
        const datosPostCuentaDeposito: any = useSelector((state: any) => state.postCuentaDeposito.data);
        const inProgressGet: any = useSelector((state: any) => state.cuentaDeposito.inProgress);
        const inProgressPost: any = useSelector((state: any) => state.postCuentaDeposito.inProgress);

        let [institucionBancaria, setInstitucionBancaria] = useState('')
        let [tipoCuenta, setTipoCuenta] = useState('')
        const [numeroCuenta, setNumeroCuenta] = useState('')
        const [beneficiario, setBeneficiario] = useState('')
        const [email, setEmail] = useState('')

        let postDataSend: IPostCuentaDepositoRequest = {
            IdCliente: idCliente,
            ClaveInstitucion: institucionBancaria,
            ClaveTipoCuenta: parseInt(tipoCuenta),
            NumeroCuenta: numeroCuenta,
            NombreBeneficiario: beneficiario,
            Email: email
        }

        const dispatch = useDispatch();
        const cuentaDepositoRequest: any = useCallback(() => dispatch(cuentaDepositoActions.requestCuentaDeposito(dataSend)), [dispatch, dataSend]);
        const postCuentaDepositoRequest: any = useCallback(() => dispatch(cuentaDepositoActions.requestPostCuentaDeposito(postDataSend)), [dispatch, postDataSend]);

        let datosIB = <MenuItem value=''>Seleccione una entidad financiera</MenuItem>
        let datosTC = <MenuItem value=''>Seleccione un tipo de cuenta</MenuItem>
        let datosInstitucionesBancarias: any = useSelector((state: any) => state.institucionesBancarias.data);

        if (!R.isNil(datosInstitucionesBancarias)) {
            datosIB = datosInstitucionesBancarias.map((institucion: DatosInstitucionesBancarias) =>
                <MenuItem key={institucion.clave} value={institucion.clave}>{institucion.descripcion}</MenuItem>)
        }

        let datosTipoCuenta: any = useSelector((state: any) => state.tipoCuenta.data);

        if (!R.isNil(datosTipoCuenta)) {
            datosTC = datosTipoCuenta.map((tipo: DatosTipoCuenta) =>
                <MenuItem key={tipo.clave} value={tipo.clave}>{tipo.descripcion}</MenuItem>)
        }

        let disabled = false;

        const [openConfirm, setOpenConfirm] = React.useState(false);

        const handleClickOpenConfirm = () => {
            setOpenConfirm(true);
        };

        const handleCloseConfirm = () => {
            setOpenConfirm(false);
        };

        if (!R.isNil(datosCuentaDeposito?.numeroCuenta)) {
            $('#saveButton').css('display', 'none');
            institucionBancaria = datosCuentaDeposito.claveInstitucion;
            tipoCuenta = datosCuentaDeposito.claveTipoCuenta.toString();
            $('#numeroCuenta').val(datosCuentaDeposito.numeroCuenta);
            $('#beneficiario').val(datosCuentaDeposito.nombreBeneficiario);
            $('#email').val(datosCuentaDeposito.email);
            disabled = true;
        }
        if (inProgressGet) {
            $('#boxFormGetCD').css('display', 'none');
            $('#boxInProgressCD').css('display', 'block');
        }
        else {
            $('#boxInProgressCD').css('display', 'none');
            $('#boxFormGetCD').css('display', 'block');
        }

        React.useEffect(() => {
            if (idClienteValid) {
                setOpenConfirm(false);
                cuentaDepositoRequest();
            }
        }, []);

        const handleSubmit = (event: any) => {
            $('#saving').css('display', 'block');
            event.preventDefault();
            setShowError(false);
            postCuentaDepositoRequest();
        };

        let datos = <DialogContentText></DialogContentText>;
        if (!R.isNil(datosPostCuentaDeposito)) {
            disabled = true;
            $('#saveButton').css('display', 'none');
            $('#dialogActions').css('display', 'none');
            $('#dialogContent').css('display', 'none');
            datos =
                <DialogContent sx={{ m: 4, textAlign: 'center' }}>
                    <DialogContentText sx={{ color: 'success.main' }}>
                        Datos guardados correctamente.
                    </DialogContentText>
                </DialogContent>;
        }

        return (
            <Container component="main" maxWidth="lg" sx={{ mb: 4 }}>
                <Paper variant="elevation" sx={{ my: { xs: 4, md: 4 }, p: { xs: 4, md: 4 } }}>
                    <Typography align="left" sx={{ mb: 4 }}>
                        <SavingsOutlinedIcon />
                    </Typography>
                    <Box component="form" id="boxFormGetCD" onSubmit={handleSubmit}>
                        <Grid container spacing={6}>
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth variant="standard">
                                    <InputLabel id="labelIB">Institución financiera *</InputLabel>
                                    <Select
                                        required
                                        id="institucionBancaria"
                                        disabled={disabled}
                                        label="Institución financiera *"
                                        value={institucionBancaria}
                                        onChange={changeInstitucionBancaria}>
                                        {datosIB}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth variant="standard">
                                    <InputLabel id="labelIB">Tipo de cuenta *</InputLabel>
                                    <Select
                                        required
                                        id="tipoCuenta"
                                        disabled={disabled}
                                        label="Tipo de cuenta *"
                                        value={tipoCuenta}
                                        onChange={changeTipoCuenta}>
                                        {datosTC}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    required
                                    id="numeroCuenta"
                                    disabled={disabled}
                                    label="Número de cuenta"
                                    fullWidth
                                    autoComplete="off"
                                    variant="standard"
                                    onChange={e => setNumeroCuenta(e.target.value)} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    required
                                    id="beneficiario"
                                    disabled={disabled}
                                    label="Nombre del beneficiario"
                                    fullWidth
                                    autoComplete="off"
                                    variant="standard"
                                    onChange={e => setBeneficiario(e.target.value)} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    required
                                    id="email"
                                    disabled={disabled}
                                    label="Correo electrónico"
                                    fullWidth
                                    autoComplete="off"
                                    variant="standard"
                                    onChange={e => setEmail(e.target.value)} />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Button
                                    onClick={handleClickOpenConfirm}
                                    id="saveButton"
                                    variant="contained"
                                    disabled={inProgressPost}
                                    sx={{ backgroundColor: '#16a0ff', mt: 1.5 }}
                                    fullWidth>Guardar</Button>
                                <Dialog
                                    open={openConfirm}
                                    onClose={handleCloseConfirm}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description">
                                    <DialogContent sx={{ m: 2, textAlign: 'center' }} id="dialogContent">
                                        <DialogContentText>
                                            ¿Está seguro de que desea cambiar la cuenta de depósito?
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions sx={{ m: 2 }} id="dialogActions">
                                        <Typography id='saving' sx={{ mr: 2, display: 'none' }}>Guardando...</Typography>
                                        <Button
                                            disabled={inProgressPost}
                                            autoFocus onClick={handleCloseConfirm}>
                                            Cancelar
                                        </Button>
                                        <Button variant="contained"
                                            disabled={inProgressPost}
                                            form="boxFormGetCD"
                                            type='submit'
                                            sx={{ backgroundColor: '#16a0ff' }}>Si, cambiar</Button>
                                    </DialogActions>
                                    {datos}
                                </Dialog>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box component="form" id="boxInProgressCD">
                        <Grid container spacing={6}>
                            <Grid item xs={12} sm={12}>
                                <Typography align="center"><CircularProgress /></Typography>
                                <Typography align="center">Buscando cuenta de depósito...</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Container>
        );
    }

    if (!idClienteValid) {
        return (<BadParameters />);
    }

    return (
        <FormularioCuentaDeposito />
    );
}