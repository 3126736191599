import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Header from '../../components/common/AppBar';
import { Alert, Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import * as stpCompaniesActions from './redux/actions';
import { ISubAccountMovimientosRequest, IStpCompaniesRequest, ISubAccountsRequest } from '../../models/IStpCompaniesRequest';
import * as R from 'ramda';
import { DatosStpCompanies, DatosSubAccountMovimientos, DatosSubAccounts, ICheckAccountBalanceResponse } from '../../models/IStpCompaniesResponse';
import Footer from '../../components/common/Footer';
import { Link, useParams } from 'react-router-dom';
import BadParameters from '../NotFound/BadParameters';
import $ from 'jquery';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import { convertSecondsToDate, convertSecondsToDateTime, convertSecondsToTime, getColorStatus, getIconIO, getNumberToCurrency } from '../../utils';
import { TableLoader } from '../../components/loader/TableLoader';
import { TableNotFoundData } from '../../components/notFoundData/TableNotFoundData';
import { StyledTableCell, StyledTableRow } from '../../components/styledTables/StyledTables';

export default function SubCuentas() {
    const { idCliente } = useParams();
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    let idClienteValid = regexExp.test(idCliente + '');
    const rfc: any = useSelector((state: any) => state.login.rfc);

    let dataSendStpCompanies: IStpCompaniesRequest = {
        get: ''
    }

    const [centroCostos, setCentroCostos] = useState('');
    const [subcuenta, setSubcuenta] = useState('');

    let dataSendSubAccounts: ISubAccountsRequest = {
        StpMainAccountIdInt: Number(centroCostos)
    }

    let dataSendSubAccountMovimientos: ISubAccountMovimientosRequest = {
        SubaccountIdInt32: Number(subcuenta)
    }

    const datosStpCompanies: any = useSelector((state: any) => state.stpCompanies.balanceResponseObj);
    const datosSubAccounts: any = useSelector((state: any) => state.subAccounts.dropdownDataObj);
    const datosSubAccountMovimientos: any = useSelector((state: any) => state.subAccountMovimientos.transaccionResponseObj);
    const inProgressStpCompanies: any = useSelector((state: any) => state.stpCompanies.inProgress);
    const inProgressSubAccounts: any = useSelector((state: any) => state.subAccounts.inProgress);
    const inProgressSubAccountMovimientos: any = useSelector((state: any) => state.subAccountMovimientos.inProgress);

    const dispatch = useDispatch();
    const stpCompaniesRequest = useCallback(() => dispatch(stpCompaniesActions.requestStpCompanies(dataSendStpCompanies)), [dispatch, dataSendStpCompanies]);
    const subAccountsRequest = useCallback(() => dispatch(stpCompaniesActions.requestSubAccounts(dataSendSubAccounts)), [dispatch, dataSendSubAccounts]);
    const subAccountMovimientosRequest = useCallback(() => dispatch(stpCompaniesActions.requestSubAccountMovimientos(dataSendSubAccountMovimientos)), [dispatch, dataSendSubAccountMovimientos]);

    useEffect(() => {
        if (idClienteValid) {
            stpCompaniesRequest();
        }
    }, []);

    function PopUpInProgressSubAccounts() {
        return (
            <Dialog
                open={inProgressSubAccounts}
                fullWidth={true}
                maxWidth={'xs'}>
                <DialogContent sx={{ textAlign: 'center' }} id="dialogContent">
                    <DialogContentText>
                        Buscando información...
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }

    function PopUpInProgressSubAccountMovimientos() {
        return (
            <Dialog
                open={inProgressSubAccountMovimientos}
                fullWidth={true}
                maxWidth={'xs'}>
                <DialogContent sx={{ textAlign: 'center' }} id="dialogContent">
                    <DialogContentText>
                        Buscando información...
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }

    function PopUpAlertSC() {
        return (<Dialog
            open={openAlertSC}
            fullWidth={true}
            maxWidth={'xs'}>
            <DialogContent sx={{ textAlign: 'center' }} id="dialogContent">
                <DialogContentText sx={{ color: 'error.main' }}>
                    Seleccione una subcuenta.
                </DialogContentText>
            </DialogContent>
        </Dialog>);
    }

    let datosCC = <MenuItem value=''>Elige un centro de costos</MenuItem>;
    let datosSC = <MenuItem value=''>Elige una subcuenta</MenuItem>;
    let datosMovimientos = <TableCell colSpan={9}><Typography align='center' sx={{ fontSize: 14 }}>Sin resultados.</Typography></TableCell>;

    if (!R.isNil(datosStpCompanies) && !inProgressStpCompanies) {
        if (rfc == "EGM210216UV7") {
        datosCC = datosStpCompanies.map((companies: DatosStpCompanies) =>
            <MenuItem key={companies.idCCInt} value={companies.idCCInt}>{companies.completeCompanyNameStr}</MenuItem>)
        }
    }

    if (!R.isNil(datosSubAccounts) && !inProgressSubAccounts) {
        datosSC = datosSubAccounts.map((subAccounts: DatosSubAccounts) =>
            <MenuItem key={subAccounts.valueInt} value={subAccounts.valueInt}>
                {subAccounts.accountInformationStr} - {subAccounts.descriptionStr}
            </MenuItem>)
    }

    const changeCentroCostos = (event: SelectChangeEvent) => {
        setCentroCostos(event.target.value);
    };

    useEffect(() => {
        subAccountsRequest();
    }, [centroCostos != '']);

    const changeSubcuenta = (event: SelectChangeEvent) => {
        setSubcuenta(event.target.value);
    };

    const [openAlertSC, setOpenAlertSC] = useState(false);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (subcuenta == '') {
            setOpenAlertSC(true);
            setTimeout(() => {
                setOpenAlertSC(false);
            }, 1000);
        }
        else {
            dataSendSubAccountMovimientos.SubaccountIdInt32 = Number(subcuenta);
            subAccountMovimientosRequest();
        }
    };

    let i: number = 1;
    if (!R.isNil(datosSubAccountMovimientos) && !inProgressSubAccountMovimientos) {
        datosMovimientos = datosSubAccountMovimientos.map((movimiento: DatosSubAccountMovimientos) =>
            <StyledTableRow
                key={movimiento.transactionDate.seconds}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <StyledTableCell>{i++}</StyledTableCell>
                <StyledTableCell>
                    <Typography sx={{ fontSize: 14 }}><b>{convertSecondsToDate(movimiento.transactionDate.seconds)}</b></Typography>
                    <Typography sx={{ fontSize: 14, color: 'grey' }}>{convertSecondsToTime(movimiento.transactionDate.seconds)}</Typography>
                </StyledTableCell>
                <StyledTableCell>{movimiento.transactionDescriptionStr}</StyledTableCell>
                <StyledTableCell>
                    <Typography sx={{ fontSize: 14, mb: 1 }}><b>{movimiento.beneficiaryNameStr}</b></Typography>
                    <Typography sx={{ fontSize: 14, color: 'grey' }}>{movimiento.beneficiaryAccountStr}</Typography>
                </StyledTableCell>
                <StyledTableCell>
                    {movimiento.typeTransactionStr == 'I' ? getNumberToCurrency(movimiento.transactionAmountDbl) : '-'}
                </StyledTableCell>
                <StyledTableCell>
                    {movimiento.typeTransactionStr == 'O' ? getNumberToCurrency(movimiento.transactionAmountDbl) : '-'}
                </StyledTableCell>
                <StyledTableCell>{getIconIO(movimiento.typeTransactionStr)}</StyledTableCell>
                <StyledTableCell>{getColorStatus(movimiento.transactionStatusIdInt)}</StyledTableCell>
                <StyledTableCell><b>{movimiento.transactionStatusStr}</b></StyledTableCell>
            </StyledTableRow>)
    }
    if (datosSubAccountMovimientos?.length == 0) {
        datosMovimientos = <TableCell colSpan={9}><Typography align='center' sx={{ fontSize: 14 }}>Sin resultados.</Typography></TableCell>;
    }


    if (!idClienteValid) {
        return (<BadParameters />);
    }

    return (<Grid sx={{ m: 4 }}>
        <Box component="form" onSubmit={handleSubmit} sx={{ mb: 4 }}>
            <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                    <Typography sx={{ fontSize: 14, mb: 2 }}><b>Centro de costos</b></Typography>
                    <FormControl fullWidth variant="standard">
                        <InputLabel id="labelIB">Elige un centro de costos</InputLabel>
                        <Select
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            id="centroCostos"
                            value={centroCostos}
                            onChange={changeCentroCostos}
                            label="Elige un centro de costos">
                            {datosCC}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Typography sx={{ fontSize: 14, mb: 2 }}><b>Subcuenta</b></Typography>
                    <FormControl fullWidth variant="standard">
                        <InputLabel id="labelIB">Elige una subcuenta</InputLabel>
                        <Select
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            id="subCuentas"
                            value={subcuenta}
                            onChange={changeSubcuenta}
                            label="Elige una subcuenta">
                            {datosSC}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Button
                        id="saveButton"
                        variant="contained"
                        type='submit'
                        sx={{ backgroundColor: '#16a0ff', mt: 6 }}
                        fullWidth>Consultar</Button>
                </Grid>
            </Grid>
        </Box>
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 800 }} aria-label="customized table">
                <TableBody>
                    <TableRow>
                        <TableCell><b>#</b></TableCell>
                        <TableCell><b>Fecha</b></TableCell>
                        <TableCell><b>Concepto</b></TableCell>
                        <TableCell><b>Beneficiario</b></TableCell>
                        <TableCell><b>Entrada</b></TableCell>
                        <TableCell><b>Salida</b></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell><b>Estatus</b></TableCell>
                    </TableRow>
                    {datosMovimientos}
                </TableBody>
            </Table>
            <Dialog
                open={inProgressStpCompanies}
                fullWidth={true}
                maxWidth={'xs'}>
                <DialogContent sx={{ textAlign: 'center' }} id="dialogContent">
                    <DialogContentText>
                        Buscando información...
                    </DialogContentText>
                </DialogContent>
            </Dialog>            <PopUpInProgressSubAccounts />
            <PopUpInProgressSubAccountMovimientos />
            <PopUpAlertSC />
        </TableContainer>
    </Grid>);
}