import { ILoginRequest } from '../../../../models/ILoginRequest';
import * as type from '../../../../system/types';
import * as actions from '../../../../system/types';

export function requestLogin(data: ILoginRequest): actions.requestLoginAction {
    return {
        type: type.LOGIN_REQUEST,
        inProgress: true,
        data
    };
}

export function onLoginResponse(
    data: boolean,
    message: string
): actions.onLoginResponseAction {
    return {
        type: type.LOGIN_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function requestLogout(): actions.requestLogoutAction {
    return {
        type: type.LOGOUT_REQUEST,
        token: null,
    };
}
export function onLogoutResponse(
    app: null,
    uid: null,
    email: null,
    nombreUsuario: null,
    rfc: null,
    idTipoPersona: null,
    isSigned: boolean
): actions.onLogoutResponseAction {
    return {
        type: type.LOGOUT_RESPONSE,
        app,
        email,
        uid,
        nombreUsuario,
        rfc,
        idTipoPersona,
        isSigned
    };
}

export function requestLoginByToken(token: string | null): actions.requestLoginByTokenAction {
    return {
        type: type.LOGIN_BY_TOKEN_REQUEST,
        token,
    };
}
export function onLoginByTokenResponse(
    app: string,
    uid: string,
    email: string,
    nombreUsuario: string,
    rfc: string,
    idTipoPersona: string,
    isSigned: boolean
): actions.onLoginByTokenAction {
    return {
        type: type.LOGIN_BY_TOKEN_RESPONSE,
        app,
        email,
        uid,
        nombreUsuario,
        rfc,
        idTipoPersona,
        isSigned
    };
}

export function onLoginFailed(
    data: any,
    message: string
): actions.getLoginFailedAction {
    return {
        type: type.LOGIN_FAILED,
        message,
        inProgress: false
    };
}