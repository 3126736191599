import { take, put, call, fork, select, takeEvery, all } from 'redux-saga/effects';
import * as sagaController from '../../../../controllers/sagaController';
import * as types from '../../../../system/types';
import * as R from 'ramda';
import * as actions from './actions';
import * as rootActions from '../../../../system/actions';
import { AmortizacionesCotizadorCS, ArchivoResultadoCotizadorCS, RecalculoCotizadorCS } from '../../../../api/Clients';
import { IResponse } from '../../../../models/IResponse';
import { AxiosResponse } from 'axios';
import { IAmortizacionesCotizadorCSResponse } from '../../../../models/cotizador/IAmortizacionesCotizadorCSResponse';
import { IExportarArchivoResponse } from '../../../../models/IExportarArchivoResponse';

//#region AMORTIZACIONES COTIZADOR CS
export function* amortizacionesCotizadorCSRequestAsync(action: types.requestAmortizacionesCotizadorCSAction) {
    const response: IAmortizacionesCotizadorCSResponse = yield call(AmortizacionesCotizadorCS, action.data);
    // console.log(response);
    try {
        const {
            data,
            code,
            status,
            statusText,
            // headers,
            // config,
            // request,
        } = response;
        if (!R.isNil(data)) {
            yield sagaController.controlledStates(response, action.type, status);
        } else {
            yield sagaController.controlledStates(response, action.type, 400);
        }
    } catch (error) {
        console.log(error);
    }
}

export function* amortizacionesCotizadorCSSaga() {
    yield all([
        takeEvery(types.AMORTIZACIONESCOTIZADORCS_REQUEST, amortizacionesCotizadorCSRequestAsync)
    ])
}
//#endregion

//#region RECALCULO COTIZADOR CS
export function* recalculoCotizadorCSRequestAsync(action: types.requestRecalculoCotizadorCSAction) {
    const response: IAmortizacionesCotizadorCSResponse = yield call(RecalculoCotizadorCS, action.data);
    // console.log(response);
    try {
        const {
            data,
            code,
            status,
            statusText,
            // headers,
            // config,
            // request,
        } = response;
        if (!R.isNil(data)) {
            yield sagaController.controlledStates(response, action.type, status);
        } else {
            yield sagaController.controlledStates(response, action.type, 400);
        }
    } catch (error) {
        console.log(error);
    }
}

export function* recalculoCotizadorCSSaga() {
    yield all([
        takeEvery(types.RECALCULOCOTIZADORCS_REQUEST, recalculoCotizadorCSRequestAsync)
    ])
}
//#endregion

//#region ARCHIVO RESULTADO COTIZADOR CS
export function* archivoResultadoCotizadorCSRequestAsync(action: types.requestArchivoResultadoCotizadorCSAction) {
    const response: IExportarArchivoResponse = yield call(ArchivoResultadoCotizadorCS, action.data);
    // console.log(response);
    try {
        const {
            data,
            code,
            status,
            statusText,
            // headers,
            // config,
            // request,
        } = response;
        if (!R.isNil(data)) {
            yield sagaController.controlledStates(response, action.type, status);
        } else {
            yield sagaController.controlledStates(response, action.type, 400);
        }
    } catch (error) {
        console.log(error);
    }
}

export function* archivoResultadoCotizadorCSSaga() {
    yield all([
        takeEvery(types.ARCHIVORESULTADOCOTIZADORCS_REQUEST, archivoResultadoCotizadorCSRequestAsync)
    ])
}
//#endregion