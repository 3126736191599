import * as React from 'react';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import Disposiciones from '../../components/common/Disposiciones/Disposiciones';
import * as R from 'ramda';
import DatosFinanciamiento from '../../components/common/DatosFinanciamiento/DatosFinanciamiento';
import { allProps, GuidValid, TabPanel, TipoFinanciamiento } from '../../utils';
import PagosRealizados from '../../components/common/PagosRealizados/PagosRealizados';
import Amortizaciones from '../../components/common/Amortizaciones/Amortizaciones';
import AmortizacionesPendientes from '../../components/common/Amortizaciones/AmortizacionesPendientes';
import { useSelector } from 'react-redux';
import GenerarReferenciaPago from '../../components/common/GenerarReferenciaPago/GenerarReferenciaPago';
import { useSearchParams } from 'react-router-dom';
import BadParameters from '../NotFound/BadParameters';
import PagosPendientes from '../../components/common/PagosRealizados/PagosPendientes';

export default function CreditoSimple() {
    const [searchParams, setSearchParams] = useSearchParams();

    const idForoCondiciones = searchParams.get("idFC") + '';
    const idFinanciamientoSolicitado = searchParams.get("idFS") + '';
    const idCliente: string = useSelector((state: any) => state.login.id);
    const idTipoPersona: any = useSelector((state: any) => state.login.idTipoPersona);

    let idForoCondicionesValid = GuidValid(idForoCondiciones);
    let idFinanciamientoSolicitadoValid = GuidValid(idFinanciamientoSolicitado);

    function MenusFinanciamiento() {
        const [parent, setParent] = React.useState(0);
        const [children1, setChildren1] = React.useState(0);
        const [children2, setChildren2] = React.useState(0);

        const changeParent = (event: React.SyntheticEvent, newValue: number) => {
            setParent(newValue);
        };

        const changeChildren1 = (event: React.SyntheticEvent, newValue: number) => {
            setChildren1(newValue);
        };

        const changeChildren2 = (event: React.SyntheticEvent, newValue: number) => {
            setChildren2(newValue);
        };

        return (
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={parent} onChange={changeParent} aria-label="basic tabs example">
                        <Tab label="Resumen" {...allProps(0)} />
                        <Tab label="Disposiciones" {...allProps(1)} />
                        <Tab label="Pagos" {...allProps(2)} />
                        <Tab label="Generar referencia de pago" {...allProps(3)} />
                    </Tabs>
                </Box>
                <TabPanel value={parent} index={0}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={children1} onChange={changeChildren1} aria-label="basic tabs example">
                            <Tab label="Tabla de amortización" {...allProps(0)} />
                            <Tab label="Amortizaciones pendientes" {...allProps(1)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={children1} index={0}>
                        <Amortizaciones IdForoCondiciones={idForoCondiciones} IdTipoFinanciamiento={TipoFinanciamiento['CreditoSimple']} IdTipoPersona={idTipoPersona} />
                    </TabPanel>
                    <TabPanel value={children1} index={1}>
                        <AmortizacionesPendientes IdForoCondiciones={idForoCondiciones} IdTipoFinanciamiento={TipoFinanciamiento['CreditoSimple']} IdTipoPersona={idTipoPersona} />
                    </TabPanel>
                </TabPanel>
                <TabPanel value={parent} index={1}>
                    <Disposiciones IdForoCondiciones={idForoCondiciones} IdTipoFinanciamiento={TipoFinanciamiento['CreditoSimple']} />
                </TabPanel>
                <TabPanel value={parent} index={2}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={children2} onChange={changeChildren2} aria-label="basic tabs example">
                            <Tab label="Pagos realizados" {...allProps(0)} />
                            <Tab label="Pagos pendientes" {...allProps(1)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={children2} index={0}>
                        <PagosRealizados IdForoCondiciones={idForoCondiciones} IdTipoFinanciamiento={TipoFinanciamiento['CreditoSimple']} />
                    </TabPanel>
                    <TabPanel value={children2} index={1}>
                        <PagosPendientes IdForoCondiciones={idForoCondiciones} IdTipoFinanciamiento={TipoFinanciamiento['CreditoSimple']} />
                    </TabPanel>
                </TabPanel>
                <TabPanel value={parent} index={3}>
                    <GenerarReferenciaPago IdForoCondiciones={idForoCondiciones} IdTipoFinanciamiento={TipoFinanciamiento['CreditoSimple']} IdTipoPersona={idTipoPersona} />
                </TabPanel>
            </Box>
        );
    }

    if (!idForoCondicionesValid || !idFinanciamientoSolicitadoValid) {
        return (<BadParameters />);
    }

    return (
        <Grid sx={{ m: 2 }}>
            <DatosFinanciamiento IdCliente={idCliente} IdForoCondiciones={idForoCondiciones} />
            <MenusFinanciamiento />
        </Grid>
    );
}