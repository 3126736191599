import { DatosDisposiciones } from '../../../../models/IDisposicionesResponse';
import * as types from '../../../../system/types'

//#region DISPOSICIONES
export interface DisposicionesState {
    id: string,
    // errors: [],
    inProgress: boolean,
    data: Array<DatosDisposiciones> | null,
    message?: string | null
}

const DisposicionesInitialState: DisposicionesState = {
    id: '',
    // errors: [],
    data: null,
    inProgress: false
};

export function disposicionesReducer(
    state: DisposicionesState = DisposicionesInitialState,
    action: types.RootAction
): DisposicionesState {
    switch (action.type) {
        case types.DISPOSICIONES_REQUEST:
            return {
                ...state,
                message: '',
                inProgress: true
            }
        case types.DISPOSICIONES_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                inProgress: false
            }
        case types.DISPOSICIONES_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}
//#endregion

//#region EXPORTAR ARCHIVO
export interface ExportarArchivoDisposicionesState {
    id: string,
    // errors: [],
    data: any | null,
    inProgress: boolean,
    succeeded: boolean,
    message?: string | null
}

const ExportarArchivoDisposicionesInitialState: ExportarArchivoDisposicionesState = {
    id: '',
    // errors: [],
    data: null,
    succeeded: false,
    inProgress: false
};

export function exportarArchivoDisposicionesReducer(
    state: ExportarArchivoDisposicionesState = ExportarArchivoDisposicionesInitialState,
    action: types.RootAction
): ExportarArchivoDisposicionesState {
    switch (action.type) {
        case types.EXPORTARARCHIVODISPOSICIONES_REQUEST:
            return {
                ...state,
                message: '',
                succeeded: false,
                inProgress: true
            }
        case types.EXPORTARARCHIVODISPOSICIONES_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                succeeded: action.succeeded,
                inProgress: false
            }
        case types.EXPORTARARCHIVODISPOSICIONES_FAILED:
            return {
                ...state,
                message: action.message,
                succeeded: false,
                inProgress: false
            }
        default:
            return state;
    }
}
//#endregion