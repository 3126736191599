import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Alert, Box, Typography } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import * as financiamientosActions from './redux/actions';
import { IFinanciamientosRequest } from '../../models/IFinanciamientosRequest';
import * as R from 'ramda';
import { DatosFinanciamientos } from '../../models/IFinanciamientosResponse';
import { getIconFinanciamiento, getLinkFinanciamiento, getNumberToCurrency } from '../../utils';
import { Link, useParams } from 'react-router-dom';
import BadParameters from '../NotFound/BadParameters';
import $ from 'jquery';
import { TableLoader } from '../../components/loader/TableLoader';
import { TableNotFoundData } from '../../components/notFoundData/TableNotFoundData';
import moment from 'moment';
import 'moment/locale/es';
import { StyledTableCell, StyledTableRow } from '../../components/styledTables/StyledTables';
moment.locale('es');

export default function Financiamientos() {
    const { idCliente } = useParams();
    const IdEstatusFinanciamiento = '00000000-0000-0000-0000-000000000000';
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    let idClienteValid = regexExp.test(idCliente + '');

    let dataSend: IFinanciamientosRequest = {
        IdCliente: idCliente,
        IdEstatusFinanciamiento: IdEstatusFinanciamiento
    }

    let datos = <TableLoader colums={3} text={'Buscando financiamientos...'} />;

    const datosFinanciamientos: any = useSelector((state: any) => state.financiamientos.data);
    const message: any = useSelector((state: any) => state.financiamientos.message);
    const inProgress: any = useSelector((state: any) => state.financiamientos.inProgress);
    const dispatch = useDispatch();
    const financiamientosRequest = useCallback(() => dispatch(financiamientosActions.requestFinanciamientos(dataSend)), [dispatch, dataSend]);

    React.useEffect(() => {
        if (idClienteValid) {
            financiamientosRequest();
        }
    }, []);

    if (!R.isNil(datosFinanciamientos) && !inProgress) {
        datos = datosFinanciamientos.map((financiamiento: DatosFinanciamientos) =>
            <StyledTableRow
                key={financiamiento.idFinanciamientoSolicitado}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <StyledTableCell>
                    {getIconFinanciamiento(financiamiento.idTipoFinanciamiento)}
                    <Link key={financiamiento.idFinanciamientoSolicitado} to={getLinkFinanciamiento(
                        financiamiento.tipoFinanciamiento,
                        financiamiento.idForoCondiciones,
                        financiamiento.idFinanciamientoSolicitado)}
                        style={{ color: '#db5d18', fontSize: 18, textDecoration: 'none' }}>
                        {financiamiento.tipoFinanciamiento}</Link>
                    <Typography sx={{ ml: 5, mt: 1, fontSize: 14 }}><b>Clave de producto: </b>{financiamiento.claveProducto}</Typography>
                </StyledTableCell>
                <StyledTableCell>
                    <Typography sx={{ fontSize: 14, mb: 1 }}><b>Fecha de inicio: </b>{financiamiento.fechaInicio != null ? moment(financiamiento.fechaInicio).format('dddd LL') : ""}</Typography>
                    <Typography sx={{ fontSize: 14, mb: 1 }}><b>Línea de crédito: </b>{getNumberToCurrency(financiamiento.lineaCredito)}</Typography>
                    <Typography sx={{ fontSize: 14 }}><b>Línea de crédito disponible: </b>{getNumberToCurrency(financiamiento.lineaCreditoDisponible)}</Typography>
                </StyledTableCell>
                <StyledTableCell>
                    <Typography sx={{ fontSize: 14, mb: 1, color: '#db5d18' }}><b>Pago del mes</b></Typography>
                    <Typography sx={{ fontSize: 14, mb: 1 }}><b>Fecha de pago: </b>{financiamiento.fechaDePago != null ? moment(financiamiento.fechaDePago).format('dddd LL') : ""}</Typography>
                    <Typography sx={{ fontSize: 14 }}><b>Estatus: </b>{financiamiento.estatusPago}</Typography>
                </StyledTableCell>
            </StyledTableRow>)
    }
    if (inProgress) {
        datos = <TableLoader colums={3} text={'Buscando financiamientos...'} />;
    }
    if (datosFinanciamientos?.length == 0) {
        datos = <TableNotFoundData colums={3} text={'No se encontraron financiamientos.'} />;
    }
    if (!R.isNil(message) && message != '') {
        datos = <Alert severity="error">{message}</Alert>;
    }

    function TablaFinanciamientos() {
        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="customized table">
                    <TableBody>
                        {datos}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    if (!idClienteValid) {
        return (<BadParameters />);
    }

    return (
        <Box sx={{ m: 4 }}>
            <TablaFinanciamientos />
        </Box>
    );
}