import { take, put, call, fork, select, takeEvery, all } from 'redux-saga/effects';
import * as sagaController from '../../../../controllers/sagaController';
import * as types from '../../../../system/types';
import * as R from 'ramda';
import * as actions from './actions';
import * as rootActions from '../../../../system/actions';
import { AmortizacionesAPagar, GenerarReferenciaPago, OpcionesContratoAP, ResultadoOpcionContratoAP, SimularPago } from '../../../../api/Clients';
import { IResponse } from '../../../../models/IResponse';
import { AxiosResponse } from 'axios';
import { IAmortizacionesAPagarResponse } from '../../../../models/IAmortizacionesAPagarResponse';
import { IGRPResponse, ISimularPagoResponse } from '../../../../models/ISimularPagoResponse';
import { IOpcionesContratoAPResponse, IResultadoOpcionContratoAPResponse } from '../../../../models/IOpcionesContratoAPResponse';

//#region AMORTIZACIONES A PAGAR
export function* amortizacionesAPagarRequestAsync(action: types.requestAmortizacionesAPagarAction) {
    const response: IAmortizacionesAPagarResponse = yield call(AmortizacionesAPagar, action.data);
    // console.log(response);
    try {
        const {
            data,
            code,
            status,
            statusText,
            // headers,
            // config,
            // request,
        } = response;
        if (!R.isNil(data)) {
            yield sagaController.controlledStates(response, action.type, status);
        } else {
            yield sagaController.controlledStates(response, action.type, 400);
        }
    } catch (error) {
        console.log(error);
    }
}

export function* amortizacionesAPagarSaga() {
    yield all([
        takeEvery(types.AMORTIZACIONESAPAGAR_REQUEST, amortizacionesAPagarRequestAsync)
    ])
}
//#endregion

//#region OPCIONES CONTRATO
export function* opcionesContratoAPRequestAsync(action: types.requestOpcionesContratoAPAction) {
    const response: IOpcionesContratoAPResponse = yield call(OpcionesContratoAP, action.data);
    // console.log(response);
    try {
        const {
            data,
            code,
            status,
            statusText,
            // headers,
            // config,
            // request,
        } = response;
        if (!R.isNil(data)) {
            yield sagaController.controlledStates(response, action.type, status);
        } else {
            yield sagaController.controlledStates(response, action.type, 400);
        }
    } catch (error) {
        console.log(error);
    }
}

export function* opcionesContratoAPSaga() {
    yield all([
        takeEvery(types.OPCIONESCONTRATOAP_REQUEST, opcionesContratoAPRequestAsync)
    ])
}
//#endregion

//#region RESULTADO OPCION CONTRATO
export function* resultadoOpcionContratoAPRequestAsync(action: types.requestResultadoOpcionContratoAPAction) {
    const response: IResultadoOpcionContratoAPResponse = yield call(ResultadoOpcionContratoAP, action.data);
    // console.log(response);
    try {
        const {
            data,
            code,
            status,
            statusText,
            // headers,
            // config,
            // request,
        } = response;
        if (!R.isNil(data)) {
            yield sagaController.controlledStates(response, action.type, status);
        } else {
            yield sagaController.controlledStates(response, action.type, 400);
        }
    } catch (error) {
        console.log(error);
    }
}

export function* resultadoOpcionContratoAPSaga() {
    yield all([
        takeEvery(types.RESULTADOOPCIONCONTRATOAP_REQUEST, resultadoOpcionContratoAPRequestAsync)
    ])
}
//#endregion

//#region SIMULAR PAGO
export function* simularPagoRequestAsync(action: types.requestSimularPagoAction) {
    const response: ISimularPagoResponse = yield call(SimularPago, action.data);
    // console.log(response);
    try {
        const {
            data,
            code,
            status,
            statusText,
            // headers,
            // config,
            // request,
        } = response;
        if (!R.isNil(data)) {
            yield sagaController.controlledStates(response, action.type, status);
        } else {
            yield sagaController.controlledStates(response, action.type, 400);
        }
    } catch (error) {
        console.log(error);
    }
}

export function* simularPagoSaga() {
    yield all([
        takeEvery(types.SIMULARPAGO_REQUEST, simularPagoRequestAsync)
    ])
}
//#endregion

//#region GENERAR REFERENCIA DE PAGO
export function* generarReferenciaPagoRequestAsync(action: types.requestGenerarReferenciaPagoAction) {
    const response: IGRPResponse = yield call(GenerarReferenciaPago, action.data);
    // console.log(response);
    try {
        const {
            data,
            code,
            status,
            statusText,
            // headers,
            // config,
            // request,
        } = response;
        if (!R.isNil(data)) {
            yield sagaController.controlledStates(response, action.type, status);
        } else {
            yield sagaController.controlledStates(response, action.type, 400);
        }
    } catch (error) {
        console.log(error);
    }
}

export function* generarReferenciaPagoSaga() {
    yield all([
        takeEvery(types.GENERARREFERENCIAPAGO_REQUEST, generarReferenciaPagoRequestAsync)
    ])
}
//#endregion