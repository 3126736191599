import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, CircularProgress, Typography } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import * as resumenCuentaCorrienteActions from './redux/actions';
import { IResumenCuentaCorrienteRequest } from '../../../models/IResumenCuentaCorrienteRequest';
import * as R from 'ramda';
import { DatosResumenCuentaCorriente } from '../../../models/IResumenCuentaCorrienteResponse';
import { getIconBoolean, getNumberToCurrency } from '../../../utils';
import theme from '../../../theme';
import { TableLoader } from '../../loader/TableLoader';
import { TableNotFoundData } from '../../notFoundData/TableNotFoundData';
import moment from 'moment';
import 'moment/locale/es';
import { StyledTableCell, StyledTableRow } from '../../styledTables/StyledTables';
moment.locale('es');

export default function ResumenCuentaCorriente(dataSend: IResumenCuentaCorrienteRequest) {
    let datos = <TableLoader colums={6} text={'Buscando información de pago...'} />;

    const dispatch = useDispatch();
    const resumenCuentaCorrienteRequest = useCallback(() => dispatch(resumenCuentaCorrienteActions.requestResumenCuentaCorriente(dataSend)), [dispatch, dataSend]);
    React.useEffect(() => {
        resumenCuentaCorrienteRequest();
    }, []);

    const datosResumenCuentaCorriente: DatosResumenCuentaCorriente = useSelector((state: any) => state.resumenCuentaCorriente.data);
    const inProgress: any = useSelector((state: any) => state.resumenCuentaCorriente.inProgress);

    if (!R.isNil(datosResumenCuentaCorriente) && !inProgress) {
        datos = <StyledTableRow
            key={1}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <StyledTableCell>{getNumberToCurrency(datosResumenCuentaCorriente.saldoInsoluto)}</StyledTableCell>
            <StyledTableCell>{getNumberToCurrency(datosResumenCuentaCorriente.interesOrdinarioHoy)}</StyledTableCell>
            <StyledTableCell>{getNumberToCurrency(datosResumenCuentaCorriente.interesMoratorioHoy)}</StyledTableCell>
            <StyledTableCell>{datosResumenCuentaCorriente.proximaFechaVencimiento != null ? moment(datosResumenCuentaCorriente.proximaFechaVencimiento).format('dddd LL') : ""}</StyledTableCell>
            <StyledTableCell>{getNumberToCurrency(datosResumenCuentaCorriente.montoMinimoApagar)}</StyledTableCell>
            <StyledTableCell>{datosResumenCuentaCorriente.fechaTerminoCredito != null ? moment(datosResumenCuentaCorriente.fechaTerminoCredito).format('dddd LL') : ""}</StyledTableCell>
        </StyledTableRow>
    }
    if (inProgress) {
        datos = <TableLoader colums={6} text={'Buscando información de pago...'} />;
    }
    if (R.isNil(datosResumenCuentaCorriente) && !inProgress) {
        datos = <TableNotFoundData colums={6} text={'No se encontró información de pago.'} />;
    }

    function TablaResumenCuentaCorriente() {
        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 800 }} aria-label="customized table">
                    <TableBody>
                        <TableRow>
                            <TableCell><b>Saldo insoluto</b></TableCell>
                            <TableCell><b>Interés ordinario al día de hoy</b></TableCell>
                            <TableCell><b>Interés moratorio al día de hoy</b></TableCell>
                            <TableCell><b>Próxima fecha de vencimiento</b></TableCell>
                            <TableCell><b>Monto mínimo a pagar a la fecha de vencimiento</b></TableCell>
                            <TableCell><b>Fecha de término del crédito</b></TableCell>
                        </TableRow>
                        {datos}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    return (
        <><Typography variant="h6" color="#2E2D2E" sx={{ mb: 2, mt: 2 }}>Información de pago</Typography>
            <TablaResumenCuentaCorriente /></>
    );
}