import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import * as generarReferenciaPagoActions from './redux/actions';
import { IAmortizacionesAPagarRequest } from '../../../models/IAmortizacionesAPagarRequest';
import * as R from 'ramda';
import { DatosAmortizacionesAPagar } from '../../../models/IAmortizacionesAPagarResponse';
import { getNumberToCurrency, OpcionesContrato, TipoFinanciamiento } from '../../../utils';
import theme from '../../../theme';
import $ from 'jquery';
import { IGRPRequest, ISimularPagoRequest } from '../../../models/ISimularPagoRequest';
import { DatosGRP, DatosSimularPago } from '../../../models/ISimularPagoResponse';
import { TableLoader } from '../../loader/TableLoader';
import { TableNotFoundData } from '../../notFoundData/TableNotFoundData';
import moment from 'moment';
import 'moment/locale/es';
import { StyledTableCell, StyledTableRow } from '../../styledTables/StyledTables';
import { IOpcionesContratoAPRequest, IResultadoOpcionContratoAPRequest } from '../../../models/IOpcionesContratoAPRequest';
import { DatosOpcionesContratoAP } from '../../../models/IOpcionesContratoAPResponse';
moment.locale('es');

export default function GenerarReferenciaPago(dataSend: IAmortizacionesAPagarRequest) {
    const [isCuentaCorriente, setIsCuentaCorriente] = useState(false);
    const idCliente = useSelector((state: any) => state.login.id);

    let opcionesContratoAPSend: IOpcionesContratoAPRequest = {
        IdForoCondiciones: dataSend.IdForoCondiciones,
        IdTipoPersona: dataSend.IdTipoPersona
    }

    let resultadoOpcionesContratoAPSend: IResultadoOpcionContratoAPRequest = {
        IdCliente: idCliente,
        IdForoCondiciones: dataSend.IdForoCondiciones,
        IdOpcionContrato: ''
    }

    let simularPagoSend: ISimularPagoRequest = {
        MontoAPagar: 0,
        IdForoCondiciones: dataSend.IdForoCondiciones,
        IdTipoFinanciamiento: dataSend.IdTipoFinanciamiento,
        IdTipoPersona: dataSend.IdTipoPersona
    }

    let GRPSend: IGRPRequest = {
        MontoAPagar: 0,
        IdForoCondiciones: dataSend.IdForoCondiciones,
        IdCliente: idCliente,
        IdTipoFinanciamiento: dataSend.IdTipoFinanciamiento,
        IdTipoPersona: dataSend.IdTipoPersona
    }

    let totalesAmortizacionesAPagar: any = [];
    let datos = <TableLoader colums={8} text={'Buscando amortizaciones a pagar...'} />;

    const dispatch = useDispatch();
    const amortizacionesAPagarRequest = useCallback(() => dispatch(generarReferenciaPagoActions.requestAmortizacionesAPagar(dataSend)), [dispatch, dataSend]);
    const opcionesContratoAPRequest = useCallback(() => dispatch(generarReferenciaPagoActions.requestOpcionesContratoAP(opcionesContratoAPSend)), [dispatch, opcionesContratoAPSend]);
    const resultadoOpcionContratoAPRequest = useCallback(() => dispatch(generarReferenciaPagoActions.requestResultadoOpcionContratoAP(resultadoOpcionesContratoAPSend)), [dispatch, resultadoOpcionesContratoAPSend]);
    const simularPagoRequest = useCallback(() => dispatch(generarReferenciaPagoActions.requestSimularPago(simularPagoSend)), [dispatch, simularPagoSend]);
    const generarReferenciaPagoRequest = useCallback(() => dispatch(generarReferenciaPagoActions.requestGenerarReferenciaPago(GRPSend)), [dispatch, GRPSend]);

    const datosAmortizacionesAPagar = useSelector((state: any) => state.amortizacionesAPagar.data);
    const inProgress = useSelector((state: any) => state.amortizacionesAPagar.inProgress);
    const datosOpcionesContratoAP = useSelector((state: any) => state.opcionesContratoAP.data);
    const datosResultadoOpcionContratoAP = useSelector((state: any) => state.resultadoOpcionContratoAP.data);
    const inProgressOpcionesContratoAP = useSelector((state: any) => state.opcionesContratoAP.inProgress);
    const inProgressResultadoOpcionContratoAP = useSelector((state: any) => state.resultadoOpcionContratoAP.inProgress);
    const datosSimularPago: DatosSimularPago = useSelector((state: any) => state.simularPago.data);
    const inProgresSimularPago: any = useSelector((state: any) => state.simularPago.inProgress);
    const datosGRP: DatosGRP = useSelector((state: any) => state.generarReferenciaPago.data);
    const inProgressGRP: any = useSelector((state: any) => state.generarReferenciaPago.inProgress);

    let GRPmessage = <Typography variant='body1'>Generando número de referencia...</Typography>;

    const [monto, setMonto] = useState('');
    const [openConfirmReferenciaPago, setOpenConfirmReferenciaPago] = useState(false);
    const [openAlertGRP, setOpenAlertGRP] = useState(false);
    const [openResultGRP, setOpenResultGRP] = useState(false);
    const [checkedState, setCheckedState] = useState(new Array(0));

    useEffect(() => {
        if (dataSend.IdTipoFinanciamiento != TipoFinanciamiento['CuentaCorriente']) {
            setIsCuentaCorriente(false);
            if (dataSend.IdTipoFinanciamiento == TipoFinanciamiento['ArrendamientoPuro']) {
                opcionesContratoAPRequest();
            }
            else {
                amortizacionesAPagarRequest();
            }
        }
        else {
            setIsCuentaCorriente(true);
        }
    }, []);

    useEffect(() => {
        if (!R.isNil(datosAmortizacionesAPagar?.amortizacionesAP)) {
            setCheckedState(new Array(datosAmortizacionesAPagar?.amortizacionesAP.length).fill(false))
        }
        else if (!R.isNil(datosAmortizacionesAPagar?.amortizacionesCS)) {
            setCheckedState(new Array(datosAmortizacionesAPagar?.amortizacionesCS.length).fill(false))
        }
    }, [!R.isNil(datosAmortizacionesAPagar)]);

    let datosOCAP = <MenuItem value=''>Seleccione una opción del contrato</MenuItem>

    if (!R.isNil(datosOpcionesContratoAP) && !inProgressOpcionesContratoAP) {
        datosOCAP = datosOpcionesContratoAP.map((opcion: DatosOpcionesContratoAP) =>
            <MenuItem key={opcion.id} value={opcion.id}>{opcion.descripcion}</MenuItem>)
    }

    const getMontoAPagar = (checked: boolean, pago: number, index: number) => {
        if ($('#monto').val() == '') {
            $('#monto').val('0');
        }
        if (checked) {
            $('#monto').val((parseFloat($('#monto').val() + '') + pago).toString());
        }
        else {
            $('#monto').val((parseFloat($('#monto').val() + '') - pago).toString());
        }
        setMonto($('#monto').val() + '');
        handleOnChange(index);
    }

    const handleOnChange = (position: number) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedState(updatedCheckedState);
    }

    const generarReferenciaPago = () => {
        setOpenConfirmReferenciaPago(false)
        if ($('#monto').val() == null || $('#monto').val() == undefined || $('#monto').val() == '') {
            setOpenAlertGRP(true);
            setTimeout(() => {
                setOpenAlertGRP(false);
            }, 1000);
        }
        else {
            GRPSend.MontoAPagar = parseFloat($('#monto').val() + '');
            setOpenResultGRP(true);
            generarReferenciaPagoRequest();
        }
    };

    const simularPago = (event: any) => {
        event.preventDefault();
        simularPagoSend.MontoAPagar = parseFloat($('#monto').val() + '');
        simularPagoRequest();
    };

    function PopUpSimularPagoInProgress() {
        return (<Dialog
            open={inProgresSimularPago}
            fullWidth={true}
            maxWidth={'xs'}>
            <DialogContent sx={{ m: 2, textAlign: 'center' }} id="dialogContent">
                <DialogContentText>
                    Simulando...
                </DialogContentText>
            </DialogContent>
        </Dialog>);
    }

    function PopUpOpcionesContratoAPInProgress() {
        return (<Dialog
            open={inProgressOpcionesContratoAP}
            fullWidth={true}
            maxWidth={'xs'}>
            <DialogContent sx={{ m: 2, textAlign: 'center' }} id="dialogContent">
                <DialogContentText>
                    Cargando...
                </DialogContentText>
            </DialogContent>
        </Dialog>);
    }

    function PopUpConfirmReferenciaPago() {
        return (<Dialog
            open={openConfirmReferenciaPago}
            fullWidth={true}
            maxWidth={'sm'}>
            <DialogContent sx={{ mt: 2, textAlign: 'center' }} id="dialogContent">
                <DialogContentText>
                    ¿Está seguro de que desea generar el número de referencia?
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ m: 2 }} id="dialogActions">
                <Button
                    onClick={() => setOpenConfirmReferenciaPago(false)}>
                    Cancelar
                </Button>
                <Button variant="contained"
                    onClick={generarReferenciaPago}
                    sx={{ backgroundColor: '#16a0ff' }}>Si, generar</Button>
            </DialogActions>
        </Dialog>);
    }

    function PopUpAlertGRP() {
        return (<Dialog
            open={openAlertGRP}
            fullWidth={true}
            maxWidth={'xs'}>
            <DialogContent sx={{ m: 2, textAlign: 'center' }} id="dialogContent">
                <DialogContentText sx={{ color: 'error.main' }}>
                    Ingrese el monto a pagar.
                </DialogContentText>
            </DialogContent>
        </Dialog>);
    }

    function PopUpGRP() {
        return (<Dialog
            open={openResultGRP}
            fullWidth={true}
            maxWidth={'sm'}>
            <DialogContent sx={{ m: 2, textAlign: 'center' }} id="dialogContent">
                <DialogContentText>
                    {GRPmessage}
                </DialogContentText>
            </DialogContent>
        </Dialog>);
    }

    let datosSimulacion = <StyledTableRow
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}><StyledTableCell></StyledTableCell>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell></StyledTableCell></StyledTableRow>;

    if (!R.isNil(datosSimularPago) && !inProgresSimularPago) {
        datosSimulacion = (
            <StyledTableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <StyledTableCell>{datosSimularPago.parcialidades}</StyledTableCell>
                {dataSend.IdTipoFinanciamiento == TipoFinanciamiento['CreditoSimple'] &&
                    <><StyledTableCell>{getNumberToCurrency(datosSimularPago.capital)}</StyledTableCell>
                        <StyledTableCell>{getNumberToCurrency(datosSimularPago.interesOrdinario)}</StyledTableCell></>
                }
                {dataSend.IdTipoFinanciamiento == TipoFinanciamiento['ArrendamientoPuro'] &&
                    <StyledTableCell>{getNumberToCurrency((datosSimularPago.capital) + (datosSimularPago.interesOrdinario))}</StyledTableCell>
                }
                <StyledTableCell>{getNumberToCurrency(datosSimularPago.interesMoratorio)}</StyledTableCell>
                <StyledTableCell>{getNumberToCurrency(datosSimularPago.ivaDeIntereses_PersonaFisica)}</StyledTableCell>
            </StyledTableRow>
        );
    }

    if (!R.isNil(datosAmortizacionesAPagar) && !inProgress) {
        if (!R.isNil(datosAmortizacionesAPagar.amortizacionesAP)) {
            totalesAmortizacionesAPagar = datosAmortizacionesAPagar.amortizacionesAP;
            datos = datosAmortizacionesAPagar.amortizacionesAP.map((pago: DatosAmortizacionesAPagar, index: number) =>
                <StyledTableRow
                    key={pago.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <StyledTableCell align='center'>
                        <Checkbox
                            checked={checkedState[index]}
                            onChange={e => (getMontoAPagar(e.target.checked, pago.pagoTotal, index))} />
                    </StyledTableCell>
                    <StyledTableCell>{pago.numeroPago}</StyledTableCell>
                    <StyledTableCell>{pago.fechaPago != null ? moment(pago.fechaPago).format('dddd LL') : ""}</StyledTableCell>
                    <StyledTableCell>{getNumberToCurrency((pago.capitalApagar ?? 0) + (pago.interesApagar ?? 0))}</StyledTableCell>
                    <StyledTableCell>{getNumberToCurrency(pago.interesMoratorioApagar)}</StyledTableCell>
                    <StyledTableCell>{getNumberToCurrency(pago.ivaDeInteresesApagar)}</StyledTableCell>
                    <StyledTableCell>{getNumberToCurrency(pago.pagoTotal)}</StyledTableCell>
                </StyledTableRow>)
        }
        else if (!R.isNil(datosAmortizacionesAPagar.amortizacionesCS)) {
            totalesAmortizacionesAPagar = datosAmortizacionesAPagar.amortizacionesCS;
            datos = datosAmortizacionesAPagar.amortizacionesCS.map((pago: DatosAmortizacionesAPagar, index: number) =>
                <StyledTableRow
                    key={pago.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <StyledTableCell>
                        <Checkbox
                            checked={checkedState[index]}
                            onChange={e => (getMontoAPagar(e.target.checked, pago.pagoTotal, index))} />
                    </StyledTableCell>
                    <StyledTableCell>{pago.numeroPago}</StyledTableCell>
                    <StyledTableCell>{pago.fechaPago != null ? moment(pago.fechaPago).format('dddd LL') : ""}</StyledTableCell>
                    <StyledTableCell>{getNumberToCurrency(pago.capitalApagar)}</StyledTableCell>
                    <StyledTableCell>{getNumberToCurrency(pago.interesApagar)}</StyledTableCell>
                    <StyledTableCell>{getNumberToCurrency(pago.interesMoratorioApagar)}</StyledTableCell>
                    <StyledTableCell>{getNumberToCurrency(pago.ivaDeInteresesApagar)}</StyledTableCell>
                    <StyledTableCell>{getNumberToCurrency(pago.pagoTotal)}</StyledTableCell>
                </StyledTableRow>)
        }
    }
    if (inProgress) {
        totalesAmortizacionesAPagar = [];
        datos = <TableLoader colums={8} text={'Buscando amortizaciones a pagar...'} />;
    }
    if (datosAmortizacionesAPagar?.length == 0) {
        totalesAmortizacionesAPagar = [];
        datos = <TableNotFoundData colums={8} text={'No se encontraron amortizaciones a pagar.'} />;
    }

    if (!R.isNil(datosGRP) && !inProgressGRP) {
        GRPmessage = <Typography variant='body1' sx={{ color: datosGRP.generado ? 'success.main' : 'error.main' }}>{datosGRP.message}</Typography>
        setTimeout(() => {
            setOpenResultGRP(false);
        }, 5000);
    }

    const [opcionContrato, setOpcionContrato] = useState('');
    const [openPopUpMensajeOpcionContrato, setOpenPopUpMensajeOpcionContrato] = useState(false);
    const [mensajeOpcionContrato, setMensajeOpcionContrato] = useState('');
    const [mensajeConfirmacionOpcionContrato, setMensajeConfirmacionOpcionContrato] = useState('');

    function PopUpMensajeOpcionContratoAP() {
        return (<Dialog
            open={openPopUpMensajeOpcionContrato}
            fullWidth={true}
            maxWidth={'xs'}>
            <DialogContent sx={{ m: 2, textAlign: 'center' }} id="dialogContent">
                <DialogContentText>
                    {mensajeOpcionContrato}
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ mb: 2, mr: 2 }} id="dialogActions">
                <Button
                    onClick={() => (setOpcionContrato(''), setOpenPopUpMensajeOpcionContrato(false))}>
                    Cancelar
                </Button>
                <Button variant="contained"
                    onClick={handleResultadoOpcionesContratoAP}
                    sx={{ backgroundColor: '#16a0ff' }}>{mensajeConfirmacionOpcionContrato}</Button>
            </DialogActions>
        </Dialog>);
    }

    const handleOpcionesContratoAP = (value: string) => {
        setOpcionContrato(value);
        if (value = OpcionesContrato['LiquidarArrendamiento']) {
            setMensajeOpcionContrato('¿Está seguro que desea generar la referencia para la liquidación del arrendamiento?')
            setMensajeConfirmacionOpcionContrato('Si, generar')
        }
        setOpenPopUpMensajeOpcionContrato(true);
    }

    const handleResultadoOpcionesContratoAP = () => {
        resultadoOpcionesContratoAPSend = {
            IdCliente: idCliente,
            IdForoCondiciones: dataSend.IdForoCondiciones,
            IdOpcionContrato: opcionContrato
        }
        setOpenPopUpMensajeOpcionContrato(false);
        setOpenResultGRP(true);
        resultadoOpcionContratoAPRequest();
    }

    if (!R.isNil(datosResultadoOpcionContratoAP) && !inProgressResultadoOpcionContratoAP) {
        GRPmessage = <Typography variant='body1' sx={{ color: datosResultadoOpcionContratoAP.generado ? 'success.main' : 'error.main' }}>{datosResultadoOpcionContratoAP.message}</Typography>
        setTimeout(() => {
            setOpenResultGRP(false);
        }, 5000);
    }

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    function GenerarReferencia() {
        return (<>
            <Typography sx={{ fontSize: 14 }}>
                La <b>CLABE interbancaria</b> a la cual se realizará el pago es: <b>646180164010000003</b>,
                institución financiera: <b>STP</b>, favor de depósitar/transferir el monto a pagar exacto,
                de lo contrario el sistema devolverá el pago, y también <b>proporcionar el número de referencia</b> al
                realizar el pago ya que sin éste el sistema no sabrá el destino del depósito.
            </Typography>
            <Grid container sx={{ mt: 4, display: dataSend.IdTipoFinanciamiento == TipoFinanciamiento['ArrendamientoPuro'] ? 'block' : 'none' }}>
                <Grid item xs={12} sm={10}></Grid>
                <Grid item xs={12} sm={2}>
                    <FormControl fullWidth variant="standard">
                        <InputLabel id="labelIB">Opciones del contrato</InputLabel>
                        <Select
                            required
                            id="opcionesContrato"
                            label="Opciones del contrato"
                            value={opcionContrato}
                            onChange={(e) => handleOpcionesContratoAP(e.target.value)}>
                            {datosOCAP}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Typography sx={{ mt: 4, fontSize: 14 }}><b>Monto a pagar:</b></Typography>
            <Box component="form" onSubmit={(e) => simularPago(e)} sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={2} sm={2}>
                        <TextField
                            required
                            fullWidth
                            id="monto"
                            size='small'
                            autoFocus={true}
                            defaultValue={monto}
                            onChange={(e) => setMonto(e.target.value)}
                            autoComplete="off" />
                    </Grid>
                    <Grid item xs={2} sm={2}>
                        <TextField
                            required
                            disabled
                            type='date'
                            id="fecha"
                            size='small'
                            fullWidth
                            defaultValue={`${year}-${month < 10 ? `0${month}` : `${month}`}-${date < 10 ? `0${date}` : `${date}`}`} />
                    </Grid>
                    <Grid item xs={2} sm={2}>
                        <Button
                            disabled={inProgress}
                            variant="contained"
                            type="submit"
                            sx={{ backgroundColor: 'info.main' }}>Simular pago</Button>
                    </Grid>
                </Grid>
            </Box>
            <Grid container>
                <Grid item xs={8} sm={8}>
                    <TableContainer component={Paper} sx={{ mt: 2 }}>
                        <Table sx={{ minWidth: 650 }} aria-label="customized table">
                            <TableBody>
                                <TableRow>
                                    <TableCell><b>Parcialidad</b></TableCell>
                                    {dataSend.IdTipoFinanciamiento == TipoFinanciamiento['CreditoSimple'] &&
                                        <><TableCell><b>Capital</b></TableCell>
                                            <TableCell><b>Intereses</b></TableCell></>
                                    }
                                    {dataSend.IdTipoFinanciamiento == TipoFinanciamiento['ArrendamientoPuro'] &&
                                        <TableCell><b>Renta</b></TableCell>
                                    }
                                    <TableCell><b>Interés moratorio</b></TableCell>
                                    <TableCell><b>IVA</b></TableCell>
                                </TableRow>
                                {datosSimulacion}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <Grid style={{ display: isCuentaCorriente ? 'none' : 'block' }}>
                <Typography sx={{ mt: 4, fontSize: 14 }}><b>Amortizaciones a pagar:</b></Typography>
                <TableContainer component={Paper} sx={{ mt: 2 }}>
                    <Table sx={{ minWidth: 650 }} aria-label="customized table">
                        <TableBody>
                            <TableRow>
                                <TableCell><b>Seleccionar</b></TableCell>
                                <TableCell><b>Pago</b></TableCell>
                                <TableCell><b>Fecha</b></TableCell>
                                {dataSend.IdTipoFinanciamiento == TipoFinanciamiento['CreditoSimple'] &&
                                    <><TableCell><b>Capital</b></TableCell>
                                        <TableCell><b>Intereses</b></TableCell></>
                                }
                                {dataSend.IdTipoFinanciamiento == TipoFinanciamiento['ArrendamientoPuro'] &&
                                    <TableCell><b>Renta</b></TableCell>
                                }
                                <TableCell><b>Interés moratorio</b></TableCell>
                                <TableCell><b>IVA</b></TableCell>
                                <TableCell><b>Pago total</b></TableCell>
                            </TableRow>
                            <TableRow sx={{ backgroundColor: '#bae3ff' }}>
                                <TableCell><b>Totales:</b></TableCell>
                                <TableCell><b>{totalesAmortizacionesAPagar.reduce((a: number, b: DatosAmortizacionesAPagar) => a + 1, 0)}</b></TableCell>
                                <TableCell></TableCell>
                                {dataSend.IdTipoFinanciamiento == TipoFinanciamiento['CreditoSimple'] &&
                                    <><TableCell><b>{getNumberToCurrency(totalesAmortizacionesAPagar.reduce((a: number, b: DatosAmortizacionesAPagar) => a + b.capitalApagar, 0))}</b></TableCell>
                                        <TableCell><b>{getNumberToCurrency(totalesAmortizacionesAPagar.reduce((a: number, b: DatosAmortizacionesAPagar) => a + b.interesApagar, 0))}</b></TableCell></>
                                }
                                {dataSend.IdTipoFinanciamiento == TipoFinanciamiento['ArrendamientoPuro'] &&
                                    <TableCell><b>{getNumberToCurrency(totalesAmortizacionesAPagar.reduce((a: number, b: DatosAmortizacionesAPagar) => a + ((b.capitalApagar ?? 0) + (b.interesApagar ?? 0)), 0))}</b></TableCell>
                                }
                                <TableCell><b>{getNumberToCurrency(totalesAmortizacionesAPagar.reduce((a: number, b: DatosAmortizacionesAPagar) => a + b.interesMoratorioApagar, 0))}</b></TableCell>
                                <TableCell><b>{getNumberToCurrency(totalesAmortizacionesAPagar.reduce((a: number, b: DatosAmortizacionesAPagar) => a + b.ivaDeInteresesApagar, 0))}</b></TableCell>
                                <TableCell><b>{getNumberToCurrency(totalesAmortizacionesAPagar.reduce((a: number, b: DatosAmortizacionesAPagar) => a + b.pagoTotal, 0))}</b></TableCell>
                            </TableRow>
                            {datos}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid sx={{ mt: 4 }}>
                <Button
                    disabled={inProgress}
                    variant="contained"
                    sx={{ backgroundColor: 'info.main' }}
                    onClick={() => setOpenConfirmReferenciaPago(true)}>Generar número de referencia</Button>
            </Grid>
        </>);
    }

    return (
        <><Typography variant="h6" color="#2E2D2E" sx={{ mb: 2, mt: 2 }}>Generar referencia de pago</Typography>
            <GenerarReferencia />
            <PopUpSimularPagoInProgress />
            <PopUpConfirmReferenciaPago />
            <PopUpAlertGRP />
            <PopUpOpcionesContratoAPInProgress />
            <PopUpMensajeOpcionContratoAP />
            <PopUpGRP /></>
    );
}