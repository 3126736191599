import { DatosTipoPagoAP } from '../../../../models/catalogs/ITipoPagoAPResponse';
import * as type from '../../../../system/types';
import * as actions from '../../../../system/types';

export function requestTipoPagoAP(data: any): actions.requestTipoPagoAPAction {
    return {
        type: type.TIPOPAGOAP_REQUEST,
        inProgress: true,
        data
    };
}

export function onTipoPagoAPResponse(
    data: Array<DatosTipoPagoAP>,
    message: string
): actions.onTipoPagoAPResponseAction {
    return {
        type: type.TIPOPAGOAP_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onTipoPagoAPFailed(
    data: any,
    message: string
): actions.getTipoPagoAPFailedAction {
    return {
        type: type.TIPOPAGOAP_FAILED,
        message,
        inProgress: false
    };
}