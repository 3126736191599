import * as types from '../../../../system/types'

export interface IAuth {
    token: string | null,
    onLogin(token: string): VoidFunction,
    onLogout: VoidFunction
}

export interface LoginState {
    id: string | null,
    // userName: string,
    email: string | null,
    // jwToken: string,
    nombreUsuario: string | null,
    rfc: string | null,
    idTipoPersona: string | null,
    token: string | null,
    // errors: [],
    inProgress: boolean,
    data?: boolean | null,
    isLoggedIn: boolean,
    // isVerified: boolean,
    message?: string | null,
    isSigned: boolean
}

const LoginInitialState: LoginState = {
    id: '',
    // userName: '',
    email: '',
    // jwToken: '',
    nombreUsuario: '',
    rfc: '',
    idTipoPersona: '',
    token: '',
    // errors: [],
    data: null,
    inProgress: false,
    isLoggedIn: false,
    // isVerified: false
    isSigned: false
};

export default function loginReducer(
    state: LoginState = LoginInitialState,
    action: types.RootAction
): LoginState {
    switch (action.type) {
        case types.LOGIN_REQUEST:
            return {
                ...state,
                message: '',
                inProgress: true
            }
        case types.LOGIN_RESPONSE:
            return {
                ...state,
                data: action.data,
                message: action.message,
                inProgress: false
            }
        case types.LOGOUT_REQUEST:
            return {
                ...state,
                message: '',
                inProgress: true
            }
        case types.LOGOUT_RESPONSE:
            return {
                ...state,
                message: 'Redireccionando...',
                id: null,
                email: null,
                nombreUsuario: null,
                rfc: null,
                idTipoPersona: null,
                isSigned: false,
                isLoggedIn: false,
                inProgress: false
            }
        case types.LOGIN_BY_TOKEN_REQUEST:
            return {
                ...state,
                message: 'Autenticando...',
                token: action.token,
                inProgress: true
            }
        case types.LOGIN_BY_TOKEN_RESPONSE:
            return {
                ...state,
                message: 'Redireccionando...',
                id: action.uid,
                email: action.email,
                nombreUsuario: action.nombreUsuario,
                rfc: action.rfc,
                idTipoPersona: action.idTipoPersona,
                isSigned: action.isSigned,
                isLoggedIn: action.isSigned,
                inProgress: false
            }
        case types.LOGIN_FAILED:
            return {
                ...state,
                message: action.message,
                inProgress: false
            }
        default:
            return state;
    }
}