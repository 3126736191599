import { take, put, call, fork, select, takeEvery, all } from 'redux-saga/effects';
import * as sagaController from '../../../controllers/sagaController';
import * as types from '../../../system/types';
import * as R from 'ramda';
import * as actions from './actions';
import * as rootActions from '../../../system/actions';
import { CuentaDepositoCliente, PostCuentaDepositoCliente } from '../../../api/Clients';
import { IResponse } from '../../../models/IResponse';
import { AxiosResponse } from 'axios';
import { ICuentaDepositoResponse, IPostCuentaDepositoResponse } from '../../../models/ICuentaDepositoResponse';

//#region GET
export function* cuentaDepositoRequestAsync(action: types.requestCuentaDepositoAction) {
    const response: ICuentaDepositoResponse = yield call(CuentaDepositoCliente, action.data);
    // console.log(response);
    try {
        const {
            data,
            code,
            status,
            statusText,
            // headers,
            // config,
            // request,
        } = response;
        if (!R.isNil(data)) {
            yield sagaController.controlledStates(response, action.type, status);
        } else {
            yield sagaController.controlledStates(response, action.type, 400);
        }
    } catch (error) {
        console.log(error);
    }
}

export function* cuentaDepositoSaga() {
    yield all([
        takeEvery(types.CUENTADEPOSITO_REQUEST, cuentaDepositoRequestAsync)
    ])
}
//#endregion

//#region POST
export function* postCuentaDepositoRequestAsync(action: types.requestPostCuentaDepositoAction) {
    const response: IPostCuentaDepositoResponse = yield call(PostCuentaDepositoCliente, action.data);
    // console.log(response);
    try {
        const {
            data,
            code,
            status,
            statusText,
            // headers,
            // config,
            // request,
        } = response;
        if (!R.isNil(data)) {
            yield sagaController.controlledStates(response, action.type, status);
        } else {
            yield sagaController.controlledStates(response, action.type, 400);
        }
    } catch (error) {
        console.log(error);
    }
}

export function* postCuentaDepositoSaga() {
    yield all([
        takeEvery(types.POSTCUENTADEPOSITO_REQUEST, postCuentaDepositoRequestAsync)
    ])
}
//#endregion