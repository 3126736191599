import { DatosTipoActivo } from '../../../../models/catalogs/ITipoActivoResponse';
import * as type from '../../../../system/types';
import * as actions from '../../../../system/types';

export function requestTipoActivo(data: any): actions.requestTipoActivoAction {
    return {
        type: type.TIPOACTIVO_REQUEST,
        inProgress: true,
        data
    };
}

export function onTipoActivoResponse(
    data: Array<DatosTipoActivo>,
    message: string
): actions.onTipoActivoResponseAction {
    return {
        type: type.TIPOACTIVO_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onTipoActivoFailed(
    data: any,
    message: string
): actions.getTipoActivoFailedAction {
    return {
        type: type.TIPOACTIVO_FAILED,
        message,
        inProgress: false
    };
}