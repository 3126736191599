import { DatosPeriodicidad } from '../../../../models/catalogs/IPeriodicidadResponse';
import * as type from '../../../../system/types';
import * as actions from '../../../../system/types';

export function requestPeriodicidad(data: any): actions.requestPeriodicidadAction {
    return {
        type: type.PERIODICIDAD_REQUEST,
        inProgress: true,
        data
    };
}

export function onPeriodicidadResponse(
    data: Array<DatosPeriodicidad>,
    message: string
): actions.onPeriodicidadResponseAction {
    return {
        type: type.PERIODICIDAD_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onPeriodicidadFailed(
    data: any,
    message: string
): actions.getPeriodicidadFailedAction {
    return {
        type: type.PERIODICIDAD_FAILED,
        message,
        inProgress: false
    };
}