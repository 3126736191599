import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Tooltip, Typography } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useState } from 'react';
import * as amortizacionesActions from './redux/actions';
import { IAmortizacionesRequest } from '../../../models/IAmortizacionesRequest';
import * as R from 'ramda';
import { DatosAmortizaciones } from '../../../models/IAmortizacionesResponse';
import { getIconBoolean, getNumberToCurrency, TipoFinanciamiento } from '../../../utils';
import theme from '../../../theme';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { IExportarArchivoResponse } from '../../../models/IExportarArchivoResponse';
import { IExportarArchivoAmortizacionesRequest } from '../../../models/IExportarArchivoRequest';
import { TableLoader } from '../../loader/TableLoader';
import { TableNotFoundData } from '../../notFoundData/TableNotFoundData';
import moment from 'moment';
import 'moment/locale/es';
import { StyledTableCell, StyledTableRow } from '../../styledTables/StyledTables';
moment.locale('es');

export default function Amortizaciones(dataSend: IAmortizacionesRequest) {
    let inProgress = false;
    let datosAmortizaciones: any;
    let totalesAmortizaciones: any = [];
    let numRegistros: Array<any> = [];
    let datos = <TableLoader colums={9} text={'Buscando amortizaciones...'} />;

    inProgress = useSelector((state: any) => state.amortizaciones.inProgress);
    datosAmortizaciones = useSelector((state: any) => state.amortizaciones.data);

    let exportarArchivoSend: IExportarArchivoAmortizacionesRequest = {
        TipoExportacion: '',
        IdForoCondiciones: '',
        IdCliente: '',
        IdDisposicion: '',
        IdTipoFinanciamiento: '',
        IdTipoPersona: ''
    }

    const datosArchivo: IExportarArchivoResponse = useSelector((state: any) => state.exportarArchivoAmortizaciones);
    const inProgressArchivo: any = useSelector((state: any) => state.exportarArchivoAmortizaciones.inProgress);
    const IdCliente: any = useSelector((state: any) => state.login.id);

    const dispatch = useDispatch();
    const amortizacionesRequest = useCallback(() => dispatch(amortizacionesActions.requestAmortizaciones(dataSend)), [dispatch, dataSend]);
    const archivoAmortizacionesRequest = useCallback(() => dispatch(amortizacionesActions.requestExportarArchivoAmortizaciones(exportarArchivoSend)), [dispatch, exportarArchivoSend]);

    const [openGeneracionArchivo, setOpenGeneracionArchivo] = React.useState(false);

    const exportarArchivoAmortizaciones = (tipoExportacion: string) => {
        let idDisposicion = '';
        if (!R.isNil(datosAmortizaciones.amortizacionesAP)) {
            idDisposicion = datosAmortizaciones.amortizacionesAP[0].idArrendamientoPuroDisposicion;
        }
        else if (!R.isNil(datosAmortizaciones.amortizacionesCS)) {
            idDisposicion = datosAmortizaciones.amortizacionesCS[0].idCreditoSimpleDisposicion;
        }

        exportarArchivoSend = {
            TipoExportacion: tipoExportacion,
            IdForoCondiciones: dataSend.IdForoCondiciones,
            IdCliente: IdCliente,
            IdDisposicion: idDisposicion,
            IdTipoFinanciamiento: dataSend.IdTipoFinanciamiento,
            IdTipoPersona: dataSend.IdTipoPersona
        }
        setOpenGeneracionArchivo(true)
        archivoAmortizacionesRequest();
    }

    React.useEffect(() => {
        amortizacionesRequest();
    }, []);

    // ARRENDAMIENTO PURO
    if (!R.isNil(datosAmortizaciones) && !inProgress) {
        if (!R.isNil(datosAmortizaciones.amortizacionesAP)) {
            numRegistros = datosAmortizaciones.amortizacionesAP;
            totalesAmortizaciones = datosAmortizaciones.amortizacionesAP;
            datos = datosAmortizaciones.amortizacionesAP.map((amortizacion: DatosAmortizaciones) =>
                <StyledTableRow
                    key={amortizacion.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <StyledTableCell>{amortizacion.numeroPago}</StyledTableCell>
                    <StyledTableCell>{amortizacion.fechaPago != null ? moment(amortizacion.fechaPago).format('dddd LL') : ""}</StyledTableCell>
                    <StyledTableCell>{getNumberToCurrency((amortizacion.capitalApagar ?? 0) + (amortizacion.interesApagar ?? 0))}</StyledTableCell>
                    <StyledTableCell>{getNumberToCurrency(amortizacion.interesMoratorioApagar)}</StyledTableCell>
                    <StyledTableCell>{getNumberToCurrency(amortizacion.ivaDeInteresesApagar)}</StyledTableCell>
                    <StyledTableCell>{getNumberToCurrency(amortizacion.pagoTotal)}</StyledTableCell>
                    <StyledTableCell>{getIconBoolean(amortizacion.pagado)}</StyledTableCell>
                </StyledTableRow>)
        }
        // CREDITO SIMPLE
        else if (!R.isNil(datosAmortizaciones.amortizacionesCS)) {
            numRegistros = datosAmortizaciones.amortizacionesCS;
            totalesAmortizaciones = datosAmortizaciones.amortizacionesCS;
            datos = datosAmortizaciones.amortizacionesCS.map((amortizacion: DatosAmortizaciones) =>
                <StyledTableRow
                    key={amortizacion.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <StyledTableCell>{amortizacion.numeroPago}</StyledTableCell>
                    <StyledTableCell>{amortizacion.fechaPago != null ? moment(amortizacion.fechaPago).format('dddd LL') : ""}</StyledTableCell>
                    <StyledTableCell>{getNumberToCurrency(amortizacion.capitalApagar)}</StyledTableCell>
                    <StyledTableCell>{getNumberToCurrency(amortizacion.interesApagar)}</StyledTableCell>
                    <StyledTableCell>{getNumberToCurrency(amortizacion.interesMoratorioApagar)}</StyledTableCell>
                    <StyledTableCell>{getNumberToCurrency(amortizacion.ivaDeInteresesApagar)}</StyledTableCell>
                    <StyledTableCell>{getNumberToCurrency(amortizacion.pagoTotal)}</StyledTableCell>
                    <StyledTableCell>{getNumberToCurrency(amortizacion.capitalPendiente)}</StyledTableCell>
                    <StyledTableCell>{getIconBoolean(amortizacion.pagado)}</StyledTableCell>
                </StyledTableRow>)
        }
    }
    if (inProgress) {
        totalesAmortizaciones = [];
        numRegistros = [];
        datos = <TableLoader colums={9} text={'Buscando amortizaciones...'} />;
    }
    if (datosAmortizaciones?.length == 0) {
        datos = <TableNotFoundData colums={9} text={'No se encontraron amortizaciones.'} />;
    }

    function TablaAmortizaciones() {
        const changeFilterPagados = (event: SelectChangeEvent) => {
            setFilterPagados(event.target.value);
            if (event.target.value == '0') {
                // ARRENDAMIENTO PURO
                if (!R.isNil(datosAmortizaciones.amortizacionesAP)) {
                    totalesAmortizaciones = datosAmortizaciones.amortizacionesAP;
                    datos = datosAmortizaciones.amortizacionesAP.map((amortizacion: DatosAmortizaciones) =>
                        <StyledTableRow
                            key={amortizacion.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <StyledTableCell>{amortizacion.numeroPago}</StyledTableCell>
                            <StyledTableCell>{amortizacion.fechaPago != null ? moment(amortizacion.fechaPago).format('dddd LL') : ""}</StyledTableCell>
                            <StyledTableCell>{getNumberToCurrency((amortizacion.capitalApagar ?? 0) + (amortizacion.interesApagar ?? 0))}</StyledTableCell>
                            <StyledTableCell>{getNumberToCurrency(amortizacion.interesMoratorioApagar)}</StyledTableCell>
                            <StyledTableCell>{getNumberToCurrency(amortizacion.ivaDeInteresesApagar)}</StyledTableCell>
                            <StyledTableCell>{getNumberToCurrency(amortizacion.pagoTotal)}</StyledTableCell>
                            <StyledTableCell>{getIconBoolean(amortizacion.pagado)}</StyledTableCell>
                        </StyledTableRow >)
                }
                // CREDITO SIMPLE
                else if (!R.isNil(datosAmortizaciones.amortizacionesCS)) {
                    totalesAmortizaciones = datosAmortizaciones.amortizacionesCS;
                    datos = datosAmortizaciones.amortizacionesCS.map((amortizacion: DatosAmortizaciones) =>
                        <StyledTableRow
                            key={amortizacion.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <StyledTableCell>{amortizacion.numeroPago}</StyledTableCell>
                            <StyledTableCell>{amortizacion.fechaPago != null ? moment(amortizacion.fechaPago).format('dddd LL') : ""}</StyledTableCell>
                            <StyledTableCell>{getNumberToCurrency(amortizacion.capitalApagar)}</StyledTableCell>
                            <StyledTableCell>{getNumberToCurrency(amortizacion.interesApagar)}</StyledTableCell>
                            <StyledTableCell>{getNumberToCurrency(amortizacion.interesMoratorioApagar)}</StyledTableCell>
                            <StyledTableCell>{getNumberToCurrency(amortizacion.ivaDeInteresesApagar)}</StyledTableCell>
                            <StyledTableCell>{getNumberToCurrency(amortizacion.pagoTotal)}</StyledTableCell>
                            <StyledTableCell>{getNumberToCurrency(amortizacion.capitalPendiente)}</StyledTableCell>
                            <StyledTableCell>{getIconBoolean(amortizacion.pagado)}</StyledTableCell>
                        </StyledTableRow>)
                }
            }
            if (event.target.value == '1') {
                // ARRENDAMIENTO PURO
                if (!R.isNil(datosAmortizaciones.amortizacionesAP)) {
                    totalesAmortizaciones = datosAmortizaciones.amortizacionesAP.filter((e: DatosAmortizaciones) => e.pagado == true);
                    datos = datosAmortizaciones.amortizacionesAP.filter((e: DatosAmortizaciones) => e.pagado == true).map((amortizacion: DatosAmortizaciones) =>
                        <StyledTableRow
                            key={amortizacion.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <StyledTableCell>{amortizacion.numeroPago}</StyledTableCell>
                            <StyledTableCell>{amortizacion.fechaPago != null ? moment(amortizacion.fechaPago).format('dddd LL') : ""}</StyledTableCell>
                            <StyledTableCell>{getNumberToCurrency((amortizacion.capitalApagar ?? 0) + (amortizacion.interesApagar ?? 0))}</StyledTableCell>
                            <StyledTableCell>{getNumberToCurrency(amortizacion.interesMoratorioApagar)}</StyledTableCell>
                            <StyledTableCell>{getNumberToCurrency(amortizacion.ivaDeInteresesApagar)}</StyledTableCell>
                            <StyledTableCell>{getNumberToCurrency(amortizacion.pagoTotal)}</StyledTableCell>
                            <StyledTableCell>{getIconBoolean(amortizacion.pagado)}</StyledTableCell>
                        </StyledTableRow>)
                }
                // CREDITO SIMPLE
                else if (!R.isNil(datosAmortizaciones.amortizacionesCS)) {
                    totalesAmortizaciones = datosAmortizaciones.amortizacionesCS.filter((e: DatosAmortizaciones) => e.pagado == true);
                    datos = datosAmortizaciones.amortizacionesCS.filter((e: DatosAmortizaciones) => e.pagado == true).map((amortizacion: DatosAmortizaciones) =>
                        <StyledTableRow
                            key={amortizacion.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <StyledTableCell>{amortizacion.numeroPago}</StyledTableCell>
                            <StyledTableCell>{amortizacion.fechaPago != null ? moment(amortizacion.fechaPago).format('dddd LL') : ""}</StyledTableCell>
                            <StyledTableCell>{getNumberToCurrency(amortizacion.capitalApagar)}</StyledTableCell>
                            <StyledTableCell>{getNumberToCurrency(amortizacion.interesApagar)}</StyledTableCell>
                            <StyledTableCell>{getNumberToCurrency(amortizacion.interesMoratorioApagar)}</StyledTableCell>
                            <StyledTableCell>{getNumberToCurrency(amortizacion.ivaDeInteresesApagar)}</StyledTableCell>
                            <StyledTableCell>{getNumberToCurrency(amortizacion.pagoTotal)}</StyledTableCell>
                            <StyledTableCell>{getNumberToCurrency(amortizacion.capitalPendiente)}</StyledTableCell>
                            <StyledTableCell>{getIconBoolean(amortizacion.pagado)}</StyledTableCell>
                        </StyledTableRow>)
                }
            }
        };

        let mensajeGeneracionArchivo = <Typography variant='body1'>Generando...</Typography>
        const [filterPagados, setFilterPagados] = useState('');

        if (!R.isNil(datosArchivo) && !inProgressArchivo) {
            mensajeGeneracionArchivo =
                <Typography sx={{ color: datosArchivo.succeeded ? 'success.main' : 'error.main' }}>{datosArchivo.message}</Typography>
            setTimeout(() => {
                setOpenGeneracionArchivo(false);
            }, 3000);
        }

        return (
            <><Box sx={{ mb: 2, float: 'right' }}>
                <Tooltip arrow title={numRegistros.length > 0 ? '' : 'No se podrá exportar la tabla si no hay amortizaciones.'}>
                    <Button sx={{ ml: 2, color: numRegistros.length > 0 ? '#16a0ff' : 'error.main' }}
                        disabled={inProgress}
                        onClick={() => exportarArchivoAmortizaciones('XLS')}>
                        <FileDownloadOutlinedIcon sx={{ mr: 1 }} />Exportar excel</Button>
                </Tooltip>
                <Tooltip arrow title={numRegistros.length > 0 ? '' : 'No se podrá exportar la tabla si no hay amortizaciones.'}>
                    <Button sx={{ ml: 2, color: numRegistros.length > 0 ? '#16a0ff' : 'error.main' }}
                        disabled={inProgress}
                        onClick={() => exportarArchivoAmortizaciones('PDF')}>
                        <FileDownloadOutlinedIcon sx={{ mr: 1 }} />Exportar PDF</Button>
                </Tooltip>
            </Box>
                <Dialog
                    key={'archivoExportado'}
                    open={openGeneracionArchivo}
                    fullWidth={true}
                    maxWidth={'xs'}>
                    <DialogContent sx={{ m: 2, textAlign: 'center' }} id="dialogContent">
                        <DialogContentText>
                            {mensajeGeneracionArchivo}
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 800 }} aria-label="customized table">
                        <TableBody>
                            <TableRow>
                                <TableCell><b>Pago</b></TableCell>
                                <TableCell><b>Fecha</b></TableCell>
                                {dataSend.IdTipoFinanciamiento == TipoFinanciamiento['CreditoSimple'] &&
                                    <><TableCell><b>Capital</b></TableCell>
                                        <TableCell><b>Intereses</b></TableCell></>
                                }
                                {dataSend.IdTipoFinanciamiento == TipoFinanciamiento['ArrendamientoPuro'] &&
                                    <TableCell><b>Renta</b></TableCell>
                                }
                                <TableCell><b>Interés moratorio</b></TableCell>
                                <TableCell><b>IVA</b></TableCell>
                                <TableCell><b>Pago total</b></TableCell>
                                {dataSend.IdTipoFinanciamiento == TipoFinanciamiento['CreditoSimple'] &&
                                    <TableCell><b>Capital pendiente</b></TableCell>
                                }
                                <TableCell width='10%'>
                                    <FormControl fullWidth variant="standard" sx={{ mt: -2 }}>
                                        <InputLabel>Estatus</InputLabel>
                                        <Select
                                            id="filterPagados"
                                            label="Estatus"
                                            value={filterPagados}
                                            onChange={changeFilterPagados}>
                                            <MenuItem value={'0'}>Todos</MenuItem>
                                            <MenuItem value={'1'}>Pagados</MenuItem>
                                        </Select>
                                    </FormControl>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ backgroundColor: '#bae3ff' }}>
                                <TableCell><b>Totales:</b></TableCell>
                                <TableCell></TableCell>
                                {dataSend.IdTipoFinanciamiento == TipoFinanciamiento['CreditoSimple'] &&
                                    <><TableCell><b>{getNumberToCurrency(totalesAmortizaciones.reduce((a: number, b: DatosAmortizaciones) => a + b.capitalApagar, 0))}</b></TableCell>
                                        <TableCell><b>{getNumberToCurrency(totalesAmortizaciones.reduce((a: number, b: DatosAmortizaciones) => a + (b.interesApagar ?? 0), 0))}</b></TableCell></>
                                }
                                {dataSend.IdTipoFinanciamiento == TipoFinanciamiento['ArrendamientoPuro'] &&
                                    <TableCell><b>{getNumberToCurrency(totalesAmortizaciones.reduce((a: number, b: DatosAmortizaciones) => a + ((b.capitalApagar ?? 0) + (b.interesApagar ?? 0)), 0))}</b></TableCell>
                                }
                                <TableCell><b>{getNumberToCurrency(totalesAmortizaciones.reduce((a: number, b: DatosAmortizaciones) => a + (b.interesMoratorioApagar ?? 0), 0))}</b></TableCell>
                                <TableCell><b>{getNumberToCurrency(totalesAmortizaciones.reduce((a: number, b: DatosAmortizaciones) => a + (b.ivaDeInteresesApagar ?? 0), 0))}</b></TableCell>
                                <TableCell><b>{getNumberToCurrency(totalesAmortizaciones.reduce((a: number, b: DatosAmortizaciones) => a + (b.pagoTotal ?? 0), 0))}</b></TableCell>
                                <TableCell></TableCell>
                                {dataSend.IdTipoFinanciamiento == TipoFinanciamiento['CreditoSimple'] &&
                                    <TableCell></TableCell>
                                }
                            </TableRow>
                            {datos}
                        </TableBody>
                    </Table>
                </TableContainer></>
        );
    }

    return (
        <><Typography variant="h6" color="#2E2D2E" sx={{ mt: 2 }}>Tabla de amortización</Typography>
            <TablaAmortizaciones /></>
    );
}