import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { styled, ThemeProvider } from '@mui/material/styles';
import theme from '../../theme';
import Header from '../../components/common/AppBar';
import Paper from '@mui/material/Paper';
import { ButtonBase, LinearProgress, Stack } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { Link } from 'react-router-dom';
import { PATH_SECURE } from '../../system/selector';
import { useSelector } from 'react-redux';
import { env } from '../../api/env';
import { Fragment } from 'react';

export default function Redirect() {
    setTimeout(() => {
        window.location.replace(env.URL_PCHSF);
    }, 100);

    return (
        <Fragment>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '100vh' }}>
                <Grid item xs={5}>
                    <Paper elevation={24} sx={{ p: 2 }} >
                        <Box sx={{ color: 'text.secondary' }}>Redireccionando</Box>
                        <Box sx={{ width: '100%', pt: 2 }}>
                            <LinearProgress color='info' />
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Fragment>
    );
}