import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, Tooltip, Typography } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import * as activosArrendamientoActions from './redux/actions';
import { IActivosArrendamientoRequest } from '../../../models/IActivosArrendamientoRequest';
import * as R from 'ramda';
import { DatosActivosArrendamiento } from '../../../models/IActivosArrendamientoResponse';
import { getIconBoolean, getNumberToCurrency } from '../../../utils';
import theme from '../../../theme';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { IAmortizacionesActivoRequest } from '../../../models/IAmortizacionesActivoRequest';
import { DatosAmortizacionesActivo } from '../../../models/IAmortizacionesActivoResponse';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { IExportarArchivoActivosRequest } from '../../../models/IExportarArchivoRequest';
import { IExportarArchivoResponse } from '../../../models/IExportarArchivoResponse';
import { TableLoader } from '../../loader/TableLoader';
import { TableNotFoundData } from '../../notFoundData/TableNotFoundData';
import { StyledTableCell, StyledTableRow } from '../../styledTables/StyledTables';

export default function ActivosArrendamiento(dataSend: IActivosArrendamientoRequest) {
    let inProgressAmortizacionesActivo = false;
    let numRegistros: Array<any> = [];

    let datos = <TableLoader colums={8} text={'Buscando activos...'} />;

    let datosAA = <TableLoader colums={7} text={'Buscando amortizaciones...'} />;

    let dataSendAA: IAmortizacionesActivoRequest = {
        IdCliente: '',
        IdActivoArrendamiento: '',
        IdArrendamientoPuroDisposicion: ''
    };

    let exportarArchivoSend: IExportarArchivoActivosRequest = {
        TipoExportacion: '',
        IdFinanciamientoSolicitado: '',
        IdCliente: '',
    }

    const datosArchivo: IExportarArchivoResponse = useSelector((state: any) => state.exportarArchivoActivos);
    const inProgressArchivo: any = useSelector((state: any) => state.exportarArchivoActivos.inProgress);
    const [openGeneracionArchivo, setOpenGeneracionArchivo] = React.useState(false);
    const IdCliente: any = useSelector((state: any) => state.login.id);
    let totalesAmortizacionesActivo: any = [];

    const dispatch = useDispatch();
    const activosArrendamientoRequest = useCallback(() => dispatch(activosArrendamientoActions.requestActivosArrendamiento(dataSend)), [dispatch, dataSend]);
    const amortizacionesActivoRequest = useCallback(() => dispatch(activosArrendamientoActions.requestAmortizacionesActivo(dataSendAA)), [dispatch, dataSendAA]);
    const archivoActivosRequest = useCallback(() => dispatch(activosArrendamientoActions.requestExportarArchivoActivos(exportarArchivoSend)), [dispatch, exportarArchivoSend]);

    const exportarArchivoActivos = (tipoExportacion: string) => {
        exportarArchivoSend = {
            TipoExportacion: tipoExportacion,
            IdFinanciamientoSolicitado: dataSend.IdFinanciamientoSolicitado,
            IdCliente: IdCliente
        }
        setOpenGeneracionArchivo(true)
        archivoActivosRequest();
    }

    React.useEffect(() => {
        activosArrendamientoRequest();
    }, []);

    const idCliente = useSelector((state: any) => state.login.id);
    const getAmortizacionesActivo = (idActivoArrendamiento: string, IdArrendamientoPuroDisposicion: string) => {
        dataSendAA = {
            IdCliente: idCliente,
            IdActivoArrendamiento: idActivoArrendamiento,
            IdArrendamientoPuroDisposicion: IdArrendamientoPuroDisposicion
        };
        amortizacionesActivoRequest();
    }

    let mensajeGeneracionArchivo = <Typography variant='body1'>Generando...</Typography>
    const [openAmortizacionesActivo, setOpenAmortizacionesActivo] = React.useState(false);

    if (!R.isNil(datosArchivo) && !inProgressArchivo) {
        mensajeGeneracionArchivo =
            <Typography sx={{ color: datosArchivo.succeeded ? 'success.main' : 'error.main' }}>{datosArchivo.message}</Typography>
        setTimeout(() => {
            setOpenGeneracionArchivo(false);
        }, 3000);
    }

    const datosActivosArrendamiento: any = useSelector((state: any) => state.activosArrendamiento.data);
    const inProgressActivosArrendamiento: any = useSelector((state: any) => state.activosArrendamiento.inProgress);
    const datosAmortizacionesActivo: any = useSelector((state: any) => state.amortizacionesActivo.data);
    inProgressAmortizacionesActivo = useSelector((state: any) => state.amortizacionesActivo.inProgress);

    if (!R.isNil(datosActivosArrendamiento) && !inProgressActivosArrendamiento) {
        numRegistros = datosActivosArrendamiento;
        datos = datosActivosArrendamiento.map((activo: DatosActivosArrendamiento) =>
            <StyledTableRow
                key={activo.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <StyledTableCell>
                    <Typography variant="body2" sx={{ mb: 2 }}><b>{activo.tipoActivo}</b></Typography>
                    <Typography variant="body2" sx={{ mb: 2 }}><b>Descripción: </b>{activo.descripcion}</Typography>
                    <Typography variant="body2" sx={{ mb: 2 }}><b>Marca: </b>{activo.marca}</Typography>
                    <Typography variant="body2"><b>Modelo: </b>{activo.modelo}</Typography>
                </StyledTableCell>
                <StyledTableCell>{getNumberToCurrency(activo.precioReferencia)}</StyledTableCell>
                <StyledTableCell>{getNumberToCurrency(activo.montoAfinanciar)}</StyledTableCell>
                <StyledTableCell>{getNumberToCurrency(activo.montoAdispersar)}</StyledTableCell>
                <StyledTableCell>{getNumberToCurrency(activo.valorResidual)} ({activo.porcentajeValorResidual}%)</StyledTableCell>
                <StyledTableCell>{getNumberToCurrency(activo.enganche)}</StyledTableCell>
                <StyledTableCell>{getNumberToCurrency(activo.capitalAamortizar)}</StyledTableCell>
                <StyledTableCell>
                    <Button color='info'
                        onClick={() => (setOpenAmortizacionesActivo(true), getAmortizacionesActivo(activo.id, activo.idArrendamientoPuroDisposicion))}
                        startIcon={<VisibilityOutlinedIcon />}>Amortizaciones</Button>
                </StyledTableCell>
            </StyledTableRow>)
    }
    if (inProgressActivosArrendamiento) {
        numRegistros = [];
        datos = <TableLoader colums={8} text={'Buscando activos...'} />;
    }
    if (datosActivosArrendamiento?.length == 0) {
        datos = <TableNotFoundData colums={7} text={'No se encontraron activos.'} />;
    }

    if (!R.isNil(datosAmortizacionesActivo) && !inProgressAmortizacionesActivo) {
        totalesAmortizacionesActivo = datosAmortizacionesActivo;
        datosAA = datosAmortizacionesActivo.map((amortizacion: DatosAmortizacionesActivo) =>
            <StyledTableRow
                key={amortizacion.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <StyledTableCell>{amortizacion.numeroPago}</StyledTableCell>
                <StyledTableCell>{getNumberToCurrency((amortizacion.capitalApagar ?? 0) + (amortizacion.interesApagar ?? 0))}</StyledTableCell>
                <StyledTableCell>{getNumberToCurrency(amortizacion.interesMoratorioApagar)}</StyledTableCell>
                <StyledTableCell>{getNumberToCurrency(amortizacion.ivaDeInteresesApagar)}</StyledTableCell>
                <StyledTableCell>{getNumberToCurrency(amortizacion.pagoTotal)}</StyledTableCell>
            </StyledTableRow>)
    }
    if (inProgressAmortizacionesActivo) {
        totalesAmortizacionesActivo = [];
        datosAA = <TableLoader colums={7} text={'Buscando amortizaciones...'} />;
    }
    if (datosAmortizacionesActivo?.length == 0) {
        datosAA = <TableNotFoundData colums={7} text={'No se encontraron amortizaciones.'} />;
    }

    function PopUpGeneracionArchivo() {
        return (
            <Dialog
                key={'archivoExportado'}
                open={openGeneracionArchivo}
                fullWidth={true}
                maxWidth={'xs'}>
                <DialogContent sx={{ m: 2, textAlign: 'center' }} id="dialogContent">
                    <DialogContentText>
                        {mensajeGeneracionArchivo}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }

    function TablaActivosArrendamiento() {
        return (<>
            <Box sx={{ mb: 2, float: 'right' }}>
                <Tooltip arrow title={numRegistros.length > 0 ? '' : 'No se podrá exportar la tabla si no hay amortizaciones.'}>
                    <Button sx={{ ml: 2, color: numRegistros.length > 0 ? '#16a0ff' : 'error.main' }}
                        disabled={inProgressActivosArrendamiento}
                        onClick={() => exportarArchivoActivos('XLS')}>
                        <FileDownloadOutlinedIcon sx={{ mr: 1 }} />Exportar excel</Button>
                </Tooltip>
                <Tooltip arrow title={numRegistros.length > 0 ? '' : 'No se podrá exportar la tabla si no hay amortizaciones.'}>
                    <Button sx={{ ml: 2, color: numRegistros.length > 0 ? '#16a0ff' : 'error.main' }}
                        disabled={inProgressActivosArrendamiento}
                        onClick={() => exportarArchivoActivos('PDF')}>
                        <FileDownloadOutlinedIcon sx={{ mr: 1 }} />Exportar PDF</Button>
                </Tooltip>
            </Box>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 800 }} aria-label="customized table">
                    <TableBody>
                        <TableRow>
                            <TableCell><b>Información</b></TableCell>
                            <TableCell><b>Precio de referencia (con IVA)</b></TableCell>
                            <TableCell><b>Monto a financiar</b></TableCell>
                            <TableCell><b>Monto a dispersar</b></TableCell>
                            <TableCell><b>Valor residual</b></TableCell>
                            <TableCell><b>Enganche</b></TableCell>
                            <TableCell><b>Capital a amortizar</b></TableCell>
                            <TableCell><b>Acciones</b></TableCell>
                        </TableRow>
                        {datos}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                key={'amortizacionesActivo'}
                open={openAmortizacionesActivo}
                fullWidth={true}
                maxWidth={'lg'}>
                <DialogContent sx={{ m: 2, textAlign: 'center' }} id="dialogContent">
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="customized table">
                            <TableBody>
                                <TableRow>
                                    <TableCell><b>Pago</b></TableCell>
                                    <TableCell><b>Renta</b></TableCell>
                                    <TableCell><b>Interés moratorio</b></TableCell>
                                    <TableCell><b>IVA</b></TableCell>
                                    <TableCell><b>Pago total</b></TableCell>
                                </TableRow>
                                <TableRow sx={{ backgroundColor: '#bae3ff' }}>
                                    <TableCell><b>Totales:</b></TableCell>
                                    <TableCell><b>{getNumberToCurrency(totalesAmortizacionesActivo.reduce((a: number, b: DatosAmortizacionesActivo) => a + ((b.capitalApagar ?? 0) + (b.interesApagar ?? 0)), 0))}</b></TableCell>
                                    <TableCell><b>{getNumberToCurrency(totalesAmortizacionesActivo.reduce((a: number, b: DatosAmortizacionesActivo) => a + (b.interesMoratorioApagar ?? 0), 0))}</b></TableCell>
                                    <TableCell><b>{getNumberToCurrency(totalesAmortizacionesActivo.reduce((a: number, b: DatosAmortizacionesActivo) => a + (b.ivaDeInteresesApagar ?? 0), 0))}</b></TableCell>
                                    <TableCell><b>{getNumberToCurrency(totalesAmortizacionesActivo.reduce((a: number, b: DatosAmortizacionesActivo) => a + (b.pagoTotal ?? 0), 0))}</b></TableCell>
                                </TableRow>
                                {datosAA}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions sx={{ mb: 2, mr: 4 }} id="dialogActions">
                    <Button onClick={() => setOpenAmortizacionesActivo(false)}>Cerrar</Button>
                </DialogActions>
            </Dialog>
        </>);
    }

    return (
        <><Typography variant="h6" color="#2E2D2E" sx={{ mt: 2 }}>Activos</Typography>
            <TablaActivosArrendamiento />
            <PopUpGeneracionArchivo /></>
    );
}