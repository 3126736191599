import RemoteData from "./RemoteData";
import API from "./ApiConstants";
import { env } from "./env";
import { IDisposicionesRequest } from "../models/IDisposicionesRequest";
import { IAmortizacionesRequest } from "../models/IAmortizacionesRequest";
import { IDatosFinanciamientoRequest } from "../models/IDatosFinanciamientoRequest";
import {
  IFacturasPaqueteRequest,
  IPaquetesFactorajeRequest,
} from "../models/IPaquetesFactorajeRequest";
import { ILoginRequest } from "../models/ILoginRequest";
import { IPagosRealizadosRequest } from "../models/IPagosRealizadosRequest";
import { IResumenCuentaCorrienteRequest } from "../models/IResumenCuentaCorrienteRequest";
import { IActivosArrendamientoRequest } from "../models/IActivosArrendamientoRequest";
import {
  ICuentaDepositoRequest,
  IPostCuentaDepositoRequest,
} from "../models/ICuentaDepositoRequest";
import { ICredentialsRequest } from "../models/ICredentials";
import { IAmortizacionesActivoRequest } from "../models/IAmortizacionesActivoRequest";
import { IWebHookStatusRequest } from "../models/IWebHookStatusRequest";
import { IAmortizacionesAPagarRequest } from "../models/IAmortizacionesAPagarRequest";
import {
  ICheckAccountBalanceRequest,
  IStpCompaniesRequest,
  ISubAccountMovimientosRequest,
  ISubAccountsRequest,
} from "../models/IStpCompaniesRequest";
import {
  IGRPARequest,
  IGRPFRequest,
  IGRPRequest,
  ISimularPagoRequest,
} from "../models/ISimularPagoRequest";
import {
  IExportarArchivoActivosRequest,
  IExportarArchivoAmortizacionesRequest,
  IExportarArchivoDisposicionesRequest,
  IExportarArchivoPagosRealizadosRequest,
} from "../models/IExportarArchivoRequest";
import { IPaquetesAPagarRequest } from "../models/IPaquetesAPagarRequest";
import {
  IArchivoResultadoCotizadorAPRequest,
  IResultadoCotizadorAPRequest,
} from "../models/cotizador/IResultadoCotizadorAPRequest";
import {
  IAmortizacionesCotizadorCSRequest,
  IArchivoResultadoCotizadorCSRequest,
  IRecalculoCotizadorCSRequest,
} from "../models/cotizador/IAmortizacionesCotizadorCSRequest";
import {
  IOpcionesContratoAPRequest,
  IResultadoOpcionContratoAPRequest,
} from "../models/IOpcionesContratoAPRequest";

//#region CLIENTES
export function FinanciamientosCliente(data: any) {
  return RemoteData.get(API.FINANCIAMIENTOS, {
    IdCliente: data.IdCliente,
    IdEstatusFinanciamiento: data.IdEstatusFinanciamiento,
  });
}

export function Disposiciones(data: IDisposicionesRequest) {
  return RemoteData.get(API.DISPOSICIONES, {
    IdForoCondiciones: data.IdForoCondiciones,
    IdTipoFinanciamiento: data.IdTipoFinanciamiento,
  });
}

export function Amortizaciones(data: IAmortizacionesRequest) {
  return RemoteData.get(API.AMORTIZACIONES, {
    IdForoCondiciones: data.IdForoCondiciones,
    IdTipoFinanciamiento: data.IdTipoFinanciamiento,
    IdTipoPersona: data.IdTipoPersona,
  });
}

export function ExportarArchivoAmortizaciones(
  data: IExportarArchivoAmortizacionesRequest
) {
  return RemoteData.getFile(API.EXPORTAR_ARCHIVO_AMORTIZACIONES, {
    TipoExportacion: data.TipoExportacion,
    IdForoCondiciones: data.IdForoCondiciones,
    IdCliente: data.IdCliente,
    IdDisposicion: data.IdDisposicion,
    IdTipoFinanciamiento: data.IdTipoFinanciamiento,
    IdTipoPersona: data.IdTipoPersona,
  });
}

export function ExportarArchivoPagosRealizados(
  data: IExportarArchivoPagosRealizadosRequest
) {
  return RemoteData.getFile(API.EXPORTAR_ARCHIVO_PAGOSREALIZADOS, {
    TipoExportacion: data.TipoExportacion,
    IdForoCondiciones: data.IdForoCondiciones,
    IdTipoFinanciamiento: data.IdTipoFinanciamiento,
  });
}

export function ExportarArchivoDisposiciones(
  data: IExportarArchivoDisposicionesRequest
) {
  return RemoteData.getFile(API.EXPORTAR_ARCHIVO_DISPOSICIONES, {
    TipoExportacion: data.TipoExportacion,
    IdForoCondiciones: data.IdForoCondiciones,
    IdCliente: data.IdCliente,
    IdTipoFinanciamiento: data.IdTipoFinanciamiento,
  });
}

export function ExportarArchivoActivos(data: IExportarArchivoActivosRequest) {
  return RemoteData.getFile(API.EXPORTAR_ARCHIVO_ACTIVOS, {
    TipoExportacion: data.TipoExportacion,
    IdFinanciamientoSolicitado: data.IdFinanciamientoSolicitado,
    IdCliente: data.IdCliente,
  });
}

export function DatosFinanciamiento(data: IDatosFinanciamientoRequest) {
  return RemoteData.get(API.DATOS_FINANCIAMIENTO, {
    IdCliente: data.IdCliente,
    IdForoCondiciones: data.IdForoCondiciones,
  });
}

export function PaquetesFactoraje(data: IPaquetesFactorajeRequest) {
  return RemoteData.get(API.PAQUETES_FACTORAJE, {
    IdForoCondiciones: data.IdForoCondiciones,
  });
}

export function FacturasPaquete(data: IFacturasPaqueteRequest) {
  return RemoteData.get(API.FACTURAS_PAQUETE, {
    IdFactorajePaquete: data.IdFactorajePaquete,
  });
}

export function Login(data: ILoginRequest) {
  return RemoteData.get(API.VERIFICAR_USUARIO, {
    IdCliente: data.IdCliente,
    Email: data.Email,
  });
}

export function PagosRealizados(data: IPagosRealizadosRequest) {
  return RemoteData.get(API.PAGOS_REALIZADOS, {
    IdForoCondiciones: data.IdForoCondiciones,
  });
}

export function ResumenCuentaCorriente(data: IResumenCuentaCorrienteRequest) {
  return RemoteData.get(API.RESUMEN_CUENTA_CORRIENTE, {
    IdCliente: data.IdCliente,
    IdForoCondiciones: data.IdForoCondiciones,
    FechaACalcular: data.FechaACalcular,
  });
}

export function ActivosArrendamiento(data: IActivosArrendamientoRequest) {
  return RemoteData.get(API.ACTIVOS_ARRENDAMIENTO, {
    IdFinanciamientoSolicitado: data.IdFinanciamientoSolicitado,
  });
}

export function AmortizacionesActivo(data: IAmortizacionesActivoRequest) {
  return RemoteData.get(API.AMORTIZACIONES_ACTIVO, {
    IdCliente: data.IdCliente,
    IdActivoArrendamiento: data.IdActivoArrendamiento,
    IdArrendamientoPuroDisposicion: data.IdArrendamientoPuroDisposicion,
  });
}

export function CuentaDepositoCliente(data: ICuentaDepositoRequest) {
  return RemoteData.get(API.CUENTA_DEPOSITO, {
    IdCliente: data.IdCliente,
  });
}

export function PostCuentaDepositoCliente(data: IPostCuentaDepositoRequest) {
  return RemoteData.post(API.POST_CUENTA_DEPOSITO, null, {
    IdCliente: data.IdCliente,
    ClaveInstitucion: data.ClaveInstitucion,
    ClaveTipoCuenta: data.ClaveTipoCuenta,
    NumeroCuenta: data.NumeroCuenta,
    NombreBeneficiario: data.NombreBeneficiario,
    Email: data.Email,
  });
}

export function FindValidStatus(data: string) {
  return RemoteData.get(API.CONSULTA_FIND_VALID_STATUS, {
    RFC: data,
  });
}

export function CredentialsUser(data: ICredentialsRequest) {
  return RemoteData.post(
    API.CONSULTA_CIEC,
    null,
    {
      Type: data.type,
      RFC: data.rfc,
      Password: data.password,
    },
    true
  );
}

export function WebHookStatus(data: IWebHookStatusRequest) {
  return RemoteData.get(API.CONSULTA_WEBHOOK_STATUS, {
    IdCredential: data.idCredential,
  });
}

export function AmortizacionesAPagar(data: IAmortizacionesAPagarRequest) {
  return RemoteData.get(API.AMORTIZACIONES_A_PAGAR, {
    IdForoCondiciones: data.IdForoCondiciones,
    IdTipoFinanciamiento: data.IdTipoFinanciamiento,
    IdTipoPersona: data.IdTipoPersona,
  });
}

export function SimularPago(data: ISimularPagoRequest) {
  return RemoteData.get(API.SIMULAR_PAGO, {
    MontoAPagar: data.MontoAPagar,
    IdForoCondiciones: data.IdForoCondiciones,
    IdTipoFinanciamiento: data.IdTipoFinanciamiento,
    IdTipoPersona: data.IdTipoPersona,
  });
}

export function GenerarReferenciaPago(data: IGRPRequest) {
  return RemoteData.get(API.GENERAR_REFERENCIA_PAGO, {
    MontoAPagar: data.MontoAPagar,
    IdCliente: data.IdCliente,
    IdForoCondiciones: data.IdForoCondiciones,
    IdTipoFinanciamiento: data.IdTipoFinanciamiento,
    IdTipoPersona: data.IdTipoPersona,
  });
}

export function GenerarReferenciaPagoFactoraje(data: IGRPFRequest) {
  return RemoteData.post(API.GENERAR_REFERENCIA_PAGO_FACTORAJE, null, {
    FacturasAPagar: data.FacturasAPagar,
    IdCliente: data.IdCliente,
    IdForoCondiciones: data.IdForoCondiciones,
    IdTipoPersona: data.IdTipoPersona,
  });
}

export function GenerarReferenciaPagoActivos(data: IGRPARequest) {
  return RemoteData.post(API.GENERAR_REFERENCIA_PAGO_ACTIVOS, null, {
    IdActivos: data.IdActivos,
    IdCliente: data.IdCliente,
    IdForoCondiciones: data.IdForoCondiciones,
  });
}

export function PaquetesAPagar(data: IPaquetesAPagarRequest) {
  return RemoteData.get(API.PAQUETES_A_PAGAR, {
    IdForoCondiciones: data.IdForoCondiciones,
    IdCliente: data.IdCliente,
  });
}

export function OpcionesContratoAP(data: IOpcionesContratoAPRequest) {
  return RemoteData.get(API.OPCIONES_CONTRATO_AP, {
    IdForoCondiciones: data.IdForoCondiciones,
    IdTipoPersona: data.IdTipoPersona,
  });
}

export function ResultadoOpcionContratoAP(
  data: IResultadoOpcionContratoAPRequest
) {
  return RemoteData.get(API.RESULTADO_OPCION_CONTRATO_AP, {
    IdCliente: data.IdCliente,
    IdForoCondiciones: data.IdForoCondiciones,
    IdOpcionContrato: data.IdOpcionContrato,
  });
}
//#endregion

//#region COTIZADOR
export function ResultadoCotizadorAP(data: IResultadoCotizadorAPRequest) {
  return RemoteData.post(API.RESULTADO_COTIZADOR_AP, null, {
    IdCliente: data.IdCliente,
    Condiciones: data.Condiciones,
    Activos: data.Activos,
    PlazoMeses: data.PlazoMeses,
  });
}

export function AmortizacionesCotizadorCS(
  data: IAmortizacionesCotizadorCSRequest
) {
  return RemoteData.post(API.AMORTIZACIONES_COTIZADOR_CS, null, {
    IdCliente: data.IdCliente,
    Condiciones: data.Condiciones,
  });
}

export function RecalculoCotizadorCS(data: IRecalculoCotizadorCSRequest) {
  return RemoteData.post(API.RECALCULO_COTIZADOR_CS, null, {
    IdCliente: data.IdCliente,
    Condiciones: data.Condiciones,
    PlazoCapital: data.PlazoCapital,
    PlazoInteres: data.PlazoInteres,
    AdelantoCapital: data.AdelantoCapital,
  });
}

export function ArchivoResultadoCotizadorCS(
  data: IArchivoResultadoCotizadorCSRequest
) {
  return RemoteData.postFile(API.ARCHIVO_RESULTADO_COTIZADOR_CS, null, {
    IdCliente: data.IdCliente,
    Condiciones: data.Condiciones,
    Amortizaciones: data.Amortizaciones,
  });
}

export function ArchivoResultadoCotizadorAP(
  data: IArchivoResultadoCotizadorAPRequest
) {
  return RemoteData.postFile(API.ARCHIVO_RESULTADO_COTIZADOR_AP, null, {
    IdCliente: data.IdCliente,
    Condiciones: data.Condiciones,
    Activos: data.Activos,
  });
}
//#endregion

//#region CATALOGOS
export function InstitucionesBancarias(data: any) {
  return RemoteData.get(API.INSTITUCIONES_BANCARIAS, {});
}

export function TipoCuenta(data: any) {
  return RemoteData.get(API.TIPO_CUENTA, {});
}

export function TipoActivo(data: any) {
  return RemoteData.get(API.TIPO_ACTIVO, {});
}

export function SubtipoActivo(data: any) {
  return RemoteData.get(API.SUBTIPO_ACTIVO, {});
}

export function TipoArrendamiento(data: any) {
  return RemoteData.get(API.TIPO_ARRENDAMIENTO, {});
}

export function TipoPagoAP(data: any) {
  return RemoteData.get(API.TIPO_PAGO_AP, {});
}

export function Periodicidad(data: any) {
  return RemoteData.get(API.PERIODICIDAD, {});
}

export function TipoPersona(data: any) {
  return RemoteData.get(API.TIPO_PERSONA, {});
}

export function TipoTasa(data: any) {
  return RemoteData.get(API.TIPO_TASA, {});
}

export function TipoTasaMoratoria(data: any) {
  return RemoteData.get(API.TIPO_TASA_MORATORIA, {});
}

export function TipoCalculo(data: any) {
  return RemoteData.get(API.TIPO_CALCULO, {});
}

export function ValorTiieActual(data: any) {
  return RemoteData.get(API.VALOR_TIIE_ACTUAL, {});
}
//#endregion

//#region STP
export function StpCompanies(data: IStpCompaniesRequest) {
  return RemoteData.get(
    API.CONSULTA_COMPANIES
    // ,
    // {
    //     get: data.get
    // }
  );
}

export function SubAccounts(data: ISubAccountsRequest) {
  return RemoteData.get(API.CONSULTA_SUBCUENTAS, {
    StpMainAccountIdInt: data.StpMainAccountIdInt,
  });
}

export function SubAccountMovimientos(data: ISubAccountMovimientosRequest) {
  return RemoteData.post(API.CONSULTA_MOVIMIENTOS_SUBCUENTA, {
    SubaccountIdInt32: data.SubaccountIdInt32,
  });
}

export function CheckAccountBalance(data: ICheckAccountBalanceRequest) {
  return RemoteData.get(
    API.CHECK_ACCOUNT_BALANCE
    // ,
    // {
    //     get: data.get
    // }
  );
}
//#endregion
