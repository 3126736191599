import { ICheckAccountBalanceRequest, IStpCompaniesRequest, ISubAccountMovimientosRequest, ISubAccountsRequest } from '../../../models/IStpCompaniesRequest';
import { DatosStpCompanies, DatosSubAccountMovimientos, DatosSubAccounts, ICheckAccountBalanceResponse, IStpCompaniesResponse, ISubAccountsResponse } from '../../../models/IStpCompaniesResponse';
import * as type from '../../../system/types';
import * as actions from '../../../system/types';

//#region STP COMPANIES
export function requestStpCompanies(balanceResponseObj: IStpCompaniesRequest): actions.requestStpCompaniesAction {
    return {
        type: type.STPCOMPANIES_REQUEST,
        inProgress: true,
        balanceResponseObj
    };
}

export function onStpCompaniesResponse(
    balanceResponseObj: Array<DatosStpCompanies>,
    message: string
): actions.onStpCompaniesResponseAction {
    return {
        type: type.STPCOMPANIES_RESPONSE,
        balanceResponseObj,
        message,
        inProgress: false
    };
}

export function onStpCompaniesFailed(
    balanceResponseObj: any,
    message: string
): actions.getStpCompaniesFailedAction {
    return {
        type: type.STPCOMPANIES_FAILED,
        message,
        inProgress: false
    };
}
//#endregion

//#region SUBACCOUNTS
export function requestSubAccounts(dropdownDataObj: ISubAccountsRequest): actions.requestSubAccountsAction {
    return {
        type: type.SUBACCOUNTS_REQUEST,
        inProgress: true,
        dropdownDataObj
    };
}

export function onSubAccountsResponse(
    dropdownDataObj: Array<DatosSubAccounts>,
    message: string
): actions.onSubAccountsResponseAction {
    return {
        type: type.SUBACCOUNTS_RESPONSE,
        dropdownDataObj,
        message,
        inProgress: false
    };
}

export function onSubAccountsFailed(
    data: any,
    message: string
): actions.getSubAccountsFailedAction {
    return {
        type: type.SUBACCOUNTS_FAILED,
        message,
        inProgress: false
    };
}
//#endregion

//#region SUBACCOUNT MOVIMIENTOS
export function requestSubAccountMovimientos(transaccionResponseObj: ISubAccountMovimientosRequest): actions.requestSubAccountMovimientosAction {
    return {
        type: type.SUBACCOUNTMOVIMIENTOS_REQUEST,
        inProgress: true,
        transaccionResponseObj
    };
}

export function onSubAccountMovimientosResponse(
    transaccionResponseObj: Array<DatosSubAccountMovimientos>,
    message: string
): actions.onSubAccountMovimientosResponseAction {
    return {
        type: type.SUBACCOUNTMOVIMIENTOS_RESPONSE,
        transaccionResponseObj,
        message,
        inProgress: false
    };
}

export function onSubAccountMovimientosFailed(
    data: any,
    message: string
): actions.getSubAccountMovimientosFailedAction {
    return {
        type: type.SUBACCOUNTMOVIMIENTOS_FAILED,
        message,
        inProgress: false
    };
}
//#endregion

//#region CHECK ACCOUNT BALANCE
export function requestCheckAccountBalance(data: ICheckAccountBalanceRequest): actions.requestCheckAccountBalanceAction {
    return {
        type: type.CHECKACCOUNTBALANCE_REQUEST,
        inProgress: true,
        data
    };
}

export function onCheckAccountBalanceResponse(
    data: ICheckAccountBalanceResponse,
): actions.onCheckAccountBalanceResponseAction {
    return {
        type: type.CHECKACCOUNTBALANCE_RESPONSE,
        data,
        inProgress: false
    };
}

export function onCheckAccountBalanceFailed(
    data: any,
    message: string
): actions.getCheckAccountBalanceFailedAction {
    return {
        type: type.CHECKACCOUNTBALANCE_FAILED,
        message,
        inProgress: false
    };
}
//#endregion