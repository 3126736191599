import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, FormControl, FormControlLabel, Grid, InputLabel, Radio, RadioGroup, Select, TableFooter, TablePagination, TextField, Typography } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import * as generarReferenciaFactorajeActions from './redux/actions';
import { IPaquetesAPagarRequest } from '../../../models/IPaquetesAPagarRequest';
import * as R from 'ramda';
import { DatosFacturasAPagar, DatosPaquetesAPagar } from '../../../models/IPaquetesAPagarResponse';
import { getNumberToCurrency } from '../../../utils';
import theme from '../../../theme';
import $ from 'jquery';
import { TableLoader } from '../../loader/TableLoader';
import { TableNotFoundData } from '../../notFoundData/TableNotFoundData';
import moment from 'moment';
import 'moment/locale/es';
import { StyledTableCell, StyledTableRow } from '../../styledTables/StyledTables';
import { IGRPFRequest, IGRPRequest } from '../../../models/ISimularPagoRequest';
import { DatosGRP } from '../../../models/ISimularPagoResponse';
moment.locale('es');

export default function GenerarReferenciaFactoraje(dataSend: IPaquetesAPagarRequest) {

    const idCliente = useSelector((state: any) => state.login.id);
    const idTipoPersona = useSelector((state: any) => state.login.idTipoPersona);
    const [checkedStatePaquetes, setCheckedStatePaquetes] = useState(new Array(0));
    const [checkedStateFacturas, setCheckedStateFacturas] = useState(new Array(0));
    const [paquete, setPaquete] = useState<DatosPaquetesAPagar>();
    const [listTemp, setListTemp] = useState<any>();
    const [listaFacturasReferencia, setListaFacturasReferencia] = useState<Array<DatosFacturasAPagar>>([]);
    const [radioValue, setRadioValue] = useState("");
    const [monto, setMonto] = useState("");
    const [openConfirmReferenciaPago, setOpenConfirmReferenciaPago] = useState(false);
    const [openAlertGRPF, setOpenAlertGRPF] = useState(false);
    const [openResultGRPF, setOpenResultGRPF] = useState(false);

    let GRPFSend: IGRPFRequest = {
        FacturasAPagar: [],
        IdCliente: idCliente,
        IdForoCondiciones: dataSend.IdForoCondiciones,
        IdTipoPersona: idTipoPersona
    }

    let GRPFmessage = <Typography variant='body1'>Generando número de referencia...</Typography>;
    let totalesPaquetesAPagar: any = [];
    let datos = <TableLoader colums={8} text={'Buscando paquetes a pagar...'} />;

    const dispatch = useDispatch();
    const PaquetesAPagarRequest = useCallback(() => dispatch(generarReferenciaFactorajeActions.requestPaquetesAPagar(dataSend)), [dispatch, dataSend]);
    const generarReferenciaPagoRequest = useCallback(() => dispatch(generarReferenciaFactorajeActions.requestGenerarReferenciaPagoFactoraje(GRPFSend)), [dispatch, GRPFSend]);

    const datosPaquetesAPagar: any = useSelector((state: any) => state.paquetesAPagar.data);
    const inProgress = useSelector((state: any) => state.paquetesAPagar.inProgress);
    const datosGRPF: DatosGRP = useSelector((state: any) => state.generarReferenciaPagoFactoraje.data);
    const inProgressGRPF: any = useSelector((state: any) => state.generarReferenciaPagoFactoraje.inProgress);

    useEffect(() => {
        PaquetesAPagarRequest();
    }, []);

    useEffect(() => {
        setCheckedStatePaquetes(new Array(datosPaquetesAPagar?.length).fill(false))
        setListTemp(datosPaquetesAPagar);
    }, [!R.isNil(datosPaquetesAPagar)]);

    useEffect(() => {
        setCheckedStateFacturas(new Array(paquete?.facturasAPagar.length).fill(false))
    }, [!R.isNil(paquete)]);

    const detalleFacturasAPagar = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRadioValue(event.target.value)
        datosPaquetesAPagar.filter((e: DatosPaquetesAPagar) => {
            if (e.idPaquete == event.target.value) {
                setPaquete(e);
            }
        });
    };

    const handleOnChangePaquetes = (position: number) => {
        const updatedCheckedStatePaquetes = checkedStatePaquetes.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedStatePaquetes(updatedCheckedStatePaquetes);
    }

    const handleOnChangeFacturas = (position: number) => {
        const updatedCheckedStateFacturas = checkedStateFacturas.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedStateFacturas(updatedCheckedStateFacturas);
    }

    const eliminaDuplicados = (array: Array<DatosFacturasAPagar>) => {
        var hash: any = {};
        array = array.filter(function (current) {
            var exists = !hash[current.id];
            hash[current.id] = true;
            return exists;
        });

        return array;
    }

    function PopUpConfirmReferenciaPago() {
        return (<Dialog
            open={openConfirmReferenciaPago}
            fullWidth={true}
            maxWidth={'sm'}>
            <DialogContent sx={{ mt: 2, textAlign: 'center' }} id="dialogContent">
                <DialogContentText>
                    ¿Está seguro de que desea generar el número de referencia?
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ m: 2 }} id="dialogActions">
                <Button
                    onClick={() => setOpenConfirmReferenciaPago(false)}>
                    Cancelar
                </Button>
                <Button variant="contained"
                    onClick={generarReferenciaPago}
                    sx={{ backgroundColor: '#16a0ff' }}>Si, generar</Button>
            </DialogActions>
        </Dialog>);
    }

    const generarReferenciaPago = () => {
        setOpenConfirmReferenciaPago(false)
        if ($('#monto').val() == null || $('#monto').val() == undefined || $('#monto').val() == ''
            || parseFloat($('#monto').val() + '') < 1) {
            setOpenAlertGRPF(true);
            setTimeout(() => {
                setOpenAlertGRPF(false);
            }, 1000);
        }
        else {
            setOpenResultGRPF(true);
            GRPFSend.FacturasAPagar = eliminaDuplicados(listaFacturasReferencia);
            generarReferenciaPagoRequest();
        }
    };

    function PopUpAlertGRPF() {
        return (<Dialog
            open={openAlertGRPF}
            fullWidth={true}
            maxWidth={'xs'}>
            <DialogContent sx={{ m: 2, textAlign: 'center' }} id="dialogContent">
                <DialogContentText sx={{ color: 'error.main' }}>
                    Ingrese el monto a pagar.
                </DialogContentText>
            </DialogContent>
        </Dialog>);
    }

    function PopUpGRPF() {
        return (<Dialog
            open={openResultGRPF}
            fullWidth={true}
            maxWidth={'sm'}>
            <DialogContent sx={{ m: 2, textAlign: 'center' }} id="dialogContent">
                <DialogContentText>
                    {GRPFmessage}
                </DialogContentText>
            </DialogContent>
        </Dialog>);
    }

    const showListaFacturasReferencia = () => {
        let indexes: Array<number> = [];
        checkedStatePaquetes.map((checked: boolean, index: number) => {
            if (checked) {
                listTemp.filter((e: DatosPaquetesAPagar) => {
                    if (e.idPaquete == listTemp[index].idPaquete) {
                        e.facturasAPagar.forEach((factura) => {
                            setListaFacturasReferencia(prev => [...prev, factura]);
                        })
                    }
                })
                indexes.push(index);
            }
        });
        indexes.forEach((index, count) => {
            if (count > 0) {
                index = index - count;
            }
            listTemp.splice(index, 1);
        })
        checkedStateFacturas.map((checked: boolean, index: number) => {
            if (checked) {
                paquete?.facturasAPagar.filter((e: DatosFacturasAPagar) => {
                    if (e.id == paquete?.facturasAPagar[index].id) {
                        setListaFacturasReferencia(prev => [...prev, e]);
                    }
                })
                paquete?.facturasAPagar.splice(index, 1);
                listTemp.filter((e: DatosPaquetesAPagar) => {
                    if (e.idPaquete == paquete?.idPaquete) {
                        e.numeroFacturas--;
                    }
                });
            }
        });

        setRadioValue('');
        setCheckedStatePaquetes(new Array(listTemp.length).fill(false));
        setPaquete(undefined);
    }

    if (!R.isNil(datosGRPF) && !inProgressGRPF) {
        GRPFmessage = <Typography variant='body1' sx={{ color: datosGRPF.generado ? 'success.main' : 'error.main' }}>{datosGRPF.message}</Typography>
        setTimeout(() => {
            setOpenResultGRPF(false);
        }, 5000);
    }

    {
        !R.isNil(eliminaDuplicados(listaFacturasReferencia)) && eliminaDuplicados(listaFacturasReferencia).length > 0
            &&
            setTimeout(() => {
                setMonto(
                    (eliminaDuplicados(listaFacturasReferencia).reduce((a: number, b: DatosFacturasAPagar) => a + b.depositoDePchaCliente, 0)
                        + eliminaDuplicados(listaFacturasReferencia).reduce((a: number, b: DatosFacturasAPagar) => a + b.comisionAplicadaReal, 0)
                        + eliminaDuplicados(listaFacturasReferencia).reduce((a: number, b: DatosFacturasAPagar) => a + b.interesMoratorio, 0))
                        .toFixed(2).toString())
            }, 3000);
    }

    if (!R.isNil(listTemp) && !inProgress) {
        totalesPaquetesAPagar = listTemp;
        datos = (listTemp).map((paquete: DatosPaquetesAPagar, index: number) =>
            <StyledTableRow
                key={paquete.idPaquete}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <StyledTableCell align='center'>
                    <Radio checked={radioValue === paquete.idPaquete}
                        value={paquete.idPaquete}
                        onChange={detalleFacturasAPagar} />
                </StyledTableCell>
                <StyledTableCell>{paquete.numeroPaquete}</StyledTableCell>
                <StyledTableCell>{paquete.fechaCarga != null ? moment(paquete.fechaCarga).format('dddd LL') : ""}</StyledTableCell>
                <StyledTableCell>{paquete.fechaVencimiento != null ? moment(paquete.fechaVencimiento).format('dddd LL') : ""}</StyledTableCell>
                <StyledTableCell>{paquete.numeroFacturas}</StyledTableCell>
                <StyledTableCell>{getNumberToCurrency(paquete.montoDispersado)}</StyledTableCell>
                <StyledTableCell>{getNumberToCurrency(paquete.montoAPagar)}</StyledTableCell>
                <StyledTableCell align='center'>
                    <Checkbox
                        checked={checkedStatePaquetes[index]}
                        onChange={() => handleOnChangePaquetes(index)} />
                </StyledTableCell>
            </StyledTableRow>)
    }
    if (inProgress) {
        totalesPaquetesAPagar = [];
        datos = <TableLoader colums={8} text={'Buscando paquetes a pagar...'} />;
    }
    if (datosPaquetesAPagar?.length == 0) {
        totalesPaquetesAPagar = [];
        datos = <TableNotFoundData colums={8} text={'No se encontraron paquetes a pagar.'} />;
    }

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    function GenerarReferencia() {
        return (<>
            <Typography sx={{ fontSize: 14 }}>
                La <b>CLABE interbancaria</b> a la cual se realizará el pago es: <b>646180164010000003</b>,
                institución financiera: <b>STP</b>, favor de depósitar/transferir el monto a pagar exacto,
                de lo contrario el sistema devolverá el pago, y también <b>proporcionar el número de referencia</b> al
                realizar el pago ya que sin éste el sistema no sabrá el destino del depósito.
            </Typography>
            <Grid>
                <Typography sx={{ mt: 4, fontSize: 14 }}><b>Paquetes a pagar:</b></Typography>
                <TableContainer component={Paper} sx={{ maxHeight: 500, mt: 2 }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell><b>Detalle</b></TableCell>
                                <TableCell><b>Número de paquete</b></TableCell>
                                <TableCell><b>Fecha de carga</b></TableCell>
                                <TableCell><b>Fecha de vencimiento</b></TableCell>
                                <TableCell><b>Número de facturas</b></TableCell>
                                <TableCell><b>Monto dispersado</b></TableCell>
                                <TableCell><b>Monto a pagar</b></TableCell>
                                <TableCell><b>Agregar a referencia</b></TableCell>
                            </TableRow>
                            <TableRow sx={{ backgroundColor: '#bae3ff' }}>
                                <TableCell><b>Totales:</b></TableCell>
                                <TableCell><b>{totalesPaquetesAPagar.reduce((a: number, b: DatosPaquetesAPagar) => a + 1, 0)}</b></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell><b>{totalesPaquetesAPagar.reduce((a: number, b: DatosPaquetesAPagar) => a + b.numeroFacturas, 0)}</b></TableCell>
                                <TableCell><b>{getNumberToCurrency(totalesPaquetesAPagar.reduce((a: number, b: DatosPaquetesAPagar) => a + b.montoDispersado, 0))}</b></TableCell>
                                <TableCell><b>{getNumberToCurrency(totalesPaquetesAPagar.reduce((a: number, b: DatosPaquetesAPagar) => a + b.montoAPagar, 0))}</b></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            {datos}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid sx={{ mt: 4 }}>
                <Typography sx={{ fontSize: 14 }}><b>Facturas a pagar:</b></Typography>
                <TableContainer component={Paper} sx={{ mt: 2, maxHeight: 500 }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell><b>Agregar a referencia</b></TableCell>
                                <TableCell><b>No. factura</b></TableCell>
                                <TableCell><b>Cliente</b></TableCell>
                                <TableCell><b>Monto factura original</b></TableCell>
                                <TableCell><b>Moneda</b></TableCell>
                                <TableCell><b>Monto factura (MXN)</b></TableCell>
                                <TableCell><b>Tipo cambio</b></TableCell>
                                <TableCell><b>Fecha tipo cambio</b></TableCell>
                                <TableCell><b>% valor factura</b></TableCell>
                                <TableCell><b>Depósito de PCH</b></TableCell>
                                <TableCell><b>Fecha depósito</b></TableCell>
                                <TableCell><b>Fecha vencimiento</b></TableCell>
                                <TableCell><b>Días trascurridos</b></TableCell>
                                <TableCell><b>Interés generado</b></TableCell>
                                <TableCell><b>Interés no aplicado</b></TableCell>
                                <TableCell><b>Días interés moratorio</b></TableCell>
                                <TableCell><b>Interés moratorio</b></TableCell>
                                <TableCell><b>Estatus</b></TableCell>
                            </TableRow>
                            {!R.isNil(paquete) &&
                                <TableRow sx={{ backgroundColor: '#bae3ff' }}>
                                    <TableCell><b>Totales:</b></TableCell>
                                    <TableCell><b>{paquete.facturasAPagar.reduce((a: number, b: DatosFacturasAPagar) => a + 1, 0)}</b></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell><b>{getNumberToCurrency(paquete.facturasAPagar.reduce((a: number, b: DatosFacturasAPagar) => a + b.totalFactura, 0))}</b></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell><b>{getNumberToCurrency(paquete.facturasAPagar.reduce((a: number, b: DatosFacturasAPagar) => a + b.totalFacturaMxn, 0))}</b></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell><b>{getNumberToCurrency(paquete.facturasAPagar.reduce((a: number, b: DatosFacturasAPagar) => a + b.valorAnticipado, 0))}</b></TableCell>
                                    <TableCell><b>{getNumberToCurrency(paquete.facturasAPagar.reduce((a: number, b: DatosFacturasAPagar) => a + b.depositoDePchaCliente, 0))}</b></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell><b>{getNumberToCurrency(paquete.facturasAPagar.reduce((a: number, b: DatosFacturasAPagar) => a + b.comisionAplicadaReal, 0))}</b></TableCell>
                                    <TableCell><b>{getNumberToCurrency(paquete.facturasAPagar.reduce((a: number, b: DatosFacturasAPagar) => a + b.comisionNoAplicada, 0))}</b></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell><b>{getNumberToCurrency(paquete.facturasAPagar.reduce((a: number, b: DatosFacturasAPagar) => a + b.interesMoratorio, 0))}</b></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            }
                            {!R.isNil(paquete) &&
                                paquete.facturasAPagar.map((factura: DatosFacturasAPagar, index: number) =>
                                    <StyledTableRow key={factura.id + paquete?.idPaquete} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <StyledTableCell align='center'>
                                            <Checkbox
                                                checked={checkedStateFacturas[index]}
                                                onChange={() => handleOnChangeFacturas(index)} />
                                        </StyledTableCell>
                                        <StyledTableCell>{factura.numeroFactura}</StyledTableCell>
                                        <StyledTableCell>{factura.nombreReceptor}</StyledTableCell>
                                        <StyledTableCell>{getNumberToCurrency(factura.totalFactura)}</StyledTableCell>
                                        <StyledTableCell>{factura.moneda}</StyledTableCell>
                                        <StyledTableCell>{getNumberToCurrency(factura.totalFacturaMxn)}</StyledTableCell>
                                        <StyledTableCell>{getNumberToCurrency(factura.tipoDeCambio)}</StyledTableCell>
                                        <StyledTableCell>{factura.fechaTipoCambio != null ? moment(factura.fechaTipoCambio).format('dddd LL') : "S/F"}</StyledTableCell>
                                        <StyledTableCell>{getNumberToCurrency(factura.valorAnticipado)}</StyledTableCell>
                                        <StyledTableCell>{getNumberToCurrency(factura.depositoDePchaCliente)}</StyledTableCell>
                                        <StyledTableCell>{factura.fechaDeposito != null ? moment(factura.fechaDeposito).format('dddd LL') : ""}</StyledTableCell>
                                        <StyledTableCell>{factura.fechaVencimiento != null ? moment(factura.fechaVencimiento).format('dddd LL') : ""}</StyledTableCell>
                                        <StyledTableCell>{factura.diasEfectivos}</StyledTableCell>
                                        <StyledTableCell>{getNumberToCurrency(factura.comisionAplicadaReal)}</StyledTableCell>
                                        <StyledTableCell>{getNumberToCurrency(factura.comisionNoAplicada)}</StyledTableCell>
                                        <StyledTableCell>{factura.diasInteresMoratorio}</StyledTableCell>
                                        <StyledTableCell>{getNumberToCurrency(factura.interesMoratorio)}</StyledTableCell>
                                        <StyledTableCell>{factura.estatusXml}</StyledTableCell>
                                    </StyledTableRow>)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid sx={{ mt: 2, float: 'right' }}>
                <Button variant='contained' onClick={showListaFacturasReferencia}>Agregar seleccionados a la lista</Button>
            </Grid>
            <Grid sx={{ mt: 4 }}>
                <Typography sx={{ fontSize: 14 }}><b>Facturas para generar número de referencia:</b></Typography>
                <TableContainer component={Paper} sx={{ mt: 2, maxHeight: 500 }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell><b>No. factura</b></TableCell>
                                <TableCell><b>Cliente</b></TableCell>
                                <TableCell><b>Monto factura original</b></TableCell>
                                <TableCell><b>Moneda</b></TableCell>
                                <TableCell><b>Monto factura (MXN)</b></TableCell>
                                <TableCell><b>Tipo cambio</b></TableCell>
                                <TableCell><b>Fecha tipo cambio</b></TableCell>
                                <TableCell><b>% valor factura</b></TableCell>
                                <TableCell><b>Depósito de PCH</b></TableCell>
                                <TableCell><b>Fecha depósito</b></TableCell>
                                <TableCell><b>Fecha vencimiento</b></TableCell>
                                <TableCell><b>Días trascurridos</b></TableCell>
                                <TableCell><b>Interés generado</b></TableCell>
                                <TableCell><b>Interés no aplicado</b></TableCell>
                                <TableCell><b>Días interés moratorio</b></TableCell>
                                <TableCell><b>Interés moratorio</b></TableCell>
                                <TableCell><b>Estatus</b></TableCell>
                            </TableRow>
                            {!R.isNil(eliminaDuplicados(listaFacturasReferencia)) && eliminaDuplicados(listaFacturasReferencia).length > 0 &&
                                <TableRow sx={{ backgroundColor: '#bae3ff' }}>
                                    <TableCell><b>Totales:</b></TableCell>
                                    <TableCell><b>{eliminaDuplicados(listaFacturasReferencia).reduce((a: number, b: DatosFacturasAPagar) => a + 1, 0)}</b></TableCell>
                                    <TableCell><b>{getNumberToCurrency(eliminaDuplicados(listaFacturasReferencia).reduce((a: number, b: DatosFacturasAPagar) => a + b.totalFactura, 0))}</b></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell><b>{getNumberToCurrency(eliminaDuplicados(listaFacturasReferencia).reduce((a: number, b: DatosFacturasAPagar) => a + b.totalFacturaMxn, 0))}</b></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell><b>{getNumberToCurrency(eliminaDuplicados(listaFacturasReferencia).reduce((a: number, b: DatosFacturasAPagar) => a + b.valorAnticipado, 0))}</b></TableCell>
                                    <TableCell><b>{getNumberToCurrency(eliminaDuplicados(listaFacturasReferencia).reduce((a: number, b: DatosFacturasAPagar) => a + b.depositoDePchaCliente, 0))}</b></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell><b>{getNumberToCurrency(eliminaDuplicados(listaFacturasReferencia).reduce((a: number, b: DatosFacturasAPagar) => a + b.comisionAplicadaReal, 0))}</b></TableCell>
                                    <TableCell><b>{getNumberToCurrency(eliminaDuplicados(listaFacturasReferencia).reduce((a: number, b: DatosFacturasAPagar) => a + b.comisionNoAplicada, 0))}</b></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell><b>{getNumberToCurrency(eliminaDuplicados(listaFacturasReferencia).reduce((a: number, b: DatosFacturasAPagar) => a + b.interesMoratorio, 0))}</b></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            }
                            {!R.isNil(eliminaDuplicados(listaFacturasReferencia)) && eliminaDuplicados(listaFacturasReferencia).length > 0 &&
                                eliminaDuplicados(listaFacturasReferencia).map((factura: DatosFacturasAPagar) =>
                                    <StyledTableRow
                                        key={factura.id + 'listaFacturasReferencia'}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <StyledTableCell>{factura.numeroFactura}</StyledTableCell>
                                        <StyledTableCell>{factura.nombreReceptor}</StyledTableCell>
                                        <StyledTableCell>{getNumberToCurrency(factura.totalFactura)}</StyledTableCell>
                                        <StyledTableCell>{factura.moneda}</StyledTableCell>
                                        <StyledTableCell>{getNumberToCurrency(factura.totalFacturaMxn)}</StyledTableCell>
                                        <StyledTableCell>{getNumberToCurrency(factura.tipoDeCambio)}</StyledTableCell>
                                        <StyledTableCell>{factura.fechaTipoCambio != null ? moment(factura.fechaTipoCambio).format('dddd LL') : "S/F"}</StyledTableCell>
                                        <StyledTableCell>{getNumberToCurrency(factura.valorAnticipado)}</StyledTableCell>
                                        <StyledTableCell>{getNumberToCurrency(factura.depositoDePchaCliente)}</StyledTableCell>
                                        <StyledTableCell>{factura.fechaDeposito != null ? moment(factura.fechaDeposito).format('dddd LL') : ""}</StyledTableCell>
                                        <StyledTableCell>{factura.fechaVencimiento != null ? moment(factura.fechaVencimiento).format('dddd LL') : ""}</StyledTableCell>
                                        <StyledTableCell>{factura.diasEfectivos}</StyledTableCell>
                                        <StyledTableCell>{getNumberToCurrency(factura.comisionAplicadaReal)}</StyledTableCell>
                                        <StyledTableCell>{getNumberToCurrency(factura.comisionAplicadaReal)}</StyledTableCell>
                                        <StyledTableCell>{factura.diasInteresMoratorio}</StyledTableCell>
                                        <StyledTableCell>{getNumberToCurrency(factura.interesMoratorio)}</StyledTableCell>
                                        <StyledTableCell>{factura.estatusXml}</StyledTableCell>
                                    </StyledTableRow>)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Box component="form" sx={{ mt: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={2} sm={2}>
                        <TextField
                            required
                            disabled
                            fullWidth
                            id="monto"
                            value={monto}
                            size='small' />
                    </Grid>
                    <Grid item xs={2} sm={2}>
                        <TextField
                            required
                            disabled
                            fullWidth
                            type='date'
                            id="fecha"
                            size='small'
                            defaultValue={`${year}-${month < 10 ? `0${month}` : `${month}`}-${date < 10 ? `0${date}` : `${date}`}`} />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <Button
                            disabled={inProgress}
                            variant="contained"
                            sx={{ backgroundColor: 'info.main' }}
                            onClick={() => setOpenConfirmReferenciaPago(true)}>Generar número de referencia</Button>
                    </Grid>
                </Grid>
            </Box>
        </>);
    }

    return (
        <><Typography variant="h6" color="#2E2D2E" sx={{ mb: 2, mt: 2 }}>Generar referencia de pago</Typography>
            <GenerarReferencia />
            <PopUpAlertGRPF />
            <PopUpGRPF />
            <PopUpConfirmReferenciaPago /></>
    );
}