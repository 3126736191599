import { IDatosFinanciamientoRequest } from '../../../../models/IDatosFinanciamientoRequest';
import { DatosFinanciamiento, IDatosFinanciamientoResponse } from '../../../../models/IDatosFinanciamientoResponse';
import * as type from '../../../../system/types';
import * as actions from '../../../../system/types';

export function requestDatosFinanciamiento(data: IDatosFinanciamientoRequest): actions.requestDatosFinanciamientoAction {
    return {
        type: type.DATOSFINANCIAMIENTO_REQUEST,
        inProgress: true,
        data
    };
}

export function onDatosFinanciamientoResponse(
    data: DatosFinanciamiento,
    message: string
): actions.onDatosFinanciamientoResponseAction {
    return {
        type: type.DATOSFINANCIAMIENTO_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onDatosFinanciamientoFailed(
    data: any,
    message: string
): actions.getDatosFinanciamientoFailedAction {
    return {
        type: type.DATOSFINANCIAMIENTO_FAILED,
        message,
        inProgress: false
    };
}