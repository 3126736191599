import React from 'react';
import { BrowserRouter, Link, Navigate, NavLink, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Financiamientos from '../screens/Financiamientos/Index';
import NotFound from '../screens/NotFound/Index';
import ArrendamientoPuro from '../screens/ArrendamientoPuro/Index';
import CreditoSimple from '../screens/CreditoSimple/Index';
import CuentaCorriente from '../screens/CuentaCorriente/Index';
import FactorajeFinanciero from '../screens/FactorajeFinanciero/Index';
import CuentaDeposito from '../screens/CuentaDeposito/Index';
import Login from '../components/common/Login/Login';
import { env } from '../api/env';
import { createContext, Fragment, useState } from 'react';
import Header from '../components/common/AppBar';
import { Box, Card, CardActionArea, CardContent, Container, Grid, Paper, Typography } from '@mui/material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import * as loginActions from '../components/common/Login/redux/actions';
import { IAuth } from '../components/common/Login/redux/reducers';
import { PATH_SECURE } from '../system/selector';
import Credentials from '../screens/Credenciales/Index';
import Dashboard from '../screens/Dashboard/Index';
import ConsultaSaldo from '../screens/Subcuentas/Index';
import Cotizador from '../screens/Cotizador/Index';
import Redirect from '../screens/NotFound/Redirect';

const ProtectedRoute = ({ children }: any) => {
    const { token } = useAuth();
    const location = useLocation();

    if (!token) {
        return <Navigate to="/home" replace state={{ from: location }} />;
    }

    return children;
};

const AuthProvider = ({ children }: any) => {

    const navigate = useNavigate();
    const location = useLocation();
    const id: string = useSelector((state: any) => state.login.id);
    const [idCliete, setIdCliete] = React.useState<string | null>(id);

    const handleLogin = (token: string | null): any => {
        setIdCliete(token);
        const origin = location.state?.from?.pathname || PATH_SECURE;
        navigate(origin);
    };

    const handleLogout = () => {
        window.location.href = env.URL_PCHSF;
        setIdCliete(null);
    };

    const value = {
        token: idCliete,
        onLogin: (t: string | null) => handleLogin(t),
        onLogout: handleLogout,
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export default function NavigationRoutes() {
    return (
        <AuthProvider>
            <Routes>
                {/* <Route index element={<Home />} /> */}
                {/* <Route path="home" element={<Home />} /> */}
                <Route path="AccountsSignInUi" element={<Login />} />
                <Route path={PATH_SECURE} element={
                    <ProtectedRoute>
                        <Header component={<Dashboard />} title={'Inicio'} />
                    </ProtectedRoute>
                } />
                <Route path="admin" element={
                    <ProtectedRoute>
                        <Admin />
                    </ProtectedRoute>
                } />
                <Route path={PATH_SECURE + '/cuentadeposito/:idCliente'} element={
                    <ProtectedRoute>
                        <Header component={<CuentaDeposito />} title={'Cuenta de depósito'} />
                    </ProtectedRoute>
                } />
                <Route path={PATH_SECURE + '/financiamientos/:idCliente'} element={
                    <ProtectedRoute>
                        <Header component={<Financiamientos />} title={'Mis financiamientos'} />
                    </ProtectedRoute>
                } />
                <Route path={PATH_SECURE + '/cuentas/:idCliente'} element={
                    <ProtectedRoute>
                        <Header component={<ConsultaSaldo />} title={'Mis cuentas'} />
                    </ProtectedRoute>
                } />
                <Route path={PATH_SECURE + '/arrendamientopuro'} element={
                    <ProtectedRoute>
                        <Header component={<ArrendamientoPuro />} title={'Financiamientos'} />
                    </ProtectedRoute>
                } />
                <Route path={PATH_SECURE + '/creditosimple'} element={
                    <ProtectedRoute>
                        <Header component={<CreditoSimple />} title={'Financiamientos'} />
                    </ProtectedRoute>
                } />
                <Route path={PATH_SECURE + '/cuentacorriente'} element={
                    <ProtectedRoute>
                        <Header component={<CuentaCorriente />} title={'Financiamientos'} />
                    </ProtectedRoute>
                } />
                <Route path={PATH_SECURE + '/factorajefinanciero'} element={
                    <ProtectedRoute>
                        <Header component={<FactorajeFinanciero />} title={'Financiamientos'} />
                    </ProtectedRoute>
                } />
                <Route path={PATH_SECURE + '/cotizador/:idCliente'} element={
                    <ProtectedRoute>
                        <Header component={<Cotizador />} title={'Cotizador'} />
                    </ProtectedRoute>
                } />
                <Route path='/credentials' element={
                    <Credentials />
                } />
                <Route path="*" element={
                    <NotFound />
                } />
                <Route path="/redirect" element={
                    <Redirect />
                } />
            </Routes>
        </AuthProvider>
    );
}

// const Navigation = () => {
//     return (<Header />);
// };

const Admin = () => {
    const { token } = useAuth();
    const nombreCliente: boolean = useSelector((state: any) => state.login.nombreUsuario);
    const email: boolean = useSelector((state: any) => state.login.email);

    return (
        <Container>
            <Card sx={{ maxWidth: 345 }}>
                <CardActionArea>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            Lizard
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Lizards are a widespread group of squamate reptiles, with over 6,000
                            species, ranging across all continents except Antarctica
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            <Paper elevation={3} sx={{ mt: 2, p: 2 }}>
                <Typography variant="h6" gutterBottom>
                    Mi perfil
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                    {nombreCliente} - {email}
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                    {token}
                </Typography>
            </Paper>
        </Container>
    );
};

const Home = () => {
    return (
        <Container>
            <Paper elevation={3} sx={{ mt: 2, p: 2 }}>
                <Typography variant="h3">
                    Noticias
                </Typography>
            </Paper>
        </Container>
    );
};

// export default function NavigationRoutes() {
//     const valueLogin = useSelector((state: any) => state.login);
//     return (
//         <AuthContext.Provider value={valueLogin}>
//             <BrowserRouter>
//                 <Routes>
//                     <Route element={<Layout />}>
//                         <Route path='/' element={<PublicPage />} />
//                     </Route>
//                 </Routes>
//             </BrowserRouter>
//         </AuthContext.Provider>
//     );
// }

const AuthContext = createContext<IAuth>(null!);


export function useAuth() {
    return React.useContext(AuthContext);
}

// function Layout() {
//     let location = useLocation();
//     console.log(location)
//     // if (location.pathname == PATH_SECURE || location.pathname == PATH_SECURE + '/') {
//     //     console.log('AUTH ' + PATH_SECURE)
//     //     return (<>{location.pathname + ' - ' + PATH_SECURE}<AuthStatus /></>);
//     // } else if (location.pathname.startsWith(PATH_SECURE + '/')) {
//     //     console.log('NO AUTH')
//     //     return (<>{location.pathname + ' - ' + PATH_SECURE}<AuthStatus /><Outlet /></>);
//     // } else if (location.key == 'default') {
//     //     console.log('DEFAULT')
//     //     return (<>{location.pathname + ' - ' + PATH_SECURE}< Outlet /></>);
//     // } else {
//     //     console.log('nuguno de los ANTERIORES')
//     return (
//         <>
//             <Box
//                 display="flex"
//                 justifyContent="center"
//                 alignItems="center"
//                 minHeight="100vh"
//                 component="main">
//                 <Paper
//                     sx={{
//                         p: 2,
//                         margin: 2,
//                         maxWidth: 350,
//                         flexGrow: 1,
//                     }}>
//                     <Grid container spacing={2}>
//                         <Grid item xs={12} sm container>
//                             {location.pathname + ' - ' + PATH_SECURE}
//                         </Grid>
//                     </Grid>
//                 </Paper>
//                 <Outlet />
//             </Box>
//         </>
//     );
//     // }
// }

// function AuthStatus() {
//     // const isLoggedIn = useSelector((state: any) => state.login.isLoggedIn);
//     // const idCliente = useSelector((state: any) => state.login.id);
//     let auth = useAuth();
//     // let navigate = useNavigate();
//     if (!auth.id) {
//         return (
//             <Box
//                 display="flex"
//                 justifyContent="center"
//                 alignItems="center"
//                 minHeight="100vh"
//                 component="main">
//                 <Paper
//                     sx={{
//                         p: 2,
//                         margin: 2,
//                         maxWidth: 350,
//                         flexGrow: 1,
//                     }}>
//                     <Grid container spacing={2}>
//                         <Grid item xs={12} sm container>
//                             <Grid item xs={10}
//                                 container
//                                 direction="column"
//                                 alignItems="center"
//                                 justifyContent="center">
//                                 <Typography
//                                     component="h1"
//                                     variant="h5"
//                                     color="#C96B12"
//                                     sx={{
//                                         mt: 2,
//                                         mb: 2
//                                     }}>
//                                     Acceso denegado
//                                 </Typography>
//                             </Grid>
//                             <Grid item xs={2}
//                                 container
//                                 direction="column"
//                                 alignItems="center"
//                                 justifyContent="center"
//                                 style={{ padding: 0 }}>
//                                 <Link to={env.URL_ACCOUNT}>
//                                     <LockOpenIcon />
//                                 </Link>
//                             </Grid>
//                         </Grid>
//                     </Grid>
//                 </Paper>
//             </Box>
//         )
//     }
//     return (<Header />);
// }

// function RequireAuth({ children }: { children: JSX.Element }) {
//     // const dispatch = useDispatch();
//     // const sToken = localStorage.getItem('token');
//     // let authorization: any;
//     // if (sToken == undefined) {
//     //     authorization = JSON.parse(Buffer.from(sToken + '', 'base64').toString());
//     //     if (authorization.app != undefined && authorization.uid != undefined && authorization.email != undefined && authorization.nombreUsuario != undefined) {
//     //         dispatch(loginActions.onLoginByToken(authorization.app, authorization.uid, authorization.email, authorization.nombreUsuario));
//     //     }
//     // }

//     let auth = useAuth();
//     let location = useLocation();
//     console.log(location.pathname);
//     if (!auth.id) {
//         // Redirect them to the /login page, but save the current location they were
//         // trying to go to when they were redirected. This allows us to send them
//         // along to that page after they login, which is a nicer user experience
//         // than dropping them off on the home page.
//         return <Navigate to="/login" state={{ from: location }} replace />;
//     }
//     return children;
// }

// function LoginPage() {
//     let navigate = useNavigate();
//     let location = useLocation();
//     let auth = useAuth();

//     let from = location.state?.from?.pathname || "/";

//     function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
//         event.preventDefault();

//         let formData = new FormData(event.currentTarget);
//         let username = formData.get("username") as string;

//         // auth.signin(username, () => {
//         //     // Send them back to the page they tried to visit when they were
//         //     // redirected to the login page. Use { replace: true } so we don't create
//         //     // another entry in the history stack for the login page.  This means that
//         //     // when they get to the protected page and click the back button, they
//         //     // won't end up back on the login page, which is also really nice for the
//         //     // user experience.
//         //     navigate(from, { replace: true });
//         // });
//     }

//     return (
//         <div>
//             <p>You must log in to view the page at {from}</p>
//             <form onSubmit={handleSubmit}>
//                 <label>
//                     Username: <input name="username" type="text" />
//                 </label>{" "}
//                 <button type="submit">Login</button>
//             </form>
//         </div>
//     );
// }

// function PublicPage() {
//     return (
//         <Box
//             display="flex"
//             justifyContent="center"
//             alignItems="center"
//             component="main">
//             <Typography variant="h3" component="h3" style={{ color: 'lightgreen' }}>
//                 PCH Capital
//             </Typography>
//             <Link to={'login'}>Login</Link>
//         </Box>
//     );
// }

// function ProtectedPage() {
//     return (
//         <Box
//             display="flex"
//             justifyContent="center"
//             alignItems="center"
//             component="main">
//             <Typography variant="h3" component="h3" style={{ color: 'green' }}>
//                 PCH Capital
//             </Typography>
//         </Box>
//     );
// }