import { ICuentaDepositoRequest, IPostCuentaDepositoRequest } from '../../../models/ICuentaDepositoRequest';
import { DatosCuentaDeposito, DatosPostCuentaDeposito, ICuentaDepositoResponse } from '../../../models/ICuentaDepositoResponse';
import * as type from '../../../system/types';
import * as actions from '../../../system/types';

//#region GET
export function requestCuentaDeposito(data: ICuentaDepositoRequest): actions.requestCuentaDepositoAction {
    return {
        type: type.CUENTADEPOSITO_REQUEST,
        inProgress: true,
        data
    };
}

export function onCuentaDepositoResponse(
    data: DatosCuentaDeposito,
    message: string
): actions.onCuentaDepositoResponseAction {
    return {
        type: type.CUENTADEPOSITO_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onCuentaDepositoFailed(
    data: any,
    message: string
): actions.getCuentaDepositoFailedAction {
    return {
        type: type.CUENTADEPOSITO_FAILED,
        message,
        inProgress: false
    };
}
//#endregion

//#region POST
export function requestPostCuentaDeposito(data: IPostCuentaDepositoRequest): actions.requestPostCuentaDepositoAction {
    return {
        type: type.POSTCUENTADEPOSITO_REQUEST,
        inProgress: true,
        data
    };
}

export function onPostCuentaDepositoResponse(
    data: DatosPostCuentaDeposito,
    message: string
): actions.onPostCuentaDepositoResponseAction {
    return {
        type: type.POSTCUENTADEPOSITO_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onPostCuentaDepositoFailed(
    data: any,
    message: string
): actions.getPostCuentaDepositoFailedAction {
    return {
        type: type.POSTCUENTADEPOSITO_FAILED,
        message,
        inProgress: false
    };
}
//#endregion