import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, CircularProgress, Dialog, DialogContent, DialogContentText, Tooltip, Typography } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { IAmortizacionesRequest } from '../../../models/IAmortizacionesRequest';
import * as R from 'ramda';
import { DatosAmortizaciones } from '../../../models/IAmortizacionesResponse';
import { getNumberToCurrency, TipoFinanciamiento } from '../../../utils';
import theme from '../../../theme';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useCallback } from 'react';
import * as amortizacionesActions from './redux/actions';
import { IExportarArchivoAmortizacionesRequest } from '../../../models/IExportarArchivoRequest';
import { IExportarArchivoResponse } from '../../../models/IExportarArchivoResponse';
import { TableLoader } from '../../loader/TableLoader';
import { TableNotFoundData } from '../../notFoundData/TableNotFoundData';
import moment from 'moment';
import 'moment/locale/es';
import { StyledTableCell, StyledTableRow } from '../../styledTables/StyledTables';
moment.locale('es');

export default function AmortizacionesPendientes(dataSend: IAmortizacionesRequest) {
    let inProgress = false;
    let totalesAmortizacionesPendientes: any = [];
    let numRegistros: Array<any> = [];

    let datos = <TableLoader colums={8} text={'Buscando amortizaciones pendientes...'} />;

    let exportarArchivoSend: IExportarArchivoAmortizacionesRequest = {
        TipoExportacion: '',
        IdForoCondiciones: '',
        IdCliente: '',
        IdDisposicion: '',
        IdTipoFinanciamiento: '',
        IdTipoPersona: ''
    }

    inProgress = useSelector((state: any) => state.amortizaciones.inProgress);
    const datosAmortizacionesPendientes: any = useSelector((state: any) => state.amortizaciones.data);
    const datosArchivo: IExportarArchivoResponse = useSelector((state: any) => state.exportarArchivoAmortizaciones);
    const inProgressArchivo: any = useSelector((state: any) => state.exportarArchivoAmortizaciones.inProgress);
    const IdCliente: any = useSelector((state: any) => state.login.id);

    const dispatch = useDispatch();
    const archivoAmortizacionesRequest = useCallback(() => dispatch(amortizacionesActions.requestExportarArchivoAmortizaciones(exportarArchivoSend)), [dispatch, exportarArchivoSend]);

    const [openGeneracionArchivo, setOpenGeneracionArchivo] = React.useState(false);

    const exportarArchivoAmortizaciones = (tipoExportacion: string) => {
        let idDisposicion = '';
        if (!R.isNil(datosAmortizacionesPendientes.amortizacionesAP)) {
            idDisposicion = datosAmortizacionesPendientes.amortizacionesAP[0].idArrendamientoPuroDisposicion;
        }
        else if (!R.isNil(datosAmortizacionesPendientes.amortizacionesCS)) {
            idDisposicion = datosAmortizacionesPendientes.amortizacionesCS[0].idCreditoSimpleDisposicion;
        }

        exportarArchivoSend = {
            TipoExportacion: tipoExportacion,
            IdForoCondiciones: dataSend.IdForoCondiciones,
            IdCliente: IdCliente,
            IdDisposicion: idDisposicion,
            IdTipoFinanciamiento: dataSend.IdTipoFinanciamiento,
            IdTipoPersona: dataSend.IdTipoPersona
        }
        setOpenGeneracionArchivo(true)
        archivoAmortizacionesRequest();
    }

    if (!R.isNil(datosAmortizacionesPendientes) && !inProgress) {
        //ARRENDAMIENTO PURO
        if (!R.isNil(datosAmortizacionesPendientes.amortizacionesAP)) {
            numRegistros = datosAmortizacionesPendientes.amortizacionesAP;
            totalesAmortizacionesPendientes = datosAmortizacionesPendientes.amortizacionesAP.filter((e: DatosAmortizaciones) => e.pagado != true);
            datos = datosAmortizacionesPendientes.amortizacionesAP.filter((e: DatosAmortizaciones) => e.pagado != true).map((amortizacion: DatosAmortizaciones) =>
                <StyledTableRow
                    key={amortizacion.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <StyledTableCell>{amortizacion.numeroPago}</StyledTableCell>
                    <StyledTableCell>{amortizacion.fechaPago != null ? moment(amortizacion.fechaPago).format('dddd LL') : ""}</StyledTableCell>
                    <StyledTableCell>{getNumberToCurrency((amortizacion.capitalApagar ?? 0) + (amortizacion.interesApagar ?? 0))}</StyledTableCell>
                    <StyledTableCell>{getNumberToCurrency(amortizacion.interesMoratorioApagar)}</StyledTableCell>
                    <StyledTableCell>{getNumberToCurrency(amortizacion.ivaDeInteresesApagar)}</StyledTableCell>
                    <StyledTableCell>{getNumberToCurrency(amortizacion.pagoTotal)}</StyledTableCell>
                </StyledTableRow>)
        }
        //CREDITO SIMPLE
        else if (!R.isNil(datosAmortizacionesPendientes.amortizacionesCS)) {
            numRegistros = datosAmortizacionesPendientes.amortizacionesCS;
            totalesAmortizacionesPendientes = datosAmortizacionesPendientes.amortizacionesCS.filter((e: DatosAmortizaciones) => e.pagado != true);
            datos = datosAmortizacionesPendientes.amortizacionesCS.filter((e: DatosAmortizaciones) => e.pagado != true).map((amortizacion: DatosAmortizaciones) =>
                <StyledTableRow
                    key={amortizacion.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <StyledTableCell>{amortizacion.numeroPago}</StyledTableCell>
                    <StyledTableCell>{amortizacion.fechaPago != null ? moment(amortizacion.fechaPago).format('dddd LL') : ""}</StyledTableCell>
                    <StyledTableCell>{getNumberToCurrency(amortizacion.capitalApagar)}</StyledTableCell>
                    <StyledTableCell>{getNumberToCurrency(amortizacion.interesApagar)}</StyledTableCell>
                    <StyledTableCell>{getNumberToCurrency(amortizacion.interesMoratorioApagar)}</StyledTableCell>
                    <StyledTableCell>{getNumberToCurrency(amortizacion.ivaDeInteresesApagar)}</StyledTableCell>
                    <StyledTableCell>{getNumberToCurrency(amortizacion.pagoTotal)}</StyledTableCell>
                    <StyledTableCell>{getNumberToCurrency(amortizacion.capitalPendiente)}</StyledTableCell>
                </StyledTableRow>)
        }
    }
    if (inProgress) {
        totalesAmortizacionesPendientes = [];
        numRegistros = [];
        datos = <TableLoader colums={8} text={'Buscando amortizaciones pendientes...'} />;
    }
    if (datosAmortizacionesPendientes?.length == 0) {
        datos = <TableNotFoundData colums={8} text={'No se encontraron amortizaciones pendientes.'} />;
    }

    let mensajeGeneracionArchivo = <Typography variant='body1'>Generando...</Typography>

    if (!R.isNil(datosArchivo) && !inProgressArchivo) {
        mensajeGeneracionArchivo =
            <Typography sx={{ color: datosArchivo.succeeded ? 'success.main' : 'error.main' }}>{datosArchivo.message}</Typography>
        setTimeout(() => {
            setOpenGeneracionArchivo(false);
        }, 3000);
    }

    function TablaAmortizacionesPendientes() {
        return (
            <><Box sx={{ mb: 2, float: 'right' }}>
                <Tooltip arrow title={numRegistros.length > 0 ? '' : 'No se podrá exportar la tabla si no hay amortizaciones.'}>
                    <Button sx={{ ml: 2, color: numRegistros.length > 0 ? '#16a0ff' : 'error.main' }}
                        disabled={inProgress}
                        onClick={() => exportarArchivoAmortizaciones('XLS')}>
                        <FileDownloadOutlinedIcon sx={{ mr: 1 }} />Exportar excel</Button>
                </Tooltip>
                <Tooltip arrow title={numRegistros.length > 0 ? '' : 'No se podrá exportar la tabla si no hay amortizaciones.'}>
                    <Button sx={{ ml: 2, color: numRegistros.length > 0 ? '#16a0ff' : 'error.main' }}
                        disabled={inProgress}
                        onClick={() => exportarArchivoAmortizaciones('PDF')}>
                        <FileDownloadOutlinedIcon sx={{ mr: 1 }} />Exportar PDF</Button>
                </Tooltip>
            </Box>
                <Dialog
                    key={'archivoExportado'}
                    open={openGeneracionArchivo}
                    // onClose={handleCloseGeneracionArchivo}
                    fullWidth={true}
                    maxWidth={'xs'}>
                    <DialogContent sx={{ m: 2, textAlign: 'center' }} id="dialogContent">
                        <DialogContentText>
                            {mensajeGeneracionArchivo}
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 800 }} aria-label="customized table">
                        <TableBody>
                            <TableRow>
                                <TableCell><b>Pago</b></TableCell>
                                <TableCell><b>Fecha</b></TableCell>
                                {dataSend.IdTipoFinanciamiento == TipoFinanciamiento['CreditoSimple'] &&
                                    <><TableCell><b>Capital</b></TableCell>
                                        <TableCell><b>Intereses</b></TableCell></>
                                }
                                {dataSend.IdTipoFinanciamiento == TipoFinanciamiento['ArrendamientoPuro'] &&
                                    <TableCell><b>Renta</b></TableCell>
                                }
                                <TableCell><b>Interés moratorio</b></TableCell>
                                <TableCell><b>IVA</b></TableCell>
                                <TableCell><b>Pago total</b></TableCell>
                                {dataSend.IdTipoFinanciamiento == TipoFinanciamiento['CreditoSimple'] &&
                                    <TableCell><b>Capital pendiente</b></TableCell>
                                }
                            </TableRow>
                            <TableRow sx={{ backgroundColor: '#bae3ff' }}>
                                <TableCell><b>Totales:</b></TableCell>
                                <TableCell></TableCell>
                                {dataSend.IdTipoFinanciamiento == TipoFinanciamiento['CreditoSimple'] &&
                                    <><TableCell><b>{getNumberToCurrency(totalesAmortizacionesPendientes.reduce((a: number, b: DatosAmortizaciones) => a + b.capitalApagar, 0))}</b></TableCell>
                                        <TableCell><b>{getNumberToCurrency(totalesAmortizacionesPendientes.reduce((a: number, b: DatosAmortizaciones) => a + (b.interesApagar ?? 0), 0))}</b></TableCell></>
                                }
                                {dataSend.IdTipoFinanciamiento == TipoFinanciamiento['ArrendamientoPuro'] &&
                                    <TableCell><b>{getNumberToCurrency(totalesAmortizacionesPendientes.reduce((a: number, b: DatosAmortizaciones) => a + ((b.capitalApagar ?? 0) + (b.interesApagar ?? 0)), 0))}</b></TableCell>
                                }
                                <TableCell><b>{getNumberToCurrency(totalesAmortizacionesPendientes.reduce((a: number, b: DatosAmortizaciones) => a + (b.interesMoratorioApagar ?? 0), 0))}</b></TableCell>
                                <TableCell><b>{getNumberToCurrency(totalesAmortizacionesPendientes.reduce((a: number, b: DatosAmortizaciones) => a + (b.ivaDeInteresesApagar ?? 0), 0))}</b></TableCell>
                                <TableCell><b>{getNumberToCurrency(totalesAmortizacionesPendientes.reduce((a: number, b: DatosAmortizaciones) => a + (b.pagoTotal ?? 0), 0))}</b></TableCell>
                                {dataSend.IdTipoFinanciamiento == TipoFinanciamiento['CreditoSimple'] &&
                                    <TableCell></TableCell>
                                }
                            </TableRow>
                            {datos}
                        </TableBody>
                    </Table>
                </TableContainer></>
        );
    }

    return (
        <><Typography variant="h6" color="#2E2D2E" sx={{ mt: 2 }}>Amortizaciones pendientes</Typography>
            <TablaAmortizacionesPendientes /></>
    );
}