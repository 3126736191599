import { IAmortizacionesAPagarRequest } from '../../../../models/IAmortizacionesAPagarRequest';
import { DatosAmortizacionesAPagar } from '../../../../models/IAmortizacionesAPagarResponse';
import { IOpcionesContratoAPRequest, IResultadoOpcionContratoAPRequest } from '../../../../models/IOpcionesContratoAPRequest';
import { DatosOpcionesContratoAP, DatosResultadoOpcionContratoAP } from '../../../../models/IOpcionesContratoAPResponse';
import { IGRPRequest, ISimularPagoRequest } from '../../../../models/ISimularPagoRequest';
import { DatosGRP, DatosSimularPago } from '../../../../models/ISimularPagoResponse';
import * as type from '../../../../system/types';
import * as actions from '../../../../system/types';

//#region AMORTIZACIONES A PAGAR
export function requestAmortizacionesAPagar(data: IAmortizacionesAPagarRequest): actions.requestAmortizacionesAPagarAction {
    return {
        type: type.AMORTIZACIONESAPAGAR_REQUEST,
        inProgress: true,
        data
    };
}

export function onAmortizacionesAPagarResponse(
    data: Array<DatosAmortizacionesAPagar>,
    message: string
): actions.onAmortizacionesAPagarResponseAction {
    return {
        type: type.AMORTIZACIONESAPAGAR_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onAmortizacionesAPagarFailed(
    data: any,
    message: string
): actions.getAmortizacionesAPagarFailedAction {
    return {
        type: type.AMORTIZACIONESAPAGAR_FAILED,
        message,
        inProgress: false
    };
}
//#endregion

//#region OPCIONES CONTRATO AP
export function requestOpcionesContratoAP(data: IOpcionesContratoAPRequest): actions.requestOpcionesContratoAPAction {
    return {
        type: type.OPCIONESCONTRATOAP_REQUEST,
        inProgress: true,
        data
    };
}

export function onOpcionesContratoAPResponse(
    data: Array<DatosOpcionesContratoAP>,
    message: string
): actions.onOpcionesContratoAPResponseAction {
    return {
        type: type.OPCIONESCONTRATOAP_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onOpcionesContratoAPFailed(
    data: any,
    message: string
): actions.getOpcionesContratoAPFailedAction {
    return {
        type: type.OPCIONESCONTRATOAP_FAILED,
        message,
        inProgress: false
    };
}
//#endregion

//#region RESULTADO OPCION CONTRATO AP
export function requestResultadoOpcionContratoAP(data: IResultadoOpcionContratoAPRequest): actions.requestResultadoOpcionContratoAPAction {
    return {
        type: type.RESULTADOOPCIONCONTRATOAP_REQUEST,
        inProgress: true,
        data
    };
}

export function onResultadoOpcionContratoAPResponse(
    data: Array<DatosResultadoOpcionContratoAP>,
    message: string
): actions.onResultadoOpcionContratoAPResponseAction {
    return {
        type: type.RESULTADOOPCIONCONTRATOAP_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onResultadoOpcionContratoAPFailed(
    data: any,
    message: string
): actions.getResultadoOpcionContratoAPFailedAction {
    return {
        type: type.RESULTADOOPCIONCONTRATOAP_FAILED,
        message,
        inProgress: false
    };
}
//#endregion

//#region SIMULAR PAGO
export function requestSimularPago(data: ISimularPagoRequest): actions.requestSimularPagoAction {
    return {
        type: type.SIMULARPAGO_REQUEST,
        inProgress: true,
        data
    };
}

export function onSimularPagoResponse(
    data: DatosSimularPago,
    message: string
): actions.onSimularPagoResponseAction {
    return {
        type: type.SIMULARPAGO_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onSimularPagoFailed(
    data: any,
    message: string
): actions.getSimularPagoFailedAction {
    return {
        type: type.SIMULARPAGO_FAILED,
        message,
        inProgress: false
    };
}
//#endregion

//#region GENERAR REFERENCIA DE PAGO
export function requestGenerarReferenciaPago(data: IGRPRequest): actions.requestGenerarReferenciaPagoAction {
    return {
        type: type.GENERARREFERENCIAPAGO_REQUEST,
        inProgress: true,
        data
    };
}

export function onGenerarReferenciaPagoResponse(
    data: DatosGRP,
    message: string
): actions.onGenerarReferenciaPagoResponseAction {
    return {
        type: type.GENERARREFERENCIAPAGO_RESPONSE,
        data,
        message,
        inProgress: false
    };
}

export function onGenerarReferenciaPagoFailed(
    data: any,
    message: string
): actions.getGenerarReferenciaPagoFailedAction {
    return {
        type: type.GENERARREFERENCIAPAGO_FAILED,
        message,
        inProgress: false
    };
}
//#endregion